import useCartQuery from './useCartQuery';
import notUndefined from '../utils/notUndefined';
import useActiveLicenceTypes from './useActiveLicenceTypes';
import type { LicenceType } from './useActiveLicenceTypes';

/**
 * Hook to return the set of unique licence types in a cart, in the correct order as
 * laid out by useActiveLicenceTypes.
 */
export default function useCartLicenceTypes(): LicenceType[] {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const licenceTypes = useActiveLicenceTypes();

    return licenceTypes
        .map((licenceType) => {
            if (
                cart?.licenceTiers.find(
                    (cartTier) =>
                        cartTier.tier.licenceType.id === licenceType.id,
                )
            ) {
                return licenceType;
            }
        })
        .filter(notUndefined);
}
