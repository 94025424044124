import type { EditorState } from 'draft-js';

type StyleRecord = {
    style: string;
    indeterminate: boolean;
};

function accumulateStyles(
    aggregateStyles: { style: string; characterCount: number }[],
    style: string,
): void {
    const existingIndex = aggregateStyles.findIndex(
        (aggregateStyle) => aggregateStyle.style === style,
    );
    if (existingIndex > -1) {
        aggregateStyles[existingIndex].characterCount += 1;
    } else {
        aggregateStyles.push({
            style,
            characterCount: 1,
        });
    }
}

export default function getInlineStylesForSelection(
    editorState: EditorState,
): StyleRecord[] {
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
        return [];
    }
    const contentState = editorState.getCurrentContent();
    const aggregateStyles: { style: string; characterCount: number }[] = [];
    let key = selection.getStartKey();
    let startOffset = selection.getStartOffset();
    const endKey = selection.getEndKey();
    const endOffset = selection.getEndOffset();
    let totalCharacterCount = 0;
    for (;;) {
        const lastRound = key == endKey;
        const block = contentState.getBlockForKey(key);
        const offsetEnd = lastRound ? endOffset : block.getLength();
        const characterList = block.getCharacterList();
        for (
            let offsetIndex = startOffset;
            offsetIndex < offsetEnd;
            offsetIndex++
        ) {
            characterList
                .get(offsetIndex)
                .getStyle()
                .forEach((style) => {
                    if (style) {
                        accumulateStyles(aggregateStyles, style);
                    }
                });
            totalCharacterCount += 1;
        }
        if (lastRound) break;
        key = contentState.getKeyAfter(key);
        startOffset = 0;
    }
    return aggregateStyles.map((aggregateStyle) => {
        return {
            style: aggregateStyle.style,
            indeterminate: aggregateStyle.characterCount < totalCharacterCount,
        };
    });
}
