import React from 'react';
import { LicenceTypeId } from '../gql/api-public';
import { sentryException } from '../utils/sentry';
import { A } from './InlineUnderlinedLink';
import { createSupportEmailUrl } from '../utils/urlHelper';
import { useDebouncedCartUpdateMutationContext } from '../hooks/useDebouncedCartUpdateMutation';
import useCartQuery from '../hooks/useCartQuery';
import useConfig from '../hooks/useConfig';
import { useActiveLicenceTypesAvailableViaCart } from '../hooks/useActiveLicenceTypes';
import { trackClick } from '../utils/googleEvents';
import { useErrorOverlayState, useGlobalState } from './GlobalRuntimeState';

export default function LicenceSelectorAdvertisingModal(): null {
    const config = useConfig();
    const activeLicenceTypes = useActiveLicenceTypesAvailableViaCart();
    const doDebouncedCartUpdate = useDebouncedCartUpdateMutationContext();
    const [, setShowErrorOverlay] = useErrorOverlayState();
    const [, setSiteOverlayState] = useGlobalState('siteOverlay');
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const previousLicenceTypeIds = React.useRef<string[] | null>(null);
    const [, setModalState] = useGlobalState('modal');

    /**
     * Check cart state changes. When we add Advertising (licence) and Desktop is
     * not added, show a modal that prompts customers to add Desktop...
     */
    React.useEffect(() => {
        if (previousLicenceTypeIds.current === null || !cart) {
            // Don't do this on the first refresh.
            return;
        }
        const currentLicenceTypeIds = cart.licenceTiers
            .map((lt) => lt.tier.licenceType.id)
            .sort();

        if (
            currentLicenceTypeIds.includes(LicenceTypeId.AD) &&
            !currentLicenceTypeIds.includes(LicenceTypeId.DESKTOP) &&
            !previousLicenceTypeIds.current.includes(LicenceTypeId.AD)
        ) {
            const addDesktopToCart = (): void => {
                const desktopLicenceType = activeLicenceTypes.find(
                    (loopLicenceType) =>
                        loopLicenceType.id === LicenceTypeId.DESKTOP,
                );
                if (!desktopLicenceType) {
                    throw Error(`Couldn't retrieve Desktop licence type!`);
                }
                doDebouncedCartUpdate({
                    cart,
                    config,
                    // We'll just add the first tier (a little hacky!)
                    tierToAdd: desktopLicenceType.tieredLicensing.filter(
                        (tieredLicence) => tieredLicence.isActive,
                    )[0].tiers[0],
                }).catch((error) => {
                    sentryException(error);
                    setShowErrorOverlay({
                        isShown: true,
                    });
                });
                setModalState({});
                setSiteOverlayState({});
                trackClick(
                    'add_desktop_licence_from_ad_licence_popup',
                    'add_desktop_licence_from_ad_licence_popup',
                );
            };

            setModalState({
                isVisible: true,
                primaryButtonCallback: addDesktopToCart,
                primaryButtonText: 'Add Desktop',
                message: (
                    <>
                        Our Advertising Licence does not come with font files.
                        You need a Desktop Licence as well. If you don’t already
                        own one, please{' '}
                        <A
                            href="#"
                            onClick={(event): void => {
                                addDesktopToCart();
                                event.preventDefault();
                            }}
                        >
                            add it
                        </A>{' '}
                        to your cart. For questions{' '}
                        <A href={createSupportEmailUrl(true)}>contact us</A>.
                    </>
                ),
            });
        }
    }, [cart?.licenceTiers]);

    React.useEffect(() => {
        if (!cart) {
            return;
        }
        previousLicenceTypeIds.current =
            cart.licenceTiers.map((lt) => lt.tier.licenceType.id).sort() || [];
    }, [cart?.licenceTiers]);

    return null;
}
