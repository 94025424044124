import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import SVG from 'react-inlinesvg';
import { VIEWPORT } from '../settings/Global';
import forcePreserveAspectRatio from '../utils/forcePreserveAspectRatio';
import { preventPrintPageBreak } from '../utils/stylesMixins';
import { TEST_ID } from '../settings/E2e';

const Container = styled.div`
    max-width: 100%;
`;

const Svg = styled(SVG)<{ $isCustomFont?: boolean }>`
    display: block;
    overflow: visible;
    width: 100%;
    ${preventPrintPageBreak};

    /* Using both color and fill as some of the SVGs respond to 'fill' and some to 'color' */
    fill: var(--svgColor, var(--foregroundColor));
    color: var(--svgColor, var(--foregroundColor));

    &:hover,
    &:focus,
    &:active {
        fill: var(--svgHoverColor, var(--foregroundColorMix4));
        color: var(--svgHoverColor, var(--foregroundColorMix4));
    }

    ${({ $isCustomFont }): ReturnType<typeof css> =>
        $isCustomFont
            ? css`
                  max-height: var(--fontSizeFluid6);

                  @media screen and (max-width: ${VIEWPORT.DESKTOP_LARGE}px) {
                      max-height: var(--fontSizeFluid6_5);
                  }

                  @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
                      max-height: var(--fontSizeFluid8);
                  }

                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      max-height: var(--fontSizeFluid6_5);
                  }

                  @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
                      max-height: var(--fontSizeFluid5_5);
                  }
              `
            : css`
                  max-height: var(--fontSizeFluid6);

                  @media screen and (max-width: ${VIEWPORT.DESKTOP_LARGE}px) {
                      max-height: var(--fontSizeFluid6_5);
                  }

                  @media screen and (max-width: ${VIEWPORT.DESKTOP}px) {
                      max-height: var(--fontSizeFluid7);
                  }

                  @media screen and (max-width: ${VIEWPORT.NETBOOK_SMALL}px) {
                      max-height: var(--fontSizeFluid8);
                  }

                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      max-height: var(--fontSizeFluid6);
                  }

                  @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
                      max-height: var(--fontSizeFluid5_5);
                  }
              `};
`;

function FontsMenuSvgLink({
    svg,
    title,
    to,
    isCustomFont,
    testId,
}: {
    svg: string;
    title: string;
    to: string;
    isCustomFont?: boolean;
    testId?: TEST_ID;
}): React.ReactElement {
    return (
        <Container>
            <Link to={to} aria-label={title} data-cy={testId}>
                <Svg
                    src={svg}
                    $isCustomFont={isCustomFont}
                    description={title}
                    uniquifyIDs={true}
                    preProcessor={(svg): string =>
                        forcePreserveAspectRatio(svg)
                    }
                />
            </Link>
        </Container>
    );
}

export default React.memo(FontsMenuSvgLink);
