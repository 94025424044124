import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { VIEWPORT } from '../settings/Global';
import {
    FontStylesContainer,
    FontStylesItem,
    FontStylesTitle,
    FontStylesWeight,
} from './FontStylesElements';
import { HeadingExtraLarge } from './Heading';
import { useCustomFont } from './PageContext';
import type { CustomFont } from './PageContext';
import notDuplicate from '../utils/notDuplicate';
import sortByName from '../utils/sortByName';

type CustomFontStyle = CustomFont['styles'][number];

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing9);
`;

const FontStylesGroup = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing6);
    align-items: baseline;
`;

const FontStylesGroupTitle = styled(HeadingExtraLarge)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed8);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        font-size: 42px; /* There isn't a custom font size for the size we want */
    }
`;

const FontStylesGroupSvg = styled(SVG)`
    display: block;
    height: 0.7em;
    width: auto;
    overflow: visible;
`;

const FontStylesTitleSvg = styled(SVG)`
    display: inline-block;

    /*
     * SVGs are cropped from baseline-to-cap-height, so they shouldn't be
     * rendered at full-font-size-height.
     *
     * We could be specific if we knew the font metrics (cap-height
     * specifically), but we don't so we're just going to use an informed
     * average value.
     */
    height: 0.7em;
    width: auto;
    fill: var(--foregroundColor);
    overflow: visible;
`;

function sortStylesFn(a: CustomFontStyle, b: CustomFontStyle): number {
    return (
        // Sort by weight first (ascending)
        a.weight - b.weight ||
        // Then by name (ascending)
        sortByName(a, b)
    );
}

function sortFamiliesFn(
    a: CustomFontStyle['family'],
    b: CustomFontStyle['family'],
): number {
    // Sort 'null' to the top
    if (a === null && b === null) return 0;
    if (a === null) return -1;
    if (b === null) return 1;

    // Then by sort order (ascending)
    return a.sortOrder - b.sortOrder;
}

function CustomFontsPostStyles(): React.ReactElement {
    const customFont = useCustomFont();
    const { styles, families } = customFont;

    const familyIds = styles
        .map((style): CustomFontStyle['family'] => style.family)
        .sort(sortFamiliesFn)
        .map((family): string | null => family && family.id)
        // We actually still want one 'null' entry to account for any styles without a family
        // .filter(notNull)
        .filter(notDuplicate);

    return (
        <Container>
            {familyIds.map((familyId): React.ReactElement => {
                const family = families.find(
                    ({ id }): boolean => id === familyId,
                );
                return (
                    <FontStylesGroup key={familyId || ''}>
                        {family && family.svg && (
                            <FontStylesGroupTitle>
                                <FontStylesGroupSvg
                                    src={family.svg}
                                    title={undefined}
                                    description={undefined}
                                    uniquifyIDs={true}
                                />
                            </FontStylesGroupTitle>
                        )}
                        <FontStylesContainer>
                            {styles
                                .filter(
                                    (style): boolean =>
                                        // Note that this deliberately still caters for styles without a family
                                        (style.family && style.family.id) ===
                                        familyId,
                                )
                                .sort(sortStylesFn)
                                .map((style): React.ReactElement | null =>
                                    style.svg ? (
                                        <FontStylesItem key={style.id}>
                                            <FontStylesTitle>
                                                <FontStylesTitleSvg
                                                    src={style.svg}
                                                    title={undefined}
                                                    description={`${
                                                        style.family
                                                            ? style.family.name
                                                            : customFont.name
                                                    } ${style.name}`}
                                                    uniquifyIDs={true}
                                                />
                                            </FontStylesTitle>
                                            <FontStylesWeight>
                                                {style.weight}
                                            </FontStylesWeight>
                                        </FontStylesItem>
                                    ) : null,
                                )}
                        </FontStylesContainer>
                    </FontStylesGroup>
                );
            })}
        </Container>
    );
}

export default React.memo(CustomFontsPostStyles);
