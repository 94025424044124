import React from 'react';
import styled from 'styled-components';
import InUsePostRelated from '../components/InUsePostRelated';
import InUsePostHeader from '../components/InUsePostHeader';
import notNull from '../utils/notNull';
import type {
    InUseSimpleImageSectionBlock,
    InUsePostFontFamily,
} from '../union-types/inUsePost';
import { StreamFieldImageSection } from '../components/StreamFieldImageSection';
import FeaturedFonts from '../components/FeaturedFonts';
import useAllFonts, { FontFamilyGroup } from '../hooks/useAllFonts';
import notUndefined from '../utils/notUndefined';
import notDuplicate from '../utils/notDuplicate';
import isWhitelisted from '../utils/isWhitelisted';
import { navigate } from 'gatsby';
import { create404NotFoundUrl } from '../utils/urlHelper';
import useIsUserTestingSite from '../hooks/useIsUserTestingSite';
import useConfig from '../hooks/useConfig';
import isBrowser from '../utils/isBrowser';
import usePreviewOrPageQueryInUsePost from '../hooks/usePreviewOrPageQueryInUsePost';

export { Head } from '../components/Head';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    padding-top: 50vh;
`;

const Spacer = styled.div`
    min-height: var(--gridColumnGap);
`;

const Inner = styled.div``;

const TYPENAMES_IMAGE_ROW = [
    'CMS_SSR_SimpleImageSectionBlock',
    'SimpleImageSectionBlock',
];

function InUsePostPage(): React.ReactElement | null {
    const inUsePost = usePreviewOrPageQueryInUsePost();
    const allFontFamilyGroups = useAllFonts();
    const isUserTestingSite = useIsUserTestingSite();
    const config = useConfig();
    const fontFamilies = React.useMemo((): InUsePostFontFamily[] => {
        return inUsePost.fontFamilies
            ? (inUsePost.fontFamilies as InUsePostFontFamily[])
            : [];
    }, [inUsePost]);

    const fontFamilyGroups: FontFamilyGroup[] =
        React.useMemo((): FontFamilyGroup[] => {
            return fontFamilies
                .map((fontFamily): FontFamilyGroup | undefined => {
                    return allFontFamilyGroups.find(
                        (fontFamilyGroup) =>
                            fontFamily &&
                            fontFamilyGroup.slug ===
                                fontFamily.fontFamilyGroup.slug,
                    );
                })
                .filter(notUndefined)
                .filter(notDuplicate);
        }, [allFontFamilyGroups, fontFamilies]);

    const fontFamilyIdFilter: string[] = React.useMemo(
        (): string[] =>
            fontFamilies.map((ff): string => ff.id).filter(notDuplicate),
        [fontFamilies],
    );

    // Limit the "in use" posts for the user testing site,
    // so that we're not exposing any pre-release fonts.
    if (isBrowser() && isUserTestingSite) {
        const cantBeShown: boolean =
            fontFamilyGroups.length === 0 ||
            fontFamilyGroups.some((fontFamilyGroup: FontFamilyGroup) => {
                return !isWhitelisted(
                    config.userTestingFontFamilyGroupWhitelist,
                    fontFamilyGroup.id,
                );
            });
        if (cantBeShown) {
            navigate(create404NotFoundUrl());
            return null;
        }
    }

    return (
        <>
            <Container>
                <InUsePostHeader />
                <Inner>
                    {inUsePost.body
                        ?.filter(notNull)
                        .map((block, index, blockArray): React.ReactElement => {
                            const nextBlock = blockArray[index + 1];
                            const previousBlock = blockArray[index - 1];

                            const nextTypename = nextBlock
                                ? nextBlock.__typename
                                : undefined;
                            const previousTypename = previousBlock
                                ? previousBlock.__typename
                                : undefined;

                            // Whether the previous block is a SimpleImageSectionBlock...
                            const precedingImageSectionBlock:
                                | InUseSimpleImageSectionBlock
                                | undefined =
                                previousTypename &&
                                TYPENAMES_IMAGE_ROW.includes(previousTypename)
                                    ? (previousBlock as InUseSimpleImageSectionBlock)
                                    : undefined;

                            // Whether the next block is a SimpleImageSectionBlock...
                            const succeedingImageSectionBlock:
                                | InUseSimpleImageSectionBlock
                                | undefined =
                                nextTypename &&
                                TYPENAMES_IMAGE_ROW.includes(nextTypename)
                                    ? (nextBlock as InUseSimpleImageSectionBlock)
                                    : undefined;

                            const nextBlockIsSameType =
                                nextTypename === block.__typename;

                            return (
                                <React.Fragment key={`in-use-item-${index}`}>
                                    <StreamFieldImageSection
                                        block={
                                            block as InUseSimpleImageSectionBlock
                                        }
                                        precedingImageSection={
                                            precedingImageSectionBlock
                                        }
                                        succeedingImageSection={
                                            succeedingImageSectionBlock
                                        }
                                        lazyLoad={index > 0}
                                    />
                                    {nextTypename && !nextBlockIsSameType ? (
                                        <Spacer />
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                    {fontFamilyGroups.length ? (
                        <FeaturedFonts
                            fontFamilyGroups={fontFamilyGroups}
                            fontFamilyIdFilter={fontFamilyIdFilter}
                            slimline
                        />
                    ) : null}
                </Inner>
            </Container>
            <InUsePostRelated />
        </>
    );
}

export default React.memo(InUsePostPage);
