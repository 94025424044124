import React from 'react';
import { useInView, InViewHookResponse } from 'react-intersection-observer';
import { useGlobalState } from '../components/GlobalRuntimeState';

type InViewRef = InViewHookResponse[0];
type CreateIntersectionObserverRef = (colors?: {
    background: string;
    foreground: string;
    hoverBackground?: string;
    hoverForeground?: string;
}) => InViewRef;

export default function (): [CreateIntersectionObserverRef] {
    const [, setNavColors] = useGlobalState('navColors');

    const createIntersectionObserverRef = React.useCallback(
        (colors?: {
            background: string;
            foreground: string;
            hoverBackground?: string;
            hoverForeground?: string;
        }): InViewRef => {
            const [sectionRef, inView] = useInView({
                rootMargin: '-4% 0px -96% 0px',
            });

            React.useEffect((): void => {
                if (!inView) {
                    return;
                }
                setNavColors(colors);
            }, [inView]);
            return sectionRef;
        },
        [],
    );

    React.useEffect((): (() => void) => {
        return (): void => {
            setNavColors(undefined);
        };
    }, [setNavColors]);

    return [createIntersectionObserverRef];
}
