import React from 'react';
import styled from 'styled-components';
import { ReactComponent as _StripeLogo } from '../assets/icons/stripe-payments.svg';
import { HeadingSmall } from './Heading';
import {
    SectionLabel,
    SectionLabelInner,
    SubHeading,
} from './CheckoutFormElements';
import InlineUnderlinedLink from './InlineUnderlinedLink';
import { createRefundPaymentPolicyUrl } from '../utils/urlHelper';

const StripeLogo = styled(_StripeLogo)`
    fill: var(--foregroundColorMix5);
`;

const Link = styled(InlineUnderlinedLink)`
    color: inherit;
`;

export default function CheckoutFormPaymentSectionLabel(): React.ReactElement {
    return (
        <SectionLabel>
            <SectionLabelInner>
                <HeadingSmall>Payment</HeadingSmall>
                <SubHeading>
                    All transactions are
                    <br />
                    {` `}
                    secure and encrypted.
                    <br />
                    <Link to={createRefundPaymentPolicyUrl()}>Read more</Link>
                </SubHeading>
                <StripeLogo />
            </SectionLabelInner>
        </SectionLabel>
    );
}
