import { DefaultTheme } from 'styled-components';
import getLuminance from 'polished/lib/color/getLuminance';
import getContrast from 'polished/lib/color/getContrast';

export default function getColorThemeFromBaseColors(
    {
        foregroundColor,
        backgroundColor,
        highlightColor,
        foregroundColorAlt,
        backgroundColorAlt,
        highlightColorAlt,
    }: {
        foregroundColor: string;
        backgroundColor: string;
        highlightColor: string;
        foregroundColorAlt?: string | null;
        backgroundColorAlt?: string | null;
        highlightColorAlt?: string | null;
    },
    useAlternateColors?: boolean,
): DefaultTheme {
    const fgColor =
        useAlternateColors && foregroundColorAlt
            ? foregroundColorAlt
            : foregroundColor;
    const bgColor =
        useAlternateColors && backgroundColorAlt
            ? backgroundColorAlt
            : backgroundColor;
    const hlColor =
        useAlternateColors && highlightColorAlt
            ? highlightColorAlt
            : highlightColor;
    return {
        foregroundColor: fgColor,
        backgroundColor: bgColor,
        highlightColor: hlColor,
        backgroundLuminance: getLuminance(bgColor),
        foregroundLuminance: getLuminance(fgColor),
        contrastRatio: getContrast(bgColor, fgColor),
    };
}
