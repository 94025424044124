import React from 'react';
import styled from 'styled-components';
import InlineUnderlinedLink from './InlineUnderlinedLink';
import { VIEWPORT } from '../settings/Global';
import {
    createCollectionUrl,
    createFontFamilyUrl,
    createCustomFontUrl,
    createCustomFontsMenuUrl,
    createFontsInUseUrl,
    createAwardsAndAccoladesUrl,
    createFontsMenuUrl,
} from '../utils/urlHelper';

const Container = styled.div``;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--spacing4) var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        line-height: var(--lineHeightBody2);
        grid-row-gap: var(--spacing3);
    }
`;

const Column = styled.div`
    &:nth-child(1) {
        grid-column: 3 / span 7;
    }

    &:nth-child(2) {
        grid-column: 3 / span 4;
    }

    &:nth-child(3) {
        grid-column: 7 / span 4;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:nth-child(1) {
            grid-column: 1 / span 6;
        }

        &:nth-child(2) {
            grid-column: 1 / span 3;
        }

        &:nth-child(3) {
            grid-column: 4 / span 3;
        }
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            grid-column: 1 / span 6;
        }
    }
`;

const Heading = styled.h4`
    font-size: 40px; /* Unique sizing to the global sizes in CSSVariables */
    font-weight: normal;
    line-height: var(--lineHeightHeading1);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFluid5);
    }
`;

function AboutContentBlock(): React.ReactElement {
    return (
        <Container>
            <Row>
                <Column>
                    <Heading>
                        Typefaces reflect a culture’s priorities and
                        aspirations. This is why we keep designing new typefaces
                        – the job isn’t done.
                    </Heading>
                </Column>
                <Column>
                    <p>
                        Klim Type Foundry (Klim) was founded by Kris Sowersby in
                        2005 and is based in Te Whanganui-a-Tara/Wellington,
                        Aotearoa/New Zealand.
                    </p>
                    <br />
                    <p>
                        Our foundational ethos is “a thing well made”. Our
                        typefaces combine historical knowledge with rigorous
                        contemporary craft. We believe the alphabet is a concept
                        made concrete through written and designed letterforms —
                        the alphabet is not defined by a single typeface but
                        expressed through all of them.
                    </p>
                    <br />
                    <p>
                        Our{' '}
                        <InlineUnderlinedLink to={createFontsMenuUrl()}>
                            retail catalogue
                        </InlineUnderlinedLink>{' '}
                        features fonts suitable for a complete range of use,
                        from small and functional to large and decorative. They
                        can be licensed for use across physical and digital
                        applications. Our{' '}
                        <InlineUnderlinedLink to={createFontsInUseUrl()}>
                            in use
                        </InlineUnderlinedLink>{' '}
                        section showcases the many, varied examples of our fonts
                        used by international brands, institutions and
                        businesses of all sizes.
                    </p>
                </Column>
                <Column>
                    <p>
                        We have designed{' '}
                        <InlineUnderlinedLink to={createCustomFontsMenuUrl()}>
                            custom fonts
                        </InlineUnderlinedLink>{' '}
                        for international clients such as{' '}
                        <InlineUnderlinedLink
                            to={createCollectionUrl('financier')}
                        >
                            The Financial Times
                        </InlineUnderlinedLink>
                        ,{' '}
                        <InlineUnderlinedLink
                            to={createCustomFontUrl('paypal-sans')}
                        >
                            PayPal
                        </InlineUnderlinedLink>{' '}
                        and{' '}
                        <InlineUnderlinedLink
                            to={createFontFamilyUrl('geograph')}
                        >
                            National Geographic
                        </InlineUnderlinedLink>
                        . Our fonts are included in Apple’s operating system,
                        from macOS Catalina 10.15.4. Closer to home we have
                        created custom fonts for{' '}
                        <InlineUnderlinedLink
                            to={createCustomFontUrl('serrano')}
                        >
                            The Bank of New Zealand
                        </InlineUnderlinedLink>
                        ,{' '}
                        <InlineUnderlinedLink
                            to={createCustomFontUrl('story-sans')}
                        >
                            Trade Me
                        </InlineUnderlinedLink>{' '}
                        and{' '}
                        <InlineUnderlinedLink
                            to={createCustomFontUrl('pure-pakati')}
                        >
                            Tourism New Zealand
                        </InlineUnderlinedLink>
                        .
                    </p>
                    <br />
                    <p>
                        Our work has won numerous{' '}
                        <InlineUnderlinedLink
                            to={createAwardsAndAccoladesUrl()}
                        >
                            awards
                        </InlineUnderlinedLink>
                        . Most recently the{' '}
                        <InlineUnderlinedLink
                            to={createCollectionUrl('epicene')}
                        >
                            Epicene Collection
                        </InlineUnderlinedLink>{' '}
                        received a Gold Cube from the ADC and a Certificate of
                        Typographic Excellence from the TDC.
                    </p>
                </Column>
            </Row>
        </Container>
    );
}

export default React.memo(AboutContentBlock);
