/**
 * Expands custom OpenType tags into proper CSS.
 * @param html
 * @param opentypeTagNames
 */
export default function processWagtailRichText(
    html: string,
    opentypeTagNames: string[],
): string {
    let output = html.trim();
    opentypeTagNames.forEach((tagName) => {
        output = output.replace(
            new RegExp(`<${tagName}>`, 'gi'),
            `<span style="font-feature-settings: '${tagName}' on">`,
        );
        output = output.replace(new RegExp(`</${tagName}>`, 'gi'), `</span>`);
    });
    return output;
}
