import React from 'react';
import styled, { css } from 'styled-components';
import { HeadingSmall, HeadingMedium } from './Heading';
import { VIEWPORT } from '../settings/Global';
import { ParagraphMarginStyles, ParagraphStyles } from './Paragraph';
import { styles as linkStyles } from './InlineUnderlinedLink';
import { useStaticFontMetricOffsets } from './PageContext';
import type { Eula } from './PageContext';

/**
 * Styles for the licence HTML that comes from the server-side API.
 */
export const LicenceContent = styled.div`
    line-height: var(--lineHeightBody2);

    p {
        ${ParagraphStyles};
        ${ParagraphMarginStyles};
    }

    a {
        ${linkStyles};
    }

    b {
        font-weight: bold;
    }
`;

export interface SubSection {
    id?: string;
    heading?: string;
    content: React.ReactNode;
}

export interface Section {
    id: string;
    heading: string;
    subSections: SubSection[];
}

const Container = styled.div`
    border-top: 1px solid var(--foregroundColorMix6);
    margin: var(--spacing7) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin: var(--spacing6) 0;
    }
`;

const Heading = styled(HeadingMedium)`
    position: relative; /* to position the SectionNumber */
    margin: var(--spacing7) 0 var(--spacing6) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin: var(--spacing6) 0 var(--spacing5) 0;
    }
`;

const SectionNumber = styled.span`
    position: absolute;
    top: 0;
    right: calc(100% + var(--gridColumnGap));

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        position: relative;
        right: 0;
        margin-right: 8px;
    }
`;

const numberStyles = css`
    font-size: inherit;
    font-weight: bold;
    content: var(--sub-section-number);
    position: absolute;
    top: 0;
    right: calc(100% + var(--gridColumnGap));
    display: block;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        position: initial;
        top: initial;
        right: initial;
        display: inline-block;
        margin-right: 8px;
        ${ParagraphMarginStyles};
    }
`;

const SubHeading = styled(HeadingSmall)`
    font-size: var(--fontSizeFixed3);
    ${ParagraphMarginStyles};

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: 0;
    }

    &::before {
        ${numberStyles};
    }
`;

const Content = styled(LicenceContent)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed3);
    }

    &:first-child::before {
        ${numberStyles};
    }
`;

const SubSection = styled.div`
    position: relative; /* to position the SubSectionNumber */
`;

function getSubSectionLetter(index: number): string {
    return String.fromCharCode(index + 96);
}

export default function LicenceSection({
    section,
}: {
    section: Eula['sections'][number];
}): React.ReactElement {
    const fontMetricOffsets = useStaticFontMetricOffsets();
    return (
        <Container key={section.id} id={section.id}>
            <Heading as="h2" $metricOffsets={fontMetricOffsets}>
                <SectionNumber>{section.num}.</SectionNumber>
                {section.title}
            </Heading>
            <div>
                {section.subSections.map((subSection): React.ReactElement => {
                    const subSectionLetter = getSubSectionLetter(
                        subSection.num,
                    );
                    const subSectionNumber = `${section.num}${subSectionLetter}.`;
                    return (
                        <SubSection
                            key={subSectionNumber}
                            style={
                                {
                                    // Note: camelCase variable name was being renamed to kebab-case,
                                    // so we're opting for kebab-case to begin with.
                                    '--sub-section-number':
                                        JSON.stringify(subSectionNumber),
                                } as React.CSSProperties
                            }
                        >
                            {subSection.title && (
                                <SubHeading $metricOffsets={fontMetricOffsets}>
                                    {subSection.title}
                                </SubHeading>
                            )}
                            <Content
                                dangerouslySetInnerHTML={{
                                    __html: subSection.contentHtml,
                                }}
                            />
                        </SubSection>
                    );
                })}
            </div>
        </Container>
    );
}
