export default function subscribeScrollRequestAnimationFrame(
    cb: () => void,
    scrollElement?: HTMLElement | null,
): () => void {
    let raf: number | null = null;
    const scrollTarget = scrollElement || window;

    const onScroll = (): void => {
        raf =
            raf ||
            window.requestAnimationFrame((): void => {
                cb();
                raf = null;
            });
    };

    // Invoke once on initialization.
    cb();

    scrollTarget.addEventListener('scroll', onScroll, false);

    const unsubscribe = (): void => {
        if (raf) cancelAnimationFrame(raf);
        scrollTarget.removeEventListener('scroll', onScroll);
    };

    return unsubscribe;
}
