import React from 'react';
import styled from 'styled-components';
import { A as InlineUnderlinedLink } from './InlineUnderlinedLink';
import { VIEWPORT } from '../settings/Global';
import DefinitionList from '../components/DefinitionList';
import _LazyImage from './LazyImage';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import { createSalesEmailUrl } from '../utils/urlHelper';

// NOTE: These images are currently manually-uploaded to S3 from the src/assets/images folder
const aboutFooterAgi = 'manual/logos/agi.png';
const aboutFooterDinz = 'manual/logos/dinz.png';
const aboutFooterGroup = 'manual/logos/group.png';
import opcLogo from '../assets/images/about-footer/opc-logo.svg';
import kahurangiAward from '../assets/images/about-footer/kahurangi-2022.svg';

const Container = styled.div`
    display: grid;
    padding: var(--spacing8) 0 var(--spacing9) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding: var(--spacing5) 0 var(--spacing9) 0;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding: var(--spacing4) 0 var(--spacing1) 0;
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    line-height: var(--lineHeightBody1);
    grid-gap: var(--spacing8) var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing3);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        line-height: var(--lineHeightBody2);
        grid-row-gap: var(--spacing3);
    }
`;

const InnerColumn = styled.div`
    &[data-column='image'] {
        grid-column: 1 / span 2;
    }

    &[data-column='date-info'] {
        grid-column: 3 / span 3;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        &[data-column='date-info'] {
            grid-column: 3 / span 4;
        }
    }
`;

const Column = styled.div`
    dl {
        grid-template-columns: repeat(8, 1fr);

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-template-columns: var(--gridTemplateColumnsDefault);
        }
    }

    dt {
        margin: var(--spacing2) 0;
        grid-column: 1 / span 3;
        color: var(--foregroundColor);
    }

    dd {
        margin: var(--spacing2) 0;
        grid-column: 4 / span 5;
        color: var(--foregroundColorMix4);
    }

    &[data-column='name'] {
        grid-column: 1 / span 1;
        padding: 0 0 var(--spacing3) 0;
    }

    &[data-column='first'],
    &[data-column='image-section'] {
        grid-column: 3 / span 3;
    }

    &[data-column='second'] {
        grid-column: 6 / span 3;
    }

    &[data-column='third'] {
        grid-column: 9 / span 3;
    }

    &[data-column='content'] {
        grid-column: 6 / span 5;
    }

    &[data-column='table-list'] {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        dd {
            grid-column: 4 / span 3;
        }

        &[data-column='name'] {
            grid-column: 1 / span 1;
            padding: 0 0 var(--spacing3) 0;
        }

        &[data-column='first'],
        &[data-column='third'],
        &[data-column='image-section'] {
            grid-column: 1 / span 3;
        }

        &[data-column='third'],
        &[data-column='table-list'] {
            padding-bottom: var(--spacing5);
        }

        &[data-column='second'],
        &[data-column='content'] {
            grid-column: 4 / span 3;
        }

        &[data-column='table-list'] {
            grid-column: 1 / span 6;
        }
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        &[data-column='name'] {
            grid-column: 1 / span 1;
            padding: var(--spacing7) 0 var(--spacing3) 0;
        }

        &[data-column='third'],
        &[data-column='table-list'] {
            padding-bottom: 0;
        }

        &[data-column='first'],
        &[data-column='image-section'],
        &[data-column='second'],
        &[data-column='third'],
        &[data-column='content'],
        &[data-column='table-list'] {
            grid-column: 1 / span 6;
        }
    }
`;
const Heading = styled.h5`
    padding: 0 0 var(--spacing3) 0;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding-bottom: 0;
    }
`;

const SubText = styled.div`
    color: var(--foregroundColorMix4);
`;

const ImageContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: var(--spacing1) var(--gridColumnGap);
`;

const LazyImage = styled(_LazyImage)`
    width: 100%;
`;

const ImageColumn = styled.div`
    grid-column: 1 / span 4;

    &[data-size='smaller'] {
        grid-column: 1 / span 2;
    }

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        &[data-size='smaller'] {
            grid-column: 3 / span 2;
        }
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &[data-size='smaller'] {
            grid-column: 1 / span 2;
        }
    }
`;

interface MembershipContentProps {
    dataColumnImage: string;
    dataColumnInfo: string;
    url: string;
    image: string;
    heading: string;
    subText: string;
}

function MembershipContent({
    url,
    dataColumnImage,
    dataColumnInfo,
    image,
    heading,
    subText,
}: MembershipContentProps): React.ReactElement {
    return (
        <ImageContainer>
            <InnerColumn data-column={dataColumnImage}>
                <a href={url}>
                    <ImageWithPlaceholder
                        src={image}
                        aspectRatio={1}
                        lazyLoad
                        fetchPriority="low"
                    />
                </a>
            </InnerColumn>
            <InnerColumn data-column={dataColumnInfo}>
                <p>{heading}</p>
                <SubText>{subText}</SubText>
            </InnerColumn>
        </ImageContainer>
    );
}

export const membershipsGroups = [
    {
        url: 'https://designersinstitute.nz/',
        dataColumn: 'first',
        dataColumnImage: 'image',
        dataColumnInfo: 'date-info',
        image: aboutFooterDinz,
        heading: 'DINZ Fellow',
        subText: 'Member since 2005',
    },
    {
        url: 'http://a-g-i.org/',
        dataColumn: 'second',
        dataColumnImage: 'image',
        dataColumnInfo: 'date-info',
        image: aboutFooterAgi,
        heading: 'Alliance Graphique Internationale',
        subText: 'Member since 2015',
    },
    {
        url: 'https://www.tdc.org/',
        dataColumn: 'third',
        dataColumnImage: 'image',
        dataColumnInfo: 'date-info',
        image: aboutFooterGroup,
        heading: 'Type Directors Club',
        subText: 'Member since 2016',
    },
];

export const sponsorshipLinkGroups = [
    {
        name: 'Typographics',
        type: 'New York City, USA 2016–2020',
        url: 'https://typographics.com/',
    },
    {
        name: 'ATypI',
        type: 'Warsawa, Poland 2016, Montréal, Canada 2017, Antwerp, Belgium 2018',
        url: 'http://www.atypi.org',
    },
    {
        name: 'TypeCon',
        type: 'Seattle 2016, Boston 2017, Portland 2018',
        url: 'http://www.typecon.com',
    },
    {
        name: 'Kerning',
        type: 'Faenza, Italy 2016–2018',
        url: 'http://2019.kerning.it',
    },
];

function AboutFooter(): React.ReactElement {
    return (
        <Container>
            <Row>
                <Column data-column="name">Memberships</Column>
                {membershipsGroups.map(
                    ({
                        url,
                        dataColumn,
                        heading,
                        dataColumnInfo,
                        dataColumnImage,
                        image,
                        subText,
                    }): React.ReactElement => (
                        <Column key={String(heading)} data-column={dataColumn}>
                            <MembershipContent
                                url={url}
                                dataColumnImage={dataColumnImage}
                                dataColumnInfo={dataColumnInfo}
                                image={image}
                                heading={heading}
                                subText={subText}
                            />
                        </Column>
                    ),
                )}

                <Column data-column="name">Sponsorships</Column>
                <Column data-column="table-list">
                    {sponsorshipLinkGroups.map(
                        ({ name, type, url }): React.ReactElement => (
                            <DefinitionList
                                hasTopSpace
                                key={String(name)}
                                name={<a href={url}>{name}</a>}
                                type={type}
                            />
                        ),
                    )}
                </Column>

                <Column data-column="name">Community</Column>
                <Column data-column="image-section">
                    <ImageContainer>
                        <ImageColumn>
                            <a href="https://www.onepercentcollective.org/">
                                <LazyImage
                                    src={opcLogo}
                                    alt=""
                                    loading="lazy"
                                />
                            </a>
                        </ImageColumn>
                    </ImageContainer>
                </Column>
                <Column data-column="content">
                    <p>
                        Klim are proud to be Hero Sponsors of{' '}
                        <InlineUnderlinedLink href="https://www.onepercentcollective.org/">
                            One Percent Collective
                        </InlineUnderlinedLink>{' '}
                        (OPC) and donate one percent of all net sales to the{' '}
                        <InlineUnderlinedLink href="https://www.onepercentcollective.org/charity-partnerships">
                            Charity Organisations
                        </InlineUnderlinedLink>{' '}
                        that OPC support.
                    </p>
                </Column>

                <Column data-column="image-section">
                    <ImageContainer>
                        <ImageColumn data-size="smaller">
                            <a href="https://www.treesthatcount.co.nz">
                                <LazyImage
                                    src={kahurangiAward}
                                    alt=""
                                    loading="lazy"
                                />
                            </a>
                        </ImageColumn>
                    </ImageContainer>
                </Column>
                <Column data-column="content">
                    <p>
                        Klim holds a Committed to Climate Mark for supporting
                        efforts to mitigate the effects of climate change by
                        funding native trees through the New Zealand-based
                        initiative{' '}
                        <InlineUnderlinedLink href="https://www.treesthatcount.co.nz">
                            Trees That Count/Te Rahi O Tāne.
                        </InlineUnderlinedLink>
                    </p>
                </Column>

                <Column data-column="image-section">
                    <Heading>Charity and not-for-profit discounts</Heading>
                </Column>
                <Column data-column="content">
                    <p>
                        We offer discounts on font licensing to all registered
                        NZ-based charities. To inquire contact us at {''}
                        <InlineUnderlinedLink href={createSalesEmailUrl(true)}>
                            {createSalesEmailUrl()}
                        </InlineUnderlinedLink>
                        .
                    </p>
                </Column>
            </Row>
        </Container>
    );
}

export default React.memo(AboutFooter);
