import { useQuery } from '@apollo/client';
import type { QueryResult } from '@apollo/client';
import { publicConfigQuery } from '../utils/runtimeQueries';
import type { PublicConfigQueryQuery } from '../gql/api-public';

export default function usePublicConfigQuery(): QueryResult<PublicConfigQueryQuery> {
    return useQuery<PublicConfigQueryQuery>(publicConfigQuery, {
        ssr: false,
        fetchPolicy: 'no-cache',
    });
}
