import React from 'react';
import { useCustomFont } from './PageContext';
import FontIntroduction from './FontIntroduction';
import FontInformation from './FontInformation';
import CustomFontsPostCredits from './CustomFontsPostCredits';

function CustomFontsPostInformation(): React.ReactElement {
    const { informationIntro, designInformationPage } = useCustomFont();

    return (
        <FontInformation
            intro={
                informationIntro ? (
                    <FontIntroduction
                        informationIntro={informationIntro}
                        blogPostSlug={
                            designInformationPage && designInformationPage.slug
                        }
                    />
                ) : null
            }
            content={<CustomFontsPostCredits />}
        />
    );
}

export default CustomFontsPostInformation;
