export enum FontFeatureSetting {
    /*
     * Enum values must be valid 'font-feature-settings' values
     * https://developer.mozilla.org/en/docs/Web/CSS/font-feature-settings
     */
    kern = 'kern',
    liga = 'liga',
    clig = 'clig',
    calt = 'calt',

    smcp = 'smcp',
    c2sc = 'c2sc',
    dlig = 'dlig',
    swsh = 'swsh',
    lnum = 'lnum',
    onum = 'onum',
    pnum = 'pnum',
    tnum = 'tnum',
    zero = 'zero',
    frac = 'frac',
    sups = 'sups',
    sinf = 'sinf',
    ordn = 'ordn',
    case = 'case',
    ss01 = 'ss01',
    ss02 = 'ss02',
    ss03 = 'ss03',
    ss04 = 'ss04',
    ss05 = 'ss05',
    ss06 = 'ss06',
    ss07 = 'ss07',
    ss08 = 'ss08',
    ss09 = 'ss09',
    ss10 = 'ss10',
    ss11 = 'ss11',
    ss12 = 'ss12',
    ss13 = 'ss13',
    ss14 = 'ss14',
    ss15 = 'ss15',
    ss16 = 'ss16',
    ss17 = 'ss17',
    ss18 = 'ss18',
    ss19 = 'ss19',
    ss20 = 'ss20',
}
