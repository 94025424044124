exports.components = {
  "component---src-templates-404-not-found-page-tsx": () => import("./../../../src/templates/404NotFoundPage.tsx" /* webpackChunkName: "component---src-templates-404-not-found-page-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-account-page-tsx": () => import("./../../../src/templates/AccountPage.tsx" /* webpackChunkName: "component---src-templates-account-page-tsx" */),
  "component---src-templates-account-verified-page-tsx": () => import("./../../../src/templates/AccountVerifiedPage.tsx" /* webpackChunkName: "component---src-templates-account-verified-page-tsx" */),
  "component---src-templates-awards-page-tsx": () => import("./../../../src/templates/AwardsPage.tsx" /* webpackChunkName: "component---src-templates-awards-page-tsx" */),
  "component---src-templates-blog-index-page-tsx": () => import("./../../../src/templates/BlogIndexPage.tsx" /* webpackChunkName: "component---src-templates-blog-index-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/BlogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-buy-page-tsx": () => import("./../../../src/templates/BuyPage.tsx" /* webpackChunkName: "component---src-templates-buy-page-tsx" */),
  "component---src-templates-checkout-page-tsx": () => import("./../../../src/templates/CheckoutPage.tsx" /* webpackChunkName: "component---src-templates-checkout-page-tsx" */),
  "component---src-templates-checkout-success-page-tsx": () => import("./../../../src/templates/CheckoutSuccessPage.tsx" /* webpackChunkName: "component---src-templates-checkout-success-page-tsx" */),
  "component---src-templates-contact-us-page-tsx": () => import("./../../../src/templates/ContactUsPage.tsx" /* webpackChunkName: "component---src-templates-contact-us-page-tsx" */),
  "component---src-templates-custom-fonts-menu-page-tsx": () => import("./../../../src/templates/CustomFontsMenuPage.tsx" /* webpackChunkName: "component---src-templates-custom-fonts-menu-page-tsx" */),
  "component---src-templates-custom-fonts-post-page-tsx": () => import("./../../../src/templates/CustomFontsPostPage.tsx" /* webpackChunkName: "component---src-templates-custom-fonts-post-page-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../../src/templates/FaqPage.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-font-collection-page-tsx": () => import("./../../../src/templates/FontCollectionPage.tsx" /* webpackChunkName: "component---src-templates-font-collection-page-tsx" */),
  "component---src-templates-font-family-page-tsx": () => import("./../../../src/templates/FontFamilyPage.tsx" /* webpackChunkName: "component---src-templates-font-family-page-tsx" */),
  "component---src-templates-font-licences-page-tsx": () => import("./../../../src/templates/FontLicencesPage.tsx" /* webpackChunkName: "component---src-templates-font-licences-page-tsx" */),
  "component---src-templates-fonts-menu-page-tsx": () => import("./../../../src/templates/FontsMenuPage.tsx" /* webpackChunkName: "component---src-templates-fonts-menu-page-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/Homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-in-use-index-page-tsx": () => import("./../../../src/templates/InUseIndexPage.tsx" /* webpackChunkName: "component---src-templates-in-use-index-page-tsx" */),
  "component---src-templates-in-use-post-page-tsx": () => import("./../../../src/templates/InUsePostPage.tsx" /* webpackChunkName: "component---src-templates-in-use-post-page-tsx" */),
  "component---src-templates-licence-page-tsx": () => import("./../../../src/templates/LicencePage.tsx" /* webpackChunkName: "component---src-templates-licence-page-tsx" */),
  "component---src-templates-login-page-tsx": () => import("./../../../src/templates/LoginPage.tsx" /* webpackChunkName: "component---src-templates-login-page-tsx" */),
  "component---src-templates-logout-page-tsx": () => import("./../../../src/templates/LogoutPage.tsx" /* webpackChunkName: "component---src-templates-logout-page-tsx" */),
  "component---src-templates-preview-page-tsx": () => import("./../../../src/templates/PreviewPage.tsx" /* webpackChunkName: "component---src-templates-preview-page-tsx" */),
  "component---src-templates-privacy-policy-page-tsx": () => import("./../../../src/templates/PrivacyPolicyPage.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-tsx" */),
  "component---src-templates-refund-payment-policy-page-tsx": () => import("./../../../src/templates/RefundPaymentPolicyPage.tsx" /* webpackChunkName: "component---src-templates-refund-payment-policy-page-tsx" */),
  "component---src-templates-revert-login-page-tsx": () => import("./../../../src/templates/RevertLoginPage.tsx" /* webpackChunkName: "component---src-templates-revert-login-page-tsx" */),
  "component---src-templates-shared-cart-page-tsx": () => import("./../../../src/templates/SharedCartPage.tsx" /* webpackChunkName: "component---src-templates-shared-cart-page-tsx" */),
  "component---src-templates-style-guide-page-tsx": () => import("./../../../src/templates/StyleGuidePage.tsx" /* webpackChunkName: "component---src-templates-style-guide-page-tsx" */),
  "component---src-templates-subscribe-page-tsx": () => import("./../../../src/templates/SubscribePage.tsx" /* webpackChunkName: "component---src-templates-subscribe-page-tsx" */),
  "component---src-templates-support-page-tsx": () => import("./../../../src/templates/SupportPage.tsx" /* webpackChunkName: "component---src-templates-support-page-tsx" */),
  "component---src-templates-test-font-metrics-page-tsx": () => import("./../../../src/templates/TestFontMetricsPage.tsx" /* webpackChunkName: "component---src-templates-test-font-metrics-page-tsx" */),
  "component---src-templates-test-fonts-page-tsx": () => import("./../../../src/templates/TestFontsPage.tsx" /* webpackChunkName: "component---src-templates-test-fonts-page-tsx" */)
}

