import React from 'react';
import styled from 'styled-components';
import TypeEditor from './TypeEditor';
import {
    useGeneratedGroupedSpecimens,
    usePreviewSpecimens,
} from '../utils/GeneratedSpecimen';
import { MARGIN_BIG, VIEWPORT } from '../settings/Global';
import useKeyPress from '../hooks/useKeyPress';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing8);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-row-gap: var(--spacing7);
    }
`;

const Inner = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: ${MARGIN_BIG}px;
`;

function Specimens(): React.ReactElement {
    // Loaded at run time
    const previewSpecimens = usePreviewSpecimens();

    // Loaded at build time
    const generatedGroupedSpecimens = useGeneratedGroupedSpecimens();

    const [areMetricsShown, setAreMetricsShown] = React.useState(false);

    useKeyPress({
        key: {
            keyCode: 77,
            key: 'm',
            code: 'KeyM',
        },
        shiftKey: true,
        ctrlKey: true,
        altKey: true,
        preventDefault: true,
        callback: () => setAreMetricsShown((state): boolean => !state),
        postInitCallback: () =>
            // eslint-disable-next-line no-console
            console.log(
                'Press Ctrl+Alt+Shift+m to toggle the font metrics display',
            ),
    });

    const groupedSpecimens = previewSpecimens
        ? /*
           * previewSpecimens only have one 'group' because they're all one size
           * by design
           */
          [previewSpecimens]
        : generatedGroupedSpecimens;

    return (
        <Container>
            {groupedSpecimens.map(
                (specimens, index): React.ReactElement | null =>
                    specimens.length ? (
                        <Inner key={`spc-${index}`}>
                            {specimens.map(
                                (specimen): React.ReactElement => (
                                    <TypeEditor
                                        key={specimen.key}
                                        specimen={specimen}
                                        areMetricsShown={areMetricsShown}
                                    />
                                ),
                            )}
                        </Inner>
                    ) : null,
            )}
        </Container>
    );
}

export default React.memo(Specimens);
