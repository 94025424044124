// Glyph category definitions
export interface GlyphCatDef {
    title: string;
    hidden?: boolean;
    orderedGlyphNames: string[];
}

export const GLYPH_CAT_DEFS: GlyphCatDef[] = [
    {
        title: 'Hidden',
        hidden: true,
        orderedGlyphNames: [
            // Combining accents & components
            'uni0331', // COMBINING MACRON BELOW
            'acute',
            'grave',
            'macron',
            'dieresis',
            'cedilla',
            'cedilla.alt',
            'space_dotbelowcomb',
            'space_uni0326',
            'space_uni0326.alt',
            'acutecmb',
            'altcedilla',
            'breve',
            'brevecmb',
            'caron',
            'caron.Slovak',
            'caronSlovak',
            'caroncmb',
            'cedillacmb',
            'circumflex',
            'circumflexcmb',
            'commaabovecmb',
            'commaaboverightcm',
            'commaaboverightcmb',
            'commaaccent',
            'uni00200326', // commaaccent
            'commaaccentcmb',
            'commaturn',
            'commaturncmb',
            'dieresiscmb',
            'dotaccent',
            'dotaccentcmb',
            'dotbelow',
            'dotbelowcmb',
            'dotlessj',
            'doublegrave',
            'gravecmb',
            'invertedbreve',
            'hungarumlaut',
            'hungarumlautcmb',
            'macroncmb',
            'ogonek',
            'ogonekcmb',
            'ring',
            'ringcmb',
            'rinvertedbreve',
            'tilde',
            'tildecmb',
            'uni0323', // dot
            'uni0329', // linebelow
            'uni0237', // dotlessj
            'uni0237.sc', // dotlessj
            'uni02BB', // commaturnedabove
            'uni02C9', // macron
            'uni0304', // macron
            'uni0312', // commaturnedabove
            'uni0320', // linebelow
            'uni00200323', //dotbelow
            'uni0302', // circumflex
            'uni0326', // commaaccent
            'uni0307', // dotaccent
            'uni0328', // ogonek
            'uni030A', // ring
            'uni0327', // cedilla
            'uni030C', // caron
            'uni0306', // invertedbreve
            'uni0308', // umlaut
            'uni0301', // acute
            'uni0315', // acute (another one)
            'uni0303', // tilde
            'uni030B', // double acute
            'uni0300', // grave
            //
            'acute.cap',
            'breve.cap',
            'caron.cap',
            'circumflex.cap',
            'commaaccent.cap',
            'dieresis.cap',
            'dieresis.i',
            'dieresis.cap.i',
            'dotaccent.cap',
            'dotaccent.cap.i',
            'dotaccent.i',
            'doublegrave.cap',
            'grave.cap',
            'hungarumlaut.cap',
            'invertedbreve.cap',
            'macron.cap',
            'ring.cap',
            'tilde.cap',
            //
            'dieresisCap',
            'dotaccentCap',
            // Others to be hidden
            '.notdef',
            'fraction', // fraction bar for combining into dynamic fractions
            'bullseye', // Klim logo in Feijoa
            'lowline',
            'i.dot',
            'anoteleia',
            // These next four are only present in National 2 Narrow Extrabold.
            // They are a mistake in the font. They're supposed to be for interpolation
            // only, but must have drifted into the production fonts.
            'dollar.1',
            'cent.1',
            'dollar.1.lt',
            'cent.1.lt',
        ],
    },
    {
        title: 'Uppercase',
        orderedGlyphNames: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ],
    },
    {
        title: 'Uppercase alternates',
        orderedGlyphNames: [
            'A.1',
            'A.alt',
            'B.1',
            'C.1',
            'D.1',
            'E.1',
            'F.1',
            'G.1',
            'G.alt',
            'H.1',
            'I.1',
            'J.1',
            'K.1',
            'L.1',
            'M.1',
            'M.alt',
            'N.1',
            'N.alt',
            'O.1',
            'P.1',
            'Q.1',
            'R.1',
            'R.alt',
            'S.1',
            'S.alt',
            'T.1',
            'U.1',
            'V.1',
            'V.alt',
            'W.1',
            'W.alt',
            'W.editalt.alt',
            'W.brandalt',
            'X.1',
            'Y.1',
            'Z.1',
        ],
    },
    {
        title: 'Lowercase',
        orderedGlyphNames: [
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
        ],
    },
    {
        title: 'Lowercase alternates',
        orderedGlyphNames: [
            'a.1',
            'a.alt',
            'a.school',
            'c.1',
            'e.1',
            'e.alt',
            'f.1',
            'f.short',
            'f_short',
            'g.1',
            'g.2',
            'g.3',
            'g.alt',
            'g.school',
            'h.1',
            'i.i',
            'j.brandalt',
            'j.i',
            'k.1',
            'm.1',
            'n.1',
            'r.1',
            'r.2',
            's.1',
            's.alt',
            't.alt',
            'u.1',
            'u.alt',
            'v.1',
            'v.alt',
            'w.1',
            'w.alt',
            'y.1',
            'y.school',
            'z.1',
        ],
    },
    {
        title: 'Small capitals',
        orderedGlyphNames: [
            'A.sc',
            'B.sc',
            'C.sc',
            'D.sc',
            'E.sc',
            'F.sc',
            'G.sc',
            'H.sc',
            'I.sc',
            'J.sc',
            'K.sc',
            'L.sc',
            'M.sc',
            'N.sc',
            'O.sc',
            'P.sc',
            'Q.sc',
            'R.sc',
            'S.sc',
            'T.sc',
            'U.sc',
            'V.sc',
            'W.sc',
            'X.sc',
            'Y.sc',
            'Z.sc',
            'a.smcp',
            'b.smcp',
            'c.smcp',
            'd.smcp',
            'e.smcp',
            'f.smcp',
            'g.smcp',
            'h.smcp',
            'i.smcp',
            'j.smcp',
            'k.smcp',
            'l.smcp',
            'm.smcp',
            'n.smcp',
            'o.smcp',
            'p.smcp',
            'q.smcp',
            'r.smcp',
            's.smcp',
            't.smcp',
            'u.smcp',
            'v.smcp',
            'w.smcp',
            'x.smcp',
            'y.smcp',
            'z.smcp',
        ],
    },
    {
        title: 'Small capital alternates',
        orderedGlyphNames: [
            'B.1sc',
            'B.1.sc',
            'D.1sc',
            'D.1.sc',
            'P.1sc',
            'P.1.sc',
            'R.1sc',
            'R.1.sc',
            'ralt.smcp',
        ],
    },
    {
        title: 'Uppercase accents',
        orderedGlyphNames: [
            'Aacute',
            'Abreve',
            'Acaron',
            'Acircumflex',
            'Adieresis',
            'Adieresismacron',
            'uni004100A800AF', // Adieresismacron
            'Adotabove',
            'Adoublegrave',
            'Agrave',
            'Ainvertedbreve',
            'Amacron',
            'Anocross',
            'Aogonek',
            'Aring',
            'Aringacute',
            'uni004102DA00B4', // Aringacute
            'Atilde',
            'AE',
            'AEacute',
            'AEmacron',
            'Cacute',
            'Ccaron',
            'Ccedilla',
            'Ccircumflex',
            'Cdotaccent',
            'Dcaron',
            'Dcedilla',
            'Ddotbelow',
            'Dcroat',
            'Eacute',
            'Edotbelowacute',
            'uni004500B40323', // Eacutedotbelow
            'Ebreve',
            'Ecaron',
            'Ecircumflex',
            'Edieresis',
            'Edotaccent',
            'Edotbelow',
            'uni1EB8', // Edotbelow
            'Edoublegrave',
            'Egrave',
            'uni004500600323', // Egravedotbelow
            'Einvertedbreve',
            'Emacron',
            'Eogonek',
            'Eth',
            'Gacute',
            'Gbreve',
            'Gcaron',
            'Gcircumflex',
            'Gcommaaccent',
            'Gcommaturnedabove',
            'uni0122', // Gcommabelow
            'Gdotaccent',
            'Gstroke',
            'Gtilde',
            'Hbar',
            'Hcaron',
            'Hcircumflex',
            'Hdotbelow',
            'Hmacronbelow',
            'IJ',
            'IJacute',
            'Iacute',
            'Ibreve',
            'Icaron',
            'Icircumflex',
            'Idieresis',
            'Idotaccent',
            'Idotbelow',
            'Idoublegrave',
            'Igrave',
            'Iinvertedbreve',
            'Imacron',
            'Iogonek',
            'Itilde',
            'Jacute',
            'Jcaron',
            'Jcircumflex',
            'Kcaron',
            'Kcedilla',
            'Kcommaaccent',
            'uni0136', // Kcommabelow
            'Khook',
            'Lacute',
            'Lcaron',
            'Lcedilla',
            'Lcommaaccent',
            'uni013B', // Lcommabelow
            'Ldot',
            'Ldotbelow',
            'uni1E36', // Ldotbelow
            'uni1E38', // Lmacrondotbelow
            'uni004C032300AF', // Lmacrondotbelow
            'Lslash',
            'Mcedilla',
            'Mdotbelow',
            'Mmacron',
            'Nacute',
            'Ncaron',
            'Ncedilla',
            'Ncommaaccent',
            'uni0145', // Ncommabelow
            'Ndescender',
            'Ndieresis',
            'Ndotaccent',
            'Ndotbelow',
            'Ngrave',
            'Nmacron',
            'Ntilde',
            'Eng',
            'Oacute',
            'Obreve',
            'Ocaron',
            'Ocedilla',
            'uni01EC', // Ocedillamacron
            'uni004F02DB00AF', // Ocedillamacron
            'Ocircumflex',
            'Ocommaturnedabove',
            'Odieresis',
            'Odotabove',
            'uni004F02D900AF', // Odotabovemacron
            'uni004F0329', // Olinebelow
            'Odotbelow',
            'Odoublegrave',
            'Ograve',
            'Ohungarumlaut',
            'Oinvertedbreve',
            'Omacron',
            'uni0230', // Omacrondotabove
            'Oogonek',
            'Oslash',
            'Oslashacute',
            'Otilde',
            'Otildemacron',
            'Ounderlineaccent',
            'barredO',
            'OE',
            'Pmacron',
            'Racute',
            'Rcaron',
            'Rcedilla',
            'Rcommaaccent',
            'uni0156', // Rcommabelow
            'Rdotbelow',
            'Rdotbelowmacron',
            'Rdoublegrave',
            'Rinvertedbreve',
            'Sacute',
            'Scaron',
            'Scedilla',
            'uni015E', // Scedilla
            'Scircumflex',
            'Scommaaccent',
            'uni0218', // Scommabelow
            'Sdotbelow',
            'Germandbls',
            'Tbar',
            'Tcaron',
            'Tcedilla',
            'uni0162', // Tcedilla
            'Tcommaaccent',
            'Tdotbelow',
            'uni021A', // Tcommabelow
            'Uacute',
            'Ubreve',
            'Ucaron',
            'Ucircumflex',
            'Udieresis',
            'uni01D9', // Udieresiscaron
            'Udotbelow',
            'Udoublegrave',
            'Ugrave',
            'Uhungarumlaut',
            'Uinvertedbreve',
            'Umacron',
            'Umacronbelow',
            'Uogonek',
            'Uring',
            'Utilde',
            'Wacute',
            'Wcircumflex',
            'Wdieresis',
            'Wgrave',
            'Yacute',
            'Ycircumflex',
            'Ydieresis',
            'Ygrave',
            'Ymacron',
            'Ytilde',
            'Zacute',
            'Zcaron',
            'Zdotaccent',
            'Thorn',
            'Schwa',
            'Ezh',
            'Ezhcaron',
        ],
    },
    {
        title: 'Uppercase accent alternates',
        orderedGlyphNames: [
            'space_uni0326.alt',
            // copied the main Uppercase accents list and appended .1
            'Aacute.1',
            'Abreve.1',
            'Acaron.1',
            'Acircumflex.1',
            'Adieresis.1',
            'Adieresismacron.1',
            'uni004100A800AF.1',
            'Adotabove.1',
            'Adoublegrave.1',
            'Agrave.1',
            'Ainvertedbreve.1',
            'Amacron.1',
            'Anocross.1',
            'Aogonek.1',
            'Aring.1',
            'Aringacute.1',
            'uni004102DA00B4.1',
            'Atilde.1',
            'AE.1',
            'AEacute.1',
            'AEmacron.1',
            'Adieresis.i',
            'Cacute.1',
            'Ccaron.1',
            'Ccedilla.1',
            'Ccircumflex.1',
            'Cdotaccent.1',
            'Cdotaccent.i',
            'Dcaron.1',
            'Dcedilla.1',
            'Ddotbelow.1',
            'Dcroat.1',
            'Eacute.1',
            'uni004500B40323.1',
            'Ebreve.1',
            'Ecaron.1',
            'Ecircumflex.1',
            'Edieresis.1',
            'Edieresis.i',
            'Edotaccent.1',
            'Edotaccent.i',
            'Edoublegrave.1',
            'Egrave.1',
            'uni004500600323.1',
            'Einvertedbreve.1',
            'Emacron.1',
            'Eogonek.1',
            'Eth.1',
            'Gacute.1',
            'Gbreve.1',
            'Gcaron.1',
            'Gcircumflex.1',
            'Gcommaaccent.1',
            'Gcommaturnedabove.1',
            'uni0122.1',
            'Gdotaccent.1',
            'Gdotaccent.i',
            'Gstroke.1',
            'Gtilde.1',
            'Hbar.1',
            'Hcaron.1',
            'Hcircumflex.1',
            'Hdotbelow.1',
            'Hmacronbelow.1',
            'IJ.1',
            'Iacute.1',
            'Ibreve.1',
            'Icaron.1',
            'Icircumflex.1',
            'Idieresis.1',
            'Idieresis.i',
            'Idotaccent.1',
            'Idotaccent.i',
            'Idotbelow.1',
            'Idoublegrave.1',
            'Igrave.1',
            'Iinvertedbreve.1',
            'Imacron.1',
            'Iogonek.1',
            'Itilde.1',
            'Jcaron.1',
            'Jcircumflex.1',
            'Kcaron.1',
            'Kcedilla.1',
            'Kcommaaccent.1',
            'uni0136.1',
            'Khook.1',
            'Lacute.1',
            'Lcaron.1',
            'Lcedilla.1',
            'Lcommaaccent.1',
            'uni013B.1',
            'Ldot.1',
            'Ldot.i',
            'Ldotbelow.1',
            'uni1E36.1',
            'uni1E38.1',
            'uni004C032300AF.1',
            'Lslash.1',
            'Mcedilla.1',
            'Mdotbelow.1',
            'Mmacron.1',
            'Nacute.1',
            'Ncaron.1',
            'Ncedilla.1',
            'Ncommaaccent.1',
            'uni0145.1',
            'Ndescender.1',
            'Ndieresis.1',
            'Ndotaccent.1',
            'Ndotbelow.1',
            'Ngrave.1',
            'Nmacron.1',
            'Ntilde.1',
            'Eng.1',
            'Oacute.1',
            'Obreve.1',
            'Ocaron.1',
            'Ocedilla.1',
            'uni01EC.1',
            'uni004F02DB00AF.1',
            'Ocircumflex.1',
            'Ocommaturnedabove.1',
            'Odieresis.1',
            'Odieresis.i',
            'Odotabove.1',
            'uni004F02D900AF.1',
            'uni004F0329.1',
            'Odotbelow.1',
            'Odoublegrave.1',
            'Ograve.1',
            'Ohungarumlaut.1',
            'Oinvertedbreve.1',
            'Omacron.1',
            'uni0230.1',
            'Oogonek.1',
            'Oslash.1',
            'Oslashacute.1',
            'Otilde.1',
            'Otildemacron.1',
            'Ounderlineaccent.1',
            'barredO.1',
            'OE.1',
            'Pmacron.1',
            'Racute.1',
            'Rcaron.1',
            'Rcedilla.1',
            'Rcommaaccent.1',
            'uni0156.1',
            'Rdotbelow.1',
            'Rdotbelowmacron.1',
            'Rdoublegrave.1',
            'Rinvertedbreve.1',
            'Sacute.1',
            'Scaron.1',
            'Scedilla.1',
            'uni015E.1',
            'Scircumflex.1',
            'Scommaaccent.1',
            'uni0218.1',
            'Sdotbelow.1',
            'Tbar.1',
            'Tcaron.1',
            'Tcedilla.1',
            'uni0162.1',
            'Tcommaaccent.1',
            'Tdotbelow.1',
            'uni021A.1',
            'Uacute.1',
            'Ubreve.1',
            'Ucaron.1',
            'Ucircumflex.1',
            'Udieresis.1',
            'Udieresis.i',
            'uni01D9.1',
            'Udotbelow.1',
            'Udoublegrave.1',
            'Ugrave.1',
            'Uhungarumlaut.1',
            'Uinvertedbreve.1',
            'Umacron.1',
            'Umacronbelow.1',
            'Uogonek.1',
            'Uring.1',
            'Utilde.1',
            'Wacute.1',
            'Wcircumflex.1',
            'Wdieresis.1',
            'Wdieresis.i',
            'Wgrave.1',
            'Yacute.1',
            'Ycircumflex.1',
            'Ydieresis.1',
            'Ydieresis.i',
            'Ygrave.1',
            'Ymacron.1',
            'Ytilde.1',
            'Zacute.1',
            'Zcaron.1',
            'Zdotaccent.1',
            'Zdotaccent.i',
            'Thorn.1',
            'Schwa.1',
            'Ezh.1',
            'Ezhcaron.1',
            //
            'Aacute.alt',
            'Abreve.alt',
            'Acircumflex.alt',
            'Adieresis.alt',
            'Agrave.alt',
            'Amacron.alt',
            'Aogonek.alt',
            'Aring.alt',
            'Atilde.alt',
            'Gcircumflex.alt',
            'Gcommaaccent.alt',
            'Gbreve.alt',
            'Gdotaccent.alt',
            'uni0122.alt', // Gcommabelow
            'Nacute.alt',
            'Ncaron.alt',
            'Ncommaaccent.alt',
            'Ntilde.alt',
            'Racute.alt',
            'Rcaron.alt',
            'Rinvertedbreve.alt',
            'Rdoublegrave.alt',
            'Rcommaaccent.alt',
            'Rcedilla.alt',
            'Rdotbelow.alt',
            'Rdotbelowmacron.alt',
            'Sacute.alt',
            'Scedilla.alt',
            'Scircumflex.alt',
            'Scommaaccent.alt',
            'uni015E.alt',
            'Scaron.alt',
            'uni0218.alt', // Scommabelow
            'Wacute.alt',
            'Wcircumflex.alt',
            'Wdieresis.alt',
            'Wgrave.alt',
            'Wacute.editalt.alt',
            'Wcircumflex.editalt.alt',
            'Wdieresis.editalt.alt',
            'Wgrave.editalt.alt',
            //
            'Wcircumflex.brandalt',
            'Wgrave.brandalt',
            'Wacute.brandalt',
            'Wdieresis.brandalt',
            //
            'Aumlaut.alt',
            'Oumlaut.alt',
            'Uumlaut.alt',
        ],
    },
    {
        title: 'Lowercase accents',
        orderedGlyphNames: [
            'aacute',
            'abreve',
            'acaron',
            'acircumflex',
            'adieresis',
            'adieresismacron',
            'adotabove',
            'adoublegrave',
            'agrave',
            'ainvertedbreve',
            'amacron',
            'aogonek',
            'aring',
            'aringacute',
            'atilde',
            'ae',
            'aeacute',
            'aemacron',
            'cacute',
            'ccaron',
            'ccedilla',
            'ccircumflex',
            'cdotaccent',
            'dcaron',
            'dcedilla',
            'dcroat',
            'ddotbelow',
            'eacute',
            'edotbelowacute',
            'uni006500B40323', // eacutedotbelow
            'ebreve',
            'ecaron',
            'ecircumflex',
            'edieresis',
            'edotaccent',
            'edotbelow',
            'uni1EB9', // edotbelow
            'edoublegrave',
            'egrave',
            'uni006500600323', // egravedotbelow
            'einvertedbreve',
            'emacron',
            'eogonek',
            'eth',
            'gacute',
            'gbreve',
            'gcaron',
            'gcircumflex',
            'gcommaaccent',
            'gcommaturnedabove',
            'uni0123', // gcommabelow
            'gdotaccent',
            'gstroke',
            'gtilde',
            'hbar',
            'hcaron',
            'hcircumflex',
            'hdotbelow',
            'hmacronbelow',
            'iacute',
            'ibreve',
            'icaron',
            'icircumflex',
            'idieresis',
            'idotaccent',
            'idotbelow',
            'idoublegrave',
            'igrave',
            'iinvertedbreve',
            'dotlessi',
            'ij',
            'ijacute',
            'imacron',
            'iogonek',
            'itilde',
            'jacute',
            'jcaron',
            'jcircumflex',
            'kcaron',
            'kcedilla',
            'kcommaaccent',
            'uni0137', // kcommabelow
            'kgreenlandic',
            'khook',
            'lacute',
            'lcaron',
            'lcedilla',
            'lcommaaccent',
            'uni013C', // lcommabelow
            'ldot',
            'ldotbelow',
            'uni1E37', // ldotbelow
            'ldotbelowmacron',
            'uni1E39', // lmacrondotbelow
            'lslash',
            'mcedilla',
            'mdotbelow',
            'mmacron',
            'nacute',
            'ncaron',
            'ncedilla',
            'ncommaaccent',
            'uni0146', // ncommabelow
            'ndescender',
            'ndieresis',
            'ndotaccent',
            'ndotbelow',
            'ngrave',
            'nmacron',
            'ntilde',
            'eng',
            'oacute',
            'obreve',
            'ocaron',
            'ocedilla',
            'uni01ED', // ocedillamacron
            'ocircumflex',
            'ocommaturnedabove',
            'odieresis',
            'odotabove',
            'odotabovemacron',
            'uni0231', // omacrondotabove
            'odotbelow',
            'odoublegrave',
            'oe',
            'ograve',
            'ohungarumlaut',
            'oinvertedbreve',
            'omacron',
            'uni006F0329', // olinebelow
            'oogonek',
            'oogonekmacron',
            'oslash',
            'oslashacute',
            'otilde',
            'otildemacron',
            'ounderlineaccent',
            'barredo',
            'pmacron',
            'racute',
            'rcaron',
            'rcedilla',
            'rcommaaccent',
            'uni0157', // rcommabelow
            'rdotbelow',
            'rdotbelowmacron',
            'rdoublegrave',
            'sacute',
            'scaron',
            'scedilla',
            'uni015F', // scedilla
            'sdotbelow',
            'uni0219', // scommabelow
            'scircumflex',
            'scommaaccent',
            'germandbls',
            'tbar',
            'tcaron',
            'tcedilla',
            'uni0163', // tcedilla
            'tcommaaccent',
            'tdotbelow',
            'uni021B', // tcommabelow
            'uacute',
            'ubreve',
            'ucaron',
            'ucircumflex',
            'udieresis',
            'uni01DA', // udieresiscaron
            'udotbelow',
            'udoublegrave',
            'ugrave',
            'uhungarumlaut',
            'uinvertedbreve',
            'umacron',
            'umacronbelow',
            'uogonek',
            'uring',
            'utilde',
            'wacute',
            'wcircumflex',
            'wdieresis',
            'wgrave',
            'yacute',
            'ycircumflex',
            'ydieresis',
            'ygrave',
            'ymacron',
            'ytilde',
            'zacute',
            'zcaron',
            'zdotaccent',
            'thorn',
            'ezh',
            'ezhcaron',
            'schwa',
        ],
    },
    {
        title: 'Lowercase accent alternates',
        orderedGlyphNames: [
            'aacute.1',
            'aacute.alt',
            'aacute.school',
            'abreve.1',
            'abreve.alt',
            'abreve.school',
            'acaron.school',
            'acircumflex.1',
            'acircumflex.alt',
            'acircumflex.school',
            'adieresis.1',
            'adieresis.1.i',
            'adieresis.alt',
            'adieresis.school',
            'adieresismacron.school',
            'adotabove.school',
            'adoublegrave.school',
            'agrave.1',
            'agrave.school',
            'agrave.alt',
            'ainvertedbreve.school',
            'amacron.1',
            'amacron.alt',
            'amacron.school',
            'aogonek.1',
            'aogonek.alt',
            'aogonek.school',
            'aring.1',
            'aring.alt',
            'aring.school',
            'aringacute.alt',
            'aringacute.school',
            'atilde.1',
            'atilde.alt',
            'atilde.school',
            'ae.1',
            'ae.alt',
            'aeacute.1',
            'aeacute.alt',
            'ae.2',
            'aeacute.2',
            'adieresis.i',
            'ccedilla.1',
            'cacute.1',
            'ccircumflex.1',
            'cdotaccent.1',
            'ccaron.1',
            'cdotaccent.i',
            'dcaron.1',
            'egrave.1',
            'egrave.alt',
            'eacute.1',
            'eacute.alt',
            'ecircumflex.1',
            'ecircumflex.alt',
            'edieresis.1',
            'edieresis.alt',
            'emacron.1',
            'emacron.alt',
            'ebreve.1',
            'ebreve.alt',
            'edotaccent.1',
            'edotaccent.alt',
            'eogonek.1',
            'eogonek.alt',
            'ecaron.1',
            'ecaron.alt',
            'edieresis.i',
            'edotaccent.i',
            'gcommaaccent.1',
            'gcommaaccent.alt',
            'gcommaaccent.school',
            'gcaron.school',
            'gcircumflex.1',
            'gcircumflex.alt',
            'gcircumflex.school',
            'gacute.school',
            'gbreve.1',
            'gbreve.alt',
            'gbreve.school',
            'gdotaccent.1',
            'gdotaccent.1.i',
            'gdotaccent.alt',
            'gdotaccent.school',
            'gcommaturnedabove.school',
            'uni0123.1', // gcommabelow
            'gcommaaccent.2',
            'gcircumflex.2',
            'gbreve.2',
            'gdotaccent.2',
            'gdotaccent.2.i',
            'gcommaaccent.3',
            'gcircumflex.3',
            'gbreve.3',
            'gdotaccent.3',
            'gdotaccent.3.i',
            'gdotaccent.i',
            'gstroke.school',
            'gtilde.school',
            'uni0123.alt', // gcommabelow
            'hbar.1',
            'hcircumflex.1',
            'ij.i',
            'idieresis.i',
            'iogonek.i',
            'kcommaaccent.1',
            'lcaron.1',
            'ldot.i',
            'nacute.1',
            'ncommaaccent.1',
            'ncaron.1',
            'ntilde.1',
            'eng.1',
            'odieresis.i',
            'oe.alt',
            'racute.1',
            'rcaron.1',
            'rcommaaccent.1',
            'racute.2',
            'rcaron.2',
            'rcommaaccent.2',
            'scedilla.1',
            'scedilla.alt',
            'scommaaccent.1',
            'scommaaccent.alt',
            'sacute.1',
            'sacute.alt',
            'scircumflex.1',
            'scircumflex.alt',
            'uni015F.1',
            'uni015F.alt',
            'scaron.1',
            'scaron.alt',
            'uni0219.1',
            'uni0219.alt',
            'germandbls.1',
            'tcommaaccent.alt',
            'tcaron.alt',
            'uni021B.alt',
            'tbar.alt',
            'oe.1',
            'uacute.1',
            'uacute.alt',
            'ubreve.1',
            'ubreve.alt',
            'ucircumflex.1',
            'ucircumflex.alt',
            'udieresis.1',
            'udieresis.1.i',
            'udieresis.i',
            'udieresis.alt',
            'ugrave.1',
            'ugrave.alt',
            'uhungarumlaut.1',
            'uhungarumlaut.alt',
            'umacron.1',
            'umacron.alt',
            'uogonek.1',
            'uogonek.alt',
            'uring.1',
            'uring.alt',
            'utilde.1',
            'utilde.alt',
            'wcircumflex.1',
            'wcircumflex.alt',
            'wgrave.1',
            'wgrave.alt',
            'wacute.1',
            'wacute.alt',
            'wdieresis.1',
            'wdieresis.i',
            'wdieresis.alt',
            'yacute.1',
            'yacute.school',
            'ydieresis.1',
            'ydieresis.i',
            'ydieresis.school',
            'ycircumflex.1',
            'ycircumflex.school',
            'ygrave.1',
            'ygrave.school',
            'ymacron.school',
            'ytilde.school',
            'zacute.1',
            'zdotaccent.1',
            'zdotaccent.i',
            'zcaron.1',
        ],
    },
    {
        title: 'Small capital accents',
        orderedGlyphNames: [
            // copied the main Uppercase accents list and appended .sc
            'Aacute.sc',
            'Abreve.sc',
            'Acaron.sc',
            'Acircumflex.sc',
            'Adieresis.sc',
            'Adieresismacron.sc',
            'uni004100A800AF.sc',
            'Adotabove.sc',
            'Adoublegrave.sc',
            'Agrave.sc',
            'Ainvertedbreve.sc',
            'Amacron.sc',
            'Anocross.sc',
            'Aogonek.sc',
            'Aring.sc',
            'Aringacute.sc',
            'uni004102DA00B4.sc',
            'Atilde.sc',
            'AE.sc',
            'AEacute.sc',
            'AEmacron.sc',
            'Cacute.sc',
            'Ccaron.sc',
            'Ccedilla.sc',
            'Ccircumflex.sc',
            'Cdotaccent.sc',
            'Dcaron.sc',
            'Dcedilla.sc',
            'Ddotbelow.sc',
            'Dcroat.sc',
            'Eacute.sc',
            'Edotbelowacute.sc',
            'uni004500B40323.sc',
            'Ebreve.sc',
            'Ecaron.sc',
            'Ecircumflex.sc',
            'Edieresis.sc',
            'Edotaccent.sc',
            'Edotbelow.sc',
            'uni1EB8.sc', // Edotbelow.sc
            'Edoublegrave.sc',
            'Egrave.sc',
            'uni004500600323.sc',
            'Einvertedbreve.sc',
            'Emacron.sc',
            'Eogonek.sc',
            'Eth.sc',
            'Gacute.sc',
            'Gbreve.sc',
            'Gcaron.sc',
            'Gcircumflex.sc',
            'Gcommaaccent.sc',
            'Gcommaturnedabove.sc',
            'uni0122.sc',
            'Gdotaccent.sc',
            'Gstroke.sc',
            'Gtilde.sc',
            'Hbar.sc',
            'Hcaron.sc',
            'Hcircumflex.sc',
            'Hdotbelow.sc',
            'Hmacronbelow.sc',
            'IJ.sc',
            'IJacute.sc',
            'Iacute.sc',
            'Ibreve.sc',
            'Icaron.sc',
            'Icircumflex.sc',
            'Idieresis.sc',
            'Idotaccent.sc',
            'Idotbelow.sc',
            'Idoublegrave.sc',
            'Igrave.sc',
            'Iinvertedbreve.sc',
            'Imacron.sc',
            'Iogonek.sc',
            'Itilde.sc',
            'dotlessi.sc',
            'Jacute.sc',
            'Jcaron.sc',
            'Jcircumflex.sc',
            'Kcaron.sc',
            'Kcedilla.sc',
            'Kcommaaccent.sc',
            'uni0136.sc',
            'Khook.sc',
            'Lacute.sc',
            'Lcaron.sc',
            'Lcedilla.sc',
            'Lcommaaccent.sc',
            'uni013B.sc',
            'Ldot.sc',
            'Ldotbelow.sc',
            'uni1E36.sc',
            'uni1E38.sc',
            'uni004C032300AF.sc',
            'Lslash.sc',
            'Mcedilla.sc',
            'Mdotbelow.sc',
            'Mmacron.sc',
            'Nacute.sc',
            'Ncaron.sc',
            'Ncedilla.sc',
            'Ncommaaccent.sc',
            'uni0145.sc',
            'Ndescender.sc',
            'Ndieresis.sc',
            'Ndotaccent.sc',
            'Ndotbelow.sc',
            'Ngrave.sc',
            'Nmacron.sc',
            'Ntilde.sc',
            'Eng.sc',
            'Oacute.sc',
            'Obreve.sc',
            'Ocaron.sc',
            'Ocedilla.sc',
            'uni01EC.sc',
            'uni004F02DB00AF.sc',
            'Ocircumflex.sc',
            'Ocommaturnedabove.sc',
            'Odieresis.sc',
            'Odotabove.sc',
            'uni004F02D900AF.sc',
            'uni004F0329.sc',
            'Odotbelow.sc',
            'Odoublegrave.sc',
            'Ograve.sc',
            'Ohungarumlaut.sc',
            'Oinvertedbreve.sc',
            'Omacron.sc',
            'uni0230.sc',
            'Oogonek.sc',
            'Oslash.sc',
            'Oslashacute.sc',
            'Otilde.sc',
            'Otildemacron.sc',
            'Ounderlineaccent.sc',
            'barredO.sc',
            'OE.sc',
            'Pmacron.sc',
            'Racute.sc',
            'Rcaron.sc',
            'Rcedilla.sc',
            'Rcommaaccent.sc',
            'uni0156.sc',
            'Rdotbelow.sc',
            'Rdotbelowmacron.sc',
            'Rdoublegrave.sc',
            'Rinvertedbreve.sc',
            'Sacute.sc',
            'Scaron.sc',
            'Scedilla.sc',
            'uni015E.sc',
            'Scircumflex.sc',
            'Scommaaccent.sc',
            'uni0218.sc',
            'Sdotbelow.sc',
            'Germandbls.sc',
            'Tbar.sc',
            'Tcaron.sc',
            'Tcedilla.sc',
            'uni0162.sc',
            'Tcommaaccent.sc',
            'Tdotbelow.sc',
            'uni021A.sc',
            'Uacute.sc',
            'Ubreve.sc',
            'Ucaron.sc',
            'Ucircumflex.sc',
            'Udieresis.sc',
            'uni01D9.sc',
            'Udotbelow.sc',
            'Udoublegrave.sc',
            'Ugrave.sc',
            'Uhungarumlaut.sc',
            'Uinvertedbreve.sc',
            'Umacron.sc',
            'Umacronbelow.sc',
            'Uogonek.sc',
            'Uring.sc',
            'Utilde.sc',
            'Wacute.sc',
            'Wcircumflex.sc',
            'Wdieresis.sc',
            'Wgrave.sc',
            'Yacute.sc',
            'Ycircumflex.sc',
            'Ydieresis.sc',
            'Ygrave.sc',
            'Ymacron.sc',
            'Ytilde.sc',
            'Zacute.sc',
            'Zcaron.sc',
            'Zdotaccent.sc',
            'Thorn.sc',
            'Schwa.sc',
            'Ezh.sc',
            'Ezhcaron.sc',
            // copied the main Uppercase accents list, converted to lowercase and appended .smcp
            'aacute.smcp',
            'abreve.smcp',
            'acaron.smcp',
            'acircumflex.smcp',
            'adieresis.smcp',
            'adieresismacron.smcp',
            'uni004100a800af.smcp',
            'adotabove.smcp',
            'adoublegrave.smcp',
            'agrave.smcp',
            'ainvertedbreve.smcp',
            'amacron.smcp',
            'anocross.smcp',
            'aogonek.smcp',
            'aring.smcp',
            'aringacute.smcp',
            'uni004102da00b4.smcp',
            'atilde.smcp',
            'ae.smcp',
            'aeacute.smcp',
            'aemacron.smcp',
            'cacute.smcp',
            'ccaron.smcp',
            'ccedilla.smcp',
            'ccircumflex.smcp',
            'cdotaccent.smcp',
            'dcaron.smcp',
            'dcedilla.smcp',
            'ddotbelow.smcp',
            'dcroat.smcp',
            'eacute.smcp',
            'uni006500600323.smcp', // eacutedotbelow
            'ebreve.smcp',
            'ecaron.smcp',
            'ecircumflex.smcp',
            'edieresis.smcp',
            'edotaccent.smcp',
            'edoublegrave.smcp',
            'egrave.smcp',
            'uni004500b40323.smcp', // egravedotbelow
            'uni006500B40323.smcp', // egravedotbelow
            'uni004500600323.smcp',
            'einvertedbreve.smcp',
            'emacron.smcp',
            'eogonek.smcp',
            'eth.smcp',
            'gacute.smcp',
            'gbreve.smcp',
            'gcaron.smcp',
            'gcircumflex.smcp',
            'gcommaaccent.smcp',
            'gcommaturnedabove.smcp',
            'uni0122.smcp',
            'gdotaccent.smcp',
            'gstroke.smcp',
            'gtilde.smcp',
            'hbar.smcp',
            'hcaron.smcp',
            'hcircumflex.smcp',
            'hdotbelow.smcp',
            'hmacronbelow.smcp',
            'ij.smcp',
            'iacute.smcp',
            'ibreve.smcp',
            'icaron.smcp',
            'icircumflex.smcp',
            'idieresis.smcp',
            'idotaccent.smcp',
            'idotbelow.smcp',
            'idoublegrave.smcp',
            'igrave.smcp',
            'iinvertedbreve.smcp',
            'imacron.smcp',
            'iogonek.smcp',
            'itilde.smcp',
            'jcaron.smcp',
            'jcircumflex.smcp',
            'kcaron.smcp',
            'kcedilla.smcp',
            'kcommaaccent.smcp',
            'uni0136.smcp',
            'khook.smcp',
            'lacute.smcp',
            'lcaron.smcp',
            'lcedilla.smcp',
            'lcommaaccent.smcp',
            'uni013b.smcp',
            'ldot.smcp',
            'ldotbelow.smcp',
            'uni1E37.smcp', // ldotbelow
            'ldotbelowmacron.smcp',
            'uni1E39.smcp', // lmacrondotbelow
            'uni1e36.smcp',
            'uni1e38.smcp',
            'uni004c032300af.smcp',
            'lslash.smcp',
            'mcedilla.smcp',
            'mdotbelow.smcp',
            'mmacron.smcp',
            'nacute.smcp',
            'ncaron.smcp',
            'ncedilla.smcp',
            'ncommaaccent.smcp',
            'uni0145.smcp',
            'ndescender.smcp',
            'ndieresis.smcp',
            'ndotaccent.smcp',
            'ndotbelow.smcp',
            'ngrave.smcp',
            'nmacron.smcp',
            'ntilde.smcp',
            'eng.smcp',
            'oacute.smcp',
            'obreve.smcp',
            'ocaron.smcp',
            'ocedilla.smcp',
            'uni01ED.smcp', // ocedillamacron
            'uni01ec.smcp',
            'uni004f02db00af.smcp',
            'ocircumflex.smcp',
            'ocommaturnedabove.smcp',
            'odieresis.smcp',
            'odotabove.smcp',
            'odotabovemacron.smcp',
            'uni0231.smcp', // omacrondotabove
            'uni004f02d900af.smcp',
            'uni004f0329.smcp',
            'odotbelow.smcp',
            'odoublegrave.smcp',
            'ograve.smcp',
            'ohungarumlaut.smcp',
            'oinvertedbreve.smcp',
            'omacron.smcp',
            'uni006F0329.smcp', // olinebelow
            'uni0230.smcp',
            'oogonek.smcp',
            'oogonekmacron.smcp',
            'oslash.smcp',
            'oslashacute.smcp',
            'otilde.smcp',
            'otildemacron.smcp',
            'ounderlineaccent.smcp',
            'barredo.smcp',
            'oe.smcp',
            'pmacron.smcp',
            'racute.smcp',
            'rcaron.smcp',
            'rcedilla.smcp',
            'rcommaaccent.smcp',
            'uni0156.smcp',
            'rdotbelow.smcp',
            'rdotbelowmacron.smcp',
            'rdoublegrave.smcp',
            'rinvertedbreve.smcp',
            'sacute.smcp',
            'scaron.smcp',
            'scedilla.smcp',
            'uni015e.smcp',
            'scircumflex.smcp',
            'scommaaccent.smcp',
            'germandbls.smcp',
            'uni0218.smcp',
            'sdotbelow.smcp',
            'tbar.smcp',
            'tcaron.smcp',
            'tcedilla.smcp',
            'uni0162.smcp',
            'tcommaaccent.smcp',
            'tdotbelow.smcp',
            'uni021B.smcp', // tcommabelow
            'uni021a.smcp',
            'uacute.smcp',
            'ubreve.smcp',
            'ucaron.smcp',
            'ucircumflex.smcp',
            'udieresis.smcp',
            'uni01DA.smcp', // udieresiscaron
            'uni01d9.smcp',
            'udotbelow.smcp',
            'udoublegrave.smcp',
            'ugrave.smcp',
            'uhungarumlaut.smcp',
            'uinvertedbreve.smcp',
            'umacron.smcp',
            'umacronbelow.smcp',
            'uogonek.smcp',
            'uring.smcp',
            'utilde.smcp',
            'wacute.smcp',
            'wcircumflex.smcp',
            'wdieresis.smcp',
            'wgrave.smcp',
            'yacute.smcp',
            'ycircumflex.smcp',
            'ydieresis.smcp',
            'ygrave.smcp',
            'ymacron.smcp',
            'ytilde.smcp',
            'zacute.smcp',
            'zcaron.smcp',
            'zdotaccent.smcp',
            'thorn.smcp',
            'schwa.smcp',
            'ezh.smcp',
            'ezhcaron.smcp',
        ],
    },
    {
        title: 'Small capital accent alternates',
        orderedGlyphNames: [
            'Dcaron.1sc',
            'Dcaron.1.sc',
            'Eth.1sc',
            'Eth.1.sc',
            'Dcroat.1sc',
            'Dcroat.1.sc',
            'Racute.1sc',
            'Racute.1.sc',
            'uni0156.1sc', // Rcommabelow
            'Rcommaaccent.1.sc',
            'Rcaron.1sc',
            'Rcaron.1.sc',
            //
            'racute_alt.smcp',
            'rcaron_alt.smcp',
            'rinvertedbreve_alt.smcp',
            'rdoublegrave_alt.smcp',
            'rcommaaccent_alt.smcp',
            'rcedilla_alt.smcp',
            'rdotbelow_alt.smcp',
            'rdotbelowmacron_alt.smcp',
        ],
    },
    {
        title: 'Numerals',
        orderedGlyphNames: [
            'zero',
            'one',
            'two',
            'three',
            'four',
            'five',
            'six',
            'seven',
            'eight',
            'nine',
        ],
    },
    {
        title: 'Numeral alternates',
        orderedGlyphNames: [
            'zero.slash',
            'zero.alt',
            'one.alt',
            'one.1',
            'one.2',
            'two.alt',
            'three.alt',
            'four.alt',
        ],
    },
    {
        title: 'Lining numerals',
        orderedGlyphNames: [
            'zero.LP',
            'one.LP',
            'two.LP',
            'three.LP',
            'four.LP',
            'five.LP',
            'six.LP',
            'seven.LP',
            'eight.LP',
            'nine.LP',
        ],
    },
    {
        title: 'Lining numeral alternates',
        orderedGlyphNames: ['zerolp.slash', 'zeroLP.slash'],
    },
    {
        title: 'Old-style numerals',
        orderedGlyphNames: [
            'zero.op',
            'one.op',
            'two.op',
            'three.op',
            'four.op',
            'five.op',
            'six.op',
            'seven.op',
            'eight.op',
            'nine.op',
            //
            'zero.OP',
            'one.OP',
            'two.OP',
            'three.OP',
            'four.OP',
            'five.OP',
            'six.OP',
            'seven.OP',
            'eight.OP',
            'nine.OP',
        ],
    },
    {
        title: 'Old-style numeral alternates',
        orderedGlyphNames: [
            'zero_slash.op',
            'zeroop.slash',
            'zeroOP.slash',
            'zero.slash.op',
            'one.op.alt',
        ],
    },
    {
        title: 'Small capital numerals',
        orderedGlyphNames: [
            'zero.sc',
            'one.sc',
            'two.sc',
            'three.sc',
            'four.sc',
            'five.sc',
            'six.sc',
            'seven.sc',
            'eight.sc',
            'nine.sc',
            //
            'zero.XP',
            'one.XP',
            'two.XP',
            'three.XP',
            'four.XP',
            'five.XP',
            'six.XP',
            'seven.XP',
            'eight.XP',
            'nine.XP',
        ],
    },
    // {
    //     title: 'Small capital numeral alternates',
    // },
    {
        title: 'Tabular lining numerals',
        orderedGlyphNames: [
            'zero.lt',
            'one.lt',
            'two.lt',
            'three.lt',
            'four.lt',
            'five.lt',
            'six.lt',
            'seven.lt',
            'eight.lt',
            'nine.lt',
            //
            'zero.LT',
            'one.LT',
            'two.LT',
            'three.LT',
            'four.LT',
            'five.LT',
            'six.LT',
            'seven.LT',
            'eight.LT',
            'nine.LT',
        ],
    },
    {
        title: 'Tabular lining numeral alternates',
        orderedGlyphNames: [
            'zero_slash.lt',
            'zeroLT.slash',
            'zero.slashlt',
            'zero.slash.lt',
            'one.lt.alt',
            'one.lt_brand',
            'one.1.lt',
            'one.2.lt',
            'two.lt.alt',
            'three.lt.alt',
            'four.lt.alt',
        ],
    },
    {
        title: 'Tabular old-style numerals',
        orderedGlyphNames: [
            'zero.ot',
            'one.ot',
            'two.ot',
            'three.ot',
            'four.ot',
            'five.ot',
            'six.ot',
            'seven.ot',
            'eight.ot',
            'nine.ot',
            //
            'zero.OT',
            'one.OT',
            'two.OT',
            'three.OT',
            'four.OT',
            'five.OT',
            'six.OT',
            'seven.OT',
            'eight.OT',
            'nine.OT',
        ],
    },
    {
        title: 'Tabular old-style numeral alternates',
        orderedGlyphNames: [
            'zero.ot.alt',
            'one.ot.alt',
            'zero_slash.ot',
            'zero.slash.ot',
            'zeroOT.slash',
        ],
    },
    {
        title: 'Tabular small capital numerals',
        orderedGlyphNames: [
            'zero.st',
            'one.st',
            'two.st',
            'three.st',
            'four.st',
            'five.st',
            'six.st',
            'seven.st',
            'eight.st',
            'nine.st',
        ],
    },
    {
        title: 'Currency & math',
        orderedGlyphNames: [
            'dollar',
            'cent',
            'sterling',
            'yen',
            'baht',
            'uni0E3F', // baht
            'uni20A1', // COLON
            'uni20A2', // CRUZEIRO
            'franc',
            'uni20BD', // ruble
            'lira',
            'uni20BA', // lira
            'naira',
            'uni20A6', // NAIRA
            'peseta',
            'uni20A8', // rupee
            'won',
            'uni20A9', // won
            'uni20AA', // NEW SHEQEL
            'Euro',
            'uni20B9', // INDIAN RUPEE
            'uni20AE', // TUGRIK
            'peso',
            'uni20B1', // Peso
            'dong',
            'florin',
            'uni0192', // florin
            //
            'plus',
            'minus',
            'plusminus',
            'divide',
            'multiply',
            'equal',
            'less',
            'greater',
            'lessequal',
            'greaterequal',
            'approxequal',
            'notequal',
            'logicalnot',
            //
            'numbersign',
            'percent',
            'perthousand',
        ],
    },
    {
        title: 'Currency & math alternates',
        orderedGlyphNames: ['divide.i'],
    },
    {
        title: 'Old-style currency & math',
        orderedGlyphNames: [
            'dollar.op',
            'cent.op',
            'sterling.op',
            'yen.op',
            'baht.op',
            'uni0E3F.op', // baht
            'uni20A1.op', // COLON
            'uni20A2.op', // CRUZEIRO
            'franc.op',
            'uni20BD.op', // ruble
            'lira.op',
            'uni20BA.op', // lira
            'naira.op',
            'uni20A6.op', // NAIRA
            'peseta.op',
            'uni20A8.op', // rupee
            'won.op',
            'uni20A9.op', // won
            'uni20AA.op', // NEW SHEQEL
            'Euro.op',
            'uni20B9.op', // INDIAN RUPEE
            'uni20AE.op', // TUGRIK
            'peso.op',
            'uni20B1.op', // Peso
            'dong.op',
            'florin.op',
            'uni0192.op', // florin
            //
            'plus.op',
            'minus.op',
            'plusminus.op',
            'divide.op',
            'multiply.op',
            'equal.op',
            'less.op',
            'greater.op',
            'lessequal.op',
            'greaterequal.op',
            'approxequal.op',
            'notequal.op',
            'logicalnot.op',
            //
            'percent.op',
            'perthousand.op',
        ],
    },
    {
        title: 'Old-style currency & math alternates',
        orderedGlyphNames: ['divide.op.i'],
    },
    {
        title: 'Lining currency & math',
        orderedGlyphNames: [
            'dollar.LP',
            'cent.LP',
            'sterling.LP',
            'yen.LP',
            'baht.LP',
            'uni0E3F.LP', // baht
            'uni20A1.LP', // COLON
            'uni20A2.LP', // CRUZEIRO
            'franc.LP',
            'uni20A3.LP', // franc
            'uni20BD.LP', // ruble
            'lira.LP',
            'uni20BA.LP', // lira
            'uni20A4.LP', // lira
            'naira.LP',
            'uni20A6.LP', // NAIRA
            'peseta.LP',
            'uni20A7.LP', // peseta
            'uni20A8.LP', // rupee
            'won.LP',
            'uni20A9.LP', // won
            'uni20AA.LP', // NEW SHEQEL
            'Euro.LP',
            'uni20B9.LP', // INDIAN RUPEE
            'uni20AE.LP', // TUGRIK
            'peso.LP',
            'uni20B1.LP', // Peso
            'dong.LP',
            'florin.LP',
            'uni0192.LP', // florin
            //
            'plus.LP',
            'minus.LP',
            'plusminus.LP',
            'divide.LP',
            'multiply.LP',
            'equal.LP',
            'less.LP',
            'greater.LP',
            'lessequal.LP',
            'greaterequal.LP',
            'approxequal.LP',
            'notequal.LP',
            'logicalnot.LP',
            //
            'plus.cap',
            'minus.cap',
            'plusminus.cap',
            'divide.cap',
            'multiply.cap',
            'equal.cap',
            'less.cap',
            'greater.cap',
            'lessequal.cap',
            'greaterequal.cap',
            'approxequal.cap',
            'notequal.cap',
            'logicalnot.cap',
            //
            'numbersign.LP',
            'percent.LP',
            'perthousand.LP',
        ],
    },
    {
        title: 'Small capital currency & math',
        orderedGlyphNames: [
            'dollar.sc',
            'cent.sc',
            'sterling.sc',
            'yen.sc',
            'baht.sc',
            'uni0E3F.sc', // baht
            'uni20A1.sc', // COLON
            'uni20A2.sc', // CRUZEIRO
            'franc.sc',
            'uni20BD.sc', // ruble
            'lira.sc',
            'uni20BA.sc', // lira
            'naira.sc',
            'uni20A6.sc', // NAIRA
            'peseta.sc',
            'uni20A8.sc', // rupee
            'won.sc',
            'uni20A9.sc', // won
            'uni20AA.sc', // NEW SHEQEL
            'Euro.sc',
            'uni20B9.sc', // INDIAN RUPEE
            'uni20AE.sc', // TUGRIK
            'peso.sc',
            'uni20B1.sc', // Peso
            'dong.sc',
            'florin.sc',
            'uni0192.sc', // florin
            //
            'plus.sc',
            'minus.sc',
            'plusminus.sc',
            'divide.sc',
            'multiply.sc',
            'equal.sc',
            'less.sc',
            'greater.sc',
            'lessequal.sc',
            'greaterequal.sc',
            'approxequal.sc',
            'notequal.sc',
            'logicalnot.sc',
        ],
    },
    {
        title: 'Tabular old-style currency & math',
        orderedGlyphNames: [
            'dollar.ot',
            'cent.ot',
            'sterling.ot',
            'yen.ot',
            'baht.ot',
            'uni0E3F.ot', // baht
            'uni20A1.ot', // COLON
            'uni20A2.ot', // CRUZEIRO
            'franc.ot',
            'uni20BD.ot', // ruble
            'lira.ot',
            'uni20BA.ot', // lira
            'naira.ot',
            'uni20A6.ot', // NAIRA
            'peseta.ot',
            'uni20A8.ot', // rupee
            'won.ot',
            'uni20A9.ot', // won
            'uni20AA.ot', // NEW SHEQEL
            'Euro.ot',
            'uni20B9.ot', // INDIAN RUPEE
            'uni20AE.ot', // TUGRIK
            'peso.ot',
            'uni20B1.ot', // Peso
            'dong.ot',
            'florin.ot',
            'uni0192.ot', // florin
            //
            'plus.ot',
            'minus.ot',
            'plusminus.ot',
            'divide.ot',
            'multiply.ot',
            'equal.ot',
            'less.ot',
            'greater.ot',
            'lessequal.ot',
            'greaterequal.ot',
            'approxequal.ot',
            'notequal.ot',
            'logicalnot.ot',
            //
            'percent.ot',
            'perthousand.ot',
        ],
    },
    {
        title: 'Tabular lining currency & math',
        orderedGlyphNames: [
            'dollar.lt',
            'cent.lt',
            'sterling.lt',
            'yen.lt',
            'baht.lt',
            'uni0E3F.lt', // baht
            'uni20A1.lt', // COLON
            'uni20A2.lt', // CRUZEIRO
            'franc.lt',
            'uni20BD.lt', // ruble
            'lira.lt',
            'uni20BA.lt', // lira
            'naira.lt',
            'uni20A6.lt', // NAIRA
            'peseta.lt',
            'uni20A8.lt', // rupee
            'won.lt',
            'uni20A9.lt', // won
            'uni20AA.lt', // NEW SHEQEL
            'Euro.lt',
            'uni20B9.lt', // INDIAN RUPEE
            'uni20AE.lt', // TUGRIK
            'peso.lt',
            'uni20B1.lt', // Peso
            'dong.lt',
            'florin.lt',
            'uni0192.lt', // florin
            //
            'plus.lt',
            'minus.lt',
            'plusminus.lt',
            'divide.lt',
            'multiply.lt',
            'equal.lt',
            'less.lt',
            'greater.lt',
            'lessequal.lt',
            'greaterequal.lt',
            'approxequal.lt',
            'notequal.lt',
            'logicalnot.lt',
            //
            'percent.lt',
            'perthousand.lt',
        ],
    },
    {
        title: 'Tabular small capital currency & math',
        orderedGlyphNames: [
            'dollar.st',
            'cent.st',
            'sterling.st',
            'yen.st',
            'baht.st',
            'uni0E3F.st', // baht
            'uni20A1.st', // COLON
            'uni20A2.st', // CRUZEIRO
            'franc.st',
            'uni20BD.st', // ruble
            'lira.st',
            'uni20BA.st', // lira
            'naira.st',
            'uni20A6.st', // NAIRA
            'peseta.st',
            'uni20A8.st', // rupee
            'won.st',
            'uni20A9.st', // won
            'uni20AA.st', // NEW SHEQEL
            'Euro.st',
            'uni20B9.st', // INDIAN RUPEE
            'uni20AE.st', // TUGRIK
            'peso.st',
            'uni20B1.st', // Peso
            'dong.st',
            'florin.st',
            'uni0192.st', // florin
            //
            'plus.st',
            'minus.st',
            'plusminus.st',
            'divide.st',
            'multiply.st',
            'equal.st',
            'less.st',
            'greater.st',
            'lessequal.st',
            'greaterequal.st',
            'approxequal.st',
            'notequal.st',
            'logicalnot.st',
            //
            'percent.st',
            'perthousand.st',
        ],
    },
    {
        title: 'Superscript',
        orderedGlyphNames: [
            'uni2070', // zerosuperior
            'onesuperior',
            'uni00B9', // onesuperior
            'twosuperior',
            'uni00B2', // twosuperior
            'threesuperior',
            'uni00B3', // threesuperior
            'uni2074', // foursuperior
            'uni2075', // fivesuperior
            'uni2076', // sixsuperior
            'uni2077', // sevensuperior
            'uni2078', // eightsuperior
            'uni2079', // ninesuperior
            'uni207D', // parenleft superscript
            'uni207E', // parenright superscript
            'uni207A', // plus superscript
            'uni207B', // minus superscript
            'uni207C', // equal superscript
        ],
    },
    {
        title: 'Superscript alternates',
        orderedGlyphNames: [
            'onesuperior.alt',
            'twosuperior.alt',
            'threesuperior.alt',
        ],
    },
    {
        title: 'Denominator',
        orderedGlyphNames: [
            'zero.dnom',
            'one.dnom',
            'two.dnom',
            'three.dnom',
            'four.dnom',
            'five.dnom',
            'six.dnom',
            'seven.dnom',
            'eight.dnom',
            'nine.dnom',
        ],
    },
    {
        title: 'Subscript',
        orderedGlyphNames: [
            'uni2080', // zero subscript
            'uni2081', // one subscript
            'uni2082', // two subscript
            'uni2083', // three subscript
            'uni2084', // four subscript
            'uni2085', // five subscript
            'uni2086', // six subscript
            'uni2087', // seven subscript
            'uni2088', // eight subscript
            'uni2089', // nine subscript
            'uni208D', // parenleft subscript
            'uni208E', // parenright subscript
            'uni208A', // plus subscript
            'uni208B', // minus subscript
            'uni208C', // equal subscript
            //
            'zero.inf',
            'one.inf',
            'two.inf',
            'three.inf',
            'four.inf',
            'five.inf',
            'six.inf',
            'seven.inf',
            'eight.inf',
            'nine.inf',
            'plus.inf',
            'minus.inf',
            'equal.inf',
            'parenleft.inf',
            'parenright.inf',
        ],
    },
    {
        title: 'Ordinals',
        orderedGlyphNames: [
            'ordfeminine',
            'ordmasculine',
            //
            'a.superior',
            'b.superior',
            'c.superior',
            'cordinal',
            'd.superior',
            'e.superior',
            'f.superior',
            'g.superior',
            'h.superior',
            'i.superior',
            'j.superior',
            'k.superior',
            'l.superior',
            'm.superior',
            'n.superior',
            'o.superior',
            'p.superior',
            'q.superior',
            'r.superior',
            's.superior',
            't.superior',
            'u.superior',
            'v.superior',
            'w.superior',
            'x.superior',
            'y.superior',
            'z.superior',
            'a.ord',
            'b.ord',
            'c.ord',
            'd.ord',
            'e.ord',
            'f.ord',
            'g.ord',
            'h.ord',
            'i.ord',
            'j.ord',
            'k.ord',
            'l.ord',
            'm.ord',
            'n.ord',
            'o.ord',
            'p.ord',
            'q.ord',
            'r.ord',
            's.ord',
            't.ord',
            'u.ord',
            'v.ord',
            'w.ord',
            'x.ord',
            'y.ord',
            'z.ord',
        ],
    },
    {
        title: 'Ordinal alternates',
        orderedGlyphNames: [
            'ordfeminine.school',
            'ordfeminine.1',
            'gsuperior.school',
            'ysuperior.school',
            'a.1.ord',
            'a.1ord',
            'a.ord.school',
            'g.1ord',
            'g.1.ord',
        ],
    },
    {
        title: 'Ligatures',
        orderedGlyphNames: [
            'T_T',
            'T_h',
            'c_y',
            'f_b',
            'f_f',
            'ff',
            'f_h',
            'fi',
            'uniFB01', // fi
            'f_j',
            'f_k',
            'fl',
            'uniFB02', // fl
            'f_t',
            'f_y',
            'f_f_b',
            'f_f_h',
            'ffi',
            'f_f_i',
            'f_f_j',
            'f_f_k',
            'ffl',
            'f_f_l',
            'f_f_t',
            'f_f_y',
            'f_t_t',
            'g_y',
            'r_y',
            't_t',
            't_t_y',
            't_y',
            'w_w_w',
            'z_z',
        ],
    },
    {
        title: 'Ligature accents',
        orderedGlyphNames: [
            'f_dotlessi',
            'f_idieresis',
            'f_adieresis',
            'f_f_adieresis',
            'f_f_dotlessi',
            'f_f_idieresis',
            'g_yacute',
        ],
    },
    {
        title: 'Ligature alternates',
        orderedGlyphNames: [
            'f_b.s',
            'ff.s',
            'f_h.1',
            'f_h.s',
            'f_h.s.1',
            'fi.s',
            'f_j.s',
            'f_k.s',
            'fl.s',
            'f_t.s',
            'f_f_b.s',
            'f_f.short',
            'f_f_short',
            'f_f_h.1',
            'f_f_h.s',
            'f_f_h.s.1',
            'ffi.s',
            'f_f_j.s',
            'f_f_k.s',
            'ffl.s',
            'f_f_t.s',
            'z_z.1',
        ],
    },
    {
        title: 'Discretionary ligatures',
        orderedGlyphNames: [
            'c_b',
            'c_h',
            'c_k',
            'c_l',
            'c_p',
            'c_t',
            'g_i',
            'g_j',
            'i_m',
            'i_n',
            'i_p',
            'i_r',
            'i_t',
            'i_t_t',
            'i_v',
            'i_w',
            'i_x',
            'i_y',
            's_b',
            's_h',
            's_k',
            's_l',
            's_p',
            'uniFB06', // s_t ligature
            's_t',
            's_t_y',
        ],
    },
    {
        title: 'Discretionary ligature accents',
        orderedGlyphNames: [
            'c_lslash',
            'c_tcaron',
            'c_tcommaaccent',
            'ccedilla_k',
            'ccedilla_l',
            's_lacute',
            's_lcaron',
            's_lslash',
            's_tcaron',
            's_tcommaaccent',
            'scedilla_k',
            'scedilla_l',
            'scedilla_p',
            'scedilla_t',
            'scommaaccent_p',
            'scommaaccent_t',
            'z_zcaron',
            'z_zdotaccent',
            'zcaron_z',
            'zcaron_zcaron',
            'zdotaccent_zdotaccent',
        ],
    },
    {
        title: 'Discretionary ligature alternates',
        orderedGlyphNames: [
            'c_k.1',
            'c_h.1',
            'f_k.1',
            'f_f_k.1',
            's_h.1',
            's_k.1',
        ],
    },
    {
        title: 'Discretionary ligature alternate accents',
        orderedGlyphNames: ['ccedilla_k.1', 'scedilla_k.1'],
    },
    {
        title: 'Punctuation & symbols',
        orderedGlyphNames: [
            'ampersand',
            'at',
            'uni2116', // numero
            'afii61352', // numero
            //
            'parenleft',
            'parenright',
            'bracketleft',
            'bracketright',
            'braceleft',
            'braceright',
            'anglebracketleft',
            'anglebracketright',
            //
            'slash',
            'bar',
            'doublebar',
            'brokenbar',
            'backslash',
            //
            'exclam',
            'exclamdown',
            'question',
            'questiondown',
            'interrobang',
            'interrobangdown',
            //
            'periodcentered',
            'bullet',
            //
            'uni00AD', // SOFT HYPHEN
            'hyphen',
            'uni2011', // NON-BREAKING HYPHEN
            'figuredash',
            'endash',
            'onethirdemdash',
            'threequarteremdash',
            'emdash',
            'uni2015', // HORIZONTAL BAR
            'asciitilde',
            'underscore',
            'underscoremonospace',
            //
            'period',
            'comma',
            'colon',
            'semicolon',
            'ellipsis',
            //
            'quotesingle',
            'quotedbl',
            'tripleprime',
            //
            'quoteleft',
            'quoteright',
            'quotedblleft',
            'quotedblright',
            'reversequoteleft',
            'reversequotedblleft',
            'quotesinglbase',
            'quotedblbase',
            //
            'guilsinglleft',
            'guilsinglright',
            'guillemotleft',
            'guillemotright',
            //
            'degree',
            'asciicircum',
            //
            'asterisk',
            'dagger',
            'daggerdbl',
            'section',
            'paragraph',
            //
            'currency',
            'copyright',
            'registered',
            'registered.superior',
            'trademark',
            'servicemark',
            'uni2117', // published
            'published',
            'u2117', // published
            'estimated',
            'afii61289', // litre
            //
            'partialdiff',
            'increment',
            'uni2206', // increment
            'Delta',
            'product',
            'summation',
            'ohm',
            'uni2126', // ohm
            'Omega',
            'uni03A9', // Omega
            'mu',
            'uni03BC', // mu
            'pi',
            //
            'radical',
            'infinity',
            'integral',
            'dotmath',
            'Numero',
            'numero',
        ],
    },
    {
        title: 'Punctuation & symbol alternates',
        orderedGlyphNames: [
            'ampersand.alt',
            'exclam.alt',
            'exclamdown.alt',
            'question.alt',
            'questiondown.alt',
            'periodcentered.alt',
            'period.alt',
            'comma.alt',
            'colon.mid',
            'colon.alt',
            'semicolon.alt',
            'ellipsis.alt',
            'quoteleft.alt',
            'quoteright.alt',
            'quotedblleft.alt',
            'quotedblright.alt',
            'quotesinglbase.alt',
            'quotedblbase.alt',
        ],
    },
    {
        title: 'Punctuation & symbol capital forms',
        orderedGlyphNames: [
            'at.cap',
            'uni2116.cap', // numero
            'afii61352.cap', // numero
            //
            'parenleft.cap',
            'parenright.cap',
            'bracketleft.cap',
            'bracketright.cap',
            'braceleft.cap',
            'braceright.cap',
            'anglebracketleft.cap',
            'anglebracketright.cap',
            //
            'slash.cap',
            'bar.cap',
            'doublebar.cap',
            'brokenbar.cap',
            'backslash.cap',
            //
            'exclam.cap',
            'exclamdown.cap',
            'question.cap',
            'questiondown.cap',
            'interrobang.cap',
            'interrobangdown.cap',
            //
            'periodcentered.cap',
            'bullet.cap',
            //
            'uni00AD.cap', // SOFT HYPHEN
            'hyphen.cap',
            'uni2011.cap', // NON-BREAKING HYPHEN
            'figuredash.cap',
            'endash.cap',
            'onethirdemdash.cap',
            'threequarteremdash.cap',
            'emdash.cap',
            'uni2015.cap', // HORIZONTAL BAR
            'asciitilde.cap',
            'underscore.cap',
            'underscoremonospace.cap',
            //
            'guilsinglleft.cap',
            'guilsinglright.cap',
            'guillemotleft.cap',
            'guillemotright.cap',
            //
            'registered.cap',
        ],
    },
    {
        title: 'Punctuation & symbol capital alternates',
        orderedGlyphNames: [
            'exclamdown.alt.cap',
            'questiondown.alt.cap',
            'periodcentered.alt.cap',
        ],
    },
    {
        title: 'Punctuation & symbol small capitals',
        orderedGlyphNames: [
            'ordfeminine.smcp',
            'ordmasculine.smcp',
            'cordinal.smcp',
            //
            'ampersand.smcp',
            'at.smcp',
            'uni2116.smcp', // numero
            'afii61352.smcp', // numero
            //
            'parenleft.smcp',
            'parenright.smcp',
            'bracketleft.smcp',
            'bracketright.smcp',
            'braceleft.smcp',
            'braceright.smcp',
            'anglebracketleft.smcp',
            'anglebracketright.smcp',
            //
            'slash.smcp',
            'bar.smcp',
            'doublebar.smcp',
            'brokenbar.smcp',
            'backslash.smcp',
            //
            'exclam.smcp',
            'exclamdown.smcp',
            'question.smcp',
            'questiondown.smcp',
            'interrobang.smcp',
            'interrobangdown.smcp',
            //
            'periodcentered.smcp',
            'bullet.smcp',
            //
            'uni00AD.smcp', // SOFT HYPHEN
            'hyphen.smcp',
            'uni2011.smcp', // NON-BREAKING HYPHEN
            'figuredash.smcp',
            'endash.smcp',
            'onethirdemdash.smcp',
            'threequarteremdash.smcp',
            'emdash.smcp',
            'uni2015.smcp', // HORIZONTAL BAR
            'asciitilde.smcp',
            'underscore.smcp',
            'underscoremonospace.smcp',
            //
            'guilsinglleft.smcp',
            'guilsinglright.smcp',
            'guillemotleft.smcp',
            'guillemotright.smcp',
            //
            'registered.smcp',
            //
            'parenleft.sc',
            'parenright.sc',
            'bracketleft.sc',
            'bracketright.sc',
            'braceleft.sc',
            'braceright.sc',
            'anglebracketleft.sc',
            'anglebracketright.sc',
            //
            'slash.sc',
            'bar.sc',
            'doublebar.sc',
            'brokenbar.sc',
            'backslash.sc',
            //
            'ampersand.sc',
            'exclam.sc',
            'exclamdown.sc',
            'question.sc',
            'questiondown.sc',
            'interrobang.sc',
            'interrobangdown.sc',
        ],
    },
    {
        title: 'Tabular punctuation',
        orderedGlyphNames: [
            'period.tab',
            'comma.tab',
            'colon.tab',
            'semicolon.tab',
        ],
    },
    {
        title: 'Prebuilt fractions',
        orderedGlyphNames: [
            'onequarter',
            'onehalf',
            'threequarters',
            'uni2153',
            'uni2154',
            'uni2155',
            'uni2156',
            'uni2157',
            'uni2158',
            'uni2159',
            'uni215A',
            'uni215B',
            'uni215C',
            'uni215D',
            'uni215E',
            'uni215F',
            'elevensixteenths',
            'fifteensixteenths',
            'fivesixteenths',
            'ninesixteenths',
            'onesixteenth',
            'sevensixteenths',
            'thirteensixteenths',
            'threesixteenths',
        ],
    },
    {
        title: 'Prebuilt fraction alternates',
        orderedGlyphNames: [
            'onequarter.alt',
            'onehalf.alt',
            'threequarters.alt',
            'uni2153.alt',
            'uni2154.alt',
            'uni215B.alt',
            'uni215C.alt',
        ],
    },
    {
        title: 'Arrows',
        orderedGlyphNames: [
            'arrowleft',
            'arrowup',
            'arrowright',
            'arrowdown',
            'arrowrightfeatheredwhite',
            'arrowleftright',
            'arrowupdown',
            'arrowNW',
            'uni2196', // arrowNW
            'arrowNE',
            'uni2197', // arrowNE
            'arrowSE',
            'uni2198', // arrowSE
            'arrowSW',
            'uni2199', // arrowSW
            'cloudLightning', // Cloud download
        ],
    },
    {
        title: 'Shapes',
        orderedGlyphNames: [
            'blacksquare',
            'whitesquare',
            'blacksmallsquare',
            'whitesmallsquare',
            'triagup',
            'triagrt',
            'triagdn',
            'triaglf',
            'lozenge',
            'uni25E2',
            'uni25E3',
            'uni25E4',
            'uni25E5',
            'blackcircle',
            'whitecircle',
            'circle',
        ],
    },
    {
        title: 'Playing card symbols',
        orderedGlyphNames: [
            'diamond',
            'heart',
            'spade',
            'club',
            'uni2662',
            'uni2661',
            'uni2664',
            'uni2667',
        ],
    },
    {
        title: 'Emo',
        orderedGlyphNames: [
            'emo_angry',
            'emo_frown',
            'emo_happy',
            'emo_sad',
            'emo_undecided',
            'emo_wink',
        ],
    },
];
