export default [
    {
        title: 'Basic character set',
        content:
            'ABCDEFGHIJKLMNOPQRSTUVWXYZ\nabcdefghijklmnopqrstuvwxyz\n0123456789 $£¥€¢ — @!?&.,',
    },
    {
        title: 'Čeština',
        content:
            'Newspeak (česky: novořeč) je jazyk z knihy George Orwella 1984. Newspeak je založen na angličtině, která je zde jmenována jako Oldspeak, ovšem má velice zjednodušenou gramatiku a zúženou slovní zásobu. Takto okleštěný jazyk měl sloužit totalitně vládnoucí Straně k tomu, aby občané neměli ani výrazy k vyjádření nesouhlasu s politikou Strany. Autor doplnil román doslovem, kde se věnuje problematice Newspeaku a vysvětluje jeho základní principy. „Newspeak nebyl vytvořen pouze k vyjadřování světonázorových myšlenkových postupů vlastních oddaným stoupencům Angsocu, nýbrž proto, aby znemožnil všechny jiné způsoby myšlení. Záměr byl ten, že až si newspeak všichni jednou provždy osvojí a oldspeak bude zapomenut, stane se kacířské myšlení—to jest myšlení, které se odchyluje od zásad Angsocu—doslova nemyslitelné, aspoň v té míře, v níž je myšlení závislé na slovech.“',
    },
    {
        title: 'Dansk',
        content:
            'Nysprog (fra engelsk Newspeak) er det opdigtede sprog i George Orwells roman 1984. Her fortælles det, »at nysprog er det eneste sprog i verden, hvis gloseforråd bliver mindre hvert år.« Orwell tilføjede et essay i form af et tillæg (skrevet i datid), hvori nysprogs grundlæggende principper forklares. Sproget er i høj grad baseret på engelsk, men det har et meget reduceret og forenklet ordforråd og grammatik. Det passer til Partiets totalitære styre, hvis formål er at gøre alle alternative måder at tænke på (»tankeforbrydelse«), eller tale på, ved at fjerne alle ord og sætningskonstruktioner, som beskriver begreberne frihed, oprør etc. På nysprog kaldes engelsk for »gammelsprog«. Det forventes, at gammelsprog er blevet fuldstændigt erstattet af nysprog inden 2050. Hvordan Orwells Nysprog er opstået kan man læse i hans essay Politics and the English Language fra 1946.',
    },
    {
        title: 'Deutsch',
        content:
            'Der Ausdruck Neusprech (englisch: Newspeak, in älteren Versionen als Neusprache übersetzt) stammt aus dem Roman 1984 von George Orwell und bezeichnet eine Sprache, die aus politischen Gründen künstlich modifiziert wurde. Bei Neusprech handelt es sich um eine kritische Satire auf das Basic English von Charles Kay Ogden und I. A. Richards, das Orwell zwischen 1942 und 1944 noch wohlwollend unterstützte, bevor er es ab 1946 in seinem Aufsatz Politics and the English Language bekämpfte. Das subjektive Empfinden zum Ausdruck bringende Wörter sollen durch anscheinend objektive Wendungen wie gut—plusgut—doppelplusgut abgelöst werden, schlecht wird ersetzt durch ungut, um Sprechen, Denken und Empfinden voneinander abzukoppeln. Die Wörter verlieren außerdem Teile ihrer ursprünglichen Bedeutung. Es gibt in Neusprech zwar noch das Wort „frei“, jedoch nicht mehr im Sinne von „politisch frei“, sondern nur noch in der Bedeutung von „ohne“.',
    },
    {
        title: 'English',
        content:
            'Newspeak is the fictional language in the novel Nineteen Eighty-Four, written by George Orwell. It is a controlled language created by the totalitarian state Oceania as a tool to limit freedom of thought, and concepts that pose a threat to the regime such as freedom, self-expression, individuality, and peace. Any form of thought alternative to the party’s construct is classified as “thoughtcrime”. Newspeak is explained in chapters 4 and 5 of Nineteen Eighty-Four, and in an appendix to the book. The language follows, for the most part, the same grammatical rules as English, but has a much more limiting, and constantly shifting vocabulary. Any synonyms or antonyms, along with undesirable concepts are eradicated. The goal is for everyone to be speaking this language by the year 2050.',
    },
    {
        title: 'Español',
        content:
            'La neolengua o nuevahabla (Newspeak en inglés) es una lengua artística que aparece en la novela 1984, de George Orwell. Al final de esta novela aparece un apéndice titulado Los principios de la neolengua donde se explican los principios básicos de la misma. La neolengua no es más que una versión extremadamente simplificada del inglés (aunque en las traducciones de la obra se suele simplificar el idioma al que se traduce para evitar confusión), y es uno de los pilares básicos del régimen totalitario del Partido. El objetivo de crear tal lengua era sustituir a la viejalengua (Oldspeak), es decir, el que para el lector sería el inglés actual, para así dominar el pensamiento de los miembros del Partido, y hacer inviables otras formas de pensamiento contrarias a los principios del Ingsoc.',
    },
    {
        title: 'Français',
        content:
            'Le novlangue (traduit de l’anglais Newspeak, masculin dans la traduction française d’Amélie Audiberti) est la langue officielle d’Océania, inventée par George Orwell pour son roman 1984 (publié en 1949). Le principe est simple : plus on diminue le nombre de mots d’une langue, plus on diminue le nombre de concepts avec lesquels les gens peuvent réfléchir, plus on réduit les finesses du langage, moins les gens sont capables de réfléchir, et plus ils raisonnent à l’affect. La mauvaise maîtrise de la langue rend ainsi les gens stupides et dépendants. Ils deviennent des sujets aisément manipulables par les médias de masse tels que la télévision. C’est donc une simplification lexicale et syntaxique de la langue destinée à rendre impossible l’expression des idées potentiellement subversives et à éviter toute formulation de critique de l’État, l’objectif ultime étant d’aller jusqu’à empêcher l’« idée » même de cette critique.',
    },
    {
        title: 'Hrvatski',
        content:
            'Novogovor ili novozbor (engl. Newspeak) predstavlja izmišljeni jezik koji je engleski pisac George Orwell stvorio za potrebe svog romana Tisuću devetsto osamdeset četvrte, gdje je opisan kao službeni jezik fiktivne države Oceanije. Novogovor je u toj fikciji pojednostavljeni engleski jezik sa znatno smanjenim rječnikom i pojednostavljenom gramatikom. Orwell je u eseju koji predstavlja dodatak romana objasnio da je umjetno stvoren s ciljem da uklanjanjem „problematičnih“ pojmova zaustavi zlomisao, odnosno prihvaćanje „problematičnih“ ideja o slobodi i pobuni koje bi mogle ugroziti vladajući poredak. Kao i brojne druge riječi koje su se pojavile u romanu, tako je i novogovor ušao u široku uporabu, najčešće u pogrdnu smislu da bi se označila ideološka isključivost.',
    },
    {
        title: 'Íslenska',
        content:
            'Nýlenska (enska: Newspeak) er tungumál í staðleysuskáldsögunni 1984 eftir George Orwell og á í því þjóðfélagi sem hún lýsir að koma í staðinn fyrir gamlensku (Oldspeak), þ.e. venjulega enska tungu. Nýlenska á að hafa orðfæð að takmarki til að ydda tungumálið að hugsun flokksins, eyða blæbrigðum orða og koma þannig algjörlega í veg fyrir að menn geti upphugsað glæpi (sbr.: hugsanaglæpi). Skammstafanir eru algengar í nýlensku, veigrunarorð sem og afmáning orða eins og uppreisn og frelsi. 1984 eða Nítján hundruð áttatíu og fjögur er skáldsaga eftir George Orwell sem gerist árið 1984. Hún gerist í dystópítískri framtíð þar sem ríkið ræður öllu, jafnvel hugsunum þegna sinna. Hún er oft talin ein áhrifamesta bók 20. aldar og hefur hún verið sett í flokk með Veröld ný og góð eftir Aldous Huxley og Við eftir Jevgeníj Zamjatín sem ein þekktasta dystópíska skáldsaga sögunnar.',
    },
    {
        title: 'Italiano',
        content:
            'La neolingua (nell’originale Newspeak, ossia «nuovo parlare») è una lingua artificiale artistica immaginata e descritta da George Orwell per il suo libro 1984. Fine specifico della neolingua non è solo quello di fornire, a beneficio degli adepti del Socing, un mezzo espressivo che sostituisse la vecchia visione del mondo e le vecchie abitudini mentali, ma di rendere impossibile ogni altra forma di pensiero. Una volta che la neolingua fosse stata radicata nella popolazione e la vecchia lingua (archelingua) completamente dimenticata, ogni pensiero eretico (cioè contrario ai princìpi del partito) sarebbe divenuto letteralmente impossibile, almeno per quanto attiene a quelle forme speculative che derivano dalle parole. Questa lingua è stata ampiamente descritta dall’autore stesso nel suo romanzo, in un’apposita appendice intitolata I principi della neolingua.',
    },
    {
        title: 'Latina',
        content:
            'Newspeak sive “Novloc” (scilicet “Nova locutio”) est lingua ficticia a Georgius Orwell inventa in mythistoria 1984, a lingua Anglica derivata ut homines civitatis Oceaniae nec cogitare nec loqui possint nisi quae gubernatoribus placeant. Forsitan Georgius Orwell etiam castigare voluit modum quo hodiernae linguae formentur et colantur. Omnia difficiliora lingua expelluntur censurae causa. Exempli gratia ex verbis malum et bonum verba non-bonum et bonum fiunt. Ita verbo mali non iam opus est. Ita etiam modus cogitandi apud homines simplicius redditur. Abbreviationes maxime in usu sunt. Miniluv sive “Minicarum” administerium rerum interiorum dicit et “Minipax” administerium belli gerendi et Minitrue vel “Miniverum” administerium propagandae fidei.',
    },
    {
        title: 'Māori',
        content:
            'Ko Newspeak tētahi reo pakimaero i roto i te pukapuka Nineteen Eighty-Four, nā George Orwell i tuhi.  He reo whakahaere nā te kāwanatanga o Oceania i hanga kia whakaiti ngā whakaaro me ngā mahi hei whakatuma te tikanga whakahaere pērā i te mana, te rangatiratanga, te tūhāhā me te rangimārie. He “whakaaro taihara” ētahi atu whakaaro rereke ki ngā ture o te pāti. I roto i ngā wāhanga tuawha, tuarima me te āpitihanga o te pukapuka Nineteen Eighty-Four ētahi whakamarama o te reo Newspeak. I te nuinga o te wā, ka whai tēnei reo i ngā ture wete reo ōrite ki ngā ture wete reo o te reo pakeha. Ka haepapa te kāwanatanga i ngā kupu taurite, ngā kupu tauaro me ngā whakaaro houhou. Ko te tino whainga; ka kōrero ngā tāngata kātoa i tēnei reo mai te tau rua mano rima tekau.',
    },
    {
        title: 'Nederlands',
        content:
            'Newspeak is een fictieve taal in George Orwells roman 1984. Het is een taal die wordt gecreëerd en gecontroleerd door de totalitaire staat als een instrument om de vrijheid van gedachte en concepten die een bedreiging voor het regime vormen, zoals vrijheid, zelfexpressie, individualiteit, en vrede, te beperken. Elke vorm van denken die zou kunnen afwijken van de concepten van de partij wordt beschouwd als “thoughtcrime” (“gedachtemisdaad”). Newspeak wordt toegelicht in de hoofdstukken 4 en 5 van 1984, en in een appendix bij het boek. Synoniemen of antoniemen worden, net als ongewenste concepten, uitgeroeid, zodat men alleen nog maar politiek correcte dingen kan zeggen, omdat men eenvoudig de woorden niet heeft om dingen te zeggen of zelfs maar te denken die het regime onwelgevallig zijn. Het doel is om in het jaar 2050 iedereen deze taal te laten spreken.',
    },
    {
        title: 'Polski',
        content:
            'Nowomowa (ang. Newspeak)—sztuczny język obowiązujący w fikcyjnym, totalitarnym państwie Oceania opisanym przez George’a Orwella w powieści Rok 1984. Charakteryzuje się tendencją do eliminacji jak największej liczby „niepotrzebnych” lub niekorzystnie nacechowanych wyrazów przez zastąpienie ich sztucznymi, ale poprawnymi ekwiwalentami, w celu strywializowania języka oraz wyeliminowania nieprawomyślności przez takie przekonstruowanie języka, by niemożliwe stało się sformułowanie w myśli czegokolwiek, co godziłoby w panujący reżim—zarówno poprzez mowę, jak i myśli. Pojęcie nowomowy z czasem przekroczyło ramy powieści Orwella i używane jest również jako określenie narzędzia stosowanego przez władzę w państwach totalitarnych, która posługując się stałym zestawem typowych dla siebie określeń, fałszujących rzeczywistość, ma na celu narzucenie swoim obywatelom określonego systemu wartości.',
    },
    {
        title: 'Português',
        content:
            'Novilíngua ou novafala é um idioma fictício criado pelo governo hiperautoritário na obra literária 1984, de George Orwell. A novilíngua era desenvolvida não pela criação de novas palavras, mas pela “condensação” e “remoção” delas ou de alguns de seus sentidos, com o objetivo de restringir o escopo do pensamento. Uma vez que as pessoas não pudessem se referir a algo, isso passa a não existir. Assim, por meio do controle sobre a linguagem, o governo seria capaz de controlar o pensamento das pessoas, impedindo que ideias indesejáveis viessem a surgir. Não se deve confundir novilíngua com simples tabu a respeito de palavras. A ideia aqui consiste em restringir as possibilidades de raciocínio, não o simples proibir a menção a coisas, fatos ou pessoas indesejáveis. Uma das características da novafala é o fato de ela ser a primeira língua a reduzir seus termos.',
    },
    {
        title: 'Suomi',
        content:
            'Uuskieli (engl. newspeak) on englantilaisen kirjailija George Orwellin teoksessaan Vuonna 1984 kehittämä kuvitteellinen kieli. Se pohjautuu englantiin, mutta on virallisesti ohjailtua ja rajoitettua. Uuskielen tarkoitus on Orwellin kuvaamassa maailmassa tehdä toisinajattelu ja poliittinen kapinallisuus mahdottomaksi viemällä kansalaisilta käsitteet, joilla ilmaista poliittisesti vääräoppisia mielipiteitä. Romaanin mukaan uuskieli on ”maailman ainoa kieli, jonka sanasto pienenee joka vuosi.” Todellisuudessa uuskielen kaltaisia agglutinoivia kieliä on olemassa paljon, esimerkkeinä suomi ja japani. On mahdollista että Orwell mallinsi uuskielen esperanton mukaan; esimerkiksi uuskielen sana ungood on rakennettu samoin kuin esperanton sana malbona. Orwell asui vuonna 1927 Pariisissa tätinsä Ellen Kate Limouzinin ja tämän miehen Eugène Lantin, tunnetun esperantoaktiivin, luona.',
    },
    {
        title: 'Svenska',
        content:
            'Nyspråk, engelska: Newspeak, är språket som talas i samhället som beskrivs i romanen 1984 av George Orwell. Det syftar till att upprätthålla romanvärldens rigida och ofria samhälle genom att begränsa individens tankeutrymme och därigenom förhindra kritik mot ”Partiet” och dess ledare ”Storebror”. Språkets struktur och syfte beskrivs detaljerat i ett fristående appendix i Orwells roman. Det som är mest utmärkande för nyspråket är att mängden ord hela tiden minskas, och att sidobetydelser undviks.Begreppet nyspråk används också i överförd bemärkelse vid kritik mot svårförståeliga eufemismer i den politiska debatten och mot intetsägande organisationsnamn. Språket går ut på att man tar bort synonymer från ursprungsspråket (gammalspråk, engelska) så att språkets nyanser försvinner. Även motsatsadjektiv, och ord som kan betyda “fel” sak enligt partiet försvinner.',
    },
    {
        title: 'Türkçe',
        content:
            'Bin Dokuz Yüz Seksen Dört, George Orwell tarafından kaleme alınmış alegorik bir politik romandır. Hikayesi distopik bir dünyada geçer. Distopya romanlarının ünlülerindendir. Özellikle kitapta tanımlanan Big Brother (Büyük Birader) kavramı günümüzde de sıklıkla kullanılmaktadır. Aynı zamanda kitapta geçen “düşünce polisi” gibi kavramları da George Orwell günümüze kazandırmıştır. Orwell, romanı İskoçya’da verem ile boğuşurken 1947–1948 yılları arasında yazmıştır. Roman, Avrupa’daki Son Adam (The Last Man in Europe) ismiyle yazılmıştır. Öte yandan, ABD ve Birleşik Krallık’taki yayımcısı (roman bu iki ülkede aynı anda satışa sunulmuştur) pazarlama meseleleri nedeniyle romanın adını Bin Dokuz Yüz Seksen Dört’e (Nineteen Eighty-Four) çevirmiştir. Roman ilk kez 8 Haziran 1949’da basılmıştır.',
    },
];
