import React from 'react';
import styled from 'styled-components';
import { ZINDEX } from '../settings/Global';
import { useGlobalState } from './GlobalRuntimeState';

export const MENU_ANIMATION_TIME = '300ms';

const SiteOverlayBlockClicks = styled.div<{
    $isVisible: boolean;
    $isUberAlles?: boolean;
}>`
    display: ${({ $isVisible }): string => ($isVisible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: auto;
    z-index: ${({ $isUberAlles }): number =>
        $isUberAlles
            ? ZINDEX.SITE_OVERLAY_BLOCK_INTERACTION_UBER_ALLES
            : ZINDEX.SITE_OVERLAY_BLOCK_INTERACTION};
`;

const SiteOverlayColor = styled.div<{
    $isVisible: boolean;
    $isUberAlles?: boolean;
}>`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: ${({ $isUberAlles }): number =>
        $isUberAlles
            ? ZINDEX.SITE_OVERLAY_COLOR_UBER_ALLES
            : ZINDEX.SITE_OVERLAY_COLOR};
    transition:
        opacity ${MENU_ANIMATION_TIME},
        visibility ${MENU_ANIMATION_TIME};
    background-color: var(--foregroundColorMix8);
    opacity: ${({ $isVisible }): number => ($isVisible ? 0.8 : 0)};
    visibility: ${({ $isVisible }): string =>
        $isVisible ? 'visible' : 'hidden'};
`;

export default function SiteOverlay(): React.ReactElement | null {
    const [siteOverlayState] = useGlobalState('siteOverlay');
    const isVisible = Boolean(
        siteOverlayState.isVisible || siteOverlayState.isForcedVisible,
    );

    return (
        <>
            <SiteOverlayBlockClicks
                $isVisible={isVisible}
                $isUberAlles={siteOverlayState.isUberAlles}
            />
            <SiteOverlayColor
                $isVisible={isVisible}
                $isUberAlles={siteOverlayState.isUberAlles}
            />
        </>
    );
}
