import type { Country } from '../hooks/useConfig';
import type { CountryChoices } from '../gql/api-public';

export default function printCountryName(
    countryCode: CountryChoices | null,
    countries: Country[],
): string | undefined {
    if (countryCode) {
        const country = countries.find(
            (country: Country): boolean =>
                (country.code as string) === countryCode,
        );
        if (country) {
            return country.name;
        }
    }
}
