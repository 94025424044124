import type { Font, Glyph } from 'opentype.js';

export enum ACTION_TYPE {
    SET_GLYPH,
    SET_FONT_AND_GLYPH,
}

export interface SetGlyphAction {
    type: ACTION_TYPE.SET_GLYPH;
    glyph: Glyph;
}

export const setGlyph = (glyph: Glyph): SetGlyphAction => ({
    type: ACTION_TYPE.SET_GLYPH,
    glyph,
});

export interface SetFontAndGlyphAction {
    type: ACTION_TYPE.SET_FONT_AND_GLYPH;
    font: Font;
    glyph: Glyph;
}

export const setFontAndGlyph = (
    font: Font,
    glyph: Glyph,
): SetFontAndGlyphAction => ({
    type: ACTION_TYPE.SET_FONT_AND_GLYPH,
    font,
    glyph,
});
