import React from 'react';
import { Editor, EditorState } from 'draft-js';
import styled from 'styled-components';
import customStyleFn from '../utils/type-editor/customStyleFn';
import useCustomStyleMap from '../utils/type-editor/useCustomStyleMap';
import TypeEditorToolbar from './TypeEditorToolbar';
import { Provider as ResetContextProvider } from './ResetContext';
import type { GeneratedSpecimen } from '../utils/GeneratedSpecimen';

import {
    Provider as TypeEditorProvider,
    useEditorState,
    useLineHeightState,
    usePreventWrappingState,
} from './TypeEditorContext';
import TypeEditorStyles from './TypeEditorStyles';
import useOnClickAway from '../hooks/useOnClickAway';
import { TEST_ID } from '../settings/E2e';
import getTypeEditorId from '../utils/getTypeEditorId';
import { getMetricLines } from '../templates/TestFontMetricsPage';
import { LOZENGE_VERTICAL_PADDING } from './Lozenge';

const Container = styled.div`
    position: relative;
    color: var(--foregroundColor);
`;

const HeadsUpDisplay = styled.div`
    position: relative;
    width: 100%;
    padding: ${LOZENGE_VERTICAL_PADDING}px 0;
    user-select: none;

    --lozengeBackgroundColor: var(--menuBackgroundColor);
    --lozengeWidthNarrow: calc((100vw - var(--gridMarginGap) * 2) * 0.15);
    --lozengeWidthWide: calc((100vw - var(--gridMarginGap) * 2) * 0.2);
`;

function TypeEditor({
    specimen,
    areMetricsShown,
}: {
    specimen: GeneratedSpecimen;
    areMetricsShown: boolean;
}): React.ReactElement {
    const [editorState, setEditorState] = useEditorState();
    const [lineHeight] = useLineHeightState();
    const [preventWrapping, setPreventWrapping] = usePreventWrappingState();
    const [isEditorFocused, setIsEditorFocused] = React.useState(false);

    const customStyleMap = useCustomStyleMap();
    const containerRef = React.useRef<HTMLElement | null>(null);

    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const onFocus = React.useCallback((): void => setIsFocused(true), []);
    const onBlur = React.useCallback((): void => {
        // Sometimes the blur happens when we drag-select text in the editor and the mouseUp happens
        // outside the editor. We don't want to remove the editor in this case!
        if (!isEditorFocused) {
            setIsFocused(false);
        }
    }, [isEditorFocused]);
    const onMouseEnter = React.useCallback((): void => setIsHovered(true), []);
    const onMouseLeave = React.useCallback((): void => setIsHovered(false), []);
    const setContainerRef = React.useCallback(
        (element: HTMLElement | null): void => {
            if (element) {
                containerRef.current = element;
            }
        },
        [containerRef],
    );

    const onChange = React.useCallback(
        (state: EditorState): void => {
            if (preventWrapping) {
                // The moment someone edits the specimen we want to turn off text wrap prevention.
                // We only want to prevent wrapping on Big specimens as they are loaded in.
                setPreventWrapping(false);
            }
            // We're calling this as seemingly without it the editor state doesn't update properly...
            setEditorState(state);
        },
        [preventWrapping, setEditorState, setPreventWrapping],
    );

    useOnClickAway([containerRef], onBlur);

    React.useEffect(() => {
        if (isFocused) {
            // Restore the selection on focus, if there was one
            const selection = editorState.getSelection();
            if (!selection.isCollapsed()) {
                setEditorState(
                    EditorState.forceSelection(editorState, selection),
                );
            }
        }
    }, [setEditorState, isFocused]);

    const metricLines = React.useMemo(() => {
        return areMetricsShown ? getMetricLines(specimen, lineHeight) : null;
    }, [specimen, lineHeight, areMetricsShown]);

    return (
        <Container
            id={getTypeEditorId(specimen.fontStyleName)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            ref={setContainerRef}
            data-cy={TEST_ID.TYPE_EDITOR}
        >
            <HeadsUpDisplay>
                <TypeEditorToolbar
                    activeStatus={
                        !isHovered && !isFocused
                            ? 'inactive'
                            : isHovered && !isFocused
                              ? 'hovered'
                              : 'active'
                    }
                />
            </HeadsUpDisplay>
            <TypeEditorStyles>
                <Editor
                    editorState={editorState}
                    onChange={onChange}
                    customStyleFn={customStyleFn}
                    customStyleMap={customStyleMap}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck={false}
                    preserveSelectionOnBlur={true}
                    onFocus={(): void => {
                        setIsEditorFocused(true);
                    }}
                    onBlur={(): void => {
                        setIsEditorFocused(false);
                    }}
                />
                {(isHovered || isFocused) && metricLines}
            </TypeEditorStyles>
        </Container>
    );
}

function TypeEditorWrapper({
    specimen,
    areMetricsShown,
}: {
    specimen: GeneratedSpecimen;
    areMetricsShown: boolean;
}): React.ReactElement {
    return (
        <ResetContextProvider>
            <TypeEditorProvider specimen={specimen}>
                <TypeEditor
                    specimen={specimen}
                    areMetricsShown={areMetricsShown}
                />
            </TypeEditorProvider>
        </ResetContextProvider>
    );
}

export default React.memo(TypeEditorWrapper);
