/*
 * An abstraction for creating arbitrary stateful 'Context' with an API that is
 * consistent with `React.useState`.
 *
 *   1) Instantiate a `Provider` and `useState` hook like:
 *     ```
 *     const { Provider: FooProvider, useState: useFooState } = createStatefulContext<boolean>(false);
 *
 *     export { FooProvider, useFooState };
 *     ```
 *
 *   2) Wrap your components with the `Provider`.
 *
 *   3) Access your state with the `useState` hook you generated in step #1:
 *     ```
 *     import { useFooState } from './FooStateContext';
 *
 *     function ExampleComponent() {
 *       const [fooState, setFooState] = useFooState();
 *
 *       ...
 *     }
 *     ```
 */
import React from 'react';

type StateType<T> = [T, React.Dispatch<React.SetStateAction<T>>];

interface ProviderProps<T> {
    children: React.ReactNode;
    initial?: T;
}

export default function createStatefulContext<T>(defaultValue?: T): {
    Provider(props: ProviderProps<T>): React.ReactElement;
    useState(): StateType<T>;
} {
    const Context = React.createContext<StateType<T> | undefined>(undefined);

    function Provider({
        children,
        initial,
    }: {
        children: React.ReactNode;
        initial?: T;
    }): React.ReactElement {
        const state: [T, React.Dispatch<React.SetStateAction<T>>] =
            React.useState<T>((): T => {
                if (defaultValue !== undefined) {
                    return defaultValue;
                }
                if (initial !== undefined) {
                    return initial;
                }
                throw new Error('Provider initialized with undefined');
            });

        return <Context.Provider value={state}>{children}</Context.Provider>;
    }

    function useState(): StateType<T> {
        const state = React.useContext(Context);

        if (state === undefined) {
            throw new Error('useState used outside of context Provider');
        }

        return state;
    }

    return {
        Provider,
        useState,
    };
}
