import { createImgixUrl, createStaticAssetUrl } from './urlHelper';
import filenameIsSvg from './filenameIsSvg';

export const WIDTH_BUCKETS = [300, 400, 600, 800, 1200, 1600, 2000, 3000];

interface ResponsiveImagesWithWidths {
    url: string;
    minWidth?: number;
    maxWidth: number;
}

export function getImagesForWidths(
    src: string,
    imageQuality?: number,
): ResponsiveImagesWithWidths[] {
    return WIDTH_BUCKETS.map((targetWidth, index) => {
        return {
            minWidth: index > 0 ? WIDTH_BUCKETS[index - 1] + 0.1 : undefined,
            maxWidth: targetWidth,
            url: createImgixUrl({
                src,
                imgixParams: {
                    w: targetWidth,
                    fit: 'max',
                    auto: 'format,compress',
                    cs: 'srgb',
                    q: imageQuality || 90,
                },
            }),
        };
    });
}

/*
 * Produces the contents of a <srcset> tag.
 * For each of the widths in the imageWidthBuckets, it will produce
 * an imgix src url requesting that specific width
 */

export default function imageCreateSrcSet(
    src: string,
    imageQuality?: number,
): string {
    // SVG are the best, aren't they?!
    if (filenameIsSvg(src)) {
        return createStaticAssetUrl(src);
    }

    return getImagesForWidths(src, imageQuality)
        .map(
            (imageForWidth) =>
                `${imageForWidth.url} ${imageForWidth.maxWidth}w`,
        )
        .join(',');
}
