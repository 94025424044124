import { graphql, useStaticQuery } from 'gatsby';
import type { TestFontsFilesizeQuery } from '../gql/api-ssr';

export type TestFontsInfo = TestFontsFilesizeQuery['ssr']['testFonts'];

export default function useTestFontsInfo(): TestFontsInfo {
    return useStaticQuery<TestFontsFilesizeQuery>(graphql`
        query TestFontsFilesizeQuery {
            ssr {
                testFonts {
                    allFontsArchiveFilesize
                }
            }
        }
    `).ssr.testFonts;
}
