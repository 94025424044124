import React from 'react';
import * as Slider from '@radix-ui/react-slider';
import styled from 'styled-components';
import { SliderProps } from '@radix-ui/react-slider';

const SliderThumb = styled(Slider.Thumb)`
    display: block;
    appearance: none;
    box-shadow: none;
    top: 100%;
    height: 1em;
    width: 1em;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid var(--foregroundColor);
    background-color: var(--foregroundColorMix7);
`;

const SliderRange = styled(Slider.Range)`
    position: absolute;
    background: var(--foregroundColorMix7);
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    border-radius: 0;
`;

const SliderRoot = styled(Slider.Root)`
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 100%;
    height: 1em;
    margin: 0;
    font-size: var(--fontSizeFixed2);
    cursor: pointer;

    &:hover ${SliderThumb} {
        background-color: var(--foregroundColor);
    }
`;

const SliderTrack = styled(Slider.Track)`
    background: var(--foregroundColor);
    position: relative;
    width: 100%;
    flex-grow: 1;
    border-radius: 0;
    height: 1px;
    box-shadow: none;
`;

const InputSlider = React.forwardRef(
    (
        props: SliderProps,
        forwardedRef: React.Ref<HTMLSpanElement>,
    ): React.ReactElement => {
        return (
            <SliderRoot {...props} ref={forwardedRef}>
                <SliderTrack>
                    <SliderRange />
                </SliderTrack>
                <SliderThumb />
            </SliderRoot>
        );
    },
);
InputSlider.displayName = 'InputSlider';

export default React.memo(InputSlider);
