import { useQuery } from '@apollo/client';
import type { QueryResult } from '@apollo/client';
import { userQuery } from '../utils/runtimeQueries';
import type { UserQueryQuery } from '../gql/api-public';

export type User = NonNullable<UserQueryQuery['user']>;

export default function useUserQuery(): QueryResult<UserQueryQuery> {
    return useQuery<UserQueryQuery>(userQuery, {
        ssr: false,
    });
}
