import { usePreviewHomepage } from '../components/GlobalRuntimeState';
import { usePageQueryHomepage } from '../components/PageContext';
import type { Homepage } from '../union-types/homepage';

export default function usePreviewOrPageQueryHomepage(): Homepage {
    const previewPage = usePreviewHomepage();
    const pageQueryPage = usePageQueryHomepage();
    if (!previewPage && !pageQueryPage) {
        throw Error('homepage is undefined');
    }
    return (previewPage || pageQueryPage) as Homepage;
}
