const existingAttributeRegex = /(preserveAspectRatio=")([^"]*)(.*)$/;
const nonExistingAttributeRegex = /(<svg)(.*)$/s;

export default function forcePreserveAspectRatio(
    svg: string,
    attributeValue = 'xMinYMin meet',
): string {
    if (existingAttributeRegex.test(svg)) {
        return svg.replace(existingAttributeRegex, `$1${attributeValue}$3`);
    }

    return svg.replace(
        nonExistingAttributeRegex,
        `$1 preserveAspectRatio="${attributeValue}"$2`,
    );
}
