import React from 'react';
import styled from 'styled-components';
import useUserQuery from '../hooks/useUserQuery';
import { createAccountUrl } from '../utils/urlHelper';
import { HeadingExtraLarge } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import Link from '../components/InlineUnderlinedLink';
import { VIEWPORT } from '../settings/Global';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
`;

const InnerWrapper = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    ${HeadingExtraLarge}, ${Paragraph} {
        grid-column: 3 / span 6;
        margin: var(--spacing1) 0;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span 6;
        }
    }
`;

function AccountVerifiedPage(): React.ReactElement {
    const userQuery = useUserQuery();
    return (
        <>
            <Container>
                {userQuery.loading ? (
                    // TODO: Nice loading state, as per login form
                    <HeadingExtraLarge>Loading…</HeadingExtraLarge>
                ) : (
                    <>
                        {userQuery?.data?.user ? (
                            <InnerWrapper>
                                <HeadingExtraLarge>
                                    Your email address has been verified.
                                </HeadingExtraLarge>

                                <Paragraph>
                                    Your cart is empty. You can update
                                    subscription preferences from your{' '}
                                    <Link to={createAccountUrl()}>Account</Link>
                                    .
                                </Paragraph>
                            </InnerWrapper>
                        ) : (
                            <InnerWrapper>
                                <HeadingExtraLarge>
                                    Your email address could not be verified.
                                </HeadingExtraLarge>

                                <Paragraph>
                                    Please try again by logging in to your{' '}
                                    <Link to={createAccountUrl()}>Account</Link>
                                    .
                                </Paragraph>
                            </InnerWrapper>
                        )}
                    </>
                )}
            </Container>
        </>
    );
}

export default React.memo(AccountVerifiedPage);
