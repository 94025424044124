import { useQuery } from '@apollo/client';
import type { QueryResult } from '@apollo/client';
import { isInvoicePaidQuery } from '../utils/runtimeQueries';
import type {
    IsInvoicePaidQueryQuery,
    IsInvoicePaidQueryQueryVariables,
} from '../gql/api-public';
import useLocationInvoiceNumber from './useLocationInvoiceNumber';
import { useIsInvoicePage } from '../components/PageContext';

export default function useIsInvoicePaidQuery(): QueryResult<
    IsInvoicePaidQueryQuery,
    IsInvoicePaidQueryQueryVariables
> {
    const invoiceNumber = useLocationInvoiceNumber();
    const isInvoiceCheckout = useIsInvoicePage();

    return useQuery<IsInvoicePaidQueryQuery, IsInvoicePaidQueryQueryVariables>(
        isInvoicePaidQuery,
        {
            variables: {
                invoiceNumber: invoiceNumber,
            },
            ssr: false,
            skip: !isInvoiceCheckout || invoiceNumber === undefined,
        },
    );
}
