import React from 'react';
import type {
    FontFamilyGroupsQuery,
    FontFamiliesQuery,
    CustomFontsPostPagesQuery,
    HomePageQuery,
    InUsePostPagesQuery,
    BigSpecimenSetQuery,
    MediumSpecimenSetQuery,
    SmallSpecimenSetQuery,
    BlogCategoriesQuery,
    BlogPostsQuery,
    EulasQuery,
} from '../gql/api-ssr';
import type { AllMetricOffsets } from '../utils/extractFontAssetMetricOffsets';

export type BlogPost = NonNullable<BlogPostsQuery['ssr']['blogPosts']>[number];
type BlogCategory = BlogCategoriesQuery['ssr']['blogCategories'][number];
export type InUsePost = NonNullable<
    InUsePostPagesQuery['ssr']['inUsePosts']
>[number];
export type Homepage = NonNullable<HomePageQuery['ssr']['homepage']>;
export type FontFamily = FontFamiliesQuery['ssr']['fontFamilies'][number];
export type FontFamilyGroup =
    FontFamilyGroupsQuery['ssr']['fontFamilyGroups'][number];
export type CustomFont =
    CustomFontsPostPagesQuery['ssr']['customFonts'][number];
export type BigSpecimenSet = NonNullable<
    BigSpecimenSetQuery['ssr']['bigSpecimenSet']
>;
export type MediumSpecimenSet = NonNullable<
    MediumSpecimenSetQuery['ssr']['mediumSpecimenSet']
>;
export type SmallSpecimenSet = NonNullable<
    SmallSpecimenSetQuery['ssr']['smallSpecimenSet']
>;
export type Eula = EulasQuery['ssr']['config']['eulas'][number];

export interface InUseFilter {
    fontFamilyGroup?: {
        slug: string;
        isCollection: boolean;
    };
    category?: string;
}

export interface PageQueryContextType {
    fontFamily?: FontFamily;
    fontFamilyGroup?: FontFamilyGroup;
    inUseFilter?: InUseFilter;
    blogCategories?: BlogCategory[];
    blogCategoryFilter?: string;
    customFontsPostPage?: CustomFont;
    bigSpecimenSet?: BigSpecimenSet;
    mediumSpecimenSet?: MediumSpecimenSet;
    smallSpecimenSet?: SmallSpecimenSet;
    eula?: Eula;
    homepage?: Homepage;
    inUsePostPage?: InUsePost;
    blogPost?: BlogPost;
    blogWordCount?: number;
    isInvoiceCheckout?: boolean;
    staticFontMetricOffsets: AllMetricOffsets;
}

export const Context = React.createContext<undefined | PageQueryContextType>(
    undefined,
);

export default Context;

export function usePageContext(): PageQueryContextType {
    const context = React.useContext(Context);

    if (context === undefined) {
        throw new Error('usePageQueryContext used outside of provider');
    }

    return context;
}

export function useFontFamily(): FontFamily {
    const { fontFamily } = usePageContext();

    if (fontFamily === undefined) {
        throw new Error('fontFamily is undefined');
    }

    return fontFamily;
}

export function useEula(): Eula {
    const { eula } = usePageContext();

    if (eula === undefined) {
        throw new Error('EULA is undefined');
    }

    return eula;
}

export function useFontFamilyGroup(): FontFamilyGroup {
    const { fontFamilyGroup } = usePageContext();

    if (fontFamilyGroup === undefined) {
        throw new Error('fontFamilyGroup is undefined');
    }

    return fontFamilyGroup;
}

export function useBigSpecimenSet(): BigSpecimenSet | undefined {
    const { bigSpecimenSet } = usePageContext();
    return bigSpecimenSet;
}

export function useMediumSpecimenSet(): MediumSpecimenSet | undefined {
    const { mediumSpecimenSet } = usePageContext();
    return mediumSpecimenSet;
}

export function useSmallSpecimenSet(): SmallSpecimenSet | undefined {
    const { smallSpecimenSet } = usePageContext();
    return smallSpecimenSet;
}

export function useInUseFilter(): InUseFilter {
    const { inUseFilter } = usePageContext();
    return inUseFilter || {};
}

export function useCustomFont(): CustomFont {
    const { customFontsPostPage } = usePageContext();
    if (customFontsPostPage === undefined) {
        throw new Error('customFontsPostPage is undefined');
    }
    return customFontsPostPage;
}

export function usePageQueryHomepage(): Homepage | undefined {
    const { homepage } = usePageContext();
    return homepage;
}

export function usePageQueryInUsePost(): InUsePost | undefined {
    const { inUsePostPage } = usePageContext();
    return inUsePostPage;
}

export function usePageQueryBlogPost(): BlogPost | undefined {
    const { blogPost } = usePageContext();
    return blogPost;
}

export function usePageQueryBlogPostWordCount(): number | undefined {
    const { blogWordCount } = usePageContext();
    return blogWordCount;
}

export function useIsInvoicePage(): boolean {
    const { isInvoiceCheckout } = usePageContext();
    return Boolean(isInvoiceCheckout);
}

export function useStaticFontMetricOffsets(): AllMetricOffsets {
    const { staticFontMetricOffsets } = usePageContext();
    if (staticFontMetricOffsets === undefined) {
        throw new Error('staticFontMetricOffsets is undefined');
    }
    return staticFontMetricOffsets;
}
