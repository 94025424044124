import React from 'react';
import styled from 'styled-components';
import { Paragraph } from './Paragraph';
import { VIEWPORT } from '../settings/Global';

const List = styled.ul`
    display: block;
    column-count: 5;
    column-gap: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        columns: 3;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        columns: 2;
    }
`;

const ListItem = styled(Paragraph).attrs({ as: 'li' })`
    color: var(--foregroundColor);
`;

function SupportedLanguages({
    languages,
}: {
    languages: string[];
}): React.ReactElement {
    return (
        <List>
            {languages.map(
                (language: string): React.ReactElement => (
                    <ListItem key={language}>{language}</ListItem>
                ),
            )}
        </List>
    );
}

export default React.memo(SupportedLanguages);
