import { useLocation } from '@reach/router';
import { useIsInvoicePage } from '../components/PageContext';
import isBrowser from '../utils/isBrowser';

function cleanParam(param: string): string {
    // Quote numbers can start with `q`, but are otherwise numeric
    return param.replace(/[^q0-9]/g, '');
}

export default function useLocationInvoiceNumber(): string | undefined {
    const location = useLocation();
    const isInvoicePage = useIsInvoicePage();
    if (!isInvoicePage || !isBrowser()) {
        return;
    }
    // Klim invoice numbers are always alphanumeric
    const hashValue = cleanParam(location.hash);
    if (hashValue) {
        return hashValue;
    }

    const nParam = new URL(window.location.href).searchParams.get('n');
    if (nParam) {
        return cleanParam(nParam);
    }
}
