import React from 'react';
import styled from 'styled-components';
import { ParagraphSmall } from './Paragraph';
import { Link as _Link } from './Primitives';
import { createCollectionUrl } from '../utils/urlHelper';

const Container = styled.div`
    margin-bottom: var(--spacing4);
`;

const Title = styled(ParagraphSmall)`
    color: var(--foregroundColorMix4);
`;

const Link = styled(_Link)`
    font-size: var(--fontSizeFixed1);
`;

export function printCounts({
    fontFamilyCount,
    fontStyleCount,
}: {
    fontFamilyCount: number;
    fontStyleCount: number;
}): string {
    return [
        `${String(fontFamilyCount)} ${
            fontFamilyCount === 1 ? 'family' : 'families'
        }`,
        `${String(fontStyleCount)} ${
            fontStyleCount === 1 ? 'style' : 'styles'
        }`,
    ].join(', ');
}

function FontCounts({
    slug,
    fontFamilyCount,
    fontStyleCount,
    isCollection,
}: {
    slug: string;
    fontFamilyCount: number;
    fontStyleCount: number;
    isCollection: boolean;
}): React.ReactElement {
    return (
        <Container>
            <Title>
                {isCollection ? (
                    <Link to={createCollectionUrl(slug)}>Collection</Link>
                ) : (
                    'Family'
                )}
            </Title>
            <ParagraphSmall>
                {isCollection ? (
                    <Link to={createCollectionUrl(slug)}>
                        {printCounts({
                            fontFamilyCount,
                            fontStyleCount,
                        })}
                    </Link>
                ) : (
                    `${String(fontStyleCount)} ${
                        fontStyleCount === 1 ? 'Style' : 'Styles'
                    }`
                )}
            </ParagraphSmall>
        </Container>
    );
}

export default React.memo(FontCounts);
