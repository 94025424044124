import { useCountries } from './useConfig';
import { DEFAULT_TAX_ID_LABEL } from '../settings/Global';

export default function useTaxIdLabel(countryCode: string): string {
    const countries = useCountries();
    const countryForLabel = countries.find(
        (country) => country.code === countryCode,
    );
    return countryForLabel?.taxId || DEFAULT_TAX_ID_LABEL;
}
