import useConfig from './useConfig';
import useIsUserTestingSite from './useIsUserTestingSite';
import isWhitelisted from '../utils/isWhitelisted';

export default function useIsAllowedForUserTesting(
    fontFamilyGroupId: string,
): boolean {
    const isUserTestingSite = useIsUserTestingSite();
    const config = useConfig();

    if (!isUserTestingSite) {
        return true;
    }
    return isWhitelisted(
        config.userTestingFontFamilyGroupWhitelist,
        fontFamilyGroupId,
    );
}
