import styled, { css } from 'styled-components';
import { ulStyles, olStyles } from './Primitives';
import { hyphensAuto } from '../utils/stylesMixins';

/* This container, despite its name, is also used for In Use image sections */
export const blogAndInUseWrapStyles = css`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--gridColumnGap) var(--gridColumnGap);
    margin: 0;
    padding: 0 var(--gridMarginGap);
`;

export const hyperlinkStyle = css`
    --underlineColor: var(--foregroundColorMix6);
    --hoverUnderlineColor: var(--foregroundColor);

    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: var(--underlineColor);

    &:hover,
    &:focus,
    &:active {
        text-decoration-color: var(--hoverUnderlineColor);
    }
`;

export const hyperlinkLighterStyle = css`
    ${hyperlinkStyle};
    --underlineColor: var(--foregroundColorMix7);
    --hoverUnderlineColor: var(--foregroundColorMix6);
`;

export const hyperlinkDarkerStyle = css`
    ${hyperlinkStyle};
    --underlineColor: var(--foregroundColorMix3);
    --hoverUnderlineColor: var(--foregroundColorMix7);

    color: var(--foregroundColorMix8);

    @media print {
        color: var(--foregroundColorMix3);
    }

    :focus,
    :hover,
    :active {
        color: var(--foregroundColorMix8);
    }
`;

const BaseBlogSpacerStyles = css`
    background: var(--primaryColorRef);

    @media print {
        background: var(--secondaryColorRef);
    }
`;

export const BlogSpacer = styled.div`
    ${BaseBlogSpacerStyles};
    /* min-height: var(--streamFieldSpacing); */
    min-height: 25vh;
`;

export const BlogSpacerLineHeight = styled.div<{ $double?: boolean }>`
    ${BaseBlogSpacerStyles};
    ${({ $double }): ReturnType<typeof css> =>
        $double
            ? css`
                  min-height: calc(var(--lineHeightBlogBodyPx) * 2);
              `
            : css`
                  min-height: var(--lineHeightBlogBodyPx);
              `}
`;

export const signifierStyle = css`
    font-family: var(--fontFamilyMartinaPlantijn);
    line-height: var(--lineHeightBlogBody);
    font-feature-settings: 'onum';
`;

export const bodyStyles = css`
    ${signifierStyle};
    font-size: var(--fontSizeBlog);
    margin: 0;
    padding: 0;

    ${hyphensAuto};

    cite {
        font-family: var(--fontFamilyCalibre2);
        font-size: var(--fontSizeBlogGlyph);
        font-style: normal;
    }

    p {
        margin-bottom: var(--lineHeightBlogBodyPx);
        padding: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }

    b {
        font-weight: bold;
    }

    i {
        font-style: italic;
    }

    ul {
        ${ulStyles};
        margin-bottom: var(--lineHeightBlogBodyPx);

        ul {
            li::before {
                content: '\\002D';
            }
        }
    }

    ol {
        ${olStyles};
        margin-bottom: var(--lineHeightBlogBodyPx);
    }

    ul:last-child,
    ol:last-child {
        margin-bottom: 0;
    }

    li {
        padding: 0;
    }
`;
