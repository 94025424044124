import { FontFamilyGroup, FontFamily, FontStyle } from '../hooks/useAllFonts';

export default function getFontStyleFromId(
    fontFamilyGroups: FontFamilyGroup[],
    fontStyleId: string,
): FontStyle | undefined {
    return fontFamilyGroups
        .reduce(
            (carry: FontFamily[], fontFamilyGroup): FontFamily[] =>
                carry.concat(fontFamilyGroup.fontFamilies),
            [],
        )
        .reduce(
            (carry: FontStyle[], fontFamily): FontStyle[] =>
                carry.concat(fontFamily.fontStyles),
            [] as FontStyle[],
        )
        .find((style: FontStyle): boolean => style.id === fontStyleId);
}
