import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionItem, Button } from './Accordion';
import Metrics from './Metrics';
import FontFamilyCredits from './FontFamilyCredits';
import SupportedLanguages from './SupportedLanguages';
import { useFontFamily } from './PageContext';
import { default as _Icon, IconType } from './Icon';
import { createStaticAssetUrl } from '../utils/urlHelper';
import { VIEWPORT } from '../settings/Global';
import getFontFamilyTestFontUrl from '../utils/getFontFamilyTestFontUrl';

export const LargeText = styled.span`
    font-size: var(--fontSizeFixed4);
`;

const ItemWrapper = styled.div`
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: none;
    }
`;

const Icon = styled(_Icon)`
    font-size: var(--fontSizeFixed4);
`;

function FontFamilyAccordion(): React.ReactElement {
    const fontFamily = useFontFamily();
    const { supportedLanguages, fontFamilyGroup, fontStyles } = fontFamily;

    const hasSizeData = fontStyles.some(
        (fontStyle): boolean =>
            fontStyle.pixelSize !== null || fontStyle.pointSize !== null,
    );

    return (
        <Accordion>
            <AccordionItem
                heading={<LargeText>Credits &amp; details</LargeText>}
                headingLevel={3}
                panel={<FontFamilyCredits />}
            />
            {supportedLanguages.length > 0 && (
                <AccordionItem
                    heading={<LargeText>Supported languages</LargeText>}
                    headingLevel={3}
                    hidePanelUnlessExpanded={true}
                    panel={
                        <SupportedLanguages languages={supportedLanguages} />
                    }
                />
            )}
            <ItemWrapper>
                <AccordionItem
                    heading={
                        <LargeText>
                            {hasSizeData
                                ? 'Recommended minimum size & web font file sizes'
                                : 'Web font file sizes'}
                        </LargeText>
                    }
                    headingLevel={3}
                    panel={<Metrics />}
                />
            </ItemWrapper>

            <Button
                as="a"
                role="button"
                href={getFontFamilyTestFontUrl(fontFamily)}
            >
                <LargeText>Download test fonts</LargeText>
                <Icon type={IconType.DOWNLOAD} />
            </Button>

            {fontFamilyGroup && fontFamilyGroup.pdfSpecimen && (
                <Button
                    as="a"
                    role="button"
                    download
                    href={createStaticAssetUrl(fontFamilyGroup.pdfSpecimen)}
                >
                    <LargeText>Download PDF specimen</LargeText>
                    <Icon type={IconType.DOWNLOAD} />
                </Button>
            )}
        </Accordion>
    );
}

export default React.memo(FontFamilyAccordion);
