export default function replaceSvgTitle(
    element: HTMLElement | null,
    title: string | undefined,
): void {
    if (element) {
        const svg = element.querySelector('svg');
        if (svg) {
            svg.querySelectorAll('title').forEach(
                (el: HTMLTitleElement): void => {
                    svg.removeChild(el);
                },
            );
            if (title) {
                const titleEl = document.createElement('title');
                titleEl.textContent = title;
                svg.insertBefore(titleEl, svg.firstChild); // Edge 16 doesn't support .prepend() so using .insertBefore instead
            }
        }
    }
}
