import React from 'react';
import styled from 'styled-components';

export const I = styled.i`
    font-family: var(--fontFamilySoehneIkon);
`;

const SocialI = styled(I)`
    font-feature-settings: 'calt';
`;

export enum IconType {
    /*
     * To determine character mapping, you can use the 'Font Book' app or this
     * link: `https://wakamaifondue.com/`
     */
    CROSS = '×',
    PLUS = '+',
    DOWNLOAD = '⤓',
    RADIO_UNCHECKED = '○',
    RADIO_CHECKED = '◉',
    RADIO_INDETERMINATE = '⊖',
    CHECKBOX_UNCHECKED = '□',
    CHECKBOX_CHECKED = '☑',
    CHECKBOX_INDETERMINATE = '⊟',
    ARROW_UP = '↑',
    ARROW_DOWN = '↓',
    ARROW_RIGHT = '→',
    ARROW_LEFT = '←',
    CHEVRON_DOWN = '▼',
    CHEVRON_UP = '▲',
    ARROW_UP_DOWN = '⭥',
    THEME_TOGGLE = '◑',
    EXTERNAL_LINK = '⇗',
    RESET = '⭯',
    BUILD_TIME = '⭍',
    HAMBURGER = '☰',
}

export enum SocialIconType {
    TUMBLR = 'Tm',
    TWITTER = 'Tw',
    VIMEO = 'Vm',
    FLICKR = 'Fk',
    INSTAGRAM = 'Ig',
    PINTEREST = 'Pn',
}

interface IconProps extends React.HTMLAttributes<HTMLElement> {
    type: IconType;
}

export default function Icon({
    type,
    ...props
}: IconProps): React.ReactElement {
    return (
        <I {...props} role="presentation" aria-hidden="true">
            {type}
        </I>
    );
}

interface SocialIconProps extends React.HTMLAttributes<HTMLElement> {
    type: SocialIconType;
}

export function SocialIcon({
    type,
    ...props
}: SocialIconProps): React.ReactElement {
    return <SocialI {...props}>{type}</SocialI>;
}
