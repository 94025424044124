import type { Font } from 'opentype.js';
import { load as openTypeLoad } from 'opentype.js';

export default function loadFont(fontUrl: string): Promise<Font> {
    return new Promise((resolve, reject): void => {
        openTypeLoad(fontUrl, (error, font): void => {
            if (error) {
                return reject(error);
            }
            if (font) {
                resolve(font);
            }
            reject('Font could not be loaded and no error was returned.');
        });
    });
}
