import React from 'react';
import { useCustomFont } from './PageContext';
import Credits from './Credits';

function CustomFontsPostCredits(): React.ReactElement {
    const {
        creditClient,
        creditDesign,
        creditCollaborators,
        creditAgency,
        awards,
    } = useCustomFont();

    return (
        <Credits
            client={creditClient}
            designers={creditDesign}
            collaborators={creditCollaborators}
            agency={creditAgency}
            awards={awards}
        />
    );
}

export default CustomFontsPostCredits;
