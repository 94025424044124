import React from 'react';
import styled from 'styled-components';
import { useFontFamilyGroup } from '../components/PageContext';
import FontHeroWithWrapper from '../components/FontHeroWithWrapper';
import _FontSectionTitled from '../components/FontSectionTitled';
import FontCollectionStyles from '../components/FontCollectionStyles';
import FontCollectionInformation from '../components/FontCollectionInformation';
import { VIEWPORT } from '../settings/Global';
import useIsAllowedForUserTesting from '../hooks/useIsAllowedForUserTesting';
import { navigate } from 'gatsby';
import { create404NotFoundUrl } from '../utils/urlHelper';
import isBrowser from '../utils/isBrowser';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageDefault);
    padding-top: 0;
    margin: 0 var(--gridMarginGap);
`;

const Sections = styled.div`
    display: flex;
    flex-direction: column;
`;

const FontSectionTitled = styled(_FontSectionTitled)`
    &:not(:first-child) {
        margin-top: 50vh;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            margin-top: 25vh;
        }
    }
`;

function FontCollectionPage(): React.ReactElement | null {
    const fontFamilyGroup = useFontFamilyGroup();
    const { svgHeading, campaign, name } = fontFamilyGroup;

    const isAllowedForUserTesting = useIsAllowedForUserTesting(
        fontFamilyGroup.id,
    );
    if (isBrowser() && !isAllowedForUserTesting) {
        navigate(create404NotFoundUrl());
        return null;
    }

    return (
        <>
            <FontHeroWithWrapper
                svgHeading={svgHeading}
                svgTitle={`${name} Collection`}
                campaign={campaign}
            />
            <Container>
                <Sections>
                    <FontSectionTitled
                        data-section="information"
                        id="information"
                        title="Information"
                        $applyBaselineAlignment
                    >
                        <FontCollectionInformation />
                    </FontSectionTitled>
                    <FontSectionTitled
                        data-section="families"
                        id="families"
                        title="Families"
                        $applyBaselineAlignment
                    >
                        <FontCollectionStyles />
                    </FontSectionTitled>
                </Sections>
            </Container>
        </>
    );
}

export default React.memo(FontCollectionPage);
