import React from 'react';
import styled from 'styled-components';
import FontsInUseItem, { ImageRenderStyle } from './FontsInUseItem';
import { VIEWPORT } from '../settings/Global';
import type { InUsePostStub } from '../union-types/inUsePostStub';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--gridColumnGap);
`;

const Inner = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    gap: var(--gridColumnGap);
    grid-row-gap: 0;
    grid-auto-flow: dense;
`;

const Item = styled.div<{ $double?: boolean }>`
    grid-column: span ${({ $double }): string => ($double ? '6' : '3')};

    /* Firefox fix for the images that were busting out of their container (it actually doesn't hide them at all, it resizes them to the correct size) */
    overflow: hidden;
`;

const More = styled.div`
    margin-top: var(--spacing5);
    grid-column: 3 / span 10;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / var(--gridColumnCount);
    }
`;

export const MAX_IN_USE_POSTS_PER_ROW = 4;

interface SpannedInUsePost {
    span: number;
    inUsePostStub: InUsePostStub;
}

function FontsInUse({
    inUsePosts,
    rows = 2,
    forceShowMoreLink,
    moreLink,
    lazyLoad = true,
    renderStyle,
}: {
    inUsePosts: InUsePostStub[];
    rows?: number;
    forceShowMoreLink?: boolean;
    moreLink?: React.ReactNode;
    lazyLoad?: boolean;
    renderStyle?: ImageRenderStyle;
}): React.ReactElement {
    // Repeating four-row pattern, where one item per row is 'featured'.
    const featuredIndices = React.useMemo(
        (): [number, number, number, number] => [
            Math.floor(Math.random() * 3),
            Math.floor(Math.random() * 3) + MAX_IN_USE_POSTS_PER_ROW,
            Math.floor(Math.random() * 3) + MAX_IN_USE_POSTS_PER_ROW * 2,
            Math.floor(Math.random() * 3) + MAX_IN_USE_POSTS_PER_ROW * 3,
        ],
        [],
    );

    // Append a 'span' attribute to the fontsInUsePages
    const spannedInUsePosts = React.useMemo((): SpannedInUsePost[] => {
        function getIsFeatured(index: number): boolean {
            return (
                featuredIndices.indexOf(
                    index % (MAX_IN_USE_POSTS_PER_ROW * 4),
                ) !== -1
            );
        }

        let column = 0;

        return inUsePosts.map((inUsePost): SpannedInUsePost => {
            const isLandscape =
                inUsePost.blurbImage.width > inUsePost.blurbImage.height;
            const isFeatured = getIsFeatured(column);
            const span = isFeatured && isLandscape ? 2 : 1;

            column = column + span;

            return {
                span,
                inUsePostStub: inUsePost,
            };
        });
    }, [inUsePosts, featuredIndices]);

    const totalColumns = spannedInUsePosts.reduce(
        (previousValue, currentValue) => previousValue + currentValue.span,
        0,
    );

    const maxColumn = rows * MAX_IN_USE_POSTS_PER_ROW;
    const isCapped = totalColumns > maxColumn;

    let column = 0;
    return (
        <Container>
            <Inner>
                {spannedInUsePosts.map(
                    (spannedInUsePost, index): React.ReactElement | null => {
                        if (column >= maxColumn) {
                            return null;
                        }
                        column = column + spannedInUsePost.span;

                        return (
                            <Item
                                $double={spannedInUsePost.span === 2}
                                key={spannedInUsePost.inUsePostStub.slug}
                            >
                                <FontsInUseItem
                                    inUsePost={spannedInUsePost.inUsePostStub}
                                    lazyLoad={lazyLoad}
                                    renderStyle={renderStyle}
                                    fetchPriority={
                                        !lazyLoad &&
                                        index < MAX_IN_USE_POSTS_PER_ROW - 1
                                            ? 'high'
                                            : 'low'
                                    }
                                />
                            </Item>
                        );
                    },
                )}
            </Inner>
            {(isCapped || forceShowMoreLink) && moreLink && (
                <Inner>
                    <More>{moreLink}</More>
                </Inner>
            )}
        </Container>
    );
}

export default React.memo(FontsInUse);
