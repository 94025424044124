import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import { hideFromPrint } from '../utils/stylesMixins';
import Link from './Lozenge';
import { createBlogIndexUrl } from '../utils/urlHelper';
import usePreviewOrPageQueryBlogPost from '../hooks/usePreviewOrPageQueryBlogPost';
import useNavColorFromIntersection from '../hooks/useNavColorFromIntersection';
import BlogCards from './BlogCards';
import { useGlobalState } from './GlobalRuntimeState';
import { HeadingMedium } from './Heading';
import { useStaticFontMetricOffsets } from './PageContext';
import Icon, { IconType } from './Icon';

const HeadingContainer = styled.div`
    ${hideFromPrint};

    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--spacing3) var(--gridColumnGap);
    grid-template-areas: '. . a a a a a a a a . . ';
    margin: 0;
    padding: var(--spacing7) var(--gridMarginGap) var(--spacing5)
        var(--gridMarginGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: 'a a a a a a';
        padding: var(--spacing7) var(--gridMarginGap) var(--spacing6)
            var(--gridMarginGap);
    }
`;

const Heading = styled(HeadingMedium)`
    ${hideFromPrint};

    grid-area: a;
    padding: 0;
`;

const RelatedPostsContainer = styled.div`
    ${hideFromPrint};

    padding: 0 var(--gridMarginGap) var(--spacing5) var(--gridMarginGap);
`;

const ViewMoreContainer = styled.div`
    ${hideFromPrint};

    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    padding: var(--spacing5) var(--gridMarginGap) var(--spacing8)
        var(--gridMarginGap);
`;

const LinkWrapper = styled.div`
    grid-column: 3 / span 4;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 1 / span 6;
    }
`;

export function BlogRelatedPosts(): React.ReactElement | null {
    const staticFontMetrics = useStaticFontMetricOffsets();
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [createNavColorRef] = useNavColorFromIntersection();
    const blogPost = usePreviewOrPageQueryBlogPost();
    const relatedBlogPosts = React.useMemo(() => {
        // Only show 2 in some cases, according to breakpoint logic
        const maxLength =
            viewportWidth &&
            viewportWidth <= VIEWPORT.NETBOOK_SMALL &&
            viewportWidth > VIEWPORT.TABLET
                ? 2
                : 3;
        return blogPost.relatedBlogPosts.slice(0, maxLength);
    }, [blogPost.relatedBlogPosts, viewportWidth]);

    if (!blogPost.relatedBlogPosts.length) {
        return null;
    }

    return (
        <div ref={createNavColorRef()}>
            <HeadingContainer>
                <Heading $metricOffsets={staticFontMetrics} as="h2">
                    More blog posts
                </Heading>
            </HeadingContainer>
            <RelatedPostsContainer>
                <BlogCards blogPosts={relatedBlogPosts} />
            </RelatedPostsContainer>
            <ViewMoreContainer>
                <LinkWrapper>
                    <Link to={createBlogIndexUrl()}>
                        See more blog posts&nbsp;
                        <Icon type={IconType.ARROW_RIGHT} />
                    </Link>
                </LinkWrapper>
            </ViewMoreContainer>
        </div>
    );
}
