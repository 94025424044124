import React from 'react';
import styled from 'styled-components';
import BlogPost from '../components/BlogPost';
import { useBubbledFragmentLink } from '../components/FragmentLink';

export { Head } from '../components/Head';

const Container = styled.div`
    --columnWidth: calc(
        /* a column width */
            (
                100% - (var(--gridMarginGap) * 2) /* the space for the grid */ -
                    (
                        var(--gridColumnGap) * (var(--gridColumnCount) - 1)
                            /* then subtract the column gap */
                    )
            ) / var(--gridColumnCount)
            /* and divide the remaining space by the number of columns */
    );
`;

function BlogPage(): React.ReactElement {
    return (
        <Container onClick={useBubbledFragmentLink(false)}>
            <BlogPost />
        </Container>
    );
}

export default React.memo(BlogPage);
