import React from 'react';
import styled, { css } from 'styled-components';
import { HeadingExtraLarge } from '../components/Heading';
import _TestFontsInfo, {
    TestFontsFormIsSubmitted as _TestFontsFormIsSubmitted,
} from '../components/TestFontsInfo';
import _TestFontsForm from '../components/TestFontsForm';
import { VIEWPORT } from '../settings/Global';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: var(--gridColumnGap);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        font-size: var(--fontSizeFixed1);
        grid-template-columns: var(--gridTemplateColumnsDefault);
    }
`;

const Section = styled.div`
    grid-column: 3 / span 6;

    &:nth-child(2) {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: var(--spacing4) var(--gridColumnGap);
    }

    ${HeadingExtraLarge} {
        grid-column: 1 / span 6;
        padding-bottom: var(--spacing4);

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            margin-bottom: var(--spacing6);
        }
    }

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 12;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 6;
    }
`;

const infoStyles = css`
    grid-column: 1 / span 5;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 6;
    }
`;

const TestFontsInfo = styled(_TestFontsInfo)`
    ${infoStyles};
`;

const TestFontsFormIsSubmitted = styled(_TestFontsFormIsSubmitted)`
    ${infoStyles};
`;

const TestFontsForm = styled(_TestFontsForm)`
    grid-column: 1 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 12;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 6;
    }
`;

export default function TestFontsPage(): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();
    const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);
    const [downloadUrl, setDownloadUrl] = React.useState<string | null>(null);

    return (
        <Container>
            <Section>
                <HeadingExtraLarge $metricOffsets={staticFontMetrics} as="h1">
                    Download test fonts
                </HeadingExtraLarge>
                {formIsSubmitted ? (
                    <TestFontsFormIsSubmitted downloadUrl={downloadUrl} />
                ) : (
                    <>
                        <TestFontsInfo />
                        <TestFontsForm
                            setFormIsSubmitted={setFormIsSubmitted}
                            setDownloadUrl={setDownloadUrl}
                        />
                    </>
                )}
            </Section>
        </Container>
    );
}
