import jsonCookies from './jsonCookies';
import notDuplicate from './notDuplicate';

const COOKIE_NAME = 'prf';

/**
 * Returns any pre-release fonts that we've recently visited.
 */
export function getRecentlyVisitedPrereleaseFontFamilyIds(): string[] {
    const rememberedFonts = jsonCookies.get(COOKIE_NAME);
    if (rememberedFonts && Array.isArray(rememberedFonts)) {
        return rememberedFonts;
    }
    return [];
}

/**
 * Remembers a visit to a pre-release font.
 * This then enables this font to display on the
 * Buy page (where it would otherwise be excluded).
 * @param fontFamilyId
 */
export function rememberVisitedPrereleaseFontFamilyId(
    fontFamilyId: string,
): void {
    jsonCookies.set(
        COOKIE_NAME,
        getRecentlyVisitedPrereleaseFontFamilyIds()
            .concat([fontFamilyId])
            .filter(notDuplicate),
    );
}
