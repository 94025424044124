import { CardNumberElement } from '@stripe/react-stripe-js';
import printStripeError from './printStripeError';
import PaymentError from './PaymentError';
import { sentryException } from './sentry';
import type {
    PaymentMethodCreateParams,
    Stripe,
    StripeElements,
} from '@stripe/stripe-js';

export default async function submitStripe({
    stripe,
    elements,
    clientSecret,
    billingDetails,
}: {
    stripe: Stripe | null;
    elements: StripeElements | null;
    clientSecret: string;
    billingDetails: PaymentMethodCreateParams.BillingDetails;
}): Promise<string> {
    // If there were no errors from API, proceed to Stripe submission
    if (!stripe) {
        throw new PaymentError('Stripe not initialised.');
    }
    if (!elements) {
        throw new PaymentError('Stripe Elements not initialised.');
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
        throw new PaymentError('cardElement is null.');
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
            card: cardElement,
            billing_details: billingDetails,
        },
    });

    if (result.error) {
        throw new PaymentError(printStripeError(result.error));
    } else if (result.paymentIntent) {
        return result.paymentIntent.id;
    } else {
        sentryException(
            new Error(
                'Unexpected Stripe response, no error or paymentIntentId returned.',
            ),
        );
        throw new PaymentError('Unexpected Stripe response.');
    }
}
