module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://klim.co.nz"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Klim Type Foundry","short_name":"Klim","start_url":"/","background_color":"#000000","theme_color":"#FFFFFF","theme_color_in_head":false,"display":"standalone","crossOrigin":"use-credentials","icon":"static/favicons/klim-favicon-1024.png","icons":[{"src":"static/favicons/klim-favicon-1024.png","type":"image/png","sizes":"1024x1024"},{"src":"static/favicons/klim-favicon-16.png","type":"image/png","sizes":"16x16"},{"src":"static/favicons/klim-favicon-24.png","type":"image/png","sizes":"24x24"},{"src":"static/favicons/klim-favicon-29.png","type":"image/png","sizes":"29x29"},{"src":"static/favicons/klim-favicon-32.png","type":"image/png","sizes":"32x32"},{"src":"static/favicons/klim-favicon-36.png","type":"image/png","sizes":"36x36"},{"src":"static/favicons/klim-favicon-40.png","type":"image/png","sizes":"40x40"}],"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"7b50a9deb58e35dc4a051d953a04e724"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-30516761-1"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
