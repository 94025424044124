// Social link definitions
import { SocialIconType } from '../components/Icon';
import {
    createFlickrUrl,
    createInstagramUrl,
    createPinterestUrl,
    createTumblrlUrl,
    createTwitterUrl,
    createVimeoUrl,
} from '../utils/urlHelper';

export interface SocialLinkDef {
    title: string;
    url: string;
    iconType: SocialIconType;
}

export const SOCIAL_LINK_DEFS: SocialLinkDef[] = [
    {
        title: 'Twitter',
        url: createTwitterUrl(),
        iconType: SocialIconType.TWITTER,
    },
    {
        title: 'Instagram',
        url: createInstagramUrl(),
        iconType: SocialIconType.INSTAGRAM,
    },
    {
        title: 'Vimeo',
        url: createVimeoUrl(),
        iconType: SocialIconType.VIMEO,
    },
    {
        title: 'Tumblr',
        url: createTumblrlUrl(),
        iconType: SocialIconType.TUMBLR,
    },
    {
        title: 'Pinterest',
        url: createPinterestUrl(),
        iconType: SocialIconType.PINTEREST,
    },
    {
        title: 'Flickr',
        url: createFlickrUrl(),
        iconType: SocialIconType.FLICKR,
    },
];
