import React from 'react';
import styled from 'styled-components';
import GlyphInspectorToolbar from './GlyphInspectorToolbar';
import GlyphInspectorGlyphView from './GlyphInspectorGlyphView';
import GlyphInspectorGlyphList from './GlyphInspectorGlyphList';
import { Provider } from './GlyphInspectorContext';
import { GLYPH_INSPECTOR_BREAKPOINT } from '../settings/Global';
import { useFontFamily } from './PageContext';
import {
    AboveGlyphBreakpoint,
    UpToGlyphBreakpoint,
} from './mediaQueryWrappers';

const Container = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        grid-template-rows: calc(50vh - (var(--navbarHeight) / 2)) auto;
        grid-template-columns: 1fr;
    }
`;

const Column = styled.div`
    &[data-column='toolbar'] {
        grid-column: span 7;
        position: relative;
    }

    &[data-column='list'] {
        grid-column: span 5;
    }

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        &[data-column='toolbar'] {
            grid-column: span 1;
            margin-right: var(--gridMarginGap);
        }

        &[data-column='list'] {
            grid-column: span 1;

            /* move margin into negative to ensure full width scrolling */
            margin-left: calc(var(--gridMarginGap) * -1);
        }
    }
`;

const Inner = styled.div`
    position: sticky;
    top: var(--navbarHeight);
    left: 0;
    height: 100%;
    max-height: calc(100vh - var(--navbarHeight));
    display: grid;
    grid-template-rows: auto 1fr;

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        grid-template-rows: 1fr;
    }
`;

function GlyphInspector(): React.ReactElement {
    return (
        <Container>
            <Column data-column="toolbar">
                <Inner>
                    <AboveGlyphBreakpoint>
                        <GlyphInspectorToolbar />
                    </AboveGlyphBreakpoint>
                    <GlyphInspectorGlyphView />
                </Inner>
            </Column>
            <UpToGlyphBreakpoint>
                <GlyphInspectorToolbar />
            </UpToGlyphBreakpoint>
            <Column data-column="list">
                <GlyphInspectorGlyphList />
            </Column>
        </Container>
    );
}

export default function GlyphInspectorWrapper(): React.ReactElement {
    const fontFamily = useFontFamily();

    return (
        <Provider fontFamily={fontFamily}>
            <GlyphInspector />
        </Provider>
    );
}
