import { createTestFontsUrl, createPreReleaseTestFontsUrl } from './urlHelper';

/**
 * Returns URL to download test fonts for this family. Varies depending on whether the font is pre-release or not.
 * @param fontFamily
 */
export default function getFontFamilyTestFontUrl(fontFamily?: {
    id: string;
    isPreRelease: boolean;
}): string {
    return fontFamily && fontFamily.isPreRelease
        ? createPreReleaseTestFontsUrl(fontFamily.id)
        : createTestFontsUrl();
}
