import React from 'react';
import styled from 'styled-components';
import { useFontFamily } from './PageContext';
import { VIEWPORT } from '../settings/Global';
import printFilesize from '../utils/printFilesize';
import getCssFontFamilyNameFromId from '../utils/getCssFontFamilyNameFromId';
import getSafeCssStringValue from '../utils/getSafeCssStringValue';

const Table = styled.table`
    display: block;
    font-size: var(--fontSizeFixed2);
    padding-top: var(--spacing3);
`;

const Thead = styled.thead`
    display: block;
`;

const Tbody = styled.tbody`
    display: block;
`;

const Tfoot = styled.tfoot`
    display: block;
`;

const Hr = styled.td.attrs({ role: 'presentation' })`
    margin: var(--spacing2) 0;
    border-top: 1px solid var(--borderColor);

    tbody & {
        border-top: 1px solid var(--borderColor);
        grid-column: 1 / -1;
    }

    tfoot & {
        border-top: 1px solid var(--borderColor);
        grid-column: -4 / -1;
    }

    tbody tr:first-child & {
        border-width: 2px;
        border-color: var(--borderColor);
    }
`;

const Tr = styled.tr<{ $hasPixelsColumn: boolean; $hasPointsColumn: boolean }>`
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
    grid-column-gap: var(--gridColumnGap);

    thead & {
        padding: 0;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-columns: 5fr 1fr ${({ $hasPixelsColumn }): string =>
                $hasPixelsColumn ? '1fr' : ''} ${({
                $hasPointsColumn,
            }): string => ($hasPointsColumn ? '1fr' : '')};
    }
`;

const Td = styled.td<{ $fontFamily?: string }>`
    font-feature-settings: 'tnum';
    display: block;
    text-align: right;
    font-family: ${({ $fontFamily }): string => $fontFamily || 'inherit'};

    &:first-child {
        text-align: left;
    }

    &[data-cell='font-style'] {
        font-size: var(--fontSizeFixed4);
    }

    &[data-cell='total'] {
        font-weight: bold;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:empty {
            display: none;
        }
    }
`;

const Th = styled(Td).attrs({ as: 'th' })`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:empty {
            display: none;
        }
    }
`;

function Metrics(): React.ReactElement {
    const { name, fontStyles } = useFontFamily();

    const woff2Total = React.useMemo(
        () =>
            fontStyles.reduce(
                (carry: number, fontStyle): number =>
                    typeof fontStyle.ttfFiles?.woff2FileSize === 'number'
                        ? carry + fontStyle.ttfFiles.woff2FileSize
                        : carry,
                0,
            ),
        [fontStyles],
    );

    const hasPixelsColumn = React.useMemo(
        () =>
            fontStyles.some(
                (fontStyle): boolean => fontStyle.pixelSize !== null,
            ),
        [fontStyles],
    );

    const hasPointsColumn = React.useMemo(
        (): boolean =>
            fontStyles.some(
                (fontStyle): boolean => fontStyle.pointSize !== null,
            ),
        [fontStyles],
    );

    return (
        <Table>
            <Thead>
                <Tr
                    $hasPixelsColumn={hasPixelsColumn}
                    $hasPointsColumn={hasPointsColumn}
                >
                    <Th>&nbsp;</Th>
                    <Th />
                    <Th>{hasPixelsColumn && 'Pixels'}</Th>
                    <Th>{hasPointsColumn && 'Points'}</Th>
                    <Th>.woff2</Th>
                </Tr>
            </Thead>
            <Tbody>
                {fontStyles.map((fontStyle): React.ReactElement => {
                    return (
                        <React.Fragment key={fontStyle.name}>
                            <Tr
                                role="presentation"
                                $hasPixelsColumn={hasPixelsColumn}
                                $hasPointsColumn={hasPointsColumn}
                            >
                                <Hr />
                            </Tr>
                            <Tr
                                $hasPixelsColumn={hasPixelsColumn}
                                $hasPointsColumn={hasPointsColumn}
                            >
                                <Td
                                    $fontFamily={getSafeCssStringValue(
                                        getCssFontFamilyNameFromId(
                                            fontStyle.id,
                                        ),
                                    )}
                                    data-cell="font-style"
                                >
                                    {name} {fontStyle.name}
                                </Td>
                                <Td />
                                <Td>
                                    {hasPixelsColumn && fontStyle.pixelSize}
                                </Td>
                                <Td>
                                    {hasPointsColumn && fontStyle.pointSize}
                                </Td>
                                <Td>
                                    {typeof fontStyle.ttfFiles
                                        ?.woff2FileSize === 'number'
                                        ? printFilesize(
                                              fontStyle.ttfFiles.woff2FileSize,
                                          )
                                        : null}
                                </Td>
                            </Tr>
                        </React.Fragment>
                    );
                })}
            </Tbody>
            {fontStyles.length > 1 && (
                <Tfoot>
                    <Tr
                        role="presentation"
                        $hasPixelsColumn={hasPixelsColumn}
                        $hasPointsColumn={hasPointsColumn}
                    >
                        <Hr />
                    </Tr>
                    <Tr
                        $hasPixelsColumn={hasPixelsColumn}
                        $hasPointsColumn={hasPointsColumn}
                    >
                        <Td>&nbsp;</Td>
                        <Td />
                        {hasPixelsColumn ? <Td>&nbsp;</Td> : <Td />}
                        {hasPointsColumn ? <Td>&nbsp;</Td> : <Td />}
                        <Td data-cell="total">{printFilesize(woff2Total)}</Td>
                    </Tr>
                </Tfoot>
            )}
        </Table>
    );
}

export default React.memo(Metrics);
