/**
 * Formats a Django date string, either YYYY-MM-DD or ISO 8601, into a nice string for the website.
 * @param dateStr
 * @param withTime
 */
export default function formatDjangoDateString(
    dateStr: string,
    withTime = false,
): string {
    const date = new Date(dateStr);
    if (date.getFullYear() <= 1970) {
        throw Error(`Invalid date: ${dateStr} -> ${date}`);
    }

    const dateParts = new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        hourCycle: 'h12',
        minute: 'numeric',
    }).formatToParts(date);

    const day = dateParts.find((dp) => dp.type === 'day')?.value;
    const month = dateParts.find((dp) => dp.type === 'month')?.value;
    const year = dateParts.find((dp) => dp.type === 'year')?.value;

    if (day === undefined || month === undefined || year === undefined) {
        throw Error(
            `Invalid date parts: ${dateStr} -> ${date} (${day}-${month}-${year})`,
        );
    }

    const dateOut = `${day} ${month} ${year}`;
    if (!withTime) {
        return dateOut;
    }

    const hour = dateParts.find((dp) => dp.type === 'hour')?.value;
    const minute = dateParts.find((dp) => dp.type === 'minute')?.value;
    const dayPeriod = dateParts
        .find((dp) => dp.type === 'dayPeriod')
        ?.value.toLowerCase();

    if (hour === undefined || minute === undefined) {
        throw Error(
            `Invalid time parts: ${dateStr} -> ${date} (${hour}:${minute})`,
        );
    }

    return `${dateOut} at ${hour}:${minute}${
        dayPeriod !== undefined ? dayPeriod : ''
    }`;
}
