import { Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import { statefulLozengeStyles } from './Lozenge';
import { BUTTON_AND_FIELD_HEIGHT } from '../settings/Global';

export type ButtonProps = { $secondary?: boolean; $withBackground?: boolean };

const buttonStyles = css<ButtonProps>`
    --lozengeBorderStyle: solid;
    --lozengeBorderColor: ${({ $withBackground }): string =>
        $withBackground
            ? 'var(--foregroundColorMix6)'
            : 'var(--foregroundColor)'};
    --lozengeHoverBorderColor: ${({ $secondary, $withBackground }): string =>
        $secondary || $withBackground
            ? 'var(--foregroundColor)'
            : 'var(--foregroundColorMix3)'};
    --lozengeColor: ${({ $secondary, $withBackground }): string =>
        $secondary || $withBackground
            ? 'var(--foregroundColor)'
            : 'var(--backgroundColor)'};
    --lozengeHoverColor: var(--backgroundColor);
    --lozengeBackgroundColor: ${({ $secondary, $withBackground }): string =>
        $withBackground
            ? 'transparent'
            : $secondary
              ? 'var(--backgroundColor)'
              : 'var(--foregroundColor)'};
    --lozengeBackgroundHoverColor: ${({
        $secondary,
        $withBackground,
    }): string =>
        $secondary || $withBackground
            ? 'var(--foregroundColor)'
            : 'var(--foregroundColorMix3)'};
    --lozengeBackgroundActiveColor: var(--lozengeBackgroundHoverColor);

    ${statefulLozengeStyles};

    height: ${BUTTON_AND_FIELD_HEIGHT}px;
    text-align: center;
    justify-content: center;
`;

const Button = styled.button<ButtonProps>`
    ${buttonStyles};
`;

export default Button;

export const InlineButton = styled(Button)<ButtonProps>`
    width: 100%;

    /*
    TODO: Check
    line-height: var(--lineHeightBody2);
    padding: var(--spacing1) var(--spacing2);
     */
`;

export const Link = styled(GatsbyLink)<ButtonProps>`
    ${buttonStyles};
`;

export const ExternalLink = styled.a<ButtonProps>`
    ${buttonStyles};
`;
