import React from 'react';
import styled from 'styled-components';
import type { Font } from 'opentype.js';
import {
    useFontStyleState,
    useGlyphInspectorState,
} from './GlyphInspectorContext';
import {
    fontMetricData,
    Metric,
} from '../utils/glyph-inspector/fontMetricData';
import { useDrawGlyph } from '../utils/glyph-inspector/drawGlyph';
import { useCanvasDimensionsState } from './GlyphInspectorContext';
import { LOZENGE_HEIGHT, VIEWPORT } from '../settings/Global';
import type { FontFamily } from './PageContext';
import isBrowser from '../utils/isBrowser';
import { useGlobalState } from './GlobalRuntimeState';
import { lozengeStyles } from './Lozenge';

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: ${LOZENGE_HEIGHT / 2}px;
`;

const Canvas = styled.canvas`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: ${LOZENGE_HEIGHT / 2}px;
`;

const Metrics = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--foregroundColorMix7);

    /* Note --fontSizeFixed2 is also used in JS below */
    font-size: var(--fontSizeFixed2);
    padding-bottom: ${LOZENGE_HEIGHT / 2}px;
`;

const MetricContainer = styled.div`
    position: absolute;
    width: 100%;
`;

const MetricLabels = styled.div`
    position: absolute;
    width: 100%;
    bottom: ${-LOZENGE_HEIGHT / 2}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const MetricName = styled.div`
    --lozengeColor: var(--foregroundColorMix7);

    border: 1px solid var(--foregroundColorMix7);
    ${lozengeStyles};
`;

const MetricLine = styled.div`
    height: 0;
    line-height: 0;
    border-top: 1px solid var(--borderColor);
    flex: 3 0;
`;

const MetricValue = styled.div`
    --lozengeColor: var(--foregroundColorMix7);

    border: 1px solid var(--foregroundColorMix7);
    ${lozengeStyles};
    font-feature-settings: 'tnum';
`;

const useMetrics = (
    font: Font | undefined,
    fontStyle: FontFamily['fontStyles'][number],
    width: number,
    height: number,
    pixelRatio: number,
): Metric[] => {
    return React.useMemo((): Metric[] => {
        return font
            ? fontMetricData(font, fontStyle, width, height, pixelRatio)
            : [];
    }, [font, fontStyle, width, height, pixelRatio]);
};

export default function GlyphInspectorGlyphView(): React.ReactElement {
    const glyphInspectorState = useGlyphInspectorState();
    const [fontStyle] = useFontStyleState();
    const { pixelRatio, font } = glyphInspectorState;
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [viewportHeight] = useGlobalState('viewportHeight');
    const [canvasDimensions, setCanvasDimensions] = useCanvasDimensionsState();

    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect((): (() => void) => {
        const resizeObserver = new ResizeObserver((entries): void =>
            entries.forEach(
                (entry): void =>
                    void requestAnimationFrame((): void => {
                        const { width, height } = entry.contentRect;
                        setCanvasDimensions(
                            (
                                state,
                            ): {
                                width: number;
                                height: number;
                            } => {
                                if (
                                    // initial renders have height=0 for example, let's ignore those.
                                    width &&
                                    height &&
                                    // Has either dimension changed?
                                    (state.width !== width ||
                                        state.height !== height)
                                ) {
                                    return { width, height };
                                }
                                /*
                                 * An optimisation: returning a referentially equal
                                 * state value prevents the rerender.
                                 */
                                return state;
                            },
                        );
                    }),
            ),
        );

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        return (): void => resizeObserver.disconnect();
    }, [setCanvasDimensions]);

    const metrics = useMetrics(
        font,
        fontStyle,
        canvasDimensions.width,
        canvasDimensions.height,
        pixelRatio,
    );

    useDrawGlyph({ canvasRef });

    // Minimum vertical distance between two metrics in order for them to show
    const metricThreshold = React.useMemo(() => {
        const value = isBrowser()
            ? parseInt(
                  window
                      .getComputedStyle(document.documentElement)
                      .getPropertyValue('--fontSizeFixed2'),
                  10,
              )
            : NaN;
        if (!isNaN(value)) {
            return value * 2;
        }
        return 0;
    }, [viewportHeight]);

    return (
        <Container ref={containerRef}>
            <Metrics>
                {metrics.map(
                    (
                        metric: Metric,
                        index,
                        metricArray,
                    ): React.ReactElement | null => {
                        const topOffset = metric.yoffset / pixelRatio;
                        const nextTopOffset = metricArray[index + 1]
                            ? metricArray[index + 1].yoffset / pixelRatio
                            : undefined;
                        // Don't show metrics if they're too close to one-another
                        if (
                            nextTopOffset !== undefined &&
                            topOffset + metricThreshold >= nextTopOffset
                        ) {
                            return null;
                        }
                        return (
                            <MetricContainer
                                style={{ top: topOffset }}
                                key={metric.name}
                            >
                                <MetricLabels>
                                    <MetricName>
                                        {viewportWidth &&
                                        viewportWidth <= VIEWPORT.MOBILE
                                            ? metric.name.charAt(0)
                                            : metric.name}
                                    </MetricName>
                                    <MetricValue>{metric.value}</MetricValue>
                                </MetricLabels>
                                <MetricLine />
                            </MetricContainer>
                        );
                    },
                )}
            </Metrics>
            <Canvas ref={canvasRef} />
        </Container>
    );
}
