import React from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';
import hideVisually from 'polished/lib/mixins/hideVisually';
import { inputStyles, placeholderStyles } from '../utils/formStyles';

const Container = styled.div``;

const Textarea = styled.textarea<{ $isError?: boolean }>`
    ${inputStyles.base};

    line-height: var(--lineHeightBody2);
    height: auto;
    min-height: calc(5 * var(--fontSizeFixed2) * var(--lineHeightBody2));

    ${({ $isError }): ReturnType<typeof css> | null =>
        $isError ? inputStyles.errorMod : null};

    /* This removes padding underneath the textarea */
    vertical-align: top;

    &::placeholder {
        ${placeholderStyles.base};
    }

    &:focus::placeholder {
        ${placeholderStyles.focusMod};
    }
`;

const Label = styled.label`
    ${hideVisually};
`;

interface FieldTextareaProps
    extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    isError?: boolean;
    label?: string;
}

function FieldTextarea(
    { label, id, isError, ...rest }: FieldTextareaProps,
    ref: React.Ref<HTMLTextAreaElement>,
): React.ReactElement {
    const inputId = React.useMemo((): string => id || uuid(), [id]);

    return (
        <Container>
            {label && <Label htmlFor={inputId}>{label}</Label>}
            <Textarea
                id={inputId}
                {...rest}
                ref={ref}
                placeholder={label}
                $isError={isError}
            />
        </Container>
    );
}

export default React.forwardRef(FieldTextarea);
