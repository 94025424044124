import {
    FOOTNOTE_BACK_CLASSNAME,
    FOOTNOTE_CLASSNAME,
} from '../settings/Global';

const superscriptNumbers = [
    // Unicode codepoints for superscript numbers.
    // index 0 is superscript 0, index 1 is superscript 1, etc.
    '\u2070',
    '\u00B9',
    '\u00B2',
    '\u00B3',
    '\u2074',
    '\u2075',
    '\u2076',
    '\u2077',
    '\u2078',
    '\u2079',
];

export function getFootnoteId(idx: number, isBackId?: boolean): string {
    return `${isBackId ? FOOTNOTE_BACK_CLASSNAME : FOOTNOTE_CLASSNAME}-${
        idx + 1
    }`;
}

export function numberToSuperscriptNumber(number: number): string {
    return number
        .toString()
        .split('')
        .map(
            (number: string): string =>
                superscriptNumbers[parseInt(number, 10)],
        )
        .join('');
}

export interface ResolvedFootnotes {
    html: string;
    footnoteCount: number;
}

const footnotePattern = /&lt;[0-9]+&gt;/gi;

export function insertFootnotesInBody(
    html: string,
    numberOfPreviousFootnotes: number,
): ResolvedFootnotes {
    let footnoteCount = numberOfPreviousFootnotes;
    const resolvedHTML = html.replace(footnotePattern, (): string => {
        footnoteCount++;
        return `<a href="#${getFootnoteId(
            footnoteCount - 1,
        )}" class="${FOOTNOTE_CLASSNAME}" id="${getFootnoteId(
            footnoteCount - 1,
            true,
        )}">${numberToSuperscriptNumber(footnoteCount)}</a>`;
    });
    return {
        html: resolvedHTML,
        footnoteCount,
    };
}

export function splitFootnotes(
    html: string,
    numberOfPreviousFootnotes: number,
): string[] {
    // The purpose is to return an array of footnotes that we could
    // template with React.
    //
    // The input is a string that looks like:
    //
    //    <p>&lt;1&gt; footnote text</p><p>&lt;2&gt; footnote text</p>
    //
    // If we simply split on `footnotePattern` then we'd get back
    //
    //   [
    //   "<p>",
    //   " footnote text</p><p>",
    //   " footnote text</p>"
    //   ]
    //
    // So instead we have this process (this is a simplification)
    //
    //  1. Remove all linebreaks
    //  2. Convert all "<p>" and "</p>" to linebreaks
    //  4. Split on `footnotePattern`
    //  5. Restore paragraphs by wrapping each line of text
    //
    // You can see the expected input/output of this in unit tests.
    return html
        .replace(/\n/gi, ' ')
        .replace(/<\/p>/gi, '\n')
        .replace(/<p[^>]*>/gi, '\n')
        .split(footnotePattern)
        .filter((block: string): boolean => block.trim().length > 0) // otherwise
        .map((footnote, idx): string => {
            const footnoteIdx = numberOfPreviousFootnotes + idx;
            const footnoteWithPTags = footnote
                .split('\n')
                .map((line): string => line.trim())
                .filter((line: string): boolean => line.length > 0)
                .join('</p><p>');
            return `<p>${footnoteWithPTags}<a href="#${getFootnoteId(
                footnoteIdx,
                true,
            )}" class="${FOOTNOTE_BACK_CLASSNAME}" aria-label="Back to originating footnote reference ${
                footnoteIdx + 1
            }">⤴</a></p>`;
        });
}
