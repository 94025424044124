import React from 'react';

export default function printAmpersandSeries(
    items: React.ReactNode[],
    returnElements?: boolean,
): React.ReactNode[] {
    const filtered = items.filter(Boolean); // get rid of empty strings ''

    if (filtered.length <= 1) {
        return filtered;
    }

    const [first, ...rest] = filtered;

    return rest.reduce(
        (carry: React.ReactNode[], element, index): React.ReactNode[] => {
            return [
                ...carry,
                index === rest.length - 1 ? (
                    returnElements ? (
                        <React.Fragment key={`amp-${index}`}>
                            {' '}
                            &{' '}
                        </React.Fragment>
                    ) : (
                        ' & '
                    )
                ) : returnElements ? (
                    <React.Fragment key={`comma-${index}`}>, </React.Fragment>
                ) : (
                    ', '
                ),
                element,
            ];
        },
        [first],
    );
}
