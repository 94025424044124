/**
 * There is a special rendering case for fonts like Maelstrom which have
 * only a single style per family, where no 'collection' or 'family' inputs
 * are displayed etc.
 * @param fontFamilyGroup
 */
export default function isMaelstromSpecialCase(fontFamilyGroup: {
    fontFamilies: { fontStyles: unknown[] }[];
}): boolean {
    return fontFamilyGroup.fontFamilies.every(
        (fontFamily): boolean => fontFamily.fontStyles.length === 1,
    );
}
