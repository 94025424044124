import { useGlobalState } from '../components/GlobalRuntimeState';

type AspectMode = 'LANDSCAPE' | 'PORTRAIT';

export default function useLandscapePortrait(): AspectMode {
    const [viewportHeight] = useGlobalState('viewportHeight');
    const [viewportWidth] = useGlobalState('viewportWidth');

    // default to landscape if there's no data available
    return viewportWidth && viewportHeight && viewportWidth < viewportHeight
        ? 'PORTRAIT'
        : 'LANDSCAPE';
}
