import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';

const Dl = styled.dl<{ $hasTopSpace?: boolean }>`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    border-top: 1px solid var(--borderColor);
    font-size: var(--fontSizeFixed2);

    &:first-child {
        border-top: 0;
        margin-top: ${({ $hasTopSpace }): string =>
            $hasTopSpace ? 'calc(-1 * var(--spacing2))' : '0'};

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            margin-top: 0;
        }
    }
`;

const Dd = styled.dd`
    margin: var(--spacing1) 0;
    grid-column: 5 / span 8;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 4 / span 3;
    }
`;

const Dt = styled.dt`
    grid-column: 1 / span 4;
    color: var(--foregroundColorMix4);
    margin: var(--spacing1) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 3;
    }
`;

interface DefinitionListProps {
    name?: React.ReactNode;
    type?: React.ReactNode;
    hasTopSpace?: boolean;
}

function DefinitionList({
    name,
    type,
    hasTopSpace,
}: DefinitionListProps): React.ReactElement {
    return (
        <Dl $hasTopSpace={hasTopSpace}>
            <Dt>{name}</Dt>
            <Dd>{type}</Dd>
        </Dl>
    );
}

export default React.memo(DefinitionList);
