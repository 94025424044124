import React from 'react';
import { convertFromRaw, EditorState } from 'draft-js';
import { SpecimenType } from '../settings/Global';
import type { GeneratedSpecimen } from '../utils/GeneratedSpecimen';
import createStatefulContext from '../utils/createStatefulContext';
import { useGlobalState } from './GlobalRuntimeState';
import { Property } from 'csstype';

const { Provider: EditorStateProvider, useState: useEditorState } =
    createStatefulContext<EditorState>();

const { Provider: FontSizeProvider, useState: useFontSizeState } =
    createStatefulContext<number>();

const { Provider: LineHeightProvider, useState: useLineHeightState } =
    createStatefulContext<number>();

const { Provider: LetterSpacingProvider, useState: useLetterSpacingState } =
    createStatefulContext<number>();

const { Provider: TextAlignProvider, useState: useTextAlignState } =
    createStatefulContext<Property.TextAlign>();

const { Provider: FontStyleProvider, useState: useFontStyleState } =
    createStatefulContext<string>();

const { Provider: ColumnsProvider, useState: useColumnsState } =
    createStatefulContext<number>();

const { Provider: PreventWrappingProvider, useState: usePreventWrappingState } =
    createStatefulContext<boolean>();

const {
    Provider: SpecimensRenderWidthProvider,
    useState: useSpecimensRenderWidthState,
} = createStatefulContext<number>();

const SpecimenContext = React.createContext<GeneratedSpecimen | null>(null);

export const useSpecimenContext = (): GeneratedSpecimen => {
    const specimenContext = React.useContext(SpecimenContext);
    if (specimenContext === null) {
        throw new Error('SpecimenContext not provided');
    }
    return specimenContext;
};

export {
    useEditorState,
    useFontSizeState,
    useLineHeightState,
    useLetterSpacingState,
    useTextAlignState,
    useFontStyleState,
    useColumnsState,
    usePreventWrappingState,
    useSpecimensRenderWidthState,
};

export function Provider({
    children,
    specimen,
}: {
    children: React.ReactElement;
    specimen: GeneratedSpecimen;
}): React.ReactElement {
    const [viewportWidth] = useGlobalState('viewportWidth');
    const initialEditorState = React.useMemo(
        (): EditorState =>
            EditorState.createWithContent(convertFromRaw(specimen.state)),
        [specimen],
    );

    return (
        <SpecimensRenderWidthProvider initial={viewportWidth}>
            <SpecimenContext.Provider value={specimen}>
                <EditorStateProvider initial={initialEditorState}>
                    <FontSizeProvider initial={specimen.fontSize}>
                        <LineHeightProvider initial={specimen.lineHeight}>
                            <LetterSpacingProvider
                                initial={specimen.letterSpacing}
                            >
                                <TextAlignProvider
                                    initial={specimen.textAlign || 'left'}
                                >
                                    <FontStyleProvider
                                        initial={specimen.fontStyleId}
                                    >
                                        <ColumnsProvider
                                            initial={specimen.columns || 1}
                                        >
                                            <PreventWrappingProvider
                                                initial={
                                                    // For Big specimens we want to initially prevent text wrapping
                                                    specimen.type ===
                                                    SpecimenType.BIG
                                                }
                                            >
                                                {children}
                                            </PreventWrappingProvider>
                                        </ColumnsProvider>
                                    </FontStyleProvider>
                                </TextAlignProvider>
                            </LetterSpacingProvider>
                        </LineHeightProvider>
                    </FontSizeProvider>
                </EditorStateProvider>
            </SpecimenContext.Provider>
        </SpecimensRenderWidthProvider>
    );
}
