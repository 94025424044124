import React from 'react';
import { navigate } from 'gatsby';
import { lazy } from '@loadable/component';
import styled, { css } from 'styled-components';
import FontFamilyInformation from '../components/FontFamilyInformation';
import FontFamilyFontStyles from '../components/FontFamilyFontStyles';
import _FontSectionTitled from '../components/FontSectionTitled';
import { useFontFamily } from '../components/PageContext';
import FontFamilyFontsInUse from '../components/FontFamilyFontsInUse';
import {
    VIEWPORT,
    FONT_FAMILY_MINIMUM_IN_USE_POSTS,
    GLYPH_INSPECTOR_BREAKPOINT,
} from '../settings/Global';
import { rememberVisitedPrereleaseFontFamilyId } from '../utils/visitedPrereleaseFonts';
import printStylesCount from '../utils/printStylesCount';
import useIsAllowedForUserTesting from '../hooks/useIsAllowedForUserTesting';
import { create404NotFoundUrl } from '../utils/urlHelper';
import isBrowser from '../utils/isBrowser';
import NotableDetails from '../components/NotableDetails';
import FontHeroWithWrapper from '../components/FontHeroWithWrapper';

export { Head } from '../components/Head';

export enum FontFamilySectionIds {
    INFO = 'information',
    STYLES = 'styles',
    DETAILS = 'details',
    SPECIMENS = 'specimens',
    GLYPHS = 'glyphs',
    IN_USE = 'in-use',
}

// Holds the space of the glyph inspector, and provides a screen's height to the specimens, until they are loaded
const FallbackContainer = styled.div`
    height: 100vh;
`;

// The glyph inspector loads opentype.js and is generally only
// required client-side, so we lazy-load it. This will also
// split its code from the main app.
const GlyphInspector = lazy(() => import('../components/GlyphInspector'));

// Specimens might be heavy in terms of initialisation, delaying TTI (time to interactive).
// Hence, we lazy-load it...
const Specimens = lazy(() => import('../components/Specimens'));

const Container = styled.div`
    padding: var(--paddingPageDefault);
    padding-top: 0;
    margin: 0 var(--gridMarginGap);
`;

const Sections = styled.div`
    display: flex;
    flex-direction: column;
`;

const FontSectionTitled = styled(_FontSectionTitled)`
    &:not(:first-child) {
        margin-top: 50vh;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            margin-top: 25vh;
        }
    }
`;

function FontFamilyPage(): React.ReactElement | null {
    const fontFamily = useFontFamily();
    const { fontStyles, svgHeading, name, campaign } = fontFamily;

    const fallback = React.useMemo(
        () => <FallbackContainer>Loading…</FallbackContainer>,
        [],
    );

    const isAllowedForUserTesting = useIsAllowedForUserTesting(
        fontFamily.fontFamilyGroup.id,
    );
    if (isBrowser() && !isAllowedForUserTesting) {
        navigate(create404NotFoundUrl());
        return null;
    }

    const hasFontsInUsePages =
        fontFamily.featuredInUsePosts &&
        fontFamily.featuredInUsePosts.length >=
            FONT_FAMILY_MINIMUM_IN_USE_POSTS;

    // Remember that we've visited a pre-release font, if applicable.
    // For Collections, we don't need to do this, as Collections will
    // show all pre-release fonts on the Buy page, anyway.
    if (fontFamily.isPreRelease && !fontFamily.fontFamilyGroup.isCollection) {
        rememberVisitedPrereleaseFontFamilyId(fontFamily.id);
    }

    const styleOverride = css`
        @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
            padding-top: 0;
        }
    `;

    return (
        <>
            <FontHeroWithWrapper
                svgHeading={svgHeading}
                svgTitle={`${name} Family`}
                campaign={campaign}
            />
            <Container>
                <Sections>
                    <FontSectionTitled
                        id={FontFamilySectionIds.INFO}
                        title="Information"
                        $applyBaselineAlignment
                    >
                        <FontFamilyInformation />
                    </FontSectionTitled>
                    <FontSectionTitled
                        id={FontFamilySectionIds.STYLES}
                        title={
                            printStylesCount(fontFamily.fontStyleCount) ||
                            'Styles'
                        }
                        $applyBaselineAlignment
                    >
                        <FontFamilyFontStyles
                            styles={fontStyles}
                            fontSizeMultiplier={fontFamily.fontSizeMultiplier}
                            withFragmentLinks
                        />
                    </FontSectionTitled>
                    {fontFamily.notableDetails.length > 0 && (
                        <FontSectionTitled
                            id={FontFamilySectionIds.DETAILS}
                            title="Details"
                            stacked
                        >
                            <NotableDetails />
                        </FontSectionTitled>
                    )}
                    <FontSectionTitled
                        id={FontFamilySectionIds.SPECIMENS}
                        title="Specimens"
                        stacked
                    >
                        {isBrowser() ? (
                            <React.Suspense fallback={fallback}>
                                <Specimens />
                            </React.Suspense>
                        ) : (
                            fallback
                        )}
                    </FontSectionTitled>
                    <FontSectionTitled
                        id={FontFamilySectionIds.GLYPHS}
                        title="Glyphs"
                        stacked
                        styleOverride={styleOverride}
                    >
                        {isBrowser() ? (
                            <React.Suspense fallback={fallback}>
                                <GlyphInspector />
                            </React.Suspense>
                        ) : (
                            fallback
                        )}
                    </FontSectionTitled>
                    {hasFontsInUsePages && (
                        <FontSectionTitled
                            id={FontFamilySectionIds.IN_USE}
                            title={`${fontFamily.name} in use`}
                            stacked
                        >
                            <FontFamilyFontsInUse />
                        </FontSectionTitled>
                    )}
                </Sections>
            </Container>
        </>
    );
}

export default React.memo(FontFamilyPage);
