import React from 'react';
import styled, { css } from 'styled-components';
import getCssFontFamilyNameFromId from '../utils/getCssFontFamilyNameFromId';
import FontFace from '../components/FontFace';
import { createStaticAssetUrl } from '../utils/urlHelper';
import Paragraph from '../components/Paragraph';
import { HeadingExtraLarge, HeadingSmall } from '../components/Heading';
import useUserQuery from '../hooks/useUserQuery';
import useConfig from '../hooks/useConfig';
import { FontMetricsCalculator } from '../utils/fontMetricsCalculator';
import useAllFonts, {
    type FontFamily,
    type MainFontStyle,
} from '../hooks/useAllFonts';
import {
    Div as LozengeDiv,
    LOZENGE_HORIZONTAL_PADDING,
} from '../components/Lozenge';
import SVGIcon from '../components/SVGIcon';
import InputSlider from '../components/InputSlider';
import { LOZENGE_HEIGHT, LOZENGE_SPACING, ZINDEX } from '../settings/Global';
import printFontSizePx from '../utils/printFontSizePx';
import { type GeneratedSpecimen } from '../utils/GeneratedSpecimen';

export { Head } from '../components/Head';

const LINE_HEIGHT_NORMAL = 'normal';
const SPECIMEN_WORD = `Hamburg`;
const DEFAULT_FONT_SIZE_PX = 200;

const Container = styled.div`
    padding-top: calc(var(--navbarHeight) * 2);
    margin-left: var(--gridMarginGap);
    margin-right: var(--gridMarginGap);

    --lozengeBackgroundColor: var(--menuBackgroundColor);
    --lozengeWidthNarrow: calc((100vw - var(--gridMarginGap) * 2) * 0.15);
    --lozengeWidthWide: calc((100vw - var(--gridMarginGap) * 2) * 0.2);
`;

const Intro = styled.div`
    margin-bottom: var(--spacing7);
    display: grid;
    gap: var(--spacing3);
`;

const SpecimenWrap = styled.div`
    position: relative;
    white-space: nowrap;
    margin-bottom: var(--spacing7);
`;

const UiWrap = styled(LozengeDiv)`
    --lozengeMinWidth: 160px;
    --lozengeWidth: 15vw;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${LOZENGE_HORIZONTAL_PADDING}px;
    font-feature-settings: 'tnum';
`;

const SpecimenHud = styled.div`
    width: 100%;
    position: relative;
    z-index: ${ZINDEX.TYPE_EDITOR_TOOLBAR};
    display: flex;
    flex-flow: row wrap;
    gap: ${LOZENGE_SPACING}px;
    min-height: ${LOZENGE_HEIGHT + LOZENGE_SPACING}px;
`;

const Metric = styled.div<{ $top?: string; $bottom?: string; $color: string }>`
    position: absolute;
    left: 0;
    ${({ $top }): ReturnType<typeof css> | null =>
        $top === undefined
            ? null
            : css`
                  top: ${$top};
              `};
    ${({ $bottom }): ReturnType<typeof css> | null =>
        $bottom === undefined
            ? null
            : css`
                  bottom: ${$bottom};
              `};
    width: 100%;
    border-top: 1px solid ${({ $color }): string => $color};
`;

const LegendLine = styled.hr<{ $color: string }>`
    display: inline-block;
    border: 0;
    height: 1px;
    width: 30px;
    vertical-align: middle;
    background-color: ${({ $color }): string => $color};
    margin-right: 10px;
`;

const LegendWrap = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: ${LOZENGE_SPACING}px;
    min-height: ${LOZENGE_HEIGHT + LOZENGE_SPACING}px;
`;

export function getMetricLines(
    metricSource: MainFontStyle | GeneratedSpecimen,
    lineHeight: number | undefined,
): React.ReactElement | null {
    if (!metricSource.ttfFiles?.metrics) {
        return null;
    }
    const offsets = new FontMetricsCalculator({
        lineHeight: lineHeight === undefined ? 'normal' : lineHeight,
        unitsPerEm: metricSource.ttfFiles.metrics.unitsPerEmHead,
        capHeight: metricSource.ttfFiles.metrics.capHeightOs2,
        ascentCalc: metricSource.ttfFiles.metrics.ascentCalc,
        ascent: metricSource.ttfFiles.metrics.ascentHhea,
        descentCalc: metricSource.ttfFiles.metrics.descentCalc,
        descent: metricSource.ttfFiles.metrics.descentHhea,
        lineGap: metricSource.ttfFiles.metrics.lineGapHhea,
    }).calculate();

    return (
        <>
            <Metric $top="0" $color="var(--foregroundColor)" />
            <Metric $top={`${offsets.ascent}em`} $color="red" />
            <Metric $bottom={`${-offsets.descent}em`} $color="purple" />
            <Metric $top={`${offsets.baseline}em`} $color="blue" />
            <Metric $top={`${offsets.capHeight}em`} $color="green" />
            <Metric
                title="Em Square top"
                $top={`${offsets.emSquareTop}em`}
                $color="yellow"
            />
            <Metric
                title="Em Square bottom"
                $top={`${offsets.emSquareBottom}em`}
                $color="yellow"
            />
            <Metric $bottom="0" $color="var(--foregroundColor)" />
        </>
    );
}

function Specimen({
    fontStyle,
    familyName,
}: {
    fontStyle: MainFontStyle;
    familyName: string;
}): React.ReactElement | null {
    const [lineHeight, setLineHeight] = React.useState<string>('1');
    const [fontSize, setFontSize] =
        React.useState<number>(DEFAULT_FONT_SIZE_PX);
    const cssFamilyName = React.useMemo(
        () => getCssFontFamilyNameFromId(fontStyle.id),
        [fontStyle.id],
    );
    const metricLines = React.useMemo(() => {
        return getMetricLines(
            fontStyle,
            lineHeight === LINE_HEIGHT_NORMAL
                ? undefined
                : parseFloat(lineHeight),
        );
    }, [fontStyle, lineHeight]);
    const title = `${familyName} ${fontStyle.name}`;
    if (!fontStyle.ttfFiles?.woff2File) {
        return null;
    }
    return (
        <>
            <FontFace
                fonts={[
                    {
                        fontFamily: cssFamilyName,
                        fontWeight: fontStyle.weight,
                        fontStyle: 'normal',
                        ttfFiles: {
                            woff2File: createStaticAssetUrl(
                                fontStyle.ttfFiles.woff2File,
                            ),
                        },
                    },
                ]}
                preventFallbackFont
            />
            <SpecimenHud>
                <LozengeDiv>{title}</LozengeDiv>
                <UiWrap>
                    <SVGIcon type="LINE_HEIGHT" title="Line height" />
                    <InputSlider
                        value={[parseFloat(lineHeight)]}
                        min={0.8}
                        max={3}
                        step={0.1}
                        onValueChange={(val): void =>
                            val.length
                                ? setLineHeight(val[0].toString())
                                : undefined
                        }
                    />
                    <div>{lineHeight}</div>
                </UiWrap>
                <UiWrap>
                    <SVGIcon type="FONT_SIZE" title="Font size" />
                    <InputSlider
                        value={[fontSize]}
                        min={10}
                        max={Math.max(Math.round(DEFAULT_FONT_SIZE_PX * 2), 40)}
                        onValueChange={(val): void =>
                            val.length ? setFontSize(val[0]) : undefined
                        }
                    />
                    <div>{printFontSizePx(fontSize)}</div>
                </UiWrap>
            </SpecimenHud>
            <SpecimenWrap
                style={{
                    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                    fontFamily: cssFamilyName,
                    fontSize: `${fontSize}px`,
                    lineHeight,
                }}
            >
                {metricLines}
                {SPECIMEN_WORD}
            </SpecimenWrap>
        </>
    );
}

function TestFontMetricsPage(): React.ReactElement | null {
    const config = useConfig();
    const userQuery = useUserQuery();
    const fontFamilyGroups = useAllFonts();
    const fontFamilies: FontFamily[] = React.useMemo(() => {
        return fontFamilyGroups.reduce((previousValue, currentValue) => {
            return previousValue.concat(currentValue.fontFamilies);
        }, [] as FontFamily[]);
    }, [fontFamilyGroups]);
    const showPage = !config.isProductionSite || userQuery.data?.user?.isAdmin;

    return (
        <Container>
            <Intro>
                <HeadingExtraLarge>
                    Visualising vertical metrics
                </HeadingExtraLarge>
                {showPage ? (
                    <>
                        <Paragraph>
                            This page shows how line height and vertical metrics
                            behave in the browser, and visualises a few key
                            metrics.
                        </Paragraph>
                        <HeadingSmall>Legend</HeadingSmall>
                        <LegendWrap>
                            <LozengeDiv>
                                <LegendLine $color="var(--foregroundColor)" />
                                Line height bounds
                            </LozengeDiv>
                            <LozengeDiv>
                                <LegendLine $color="yellow" />
                                Em square bounds
                            </LozengeDiv>
                            <LozengeDiv>
                                <LegendLine $color="red" />
                                Ascender
                            </LozengeDiv>
                            <LozengeDiv>
                                <LegendLine $color="green" />
                                Cap height
                            </LozengeDiv>
                            <LozengeDiv>
                                <LegendLine $color="blue" />
                                Baseline
                            </LozengeDiv>
                            <LozengeDiv>
                                <LegendLine $color="purple" />
                                Descender
                            </LozengeDiv>
                        </LegendWrap>
                    </>
                ) : userQuery.loading ? (
                    <Paragraph>Loading…</Paragraph>
                ) : (
                    <Paragraph>Please log in to see this page.</Paragraph>
                )}
            </Intro>
            {showPage ? (
                fontFamilies.map((fam) => (
                    <Specimen
                        key={fam.id}
                        fontStyle={fam.mainFontStyle}
                        familyName={fam.name}
                    />
                ))
            ) : (
                <Paragraph>Loading…</Paragraph>
            )}
        </Container>
    );
}

export default React.memo(TestFontMetricsPage);
