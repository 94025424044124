import type { GlyphInspectorState } from './GlyphInspectorContext';
import { ACTION_TYPE } from './GlyphInspector.actions';
import type {
    SetGlyphAction,
    SetFontAndGlyphAction,
} from './GlyphInspector.actions';

export type GlyphInspectorAction = SetGlyphAction | SetFontAndGlyphAction;

export const reducer = (
    state: GlyphInspectorState,
    action: GlyphInspectorAction,
): GlyphInspectorState => {
    switch (action.type) {
        case ACTION_TYPE.SET_GLYPH: {
            return {
                ...state,
                glyph: action.glyph,
            };
        }
        case ACTION_TYPE.SET_FONT_AND_GLYPH: {
            return {
                ...state,
                font: action.font,
                glyph: action.glyph,
            };
        }
        default:
            return state;
    }
};

export default reducer;
