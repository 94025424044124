import { useQuery } from '@apollo/client';
import type { QueryResult } from '@apollo/client';
import { userOrdersQuery } from '../utils/runtimeQueries';
import type {
    UserOrdersQueryQuery,
    UserOrdersQueryQueryVariables,
} from '../gql/api-public';

export type User = NonNullable<UserOrdersQueryQuery['user']>;
export type Order = User['orders'][number];
export type Cart = Order['cart'];
export type Item = Cart['items'][number];

export default function useUserOrdersQuery(
    variables: UserOrdersQueryQueryVariables,
): QueryResult<UserOrdersQueryQuery, UserOrdersQueryQueryVariables> {
    return useQuery<UserOrdersQueryQuery, UserOrdersQueryQueryVariables>(
        userOrdersQuery,
        {
            ssr: false,
            notifyOnNetworkStatusChange: true,
            variables,
        },
    );
}
