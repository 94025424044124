import React from 'react';
import styled from 'styled-components';
import { labelStyles } from '../utils/formStyles';

const Container = styled.fieldset`
    /* fieldset doesn't support flex/grid layout! */
`;

const Legend = styled.legend`
    ${labelStyles};
    margin-bottom: var(--spacing2);
`;

const Inner = styled.div``;

export default function Fieldset({
    legend,
    children,
}: {
    legend: string;
    children: React.ReactNode;
}): React.ReactElement {
    return (
        <Container>
            <Legend>{legend}</Legend>
            <Inner>{children}</Inner>
        </Container>
    );
}
