import { gql as previewGql } from '@apollo/client';

export const PreviewHomePageQuery = previewGql`
    query PreviewHomePageQuery($token: String!) {
        homepage(token: $token) {
            __typename
            slug
            title
            featuredFonts {
                __typename
                id
                name
                slug
                isCollection
                isNew
                svgNavigation
                typographicRanking
                colorSchemes {
                    backgroundColor: colorBackground
                    foregroundColor: colorForeground
                    highlightColor: colorHighlight
                    backgroundColorAlt: colorBackgroundAlt
                    foregroundColorAlt: colorForegroundAlt
                    highlightColorAlt: colorHighlightAlt
                }
                fontFamilies {
                    id
                    name
                    slug
                    isPreRelease
                    fontStyleCount
                    typographicRanking
                }
            }
            campaigns {
                fontFamilyGroup {
                    id
                    slug
                    name
                    isCollection
                    isNew
                    svgNavigation
                    typographicRanking
                    fontFamilies {
                        id
                        slug
                        name
                        isPreRelease
                    }
                    colorSchemes {
                        backgroundColor: colorBackground
                        foregroundColor: colorForeground
                        highlightColor: colorHighlight
                        backgroundColorAlt: colorBackgroundAlt
                        foregroundColorAlt: colorForegroundAlt
                        highlightColorAlt: colorHighlightAlt
                    }
                }
                campaign {
                    type
                    displayMode
                    transitionDuration
                    transitionInterval
                    images {
                        sortOrder
                        imageLandscape
                        imageLandscapeWidth
                        imageLandscapeHeight
                        layoutModeLandscape
                        marginOverlapLandscape
                        imagePortrait
                        imagePortraitWidth
                        imagePortraitHeight
                        layoutModePortrait
                        marginOverlapPortrait
                        colorLoaderForeground
                        colorLoaderBackground
                        colorBackground
                    }
                    videos {
                        videoLandscape {
                            id
                            title
                            width
                            height
                            files {
                                id
                                width
                                height
                                type
                                src
                            }
                        }
                        layoutModeLandscape
                        marginOverlapLandscape
                        videoPortrait {
                            id
                            title
                            width
                            height
                            files {
                                id
                                width
                                height
                                type
                                src
                            }
                        }
                        layoutModePortrait
                        marginOverlapPortrait
                        colorLoaderForeground
                        colorLoaderBackground
                    }
                }
            }
            featuredBlogPosts(order: "-post_date") {
                ...PreviewHomepageFeaturedBlogPostFragment
            }
            featuredInUsePosts {
                __typename
                ... on FontsInUseDetailPage {
                    blurbImage {
                        file
                        width
                        height
                        placeholderColor
                        hasAlpha
                    }
                    categories {
                        id
                        name
                        slug
                    }
                    fontFamilies {
                        slug
                        name
                        typographicRanking
                        fontFamilyGroup {
                            id
                        }
                    }
                    id
                    title
                    seoTitle
                    slug
                    postDate
                    mediaCount
                }
            }
        }
    }
    fragment PreviewHomepageFeaturedBlogPostFragment on BlogPage {
        blurbImage {
            file
            width
            height
            placeholderColor
            hasAlpha
        }
        categories {
            id
            name
            slug
        }
        blurb
        id
        title
        seoTitle
        slug
        postDate
        fontFamilyGroups {
            id
        }
    }
`;

export const PreviewInUsePostQuery = previewGql`
    query PreviewInUsePostQuery($token: String!) {
        inUsePost(token: $token) {
            slug
            title
            seoTitle
            noindex
            blurbImage {
                file
                placeholderColor
                hasAlpha
            }
            feedImage {
                file
            }
            designerLinks {
                ...PreviewInUseDesignerLink
            }
            body {
                ...PreviewInUseSimpleVideoBlock
                ...PreviewInUseSimpleImageSectionBlock
            }
            fontFamilies {
                id
                name
                slug
                isPreRelease
                typographicRanking
                fontFamilyGroup {
                    id
                    slug
                    colorSchemes {
                        backgroundColor: colorBackground
                        foregroundColor: colorForeground
                        highlightColor: colorHighlight
                        backgroundColorAlt: colorBackgroundAlt
                        foregroundColorAlt: colorForegroundAlt
                        highlightColorAlt: colorHighlightAlt
                    }
                }
            }
            categories {
                name
                slug
                id
            }
        }
    }
    fragment PreviewInUseDesignerLink on DesignerLink {
        __typename
        name
        url
    }
    fragment PreviewInUseSimpleVideoBlock on SimpleVideoBlock {
        __typename
        video
        videoAspectRatio
    }

    fragment PreviewInUseSimpleImageBlock on SimpleImageBlock {
        __typename
        image {
            width
            height
            id
            title
            file
            placeholderColor
            hasAlpha
        }
        svg {
            file
        }
    }
    fragment PreviewInUseSimpleImageSectionBlock on SimpleImageSectionBlock {
        __typename
        singleImageLayout
        showBackground: background
        colorBackground
        noVerticalMargins
        content {
            ...PreviewInUseSimpleVideoBlock
            ...PreviewInUseSimpleImageBlock
        }
    }
`;

export const PreviewBlogPostQuery = previewGql`
    query PreviewBlogPostQuery($token: String!) {
        blogPost(token: $token) {
            id
            noindex
            categories {
                id
                name
                slug
            }
            title
            seoTitle
            searchDescription
            blurb
            slug
            postDate
            intro
            author
            lastPublishedAt
            expireAt
            feedImage {
                file
            }
            blurbImage {
                file
                width
                height
                placeholderColor
                hasAlpha
            }
            body {
                __typename
                ...PreviewBlogContentWithFootNotesBlock
                ...PreviewBlogVideoBlock
                ...PreviewBlogHeadingBlock
                ...PreviewBlogQuoteBlock
                ...PreviewBlogQuestionAndAnswerBlock
                ...PreviewBlogImageSectionBlock
            }
            hero {
                ...PreviewBlogHeroImage
            }
            fontFamilyGroups {
                __typename
                id
                name
                slug
                svgNavigation
                informationIntro
                isCollection
                isNew
                showInCustomFontMenu
                typographicRanking
                colorSchemes {
                    backgroundColor: colorBackground
                    foregroundColor: colorForeground
                    highlightColor: colorHighlight
                    backgroundColorAlt: colorBackgroundAlt
                    foregroundColorAlt: colorForegroundAlt
                    highlightColorAlt: colorHighlightAlt
                }
                designInformationPage {
                    __typename
                    slug
                }
                fontFamilies {
                    __typename
                    id
                    slug
                    name
                    isPreRelease
                    fontStyleCount
                    typographicRanking
                }
            }
            relatedBlogPosts(order: "?") {
                ...PreviewBlogPostStubFragment
            }
        }
    }
    fragment PreviewBlogPostStubFragment on BlogPage {
        noindex
        blurbImage {
            file
            width
            height
        }
        categories {
            id
            name
            slug
        }
        blurb
        id
        title
        seoTitle
        slug
        postDate
        fontFamilyGroups {
            id
        }
    }
    fragment PreviewBlogContentWithFootNotesBlock on ContentWithFootNotesBlock {
        __typename
        textContent: content
        footNotes
    }
    fragment PreviewBlogVideoBlock on VideoBlock {
        __typename
        video
        accessibilityTitle: title
        caption
        videoAspectRatio
        thumbnailUrl
        posterImage {
            file
        }
    }
    fragment PreviewBlogHeadingBlock on HeadingBlock {
        __typename
        title
    }
    fragment PreviewBlogSimpleVideoCaptionBlock on SimpleVideoCaptionBlock {
        __typename
        video
        videoAspectRatio
        caption
    }
    fragment PreviewBlogImageBlock on ImageBlock {
        __typename
        caption
        image {
            width
            height
            id
            title
            file
            placeholderColor
            hasAlpha
        }
        svg {
            file
        }
    }
    fragment PreviewBlogImageSectionBlock on ImageSectionBlock {
        __typename
        heading
        singleImageLayout
        colorText: colorCaption
        showBackground: background
        colorBackground
        noVerticalMargins
        content {
            ...PreviewBlogSimpleVideoCaptionBlock
            ...PreviewBlogImageBlock
        }
    }
    fragment PreviewBlogQuoteBlock on QuoteBlock {
        __typename
        quote
        caption
    }
    fragment PreviewBlogQuestionAndAnswerBlock on QuestionAndAnswerBlock {
        __typename
        question
        answer
    }
    fragment PreviewBlogHeroImage on ImageWithPortraitBlock {
        __typename
        field
        landscapeImage {
            file
            width
            height
        }
        landscapeSvg {
            file
        }
        portraitImage {
            file
            height
            width
        }
        portraitSvg {
            file
        }
    }
`;
