import React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { errorStyles } from '../utils/formStyles';
import { TEST_ID } from '../settings/E2e';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing1);
`;

const ErrorWrapper = styled.div`
    ${errorStyles};
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    input: React.ReactElement;
    error?: FieldError;
}

export default function FieldInputGroup({
    input,
    error,
    ...rest
}: Props): React.ReactElement {
    return (
        <Container {...rest} data-cy={TEST_ID.FIELD_CONTAINER}>
            <div>{input}</div>
            {error && error.message && (
                <ErrorWrapper data-cy={TEST_ID.FIELD_ERROR}>
                    {error.message}
                </ErrorWrapper>
            )}
        </Container>
    );
}
