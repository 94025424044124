import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import AccountCartInventory from './AccountCartInventory';
import AccountOrderHistoryForm from './AccountOrderHistoryForm';
import AccountCartAddress from './AccountCartAddress';
import type { Order } from '../hooks/useUserOrdersQuery';
import { useCountries } from '../hooks/useConfig';
import { Button as InlineUnderlinedButton } from './InlineUnderlinedLink';
import {
    GridContainer,
    GridLeftColumn,
    GridMainColumn,
} from './AccountOrderElements';
import useActiveLicenceTypes from '../hooks/useActiveLicenceTypes';
import { CMS_SSR_LicenceTypeId } from '../gql/api-ssr';
import capitalize from '../utils/capitalize';
import formatDjangoDateString from '../utils/formatDjangoDateString';

const Container = styled(GridContainer)`
    grid-row-gap: var(--spacing1);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing3);
    }

    ${InlineUnderlinedButton} {
        margin: 0 0 var(--spacing1) 0;
    }
`;

const Type = styled(GridLeftColumn)`
    color: var(--foregroundColorMix4);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column-end: span 3;
    }
`;

const Details = styled(GridMainColumn)`
    grid-column-end: span 5;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column-end: -1;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 4 / -1;
    }
`;

const LicenceType = styled.div`
    grid-column: 5 / span 1;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / span 1;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 3;
    }
`;

const LicenceTier = styled.div`
    grid-column: 6 / -1;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 4 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 4 / -1;
    }
`;

const Action = styled.div`
    grid-column: 10 / -1;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        display: none;
    }
`;

const ActionMobile = styled.div`
    display: none;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        display: block;
        grid-column: 3 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 4 / -1;
    }
`;

const UpgradeMessage = styled(GridMainColumn)`
    border: 1px solid var(--borderColor);
    padding: var(--spacing2);
    grid-column-end: span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column-end: -1;
    }
`;

interface AccountUserOrderDetailsProps {
    order: Order;
}

function AccountUserOrderDetails({
    order,
}: AccountUserOrderDetailsProps): React.ReactElement {
    const countries = useCountries();
    const licenceTypes = useActiveLicenceTypes();

    const downloadAction = (
        <>
            {order.cart.hasDownloadables && order.downloadUrl && (
                <>
                    <InlineUnderlinedButton
                        as="a"
                        download
                        href={order.downloadUrl}
                    >
                        Download fonts
                    </InlineUnderlinedButton>
                    {order.invoiceUrl && <br />}
                </>
            )}
            {order.invoiceUrl && (
                <InlineUnderlinedButton as="a" download href={order.invoiceUrl}>
                    Download invoice
                </InlineUnderlinedButton>
            )}
        </>
    );
    return (
        <Container>
            <Type>Date</Type>
            <Details>{formatDjangoDateString(order.date)}</Details>
            <Action>{downloadAction}</Action>

            <Type>Purchased for</Type>
            <Details>
                {order.cart.licensedForSomeoneElse ? (
                    <AccountCartAddress
                        companyName={order.cart.licensedToCompanyName}
                        address1={order.cart.licensedToAddress1}
                        address2={order.cart.licensedToAddress2}
                        city={order.cart.licensedToCity}
                        postcode={order.cart.licensedToPostcode}
                        state={order.cart.licensedToState}
                        countryCode={order.cart.licensedToCountry}
                        vatNumber={order.cart.licensedToVatNumber}
                        countries={countries}
                        legacyAddress={order.cart.legacyLicensedTo}
                    />
                ) : (
                    'Myself'
                )}
            </Details>
            <ActionMobile>{downloadAction}</ActionMobile>

            {order.cart.hasSimpleLicensing &&
            order.cart.licenceTiers.length > 0 ? (
                <>
                    <Type>Licence formats</Type>
                    {order.cart.licenceTiers
                        // Sort according to active licence types
                        .slice()
                        .sort((a, b) => {
                            const aIndex = licenceTypes.findIndex(
                                (lt) => lt.id === a.tier.licenceType.id,
                            );
                            const bIndex = licenceTypes.findIndex(
                                (lt) => lt.id === b.tier.licenceType.id,
                            );
                            // Can't be found? Sort to bottom...
                            if (aIndex === -1) {
                                return 1;
                            }
                            if (bIndex === -1) {
                                return -1;
                            }
                            return aIndex - bIndex;
                        })
                        .map((cartTier) => {
                            return (
                                <React.Fragment
                                    key={`licf-${cartTier.tier.id}`}
                                >
                                    <LicenceType>
                                        {cartTier.tier.licenceType.name}
                                    </LicenceType>
                                    <LicenceTier>
                                        {cartTier.tier.licenceType.id ===
                                        CMS_SSR_LicenceTypeId.UDLA
                                            ? null
                                            : cartTier.tier.title}
                                        {cartTier.licenceSubject ? (
                                            <>
                                                <br />
                                                {capitalize(
                                                    cartTier.tier.licenceType
                                                        .licenceSubjectLabel ||
                                                        'For',
                                                )}
                                                : {cartTier.licenceSubject}
                                            </>
                                        ) : null}
                                    </LicenceTier>
                                    {cartTier.tier.hasContractDetailsField ? (
                                        <>
                                            <LicenceType>
                                                {
                                                    cartTier.tier.licenceType
                                                        .contractDetailsLabel
                                                }
                                            </LicenceType>
                                            <LicenceTier>
                                                {cartTier.contractDetails ||
                                                    'Signature pending.'}
                                            </LicenceTier>
                                        </>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                </>
            ) : null}

            <AccountCartInventory cart={order.cart} />

            {order.activeUpgradeRequest ? (
                <UpgradeMessage>
                    Your upgrade request has been successfully sent. We will be
                    in touch within the next few days.
                </UpgradeMessage>
            ) : (
                <AccountOrderHistoryForm order={order} />
            )}
        </Container>
    );
}

export default React.memo(AccountUserOrderDetails);
