import React from 'react';
import styled from 'styled-components';
import Link from './Lozenge';
import { HeadingMedium } from './Heading';
import FontsInUse, { MAX_IN_USE_POSTS_PER_ROW } from './FontsInUse';
import type { InUsePostFontFamily } from '../union-types/inUsePost';
import notNull from '../utils/notNull';
import { createFontsInUseUrl } from '../utils/urlHelper';
import useInUsePostStubs from '../hooks/useInUsePostStubs';
import usePreviewOrPageQueryInUsePost from '../hooks/usePreviewOrPageQueryInUsePost';
import { MARGIN_SMALL } from '../settings/Global';
import printAmpersandSeries from '../utils/printAmpersandSeries';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import { useStaticFontMetricOffsets } from './PageContext';
import Icon, { IconType } from './Icon';

const Container = styled.div`
    background-color: var(--backgroundColor);
    color: var(--foregroundColor);
`;

const Inner = styled.div`
    padding: 50vh var(--gridMarginGap);
`;

const Heading = styled(HeadingMedium)`
    padding-bottom: ${MARGIN_SMALL}px;
`;

const Content = styled.div``;

const NUM_ROWS = 3;

function InUsePostRelated(): React.ReactElement | null {
    const inUsePost = usePreviewOrPageQueryInUsePost();
    const staticFontMetrics = useStaticFontMetricOffsets();
    const fontFamiliesInUse = React.useMemo(
        () =>
            inUsePost.fontFamilies
                ? (inUsePost.fontFamilies as InUsePostFontFamily[]).filter(
                      notNull,
                  )
                : [],
        [inUsePost.fontFamilies],
    );
    const fontsInUsePages = useInUsePostStubs(
        {
            fontFamilySlugs: fontFamiliesInUse.map((ff) => ff.slug),
            excludeInUseSlug: inUsePost.slug,
        },
        NUM_ROWS * MAX_IN_USE_POSTS_PER_ROW,
    );

    const fontFamilyLinks = printAmpersandSeries(
        fontFamiliesInUse
            .sort(sortFontsByTypographicRanking)
            .map((fontFamily): string => fontFamily.name),
    );

    return fontsInUsePages.length > 0 ? (
        <Container>
            <Inner>
                <Heading $metricOffsets={staticFontMetrics} as="h3">
                    More {fontFamilyLinks} in use
                </Heading>
                <Content>
                    <FontsInUse
                        renderStyle="grayscale"
                        inUsePosts={fontsInUsePages}
                        rows={NUM_ROWS}
                        forceShowMoreLink
                        moreLink={
                            <Link to={createFontsInUseUrl()}>
                                See more fonts in use&nbsp;
                                <Icon type={IconType.ARROW_RIGHT} />
                            </Link>
                        }
                    />
                </Content>
            </Inner>
        </Container>
    ) : null;
}

export default React.memo(InUsePostRelated);
