import { graphql, useStaticQuery } from 'gatsby';
import type { AwardsQuery } from '../gql/api-ssr';

export type Award = AwardsQuery['ssr']['awards'][number];

export default function useAwards(): Award[] {
    return useStaticQuery<AwardsQuery>(graphql`
        query AwardsQuery {
            ssr {
                awards {
                    id
                    name
                    year
                    receivedFor
                    sortOrder
                    essentialContribution
                    fontFamilies {
                        isPreRelease
                        name
                        slug
                        typographicRanking
                    }
                    fontFamilyGroups {
                        isCollection
                        name
                        slug
                        typographicRanking
                        fontFamilies {
                            isPreRelease
                            name
                            slug
                            typographicRanking
                        }
                    }
                    customFonts {
                        isPreRelease
                        name
                        slug
                    }
                }
            }
        }
    `).ssr.awards;
}
