import type { RawDraftContentState } from 'draft-js';

const hashString = (str: string): string => {
    let hash = 0,
        i,
        chr;
    if (str.length === 0) return hash.toString();
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
};

/**
 * Creates a unique key for a specimen, based on its raw Draft.js content state,
 * plus optional extra string.
 * @param state
 * @param extra
 */
export default function createSpecimenKey(
    state: RawDraftContentState,
    extra?: string,
): string {
    return `specimenKey-${hashString(
        state.blocks
            .map((b) => {
                return [
                    extra,
                    b.key,
                    b.text,
                    b.type,
                    b.depth,
                    b.inlineStyleRanges
                        .map((i) => [i.length, i.style, i.offset].join('/'))
                        .join('|'),
                ].join('-');
            })
            .join(),
    )}`;
}
