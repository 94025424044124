import { FontFeatureSetting } from './FontFeatureSetting';

export interface Token {
    name: string;
    features: FontFeatureSetting[];
    label: string;
}

export const STYLISTIC_SET_LABEL_PREFIX = 'Stylistic set';
export const NSTY_NAME_PREFIX = 'KLIM_nsty_';

// All names should be prefixed with 'KLIM' as a clash-prevention measure.
const tokens = {
    /*
     * These `defaults` OpenType features are hard-coded for all Type Editors.
     * If they are altered they should also be altered in back end code
     * as this will affect word width calculations and other things.
     *
     * See: backend/fonts/opentype.py
     *
     * For background on why we hard-code some of these see:
     * https://twitter.com/klimtypefoundry/status/1142156839825498112
     */
    defaults: {
        name: 'KLIM_defaults',
        features: [
            FontFeatureSetting.kern,
            FontFeatureSetting.liga,
            FontFeatureSetting.clig,
            FontFeatureSetting.calt,
        ],
        label: '',
    },
    /*
    All non-default, non-hardcoded ones, below
     */
    smcp: {
        name: 'KLIM_smcp',
        features: [FontFeatureSetting.smcp],
        label: 'Small caps',
    },
    c2sc: {
        name: 'KLIM_c2sc',
        features: [FontFeatureSetting.c2sc],
        label: 'All small caps',
    },
    dlig: {
        name: 'KLIM_dlig',
        features: [FontFeatureSetting.dlig],
        label: 'Discretionary ligatures',
    },
    swsh: {
        name: 'KLIM_swsh',
        features: [FontFeatureSetting.swsh],
        label: 'Swashes',
    },
    numstyDefault: {
        name: `${NSTY_NAME_PREFIX}def`,
        features: [],
        label: 'Default numerals',
    },
    numstyLining: {
        name: `${NSTY_NAME_PREFIX}lin`,
        features: [FontFeatureSetting.pnum, FontFeatureSetting.lnum],
        label: 'Lining numerals',
    },
    numstyOldstyle: {
        name: `${NSTY_NAME_PREFIX}old`,
        features: [FontFeatureSetting.pnum, FontFeatureSetting.onum],
        label: 'Old-style numerals',
    },
    numstyTabularLining: {
        name: `${NSTY_NAME_PREFIX}tab+lin`,
        features: [FontFeatureSetting.tnum, FontFeatureSetting.lnum],
        label: 'Tabular lining numerals',
    },
    numstyTabularOldstyle: {
        name: `${NSTY_NAME_PREFIX}tab+old`,
        features: [FontFeatureSetting.tnum, FontFeatureSetting.onum],
        label: 'Tabular old-style numerals',
    },
    zero: {
        name: 'KLIM_zero',
        features: [FontFeatureSetting.zero],
        label: 'Slashed zero',
    },
    frac: {
        name: 'KLIM_frac',
        features: [FontFeatureSetting.frac],
        label: 'Fractions',
    },
    sups: {
        name: 'KLIM_sups',
        features: [FontFeatureSetting.sups],
        label: 'Superiors',
    },
    sinf: {
        name: 'KLIM_sinf',
        features: [FontFeatureSetting.sinf],
        label: 'Inferiors',
    },
    ordn: {
        name: 'KLIM_ordn',
        features: [FontFeatureSetting.ordn],
        label: 'Ordinals',
    },
    case: {
        name: 'KLIM_case',
        features: [FontFeatureSetting.case],
        label: 'Capital forms',
    },
    ss01: {
        name: 'KLIM_ss01',
        features: [FontFeatureSetting.ss01],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 01`,
    },
    ss02: {
        name: 'KLIM_ss02',
        features: [FontFeatureSetting.ss02],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 02`,
    },
    ss03: {
        name: 'KLIM_ss03',
        features: [FontFeatureSetting.ss03],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 03`,
    },
    ss04: {
        name: 'KLIM_ss04',
        features: [FontFeatureSetting.ss04],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 04`,
    },
    ss05: {
        name: 'KLIM_ss05',
        features: [FontFeatureSetting.ss05],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 05`,
    },
    ss06: {
        name: 'KLIM_ss06',
        features: [FontFeatureSetting.ss06],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 06`,
    },
    ss07: {
        name: 'KLIM_ss07',
        features: [FontFeatureSetting.ss07],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 07`,
    },
    ss08: {
        name: 'KLIM_ss08',
        features: [FontFeatureSetting.ss08],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 08`,
    },
    ss09: {
        name: 'KLIM_ss09',
        features: [FontFeatureSetting.ss09],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 09`,
    },
    ss10: {
        name: 'KLIM_ss10',
        features: [FontFeatureSetting.ss10],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 10`,
    },
    ss11: {
        name: 'KLIM_ss11',
        features: [FontFeatureSetting.ss11],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 11`,
    },
    ss12: {
        name: 'KLIM_ss12',
        features: [FontFeatureSetting.ss12],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 12`,
    },
    ss13: {
        name: 'KLIM_ss13',
        features: [FontFeatureSetting.ss13],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 13`,
    },
    ss14: {
        name: 'KLIM_ss14',
        features: [FontFeatureSetting.ss14],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 14`,
    },
    ss15: {
        name: 'KLIM_ss15',
        features: [FontFeatureSetting.ss15],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 15`,
    },
    ss16: {
        name: 'KLIM_ss16',
        features: [FontFeatureSetting.ss16],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 16`,
    },
    ss17: {
        name: 'KLIM_ss17',
        features: [FontFeatureSetting.ss17],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 17`,
    },
    ss18: {
        name: 'KLIM_ss18',
        features: [FontFeatureSetting.ss18],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 18`,
    },
    ss19: {
        name: 'KLIM_ss19',
        features: [FontFeatureSetting.ss19],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 19`,
    },
    ss20: {
        name: 'KLIM_ss20',
        features: [FontFeatureSetting.ss20],
        label: `${STYLISTIC_SET_LABEL_PREFIX} 20`,
    },
};

export default tokens;
