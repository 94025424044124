import React from 'react';
import { v4 as uuidV4 } from 'uuid';

export default function useUuid(): string {
    /*
     * Using `useState` (instead of `useMemo`) because the docs state the
     * following:
     *
     * "React guarantees that setState function identity is stable and won’t
     * change on re-renders."
     *
     * and:
     *
     * "You may rely on useMemo as a performance optimization, not as a
     * semantic guarantee."
     */
    const [uuid] = React.useState<string>(uuidV4);

    return uuid;
}
