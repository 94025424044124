import React from 'react';
import type { HomepageCampaign as HomepageCampaignType } from '../union-types/homepage';
import HomepageCampaign from './HomepageCampaign';
import notNull from '../utils/notNull';
import notDuplicate from '../utils/notDuplicate';
import useIsUserTestingSite from '../hooks/useIsUserTestingSite';
import useConfig from '../hooks/useConfig';
import isWhitelisted from '../utils/isWhitelisted';
import usePreviewOrPageQueryHomepage from '../hooks/usePreviewOrPageQueryHomepage';

function HomepageCampaigns(): React.ReactElement {
    const homepage = usePreviewOrPageQueryHomepage();
    const isUserTestingSite = useIsUserTestingSite();
    const config = useConfig();
    const homepageCampaigns = React.useMemo(
        (): HomepageCampaignType[] =>
            homepage.campaigns
                ? (homepage.campaigns as HomepageCampaignType[])
                      .filter(notNull)
                      // Limit the campaigns for the user testing site,
                      // so that we're not exposing any pre-release fonts.
                      .filter((campaign) => {
                          if (!isUserTestingSite) {
                              return true;
                          }
                          // Not all campaigns are linked to a fontFamilyGroup
                          if (!campaign.fontFamilyGroup) {
                              return true;
                          }
                          return isWhitelisted(
                              config.userTestingFontFamilyGroupWhitelist,
                              campaign.fontFamilyGroup.id,
                          );
                      })
                : [],
        [homepage, config, isUserTestingSite],
    );

    const videoDomains = React.useMemo(
        (): string[] | undefined =>
            homepageCampaigns
                .reduce(
                    (carry: (string | null)[], campaign): (string | null)[] => {
                        if (
                            campaign?.campaign.videos.length &&
                            campaign?.campaign.videos[0].videoLandscape.files
                                .length
                        ) {
                            return [
                                new URL(
                                    campaign?.campaign.videos[0].videoLandscape.files[0].src,
                                ).origin,
                                ...carry,
                            ];
                        }
                        return carry;
                    },
                    [],
                )
                .filter(notNull)
                .filter(notDuplicate),
        [homepageCampaigns],
    );

    return (
        <div>
            {/*
             * A micro-optimization to get videos (particularly the marquee
             * campaign video) loading ASAP by bundling this `dns-prefetch`
             * and `preconnect` metadata into the static HTML of the homepage.
             */}
            {videoDomains &&
                videoDomains.map(
                    (videoDomain): React.ReactElement => (
                        <React.Fragment key={videoDomain}>
                            <link
                                rel="preconnect"
                                href={videoDomain}
                                crossOrigin="anonymous"
                            />
                            <link rel="dns-prefetch" href={videoDomain} />
                        </React.Fragment>
                    ),
                )}
            {homepageCampaigns.map(
                (homepageCampaign, index): React.ReactElement => (
                    <HomepageCampaign
                        key={`hmc-${index}`}
                        homepageCampaign={homepageCampaign}
                        index={index}
                    />
                ),
            )}
        </div>
    );
}

export default React.memo(HomepageCampaigns);
