import React from 'react';
import styled from 'styled-components';
import type { KlimPageProps } from '../layouts';
import printNumber from '../utils/printNumber';
import isWhitelisted from '../utils/isWhitelisted';
import useConfig from '../hooks/useConfig';
import useIsUserTestingSite from '../hooks/useIsUserTestingSite';
import type { BlogPostStub } from '../union-types/blogPostStub';
import { usePreviewOrPageQueryBlogPostWordCount } from '../hooks/usePreviewOrPageQueryBlogPost';
import { type HeadingProps, HeadingSuperLarge } from '../components/Heading';
import BlogCards from '../components/BlogCards';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageDefault);
    padding-top: 50vh;
    margin: 0 var(--gridMarginGap);
    display: grid;
    grid-template-columns: 100%;
    min-height: 90vh;
`;

const Hero = styled(HeadingSuperLarge)<HeadingProps>`
    padding-bottom: 5px;
`;

function BlogIndexPage({
    pageContext: { blogPostStubs },
}: KlimPageProps): React.ReactElement {
    const config = useConfig();
    const isUserTestingSite = useIsUserTestingSite();
    const staticFontMetrics = useStaticFontMetricOffsets();
    // This count will be incorrect on the user testing site,
    // but we don't care about that for now
    const wordCount = usePreviewOrPageQueryBlogPostWordCount();

    // Limit the blog posts for the user testing site,
    // so that we're not exposing any pre-release fonts.
    const stubsToShow = React.useMemo((): BlogPostStub[] => {
        return (
            blogPostStubs?.filter((blogPostStub) => {
                if (!isUserTestingSite) {
                    return true;
                }
                return !blogPostStub.fontFamilyGroups?.some(
                    (fontFamilyGroup) => {
                        return !isWhitelisted(
                            config.userTestingFontFamilyGroupWhitelist,
                            fontFamilyGroup.id,
                        );
                    },
                );
            }) || []
        );
    }, [
        blogPostStubs,
        isUserTestingSite,
        config.userTestingFontFamilyGroupWhitelist,
    ]);

    return (
        <Container>
            <Hero $metricOffsets={staticFontMetrics}>
                {printNumber(wordCount)} words about letters.
            </Hero>
            <BlogCards blogPosts={stubsToShow} />
        </Container>
    );
}

export default React.memo(BlogIndexPage);
