import type { GatsbyBrowser } from 'gatsby';
import isBrowserFirefox from './src/utils/isBrowserFirefox';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
    routerProps: { location },
    prevRouterProps,
    getSavedScrollPosition,
}) => {
    if (!prevRouterProps || location.hash || !isBrowserFirefox()) {
        return true;
    }

    /*
    BUG WORKAROUND
    There is an issue with Firefox not always scrolling to the top of the page when navigating...
     */
    const currentPosition = getSavedScrollPosition(location);
    setTimeout(() => {
        window.scrollTo({
            top: currentPosition[0] || 0,
            left: currentPosition[1] || 0,
        });
    }, 0);
    return false;
};
