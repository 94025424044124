import React from 'react';
import styled, { css } from 'styled-components';
import { I as Icon, IconType } from './Icon';
import { fakeNativeFocusOutlines } from '../utils/stylesMixins';

export enum FieldType {
    RADIO,
    CHECKBOX,
}

enum CheckedState {
    UNCHECKED,
    CHECKED,
    INDETERMINATE,
}

const Container = styled.div`
    position: relative;
    display: inline-block;
`;

const Input = styled.input<{
    $fieldType: FieldType;
    $checkedState: CheckedState;
}>`
    color: var(--foregroundColor);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; /* Still want this to be focusable and clickable, just not visible to the human eye. */

    &[disabled] {
        color: var(--foregroundColorMix7);
        cursor: not-allowed;
    }

    /* stylelint-disable string-no-newline */
    &:checked + ${Icon} {
        &::before {
            content: ${({ $fieldType }): string =>
                `'${
                    $fieldType === FieldType.RADIO
                        ? IconType.RADIO_CHECKED
                        : IconType.CHECKBOX_CHECKED
                }'`};
        }
    }
    /* stylelint-enable string-no-newline */

    /*
    Updating the CSS by looking at :indeterminate didn't work properly with radio
    buttons, so instead we're using a property, $isIndeterminate.
     */
    + ${Icon} {
        &::before {
            ${({ $checkedState, $fieldType }): ReturnType<typeof css> =>
                $checkedState === CheckedState.INDETERMINATE
                    ? $fieldType === FieldType.RADIO
                        ? css`
                              content: '${IconType.RADIO_INDETERMINATE}';
                          `
                        : css`
                              content: '${IconType.CHECKBOX_INDETERMINATE}';
                          `
                    : $checkedState === CheckedState.CHECKED
                      ? $fieldType === FieldType.RADIO
                          ? css`
                                content: '${IconType.RADIO_CHECKED}';
                            `
                          : css`
                                content: '${IconType.CHECKBOX_CHECKED}';
                            `
                      : $fieldType === FieldType.RADIO
                        ? css`
                              content: '${IconType.RADIO_UNCHECKED}';
                          `
                        : css`
                              content: '${IconType.CHECKBOX_UNCHECKED}';
                          `};
        }
    }

    [data-whatinput='keyboard'] &:focus + ${Icon} {
        ${fakeNativeFocusOutlines};
    }
`;

interface RadioCheckboxProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    fieldType: FieldType;
    indeterminate?: boolean;
}

/**
 * A radio or checkbox field are very similar, so they use the same code.
 */
export const RadioCheckbox = React.forwardRef(
    (
        {
            fieldType,
            indeterminate,
            checked,
            ...attributes
        }: RadioCheckboxProps,
        inRef: React.Ref<HTMLInputElement>,
    ): React.ReactElement => {
        const ref = React.useRef<HTMLInputElement | null>(null);

        // TODO: Maybe this can be written without a non-null assertion?

        React.useImperativeHandle(inRef, () => ref.current!, [ref]);

        // Set indeterminate state on actual input, mainly for screen readers...
        React.useEffect(() => {
            if (!ref.current) {
                return;
            }
            ref.current.indeterminate = indeterminate === true;
        }, [indeterminate, ref]);

        return (
            <Container>
                <Input
                    {...attributes}
                    type={fieldType === FieldType.RADIO ? 'radio' : 'checkbox'}
                    ref={ref}
                    $fieldType={fieldType}
                    checked={checked && !indeterminate}
                    $checkedState={
                        checked
                            ? CheckedState.CHECKED
                            : indeterminate
                              ? CheckedState.INDETERMINATE
                              : CheckedState.UNCHECKED
                    }
                />
                <Icon />
            </Container>
        );
    },
);
RadioCheckbox.displayName = 'RadioCheckbox';

export const Radio = React.forwardRef(
    (
        attributes: React.InputHTMLAttributes<HTMLInputElement>,
        ref: React.Ref<HTMLInputElement>,
    ): React.ReactElement => {
        return (
            <RadioCheckbox
                {...attributes}
                fieldType={FieldType.RADIO}
                ref={ref}
            />
        );
    },
);
Radio.displayName = 'Radio';

export const Checkbox = React.forwardRef(
    (
        attributes: React.InputHTMLAttributes<HTMLInputElement>,
        ref: React.Ref<HTMLInputElement>,
    ): React.ReactElement => {
        return (
            <RadioCheckbox
                {...attributes}
                fieldType={FieldType.CHECKBOX}
                ref={ref}
            />
        );
    },
);
Checkbox.displayName = 'Checkbox';
