import React from 'react';
import styled from 'styled-components';
import Icon, { IconType, SocialIcon, SocialIconType } from '../components/Icon';
import { A as InlineUnderlinedLink } from '../components/InlineUnderlinedLink';
import {
    HeadingSuperLarge,
    HeadingExtraLarge,
    HeadingLarge,
    HeadingMedium,
} from '../components/Heading';
import { Accordion, AccordionItem } from '../components/Accordion';
import {
    Paragraph,
    ParagraphLarge,
    ParagraphSmall,
    ParagraphExtraLarge,
} from '../components/Paragraph';
import { TEST_ID } from '../settings/E2e';

export { Head } from '../components/Head';

const Subtitle = styled.h6`
    font-size: var(--fontSizeFixed3);
    line-height: var(--lineHeightBody1);
    color: var(--foregroundColorMix4);
    margin-bottom: var(--spacing2);
`;

const Container = styled.div`
    padding: var(--paddingPageMedium);
`;

const Inner = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing9);
`;

const Row = styled.div<{
    $spacing: string;
}>`
    display: grid;
    margin: 0 var(--gridMarginGap);
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(${({ $spacing }): string => $spacing}) var(--gridColumnGap);
`;

export const Column = styled.div<{ $span: number }>`
    grid-column: span ${({ $span }): string => String($span)};
`;

const Spacer = styled.div<{
    $spacing: string;
}>`
    height: var(${({ $spacing }): string => $spacing});
    background-color: var(--foregroundColor);
    color: var(--foregroundColor);
`;

const IconRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: var(--gridColumnGap) var(--gridColumnGap);
    color: var(--foregroundColor);
    font-size: var(--fontSizeFixed5);
`;

function IconExample(): React.ReactElement {
    const columns: React.ReactNode[] = [];
    for (const key in IconType) {
        columns.push(
            <span key={key} title={key}>
                <Icon type={IconType[key as keyof typeof IconType]} />
            </span>,
        );
    }
    for (const key in SocialIconType) {
        columns.push(
            <span key={key} title={key}>
                <SocialIcon
                    type={SocialIconType[key as keyof typeof SocialIconType]}
                />
            </span>,
        );
    }

    return <IconRow data-cy={TEST_ID.STYLE_GUIDE_ICONS}>{columns}</IconRow>;
}

function HeadingExample({
    level,
}: {
    level: number;
}): React.ReactElement | null {
    switch (level) {
        case 1:
            return (
                <>
                    <Subtitle>Heading 1</Subtitle>
                    <HeadingSuperLarge>Super Large</HeadingSuperLarge>
                </>
            );
        case 2:
            return (
                <>
                    <Subtitle>Heading 2</Subtitle>
                    <HeadingExtraLarge>Extra Large</HeadingExtraLarge>
                </>
            );
        case 3:
            return (
                <>
                    <Subtitle>Heading 3</Subtitle>
                    <HeadingLarge>Large</HeadingLarge>
                </>
            );
        case 4:
            return (
                <>
                    <Subtitle>Heading 4</Subtitle>
                    <HeadingMedium>Medium</HeadingMedium>
                </>
            );
        default:
            return null;
    }
}

function ParagraphExample({
    level,
}: {
    level: number;
}): React.ReactElement | null {
    switch (level) {
        case 0:
            return (
                <>
                    <Subtitle>Body - Extra Large</Subtitle>
                    <ParagraphExtraLarge>
                        Quis excepteur enim proident est sint dolor id amet
                        eiusmod minim deserunt excepteur veniam culpa nulla
                        occaecat amet excepteur aliqua do eu qui irure ut
                        pariatur.
                    </ParagraphExtraLarge>
                </>
            );
        case 1:
            return (
                <>
                    <Subtitle>Body - Large</Subtitle>
                    <ParagraphLarge>
                        Quis excepteur enim proident est sint dolor id amet
                        eiusmod minim deserunt excepteur veniam culpa nulla
                        occaecat amet excepteur aliqua do eu qui irure ut
                        pariatur.
                    </ParagraphLarge>
                </>
            );
        case 2:
            return (
                <>
                    <Subtitle>Body</Subtitle>
                    <Paragraph>
                        Pariatur deserunt ad do ut nostrud occaecat eu cupidatat
                        non proident enim ut incididunt fugiat occaecat dolore
                        minim ut do commodo anim reprehenderit dolor eu mollit
                        id non.
                    </Paragraph>
                </>
            );
        case 3:
            return (
                <>
                    <Subtitle>{`Caption Styles & Detail UI`}</Subtitle>
                    <ParagraphSmall>
                        Lorem ipsum ex proident incididunt qui qui sunt irure
                        exercitation dolor sit cillum nulla.
                    </ParagraphSmall>
                </>
            );
        case 4:
            return (
                <>
                    <Subtitle>Inline hover link</Subtitle>
                    <Paragraph>
                        Lorem ipsum ex proident incididunt{' '}
                        <InlineUnderlinedLink href="/">
                            qui qui sunt irure
                        </InlineUnderlinedLink>{' '}
                        exercitation dolor sit cillum nulla.
                    </Paragraph>
                </>
            );

        default:
            return null;
    }
}

function PaddingExample({
    level,
}: {
    level: number;
}): React.ReactElement | null {
    switch (level) {
        case 1: {
            return (
                <>
                    <Subtitle>Padding 1</Subtitle>
                    <Spacer $spacing="--spacing1" />
                </>
            );
        }
        case 2: {
            return (
                <>
                    <Subtitle>Padding 2</Subtitle>
                    <Spacer $spacing="--spacing2" />
                </>
            );
        }
        case 3: {
            return (
                <>
                    <Subtitle>Padding 3</Subtitle>
                    <Spacer $spacing="--spacing3" />
                </>
            );
        }
        case 4: {
            return (
                <>
                    <Subtitle>Padding 4</Subtitle>
                    <Spacer $spacing="--spacing4" />
                </>
            );
        }
        case 5: {
            return (
                <>
                    <Subtitle>Padding 5</Subtitle>
                    <Spacer $spacing="--spacing5" />
                </>
            );
        }
        case 6: {
            return (
                <>
                    <Subtitle>Padding 6</Subtitle>
                    <Spacer $spacing="--spacing6" />
                </>
            );
        }
        default:
            return null;
    }
}

function SectionExample({
    level,
}: {
    level: number;
}): React.ReactElement | null {
    switch (level) {
        case 7: {
            return (
                <>
                    <Subtitle>Section 7</Subtitle>
                    <Spacer $spacing="--spacing7" />
                </>
            );
        }
        case 8: {
            return (
                <>
                    <Subtitle>Section 8</Subtitle>
                    <Spacer $spacing="--spacing8" />
                </>
            );
        }
        case 9: {
            return (
                <>
                    <Subtitle>Section 9</Subtitle>
                    <Spacer $spacing="--spacing9" />
                </>
            );
        }
        case 10: {
            return (
                <>
                    <Subtitle>Section 10</Subtitle>
                    <Spacer $spacing="--spacing10" />
                </>
            );
        }
        case 11: {
            return (
                <>
                    <Subtitle>Section 11</Subtitle>
                    <Spacer $spacing="--spacing11" />
                </>
            );
        }
        default:
            return null;
    }
}

function AccordionExample({
    heading,
}: {
    heading: string;
}): React.ReactElement {
    return (
        <AccordionItem
            heading={heading}
            headingLevel={3}
            panel={
                <Paragraph>
                    Commodo incididunt nisi proident non esse voluptate dolore
                    aliquip ad sit ut qui quis ut ut quis nostrud culpa dolore
                    dolor eiusmod ut consequat quis ut sunt officia exercitation
                    dolore ad labore anim labore anim id.
                </Paragraph>
            }
        />
    );
}

function DemoRow({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement {
    return (
        <>
            <Column $span={2} />
            <Column $span={5}>{children}</Column>
            <Column $span={5} />
        </>
    );
}

const ColorRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
`;

const ColorCell = styled.div<{ $color: string }>`
    position: relative;
    background-color: var(${({ $color }): string => $color});
    padding-top: 100%;
`;

export default function StyleGuidePage(): React.ReactElement {
    return (
        <Container>
            <Inner>
                <Row $spacing="--spacing7">
                    <DemoRow>
                        <HeadingExtraLarge>Headings</HeadingExtraLarge>
                    </DemoRow>
                    {[1, 2, 3, 4].map(
                        (level: number): React.ReactElement => (
                            <DemoRow key={level}>
                                <HeadingExample level={level} />
                            </DemoRow>
                        ),
                    )}
                </Row>

                <Row $spacing="--spacing7">
                    <DemoRow>
                        <HeadingExtraLarge>Paragraphs</HeadingExtraLarge>
                    </DemoRow>
                    {[0, 1, 2, 3, 4].map(
                        (level: number): React.ReactElement => (
                            <DemoRow key={level}>
                                <ParagraphExample level={level} />
                            </DemoRow>
                        ),
                    )}
                </Row>

                <Row $spacing="--spacing5">
                    <DemoRow>
                        <HeadingExtraLarge>Spacing - Padding</HeadingExtraLarge>
                    </DemoRow>
                    {[1, 2, 3, 4, 5, 6].map(
                        (level: number): React.ReactElement => (
                            <DemoRow key={level}>
                                <PaddingExample level={level} />
                            </DemoRow>
                        ),
                    )}
                </Row>

                <Row $spacing="--spacing5">
                    <DemoRow>
                        <HeadingExtraLarge>
                            Spacing - Sections
                        </HeadingExtraLarge>
                    </DemoRow>
                    {[7, 8, 9, 10, 11].map(
                        (level: number): React.ReactElement => (
                            <DemoRow key={level}>
                                <SectionExample level={level} />
                            </DemoRow>
                        ),
                    )}
                </Row>

                <Row $spacing="--spacing5">
                    <DemoRow>
                        <HeadingExtraLarge>Color Palette</HeadingExtraLarge>
                    </DemoRow>
                    <DemoRow>
                        <ColorRow>
                            {[
                                '--foregroundColor',
                                '--foregroundColorMix1',
                                '--foregroundColorMix2',
                                '--foregroundColorMix3',
                                '--foregroundColorMix4',
                                '--foregroundColorMix5',
                                '--foregroundColorMix6',
                                '--foregroundColorMix7',
                                '--foregroundColorMix8',
                                '--backgroundColor',
                            ].map(
                                (color): React.ReactElement => (
                                    <ColorCell key={color} $color={color} />
                                ),
                            )}
                        </ColorRow>
                    </DemoRow>
                    <DemoRow>
                        <ColorRow>
                            {['--highlightColor'].map(
                                (color): React.ReactElement => (
                                    <ColorCell key={color} $color={color} />
                                ),
                            )}
                        </ColorRow>
                    </DemoRow>
                </Row>

                <Row $spacing="--spacing5">
                    <DemoRow>
                        <HeadingExtraLarge>Accordion</HeadingExtraLarge>
                    </DemoRow>
                    <DemoRow>
                        <Accordion>
                            {[
                                'Credits & details',
                                'Supported languages',
                                'Recommended minimum size & web font file sizes',
                                'Download test fonts',
                            ].map(
                                (heading: string): React.ReactElement => (
                                    <AccordionExample
                                        key={heading}
                                        heading={heading}
                                    />
                                ),
                            )}
                        </Accordion>
                    </DemoRow>
                </Row>

                <Row $spacing="--spacing5">
                    <DemoRow>
                        <HeadingExtraLarge>Icons</HeadingExtraLarge>
                    </DemoRow>
                    <DemoRow>
                        <IconExample />
                    </DemoRow>
                </Row>
            </Inner>
        </Container>
    );
}
