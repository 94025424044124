import React from 'react';
import styled from 'styled-components';
import { useCustomFont } from '../components/PageContext';
import CustomFontsPostInformation from '../components/CustomFontsPostInformation';
import FontSectionTitled from '../components/FontSectionTitled';
import CustomFontsPostStyles from '../components/CustomFontsPostStyles';
import CustomFontsPostSpecimens from '../components/CustomFontsPostSpecimens';
import CustomFontsPostImages from '../components/CustomFontsPostImages';
import { VIEWPORT } from '../settings/Global';
import printStylesCount from '../utils/printStylesCount';
import FontHeroWithWrapper from '../components/FontHeroWithWrapper';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageDefault);
    padding-top: 0;
    margin: 0 var(--gridMarginGap);

    [data-section='styles'],
    [data-section='specimens'],
    [data-section='images'],
    [data-section='information'] {
        padding-top: var(--spacing7);
        margin-top: var(--spacing9);

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            margin-top: var(--spacing7);
        }
    }
`;

function CustomFontsPostPage(): React.ReactElement {
    const { styles, images, svgHeading, bitmapHeading, name } = useCustomFont();

    return (
        <>
            <FontHeroWithWrapper
                svgHeading={svgHeading}
                svgTitle={name}
                bitmapHeading={bitmapHeading}
            />
            <Container>
                {styles.length > 1 && (
                    <FontSectionTitled
                        data-section="styles"
                        title={printStylesCount(styles.length) || 'Styles'}
                        $applyBaselineAlignment
                    >
                        <CustomFontsPostStyles />
                    </FontSectionTitled>
                )}
                <div data-section="specimens">
                    <CustomFontsPostSpecimens />
                </div>
                {images.length > 0 && (
                    <div data-section="images">
                        <CustomFontsPostImages />
                    </div>
                )}
                <FontSectionTitled
                    data-section="information"
                    title="Information"
                    $applyBaselineAlignment
                >
                    <CustomFontsPostInformation />
                </FontSectionTitled>
            </Container>
        </>
    );
}

export default React.memo(CustomFontsPostPage);
