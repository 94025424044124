import React from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';
import hideVisually from 'polished/lib/mixins/hideVisually';
import { inputStyles, placeholderStyles } from '../utils/formStyles';

const Container = styled.div``;

const Input = styled.input<{ $isError?: boolean }>`
    ${inputStyles.base}

    ${({ $isError }): ReturnType<typeof css> | null =>
        $isError ? inputStyles.errorMod : null};

    &::placeholder {
        ${placeholderStyles.base};
    }

    &:focus::placeholder {
        ${placeholderStyles.focusMod};
    }
`;

const Label = styled.label`
    ${hideVisually};
`;

interface FieldTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
    isError?: boolean;
    label?: string;
}

function FieldText(
    { label, id, isError, ...rest }: FieldTextProps,
    ref: React.Ref<HTMLInputElement>,
): React.ReactElement {
    const inputId = React.useMemo((): string => id || uuid(), [id]);

    return (
        <Container>
            {label && <Label htmlFor={inputId}>{label}</Label>}
            <Input
                id={inputId}
                ref={ref}
                type="text"
                placeholder={label}
                $isError={isError}
                {...rest}
            />
        </Container>
    );
}

export default React.forwardRef(FieldText);
