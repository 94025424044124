import React from 'react';
import styled from 'styled-components';
import useAllFonts from '../hooks/useAllFonts';
import FontFamilyGroupsList from '../components/FontFamilyGroupsList';
import ClientOnly from '../components/ClientOnly';
import useConfig from '../hooks/useConfig';
import { VIEWPORT } from '../settings/Global';

export { Head } from '../components/Head';

export const Container = styled.div`
    padding-top: var(--navbarHeight);
    margin-left: var(--gridMarginGap);
    margin-right: var(--gridMarginGap);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding-bottom: 0;
    }
`;

function FontsMenuPage(): React.ReactElement {
    const fontFamilyGroups = useAllFonts();
    const config = useConfig();

    return (
        <Container>
            {
                /*
                Only for the Preview/User testing site.
                We render this client-side only, as the unfiltered list of fonts will
                otherwise briefly be exposed before hydration of the SSR DOM.
                */
                config.userTestingHostname !== null ? (
                    <ClientOnly>
                        <FontFamilyGroupsList groups={fontFamilyGroups} />
                    </ClientOnly>
                ) : (
                    <FontFamilyGroupsList groups={fontFamilyGroups} />
                )
            }
        </Container>
    );
}

export default React.memo(FontsMenuPage);
