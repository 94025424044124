import React from 'react';
import { useColumnsState } from './TypeEditorContext';
import SVGIcon from './SVGIcon';
import {
    ButtonGroupToggleItem,
    ButtonGroupToggleGroup,
} from './TypeEditorToolbar';

const TypeEditorColumnsInput = React.forwardRef(
    (
        props: Record<string, never>,
        forwardedRef: React.Ref<HTMLDivElement>,
    ): React.ReactElement => {
        const [columns, setColumns] = useColumnsState();

        return (
            <ButtonGroupToggleGroup
                type="single"
                value={columns.toString()}
                onValueChange={(value): void => setColumns(parseInt(value))}
                ref={forwardedRef}
            >
                <ButtonGroupToggleItem value="1" title="One column">
                    <SVGIcon type="COLUMNS_1" />
                </ButtonGroupToggleItem>
                <ButtonGroupToggleItem value="2" title="Two columns">
                    <SVGIcon type="COLUMNS_2" />
                </ButtonGroupToggleItem>
                <ButtonGroupToggleItem value="3" title="Three columns">
                    <SVGIcon type="COLUMNS_3" />
                </ButtonGroupToggleItem>
            </ButtonGroupToggleGroup>
        );
    },
);
TypeEditorColumnsInput.displayName = 'TypeEditorColumnsInput';

export default TypeEditorColumnsInput;
