import { captureMessage, captureException } from '@sentry/gatsby';

export function sentryMessage(message: string): void {
    captureMessage(message);

    if (!process.env.GATSBY_SENTRY_DSN) {
        // eslint-disable-next-line no-console
        console.warn(
            `Sentry was not configured. Unable to send message: ${message}`,
        );
    }
}

export function sentryException(error: unknown): void {
    captureException(error);

    if (!process.env.GATSBY_SENTRY_DSN) {
        // eslint-disable-next-line no-console
        console.warn(
            `Sentry was not configured. Unable to send error: ${error}`,
        );
    }
}
