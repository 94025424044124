import React from 'react';
import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import ImageFullBleed from './ImageFullBleed';
import type { ImageMeta } from './ImageFullBleed';
import type { ImageFetchPriority } from './LazyImage';
import isExternalUrl from '../utils/isExternalUrl';
import { useLocation } from '@reach/router';

type ContainerProps = {
    $colorBackground: string | null | undefined;
};

const containerStyles = css<ContainerProps>`
    display: block;
    position: relative;
    max-width: 100%; /* prevent horizontal scrollbars */

    /* Ensure we override any custom background colour from a preceding video */
    background: ${({ $colorBackground }): string =>
        $colorBackground ? $colorBackground : 'var(--backgroundColor)'};
`;

const ContainerLink = styled(GatsbyLink)<ContainerProps>`
    ${containerStyles};
`;
const ContainerA = styled.a<ContainerProps>`
    ${containerStyles};
`;

function LinkedImageFullBleed({
    title,
    landscapeImage,
    portraitImage,
    url,
    onLoadCallback,
    preload,
    lazyLoad,
    imageQuality,
    fetchPriority,
    colorBackground,
    adjustForBrowserChrome,
    withinPageMargins,
}: {
    title?: string;
    landscapeImage: ImageMeta;
    portraitImage?: ImageMeta;
    url?: string;
    onLoadCallback?: () => void;
    preload?: boolean;
    lazyLoad?: boolean;
    imageQuality?: number;
    fetchPriority?: ImageFetchPriority;
    colorBackground?: string | null;
    adjustForBrowserChrome?: boolean;
    withinPageMargins?: boolean;
}): React.ReactElement {
    const location = useLocation();
    const urlIsExternal = React.useMemo(() => {
        return url && isExternalUrl(url, location);
    }, [url, location]);

    const containerProps = {
        'aria-label': title,
        $colorBackground: colorBackground,
    };
    const imageEl = (
        <ImageFullBleed
            landscapeImage={landscapeImage}
            portraitImage={portraitImage}
            onLoadCallback={onLoadCallback}
            preload={preload}
            lazyLoad={lazyLoad}
            imageQuality={imageQuality}
            fetchPriority={fetchPriority}
            adjustForBrowserChrome={adjustForBrowserChrome}
            withinPageMargins={withinPageMargins}
        />
    );

    return urlIsExternal ? (
        <ContainerA href={url || '/'} {...containerProps}>
            {imageEl}
        </ContainerA>
    ) : (
        <ContainerLink to={url || '/'} {...containerProps}>
            {imageEl}
        </ContainerLink>
    );
}

export default React.memo(LinkedImageFullBleed);
