/*
 * Mechanism for invalidating the state of a whole subtree.
 *
 * Simply invoke the callback returned by 'useResetContext()' to invalidate
 * the whole subtree nested within the Provider.
 */
import React from 'react';
import { v4 as uuid } from 'uuid';

type ContextType = () => void;

const Context = React.createContext<ContextType | undefined>(undefined);

export function Provider({
    children,
}: React.PropsWithChildren<Record<string, unknown>>): React.ReactElement {
    const [key, setKey] = React.useState<string>((): string => uuid());
    const resetKey = React.useCallback((): void => setKey(uuid()), []);
    return (
        <Context.Provider
            value={resetKey}
            /*
             * This is where the magic happens, any change in `key` invalidates
             * `children`.
             */
            key={key}
        >
            {children}
        </Context.Provider>
    );
}

export function useReset(): ContextType {
    const context = React.useContext(Context);
    if (!context) {
        throw new Error('useReset used outside of context Provider');
    }
    return context;
}
