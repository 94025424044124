import React from 'react';
import styled from 'styled-components';
import type { UseFormRegister, FieldError } from 'react-hook-form';
import { Link as _Link } from 'gatsby';
import { createEulaUrl } from '../utils/urlHelper';
import useCartLicenceTypes from '../hooks/useCartLicenceTypes';
import type { LicenceType } from '../hooks/useActiveLicenceTypes';
import FieldInputGroup from './FieldInputGroup';
import { FieldCheckbox } from './FieldRadioCheckbox';
import { LicenceTypeId } from '../gql/api-public';
import useCartQuery from '../hooks/useCartQuery';
import type { CheckoutFormData } from './CheckoutForm';
import useIsCartEmpty from '../hooks/useIsCartEmpty';

const Link = styled(_Link)`
    text-decoration: underline;
    text-underline-position: under;
`;

/**
 * Returns the licence types that require licence agreement at checkout
 */
function useLicenceTypesForAgreement(): LicenceType[] {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const cartLicenceTypes = useCartLicenceTypes();
    return React.useMemo(() => {
        const unlimitedLicenceTypeIds =
            cart?.licenceTiers
                .filter((cartTier) => cartTier.tier.isUnlimited)
                .map((cartTier) => cartTier.tier.licenceType.id) || [];
        return cartLicenceTypes.filter(
            (licenceType) =>
                // Exclude UDLA here as it has its own custom licensing.
                licenceType.id !== LicenceTypeId.UDLA &&
                // Same with Unlimited licences
                !unlimitedLicenceTypeIds.includes(licenceType.id),
        );
    }, [cart?.licenceTiers, cartLicenceTypes]);
}

export default function CheckoutFormLicenceAgreementFieldInputGroup({
    register,
    error,
}: {
    register: UseFormRegister<CheckoutFormData>;
    error?: FieldError;
}): React.ReactElement | null {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const cartIsEmpty = useIsCartEmpty(cart);
    const licenceTypes = useLicenceTypesForAgreement();
    if (!licenceTypes.length) {
        return null;
    }

    const lastIndex = licenceTypes.length - 1;
    const secondLastIndex = licenceTypes.length - 2;
    const isPlural = licenceTypes.length > 1;

    return (
        <FieldInputGroup
            input={
                <FieldCheckbox
                    label={
                        <>
                            I agree to the{' '}
                            {licenceTypes.map(
                                (licenceType, index): React.ReactElement => {
                                    const separator =
                                        index === secondLastIndex
                                            ? ' and '
                                            : index < lastIndex
                                              ? ', '
                                              : null;

                                    return (
                                        <React.Fragment key={licenceType.id}>
                                            <Link
                                                to={createEulaUrl(
                                                    licenceType.id as LicenceTypeId,
                                                )}
                                            >
                                                {licenceType.name}
                                            </Link>
                                            {index === lastIndex &&
                                                ` Font Licence${
                                                    isPlural ? 's' : ''
                                                }`}
                                            {separator}
                                        </React.Fragment>
                                    );
                                },
                            )}
                        </>
                    }
                    {...register('licenceAgreement', {
                        required: `You need to agree to the Font Licence${
                            isPlural ? 's' : ''
                        }`,
                        disabled: cartIsEmpty,
                    })}
                    isError={!!error}
                />
            }
            error={error}
        />
    );
}
