import mix from 'polished/lib/color/mix';
import { useCurrentColorScheme } from '../components/ColorSchemeContext';

export default function useLoaderBackgroundColor(
    backgroundColor?: string,
    foregroundColor?: string,
): string {
    const colorScheme = useCurrentColorScheme();
    return mix(
        0.9,
        backgroundColor || colorScheme.backgroundColor,
        foregroundColor || colorScheme.foregroundColor,
    );
}
