import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../components/Paragraph';
import InlineUnderlinedLink from '../components/InlineUnderlinedLink';
import { HeadingExtraLarge, HeadingMedium } from '../components/Heading';
import { loadStripe } from '@stripe/stripe-js';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import { FONT_FAMILY, VIEWPORT } from '../settings/Global';
import soehneMonoWebBuchWoff2 from '../assets/fonts/Soehne/SohneMono-Buch.woff2';
import useCartQuery from '../hooks/useCartQuery';
import useUserQuery from '../hooks/useUserQuery';
import { create404NotFoundUrl, createAccountUrl } from '../utils/urlHelper';
import useMaintenanceModeOverlay from '../hooks/useMaintenanceModeOverlay';
import useIsInvoicePaidQuery from '../hooks/useIsInvoicePaidQuery';
import { navigate } from 'gatsby';
import { sentryException } from '../utils/sentry';
import { CART_UPDATE_DEBOUNCE_TIME } from '../hooks/useDebouncedCartUpdateMutation';
import { useApolloClient } from '@apollo/client';
import { cartQuery as cartQueryGql } from '../utils/runtimeQueries';
import ProgressBar from '../components/ProgressBar';
import { useIsInvoicePage } from '../components/PageContext';
import useLocationInvoiceNumber from '../hooks/useLocationInvoiceNumber';
import useCleanQuoteOrInvoiceNumber from '../hooks/useCleanQuoteOrInvoiceNumber';

export { Head } from '../components/Head';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing8);
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
    position: relative;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing7);
        padding-bottom: 0;
    }
`;

const InvoicePaid = styled(HeadingMedium)`
    min-height: 50vh;
`;

export const HeadingWrapper = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--spacing4) var(--gridColumnGap);

    ${HeadingExtraLarge}, ${InvoicePaid} {
        grid-column: 3 / span 6;
    }

    ${Paragraph} {
        grid-column: 3 / span 5;
    }

    ${HeadingExtraLarge},
    ${Paragraph} {
        @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
            grid-column: 1 / span 8;
        }

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span var(--gridColumnCount);
        }
    }
`;

const InvoiceExtraDescription = styled(Paragraph)`
    white-space: pre-wrap;
`;

// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY || '');

function CheckoutPage(): React.ReactElement | null {
    const invoiceNumber = useLocationInvoiceNumber();
    const cleanInvoiceNumber = useCleanQuoteOrInvoiceNumber(invoiceNumber);
    const isQuote = invoiceNumber && invoiceNumber !== cleanInvoiceNumber;
    const isInvoiceCheckout = useIsInvoicePage();
    const isInvoicePaidQuery = useIsInvoicePaidQuery();
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const userQuery = useUserQuery();
    const user = userQuery?.data?.user;
    useMaintenanceModeOverlay();
    const client = useApolloClient();

    const isLoading = isInvoicePaidQuery.loading || cartQuery.loading;

    const invoiceIsPaid =
        isInvoiceCheckout && isInvoicePaidQuery.data?.isInvoicePaid;

    // Ensure we get an updated cart after navigating here, specifically from the Buy page,
    // where we don't write to the cart cache. We wait `CART_UPDATE_DEBOUNCE_TIME` as that
    // gets any debounced update queries out of the way first.
    React.useEffect((): (() => void) => {
        const timeout = setTimeout((): void => {
            client.refetchQueries({ include: [cartQueryGql] });
        }, CART_UPDATE_DEBOUNCE_TIME);

        return (): void => clearTimeout(timeout);
    }, []);

    React.useEffect((): void => {
        if (
            isInvoiceCheckout &&
            !isInvoicePaidQuery.loading &&
            !isInvoicePaidQuery.data?.isInvoicePaid &&
            !cartQuery.loading &&
            (!cart || cleanInvoiceNumber !== cart.invoiceNumber)
        ) {
            // Let Sentry know if we receive the wrong cart, that ain't right...
            if (cart && cleanInvoiceNumber !== cart.invoiceNumber) {
                sentryException(
                    new Error(
                        `useCartQuery returned the wrong Cart (${cart.id}) for invoiceNumber ${cleanInvoiceNumber}`,
                    ),
                );
            }
            // 404, this invoice couldn't be retrieved...
            navigate(create404NotFoundUrl(), {
                replace: true,
            });
        }
    }, [invoiceNumber, isInvoiceCheckout, isInvoicePaidQuery, cartQuery, cart]);

    return (
        <>
            {isInvoiceCheckout && <ProgressBar loading={isLoading} />}
            <Container>
                <HeadingWrapper>
                    <HeadingExtraLarge>
                        {!isInvoiceCheckout ? (
                            'Checkout'
                        ) : isLoading ? (
                            <>
                                {isQuote ? 'Quote' : 'Invoice'} loading…
                                <br />
                                Please wait
                            </>
                        ) : (
                            `${
                                isQuote ? 'Quote' : 'Invoice'
                            } Nº ${cleanInvoiceNumber}`
                        )}
                    </HeadingExtraLarge>
                    {invoiceIsPaid && (
                        <InvoicePaid>
                            This {isQuote ? 'quote' : 'invoice'} has been paid.
                            Thank you.
                        </InvoicePaid>
                    )}
                    {isInvoiceCheckout ? (
                        cart?.invoiceExtraDescription &&
                        !invoiceIsPaid && (
                            <InvoiceExtraDescription>
                                {cart.invoiceExtraDescription}
                            </InvoiceExtraDescription>
                        )
                    ) : (
                        <>
                            {!userQuery.loading && !user && (
                                <Paragraph>
                                    Already have an account?{' '}
                                    <InlineUnderlinedLink
                                        to={createAccountUrl({
                                            next: location.pathname,
                                        })}
                                    >
                                        Sign In
                                    </InlineUnderlinedLink>
                                </Paragraph>
                            )}
                        </>
                    )}
                </HeadingWrapper>
                {/*
                 * CheckoutForm uses 'user' to create defaultValues,
                 * so it needs to be resolved before first mount
                 */}
                {!invoiceIsPaid && !userQuery.loading && cart && (
                    <StripeElements
                        stripe={stripePromise}
                        options={{
                            fonts: [
                                {
                                    family: FONT_FAMILY.SOEHNE_MONO,
                                    src: `url(${soehneMonoWebBuchWoff2}) format("woff2")`,
                                },
                            ],
                        }}
                    >
                        <CheckoutForm />
                    </StripeElements>
                )}
            </Container>
        </>
    );
}

export default React.memo(CheckoutPage);
