/**
 * Shuffles (randomises) an array using Schwartzian transform.
 * https://en.wikipedia.org/wiki/Schwartzian_transform
 * @param a
 */
export default function shuffleArray<T>(a: T[]): T[] {
    return a
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
}
