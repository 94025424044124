import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { COUNTER_FOOTNOTE, COUNTER_SIDENOTE } from '../settings/Global';

const Globals = createGlobalStyle`
    @page {
        size: 210mm 297mm;
        margin: 0;
    }

    html, body {
        min-height: 100%;
    }

    html {
        background: var(--backgroundColor);
        color: var(--foregroundColor);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        /* Prevent adjustments of font size after orientation changes in iOS. */
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-text-size-adjust: 100%;
    }

    body {
        font-family: var(--fontFamilySoehne);
        font-size: var(--fontSizeFixed2);
        line-height: var(--lineHeightBody2);

        /* CSS counters for the page */
        counter-reset: ${COUNTER_SIDENOTE}
            ${COUNTER_FOOTNOTE};
    }

    [data-radix-popper-content-wrapper] {
        pointer-events: none;
    }

    /* Box sizing */
    *, *::before, *::after {
        box-sizing: border-box;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        line-height: var(--lineHeightHeading2);
    }

    button {
        /* Android applies some styles to buttons by default. */
        background: none;
        appearance: none;
        border: 0;
        padding: 0;

        /* … and Safari applies these small margins. */
        margin: 0;

        /* … and Chrome applies a small font-size & the system-ui font-family */
        font-size: inherit;
        font-family: inherit;

    }

    button, [role="button"], [type="checkbox"], [type="radio"] {
        cursor: pointer;
    }

    /* Used in combo with 'what-input'. Prevents outlines unless focus was applied by keyboard navigability. */
    [data-whatintent='mouse'] :focus,
    [data-whatintent='touch'] :focus {
        outline: none;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    ::selection {
        background-color: var(--foregroundColor);
        color: var(--backgroundColor);
    }

    input, textarea, select {
        font-family: inherit;
    }

    @media print{
        .open-on-print{
            display: block !important;
        }
    }
`;

export default React.memo(Globals);
