interface WhitelistEntry {
    id: string;
}

export default function isWhitelisted(
    whitelist: WhitelistEntry[],
    itemId: string,
): boolean {
    return (
        whitelist.findIndex((whitelistEntry) => whitelistEntry.id === itemId) >
        -1
    );
}
