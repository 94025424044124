import React from 'react';
import styled from 'styled-components';
import { HeadingExtraLarge } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { VIEWPORT } from '../settings/Global';
export { Head } from '../components/Head';
import { Link } from '../components/Primitives';
import {
    createContactUrl,
    createEulaOverviewUrl,
    createFaqUrl,
    createPrivacyPolicyUrl,
    createRefundPaymentPolicyUrl,
} from '../utils/urlHelper';
import { useStaticFontMetricOffsets } from '../components/PageContext';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    display: grid;
    grid-template-columns: 100%;
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--spacing3) var(--gridColumnGap);
    margin-bottom: var(--spacing5);
`;

const Column = styled.div`
    grid-column: 3 / span 6;
    margin-bottom: var(--spacing1);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 6;
    }
`;

const Title = styled(HeadingExtraLarge)`
    padding-bottom: var(--spacing5);
`;

const Content = styled(Paragraph)`
    line-height: var(--lineHeightBody4);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

const HeadingLink = styled(Link)`
    font-size: var(--fontSizeFixed6);
    line-height: var(--lineHeightHeading1);
    font-weight: bold;
    margin-bottom: var(--spacing1);
    display: block;
`;

function RefundPaymentPolicyPage(): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <Container>
            <Row>
                <Column>
                    <Title $metricOffsets={staticFontMetrics} as="h1">
                        Support
                    </Title>
                    <Content>Choose from the 5 main support sections:</Content>
                </Column>
            </Row>
            <Row>
                <Column>
                    <HeadingLink to={createFaqUrl()}>FAQs</HeadingLink>
                    <Content>
                        A comprehensive list of our most common questions,
                        enquiries and answers.
                    </Content>
                </Column>
                <Column>
                    <HeadingLink to={createEulaOverviewUrl()}>
                        Font licences
                    </HeadingLink>
                    <Content>
                        Our various font licences, including common use cases.
                    </Content>
                </Column>
                <Column>
                    <HeadingLink to={createContactUrl()}>Contact</HeadingLink>
                    <Content>Who to get in touch with.</Content>
                </Column>
                <Column>
                    <HeadingLink to={createRefundPaymentPolicyUrl()}>
                        Payments & refunds
                    </HeadingLink>
                    <Content>
                        Details about our payment and refund policy.
                    </Content>
                </Column>
                <Column>
                    <HeadingLink to={createPrivacyPolicyUrl()}>
                        Privacy
                    </HeadingLink>
                    <Content>
                        Our brief but customer-focussed privacy policy.
                    </Content>
                </Column>
            </Row>
        </Container>
    );
}

export default React.memo(RefundPaymentPolicyPage);
