import React from 'react';
import styled, { css } from 'styled-components';
import _LazyResponsiveImage from './LazyResponsiveImage';
import type { ImageFetchPriority } from './LazyImage';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

const ImageWrapper = styled.div<{
    $aspectRatio: number;
    $transparent?: boolean;
    $placeholderColor: undefined | null | string;
}>`
    position: relative;
    border-radius: ${LOZENGE_BORDER_RADIUS}px;

    ${({ $transparent, $placeholderColor }): ReturnType<typeof css> | null =>
        $placeholderColor
            ? css`
                  background-color: ${$placeholderColor};
              `
            : $transparent
              ? null
              : css`
                    background-color: var(--foregroundColorMix8);
                `};

    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: calc(100% / ${({ $aspectRatio }): number => $aspectRatio});
    }
`;

const LazyResponsiveImage = styled(_LazyResponsiveImage)`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
`;

function ImageWithPlaceholder({
    className,
    src,
    alt = '',
    aspectRatio,
    transparent,
    lazyLoad,
    placeholderColor,
    fetchPriority,
}: {
    className?: string;
    src: string;
    alt?: string;
    aspectRatio: number;
    transparent?: boolean;
    lazyLoad?: boolean;
    placeholderColor?: string | null;
    fetchPriority?: ImageFetchPriority;
}): React.ReactElement {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [wrapperWidth, setWrapperWidth] = React.useState<number>(500);
    const [activePlaceholderColor, setActivePlaceholderColor] = React.useState<
        string | undefined | null
    >(placeholderColor);
    const measuredRef = React.useCallback((elm: HTMLElement | null): void => {
        if (elm) {
            setWrapperWidth(elm.getBoundingClientRect().width);
        }
    }, []);

    React.useEffect(() => {
        // We add a 50ms buffer for safety...
        // to prevent the colour from flashing as the image is shown
        if (activePlaceholderColor && isLoaded) {
            setTimeout((): void => {
                setActivePlaceholderColor(undefined);
            }, 50);
        }
    }, [isLoaded, activePlaceholderColor]);

    return (
        <ImageWrapper
            className={className}
            ref={measuredRef}
            $aspectRatio={aspectRatio}
            $placeholderColor={activePlaceholderColor}
            $transparent={transparent}
        >
            <LazyResponsiveImage
                src={src}
                sources={[{ src, sizes: [`${wrapperWidth}px`] }]}
                alt={alt}
                lazyLoad={lazyLoad}
                onLoadCallback={(): void => {
                    setIsLoaded(true);
                }}
                preload={!lazyLoad}
                fetchPriority={fetchPriority}
            />
        </ImageWrapper>
    );
}

export default React.memo(ImageWithPlaceholder);
