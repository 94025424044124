import styled, { css } from 'styled-components';
import { Link as _Link } from 'gatsby';

import { LOZENGE_HEIGHT, LOZENGE_SPACING } from '../settings/Global';

export const LOZENGE_BORDER_RADIUS = 2;

export const LOZENGE_HORIZONTAL_PADDING = 8;
export const LOZENGE_HORIZONTAL_PADDING_SPACED =
    LOZENGE_HORIZONTAL_PADDING - LOZENGE_SPACING;
export const LOZENGE_HORIZONTAL_PADDING_DIFF =
    LOZENGE_HORIZONTAL_PADDING - LOZENGE_HORIZONTAL_PADDING_SPACED;
export const LOZENGE_VERTICAL_PADDING = 5;
export const LOZENGE_VERTICAL_PADDING_SPACED = 2;
export const LOZENGE_VERTICAL_PADDING_DIFF =
    LOZENGE_VERTICAL_PADDING - LOZENGE_VERTICAL_PADDING_SPACED;

export const lozengeHorizontalPadding = `${LOZENGE_HORIZONTAL_PADDING}px`;
export const lozengeVerticalPadding = `${LOZENGE_VERTICAL_PADDING}px`;

export const lozengeHorizontalPaddingSpaced = `${LOZENGE_HORIZONTAL_PADDING_SPACED}px`;
export const lozengeVerticalPaddingSpaced = `${LOZENGE_VERTICAL_PADDING_SPACED}px`;

export const lozengeStyles = css`
    font-size: var(--fontSizeFixed2);
    text-decoration: none;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    padding: 0 ${lozengeHorizontalPadding};
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
    height: ${LOZENGE_HEIGHT}px;
    display: inline-flex;
    align-items: center; /* center the content vertically */
    overflow-y: visible;
    width: var(--lozengeWidth, auto);
    min-width: var(--lozengeMinWidth, auto);
    max-width: var(--lozengeMaxWidth, auto);
    color: var(--lozengeColor);
    background-color: var(--lozengeBackgroundColor);
    border-style: var(--lozengeBorderStyle, none);
    border-width: var(--lozengeBorderWidth, 1px);
    border-color: var(--lozengeBorderColor, transparent);
`;

export const statefulLozengeStyles = css`
    ${lozengeStyles};
    &:hover,
    &[data-state='open'] {
        color: var(--lozengeHoverColor, var(--lozengeBackgroundColor));
        background-color: var(
            --lozengeBackgroundHoverColor,
            var(--lozengeColor)
        );
        border-style: var(--lozengeHoverBorderStyle, --lozengeBorderStyle);
        border-color: var(--lozengeHoverBorderColor, --lozengeBorderColor);
    }

    &:active,
    /* TODO: Give indeterminate a different styling? */
    &[data-state='indeterminate'],
    &[data-state='checked'] {
        color: var(--lozengeHoverColor, var(--lozengeBackgroundColor));
        background-color: var(
            --lozengeBackgroundActiveColor,
            var(--lozengeColor)
        );
    }

    &[disabled] {
        cursor: not-allowed;

        --lozengeColor: var(--backgroundColor);
        --lozengeBackgroundColor: var(--foregroundColorMix3);
        --lozengeBorderColor: var(--foregroundColorMix3);
        --lozengeHoverColor: var(--lozengeColor);
        --lozengeBackgroundHoverColor: var(--lozengeBackgroundColor);
    }
`;

const Link = styled(_Link)`
    ${statefulLozengeStyles};
`;

export default Link;

export const A = styled.a`
    ${statefulLozengeStyles};
`;

export const Button = styled.button`
    ${statefulLozengeStyles};
    text-align: left;
`;

export const Div = styled.div`
    ${lozengeStyles};
`;
