import type { EditorState, SelectionState } from 'draft-js';

export default function getSelectionToApplyInlineStylesTo(
    editorState: EditorState,
): SelectionState {
    const currentSelection = editorState.getSelection();

    // If we have a selection, return it
    if (!currentSelection.isCollapsed()) {
        return currentSelection;
    }

    // If there's no selection, select all
    const currentContent = editorState.getCurrentContent();
    return currentSelection.merge({
        anchorKey: currentContent.getFirstBlock().getKey(),
        anchorOffset: 0,
        focusKey: currentContent.getLastBlock().getKey(),
        focusOffset: currentContent.getLastBlock().getLength(),
    });
}
