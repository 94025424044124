import {
    usePreviewBlogPost,
    usePreviewBlogPostWordCount,
} from '../components/GlobalRuntimeState';
import {
    usePageQueryBlogPostWordCount,
    usePageQueryBlogPost,
} from '../components/PageContext';
import type { BlogPost } from '../union-types/blogPost';

export default function usePreviewOrPageQueryBlogPost(): BlogPost {
    const previewPage = usePreviewBlogPost();
    const pageQueryPage = usePageQueryBlogPost();
    if (!previewPage && !pageQueryPage) {
        throw Error('blogPost is undefined');
    }
    return (previewPage || pageQueryPage) as BlogPost;
}

export function usePreviewOrPageQueryBlogPostWordCount(): number {
    const previewCount = usePreviewBlogPostWordCount();
    const pageQueryCount = usePageQueryBlogPostWordCount();
    if (previewCount === undefined && pageQueryCount === undefined) {
        throw Error('blogWordCount is undefined');
    }
    return (previewCount || pageQueryCount) as number;
}
