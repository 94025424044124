import React from 'react';
import styled from 'styled-components';
import {
    COUNTER_FOOTNOTE,
    FOOTNOTE_BACK_CLASSNAME,
    VIEWPORT,
} from '../settings/Global';
import { blogAndInUseWrapStyles, hyperlinkDarkerStyle } from './BlogPostStyles';
import { hyphensAuto, preventPrintPageBreak } from '../utils/stylesMixins';
import { footnoteBodyStyles, footnoteNumberStyles } from './BlogPostPartString';
import useNavColorFromIntersection from '../hooks/useNavColorFromIntersection';
import { HeadingMedium } from './Heading';
import { useStaticFontMetricOffsets } from './PageContext';
import { getFootnoteId } from '../utils/blogFootnotes';

const Wrap = styled.div`
    position: relative;
    z-index: 2;
    background: var(--foregroundColorMix1);
    color: var(--foregroundColorMix8);

    @media print {
        color: var(--foregroundColorMix1);
        background: var(--foregroundColorMix8);
    }

    padding: var(--spacing7) 0 var(--spacing7) 0;
    margin: 0;
`;

const HeadingWrap = styled.div`
    ${blogAndInUseWrapStyles};

    padding: 0 var(--gridMarginGap);
    grid-template-areas: '. . a a a a a a a a a .';

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: '. a a a a .';
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-areas: 'a a a a a a';
    }
`;

const Heading = styled(HeadingMedium)`
    grid-area: a;
    padding: 0;
    ${hyphensAuto};
    ${preventPrintPageBreak}
`;

const FootnotesWrap = styled.div`
    ${blogAndInUseWrapStyles};

    grid-row-gap: 0;
    padding: var(--spacing4) var(--gridMarginGap) var(--spacing2)
        var(--gridMarginGap);
    grid-template-areas: '. . a a a a . b b b b . ';

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        line-height: var(--lineHeightBody2);
        grid-template-areas:
            '. a a a a .'
            '. b b b b .';
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-areas:
            'a a a a a a'
            'b b b b b b';
    }
`;

const FootnoteList = styled.ol`
    ${footnoteBodyStyles};

    color: var(--foregroundColorMix8);

    @media print {
        color: var(--foregroundColorMix1);
    }

    list-style-type: none;
    scroll-margin-top: var(--navbarHeightCollapsed);

    a:not(.${FOOTNOTE_BACK_CLASSNAME}) {
        ${hyperlinkDarkerStyle};
    }
`;

const Footnote = styled.li`
    scroll-margin-top: var(--navbarHeightCollapsed);
    margin-bottom: 1em;
    counter-increment: ${COUNTER_FOOTNOTE};

    p:first-child::before {
        content: counter(${COUNTER_FOOTNOTE});
        ${footnoteNumberStyles};
        color: var(--foregroundColorMix8);

        @media print {
            color: var(--foregroundColorMix1);
        }

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            content: counter(${COUNTER_FOOTNOTE}) '.\\00a0\\00a0';
            float: none;
            margin-left: 0;
            width: auto;
        }
    }
`;

const FootnoteListLeft = styled(FootnoteList)`
    grid-area: a;
`;

const FootnoteListRight = styled(FootnoteList)`
    grid-area: b;
`;

export function BodyFootnoteFooter({
    footnotesList,
}: {
    footnotesList: string[];
}): React.ReactElement {
    const fontMetricOffsets = useStaticFontMetricOffsets();
    const [createNavColorRef] = useNavColorFromIntersection();
    const list = footnotesList;
    const midway = Math.ceil(list.length / 2);

    return (
        <Wrap
            ref={createNavColorRef({
                background: 'var(--foregroundColorMix1)',
                foreground: 'var(--foregroundColorMix8)',
            })}
        >
            <HeadingWrap>
                <Heading $metricOffsets={fontMetricOffsets} as="h2">
                    Notes &amp; references
                </Heading>
            </HeadingWrap>

            <FootnotesWrap>
                <FootnoteListLeft>
                    {list.slice(0, midway).map(
                        (footnote: string, idx: number): React.ReactElement => (
                            <Footnote
                                key={idx}
                                id={getFootnoteId(idx)}
                                dangerouslySetInnerHTML={{
                                    __html: footnote,
                                }}
                            />
                        ),
                    )}
                </FootnoteListLeft>
                <FootnoteListRight start={midway + 1}>
                    {list.slice(midway).map(
                        (footnote: string, idx: number): React.ReactElement => (
                            <Footnote
                                key={idx}
                                id={getFootnoteId(midway + idx)}
                                dangerouslySetInnerHTML={{
                                    __html: footnote,
                                }}
                            />
                        ),
                    )}
                </FootnoteListRight>
            </FootnotesWrap>
        </Wrap>
    );
}
