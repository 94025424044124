import React from 'react';
import {
    FontStylesContainer,
    FontStylesItem,
    FontStylesTitle,
    FontStylesWeight,
    FontStylesFragmentLink,
} from './FontStylesElements';
import type { FontFamily } from './PageContext';
import getCssFontFamilyNameFromId from '../utils/getCssFontFamilyNameFromId';
import getSafeCssStringValue from '../utils/getSafeCssStringValue';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import { TEST_ID } from '../settings/E2e';
import getTypeEditorId from '../utils/getTypeEditorId';

function FontFamilyFontStyles({
    styles,
    fontSizeMultiplier,
    withFragmentLinks,
}: {
    styles: FontFamily['fontStyles'];
    fontSizeMultiplier: number;
    withFragmentLinks?: boolean;
}): React.ReactElement {
    return (
        <FontStylesContainer>
            {styles
                .sort(sortFontsByTypographicRanking)
                .map((style): React.ReactElement => {
                    const Inner = (props: {
                        children: React.ReactNode;
                    }): React.ReactElement =>
                        withFragmentLinks ? (
                            <FontStylesFragmentLink
                                href={`#${getTypeEditorId(style.name)}`}
                                updateUri={false}
                            >
                                {props.children}
                            </FontStylesFragmentLink>
                        ) : (
                            <>{props.children}</>
                        );

                    return (
                        <FontStylesItem
                            key={style.id}
                            $fontSizeMultiplier={fontSizeMultiplier}
                            data-cy={TEST_ID.FONT_FAMILY_STYLES}
                        >
                            <Inner>
                                <FontStylesTitle
                                    $fontFamily={getSafeCssStringValue(
                                        getCssFontFamilyNameFromId(style.id),
                                    )}
                                    data-cy={TEST_ID.FONT_FAMILY_STYLE_TITLE}
                                >
                                    {style.name}
                                </FontStylesTitle>
                                <FontStylesWeight>
                                    {style.weight}
                                </FontStylesWeight>
                            </Inner>
                        </FontStylesItem>
                    );
                })}
        </FontStylesContainer>
    );
}

export default React.memo(FontFamilyFontStyles);
