import React from 'react';
import styled, { css, type DefaultTheme } from 'styled-components';
import type { HomepageCampaign as HomepageCampaignType } from '../union-types/homepage';
import { createCollectionUrl, createFontFamilyUrl } from '../utils/urlHelper';
import Campaign from './Campaign';
import Link from './Lozenge';

import notPreRelease from '../utils/notPreRelease';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import { type FontFamily } from './FontFamilyGroupsListItem';
import getColorThemeFromBaseColors from '../utils/getColorThemeFromBaseColors';
import useNavColorFromIntersection from '../hooks/useNavColorFromIntersection';

const Container = styled.div``;

const FontLink = styled(Link)`
    position: absolute;
    bottom: var(--gridMarginGap);
    left: var(--gridMarginGap);
`;

const CampaignContainer = styled.div<{
    $colorScheme: DefaultTheme | undefined;
    $isFirstOnPage: boolean;
    theme: DefaultTheme;
}>`
    position: relative;
    background-color: ${({ $colorScheme, theme }): string =>
        $colorScheme?.backgroundColor || theme.backgroundColor};
    overflow: hidden;
    width: 100vw;
    max-width: 100%; /* ensure Windows doesn't show horizontal scrollbar */
    height: 100vh;

    /* The first campaign on the homepage might need to be shown to correct for browser chrome on mobile */
    ${({ $isFirstOnPage }): ReturnType<typeof css> | null =>
        $isFirstOnPage
            ? css`
                  margin-top: var(--navbarHeight);
                  height: calc(100svh - var(--navbarHeight));
              `
            : null};

    ${({ $colorScheme, theme }): ReturnType<typeof css> => css`
        --lozengeColor: ${$colorScheme?.foregroundColor ||
        theme.foregroundColor};
        --lozengeHoverColor: var(--lozengeColor);
        --lozengeBackgroundColor: ${$colorScheme?.backgroundColor ||
        theme.backgroundColor};
        --lozengeBackgroundHoverColor: var(--lozengeBackgroundColor);
        --lozengeBackgroundActiveColor: var(--lozengeBackgroundColor);
    `}
`;

export function getUrlAndNameFromHomepageCampaign(
    homepageCampaign: HomepageCampaignType,
    fontFamilyIdFilter?: string[],
): { url: string | undefined; name: string | undefined } {
    const { fontFamilyGroup } = homepageCampaign;

    const fontFamilies =
        fontFamilyGroup && !fontFamilyGroup.isCollection
            ? (fontFamilyGroup.fontFamilies as FontFamily[])
                  .filter(notPreRelease)
                  .filter(
                      (ff) =>
                          !fontFamilyIdFilter ||
                          !fontFamilyIdFilter.length ||
                          fontFamilyIdFilter.includes(ff.id),
                  )
                  .sort(sortFontsByTypographicRanking)
            : [];

    const url = fontFamilyGroup.isCollection
        ? createCollectionUrl(fontFamilyGroup.slug)
        : createFontFamilyUrl(fontFamilies[0].slug);
    const name = fontFamilyGroup.isCollection
        ? `${fontFamilyGroup.name} Collection`
        : fontFamilies[0].name;

    return {
        url,
        name,
    };
}

function HomepageCampaign({
    homepageCampaign,
    index,
}: {
    homepageCampaign: HomepageCampaignType;
    index: number;
}): React.ReactElement {
    const [createNavColorRef] = useNavColorFromIntersection();
    const { fontFamilyGroup } = homepageCampaign;
    const { url, name } = React.useMemo(() => {
        return getUrlAndNameFromHomepageCampaign(homepageCampaign);
    }, [homepageCampaign]);
    const expandedColorScheme = React.useMemo(() => {
        return fontFamilyGroup.colorSchemes.length
            ? getColorThemeFromBaseColors(fontFamilyGroup.colorSchemes[0])
            : undefined;
    }, [fontFamilyGroup.colorSchemes]);

    return (
        <Container>
            <CampaignContainer
                $colorScheme={expandedColorScheme}
                $isFirstOnPage={index === 0}
                ref={createNavColorRef({
                    background:
                        expandedColorScheme?.backgroundColor ||
                        'var(--backgroundColor)',
                    foreground:
                        expandedColorScheme?.foregroundColor ||
                        'var(--foregroundColor)',
                })}
            >
                <Campaign
                    campaign={homepageCampaign.campaign}
                    linkUrl={url}
                    linkText={name}
                    // Lazy load from the second one, onwards
                    lazyLoad={index > 0}
                    loaderBackgroundColor={expandedColorScheme?.backgroundColor}
                    adjustForBrowserChrome={index === 0}
                />
                {url && name && <FontLink to={url}>{name}</FontLink>}
            </CampaignContainer>
        </Container>
    );
}

export default React.memo(HomepageCampaign);
