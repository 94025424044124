import React, { useCallback } from 'react';

type clickEvent = (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;

function smoothScrollToLink(
    e: React.MouseEvent<HTMLElement>,
    updateUri?: boolean,
): void {
    /*
     * Using `currentTarget` rather than `target`, because `target` may be a
     * descendent element of the anchor, whereas `currentTarget` will always be
     * the anchor itself.
     */
    const { target } = e;
    if (!(target instanceof HTMLAnchorElement)) return;
    const href = target.getAttribute('href');
    if (!href) return; // if it's an anchor target ie <a name="blah"></a> it won't have an href
    if (href.charAt(0) !== '#') return;
    const idTarget = document.querySelector(href) as Element | undefined;
    if (!(idTarget instanceof HTMLElement)) return;

    if (typeof IntersectionObserver !== 'undefined') {
        const intersectionObserver = new IntersectionObserver(
            (entries): void => {
                const [entry] = entries;
                if (entry.isIntersecting) {
                    try {
                        intersectionObserver.unobserve(idTarget);
                    } catch (e) {
                        // ignore
                    }

                    if (typeof updateUri === 'undefined' || updateUri) {
                        // Ensure the URI is updated to contain the anchor
                        window.location.hash = idTarget.id;
                    }

                    setTimeout((): void => {
                        // Needs a timeout https://stackoverflow.com/questions/1096436/document-getelementbyidid-focus-is-not-working-for-firefox-or-chrome
                        //
                        // Move activeElement focus once it's in view, however target will need to be
                        // focusable (eg) have a tabIndex or implicit tabIndex
                        idTarget.focus();
                    }, 0);
                }
            },
        );
        intersectionObserver.observe(idTarget);
        e.preventDefault();
    }

    try {
        // 'try' because Mitch notes that sometimes old browsers can
        // throw exceptions if scrollIntoView expects boolean not a map.
        idTarget.scrollIntoView({
            behavior: 'smooth',
        });
    } catch (e) {
        // if that's failed, try scrolling without smooth scrolling
        try {
            idTarget.scrollIntoView();
        } catch (e2) {
            // pass
        }
    }
}

export const useBubbledFragmentLink = (updateUri?: boolean): clickEvent => {
    return useCallback(
        (e: React.MouseEvent<HTMLElement>): void =>
            smoothScrollToLink(e, updateUri),
        [],
    );
};

interface Props {
    href: string;
    updateUri?: boolean;
    children?: React.ReactNode;
    className?: string;
    'aria-label'?: string;
}

function FragmentLink({
    href,
    className,
    children,
    'aria-label': ariaLabel,
    updateUri,
}: Props): React.ReactElement {
    return (
        <a
            href={href}
            onClick={useBubbledFragmentLink(updateUri)}
            className={className}
            aria-label={ariaLabel}
        >
            {children}
        </a>
    );
}

export default FragmentLink;
