import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import { createFontsMenuUrl } from '../utils/urlHelper';
import usePreviewOrPageQueryHomepage from '../hooks/usePreviewOrPageQueryHomepage';
import FontFamilyGroupsList from './FontFamilyGroupsList';
import Icon, { IconType } from './Icon';
import Link from './Lozenge';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
`;

const Footer = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
`;

const More = styled.div`
    margin-top: var(--spacing5);
    grid-column: 3 / span 8;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / var(--gridColumnCount);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-top: var(--spacing4);
    }
`;

function HomeFontGroups(): React.ReactElement | null {
    const homepage = usePreviewOrPageQueryHomepage();

    if (!homepage.featuredFonts?.length) {
        return null;
    }

    return (
        <Container>
            <FontFamilyGroupsList groups={homepage.featuredFonts} />
            <Footer>
                <More>
                    <Link to={createFontsMenuUrl()}>
                        See all fonts &nbsp;
                        <Icon type={IconType.ARROW_RIGHT} />
                    </Link>
                </More>
            </Footer>
        </Container>
    );
}

export default HomeFontGroups;
