import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';

export default new ApolloClient({
    link: ApolloLink.from([
        createHttpLink({
            uri:
                process.env.GATSBY_API_PREVIEW_URL ||
                'http://localhost:8000/api-preview/',
            // So that queries are authenticated against the correct session:
            credentials: 'include',
        }),
    ]),
    cache: new InMemoryCache(),
});
