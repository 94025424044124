import React from 'react';
import reducer from './GlyphInspector.reducer';
import type { GlyphInspectorAction } from './GlyphInspector.reducer';
import { GLYPH_RENDER_MODE } from '../settings/Global';
import type { FontFamily } from './PageContext';
import createStatefulContext from '../utils/createStatefulContext';
import type { Font, Glyph } from 'opentype.js';
import isBrowser from '../utils/isBrowser';

const {
    useState: useCanvasDimensionsState,
    Provider: CanvasDimensionsProvider,
} = createStatefulContext<{ width: number; height: number }>({
    width: 0,
    height: 0,
});

const { useState: useRenderModeState, Provider: RenderModeProvider } =
    createStatefulContext<GLYPH_RENDER_MODE>(GLYPH_RENDER_MODE.FILL);

const { useState: useFontStyleState, Provider: FontStyleProvider } =
    createStatefulContext<FontFamily['fontStyles'][number]>();

export { useFontStyleState, useCanvasDimensionsState, useRenderModeState };

export interface GlyphInspectorState {
    font?: Font;
    glyph?: Glyph;
    pixelRatio: number;
}

export type GlyphInspectorDispatch = (action: GlyphInspectorAction) => void;

const StateContext = React.createContext<GlyphInspectorState | undefined>(
    undefined,
);
const DispatchContext = React.createContext<GlyphInspectorDispatch | undefined>(
    undefined,
);

export function Provider({
    children,
    fontFamily,
}: {
    children: React.ReactElement;
    fontFamily: FontFamily;
}): React.ReactElement {
    const [state, dispatch] = React.useReducer(reducer, {
        pixelRatio: isBrowser() ? window.devicePixelRatio : 1,
    });

    /*
     * Seeing as the GlyphList is prone to performance issues it is a good idea
     * to split the 'state' and 'dispatch' into separate providers so that they
     * can be 'used' independently, and a change in the former doesn't force
     * re-renders for components which only use the latter.
     */
    return (
        <CanvasDimensionsProvider>
            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>
                    <RenderModeProvider>
                        <FontStyleProvider initial={fontFamily.mainFontStyle}>
                            {children}
                        </FontStyleProvider>
                    </RenderModeProvider>
                </DispatchContext.Provider>
            </StateContext.Provider>
        </CanvasDimensionsProvider>
    );
}

export const useGlyphInspectorState = (): GlyphInspectorState => {
    const context = React.useContext(StateContext);
    if (!context) {
        throw new Error('useGlyphInspectorState used outside of Provider.');
    }

    return context;
};

export const useGlyphInspectorDispatch = (): GlyphInspectorDispatch => {
    const context = React.useContext(DispatchContext);
    if (!context) {
        throw new Error('useGlyphInspectorDispatch used outside of Provider.');
    }

    return context;
};
