import React from 'react';
import styled from 'styled-components';
import { useFontFamilyGroup } from './PageContext';
import FontIntroduction from './FontIntroduction';
import FontRelease from './FontRelease';
import FontInformation from './FontInformation';
import FontCounts from './FontCounts';
import FontCollectionCredits from './FontCollectionCredits';
import SupportedLanguages from './SupportedLanguages';
import { Accordion, AccordionItem, Button } from './Accordion';
import { default as _Icon, IconType } from './Icon';
import { createStaticAssetUrl, createTestFontsUrl } from '../utils/urlHelper';
import notDuplicate from '../utils/notDuplicate';

export const LargeText = styled.span`
    font-size: var(--fontSizeFixed4);
`;

const Icon = styled(_Icon)`
    font-size: var(--fontSizeFixed4);
`;

function FontCollectionInformation(): React.ReactElement {
    const {
        pdfSpecimen,
        fontFamilies,
        informationIntro,
        designInformationPage,
        slug,
    } = useFontFamilyGroup();

    const releaseDates = fontFamilies.map(({ release }): string => release);

    const languages = fontFamilies
        .reduce(
            (carry: string[], fontFamily): string[] => [
                ...carry,
                ...fontFamily.supportedLanguages,
            ],
            [],
        )
        // Some items have trailing whitespace, which would otherwise makes them 'unique'
        .map((language): string => language.trim())
        .filter(notDuplicate)
        .sort();

    const fontFamilyCount = fontFamilies.length;
    const fontStyleCount = fontFamilies.reduce(
        (carry: number, fontFamily): number =>
            carry + fontFamily.fontStyleCount,
        0,
    );

    return (
        <FontInformation
            intro={
                informationIntro ? (
                    <FontIntroduction
                        informationIntro={informationIntro}
                        blogPostSlug={
                            designInformationPage && designInformationPage.slug
                        }
                    />
                ) : null
            }
            aside={
                <>
                    <FontCounts
                        isCollection={true}
                        slug={slug}
                        fontFamilyCount={fontFamilyCount}
                        fontStyleCount={fontStyleCount}
                    />
                    <FontRelease releaseDates={releaseDates} />
                </>
            }
            content={
                <Accordion>
                    <AccordionItem
                        heading={<LargeText>Credits &amp; details</LargeText>}
                        headingLevel={3}
                        panel={<FontCollectionCredits />}
                    />
                    <AccordionItem
                        heading={<LargeText>Supported languages</LargeText>}
                        headingLevel={3}
                        hidePanelUnlessExpanded={true}
                        panel={<SupportedLanguages languages={languages} />}
                    />
                    <Button as="a" role="button" href={createTestFontsUrl()}>
                        <LargeText>Download test fonts</LargeText>
                        <Icon type={IconType.DOWNLOAD} />
                    </Button>
                    {pdfSpecimen && (
                        <Button
                            as="a"
                            download
                            href={createStaticAssetUrl(pdfSpecimen)}
                        >
                            <LargeText>Download PDF specimen</LargeText>
                            <Icon type={IconType.DOWNLOAD} />
                        </Button>
                    )}
                </Accordion>
            }
        />
    );
}

export default FontCollectionInformation;
