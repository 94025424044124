import { Observable } from '@apollo/client';

export default (promise: Promise<unknown>): Observable<unknown> => {
    return new Observable((subscriber) => {
        promise.then(
            (value) => {
                if (subscriber.closed) return;
                subscriber.next(value);
                subscriber.complete();
            },
            (err) => subscriber.error(err),
        );
    });
};
