import type { DefaultTheme } from 'styled-components';

// These two colours are a Klim convention. #000 for foreground, #F00 for highlight.
const foregroundColorRegex = /["'](?:#000|#000000|black)['"]/gi;
const highlightColorRegex = /["'](?:#f00|#ff0000|red)['"]/gi;

export default function replaceSvgColors(
    svg: string,
    colorScheme: DefaultTheme,
): string {
    return svg
        .replace(foregroundColorRegex, `"${colorScheme.foregroundColor}"`)
        .replace(highlightColorRegex, `"${colorScheme.highlightColor}"`);
}
