import { useCurrentColorScheme } from '../components/ColorSchemeContext';
import { COLOR } from '../settings/Global';

export default function useIsDefaultColorScheme(): boolean {
    const currentColorScheme = useCurrentColorScheme();
    return (
        currentColorScheme.highlightColor === COLOR.RED.toString() &&
        [COLOR.BLACK.toString(), COLOR.WHITE.toString()].includes(
            currentColorScheme.backgroundColor,
        )
    );
}
