import isBrowser from './isBrowser';
import type { WindowLocation } from '@reach/router';

/**
 * Checks if a URL points to the local website or to an external address.
 * @param url
 * @param location
 */
export default function isExternalUrl(
    url: string,
    location: WindowLocation,
): boolean {
    const isLocalUri = url.match(/^https?:\/\//) === null;
    if (isLocalUri) {
        return false;
    }
    if (!isBrowser()) {
        return true;
    }
    const urlObj = new URL(url);
    return location.host !== urlObj.host;
}
