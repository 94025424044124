import React from 'react';
import styled from 'styled-components';
import { useGlyphInspectorState } from './GlyphInspectorContext';
import type { Font, Glyph } from 'opentype.js';
import GlyphInspectorGlyphListItem from './GlyphInspectorGlyphListItem';
import {
    ZINDEX,
    GLYPH_INSPECTOR_BREAKPOINT,
    MOBILE_GLYPH_BUTTON_WIDTH,
    DESKTOP_GLYPHS_PER_ROW,
    MOBILE_MAX_GLYPH_ROWS,
} from '../settings/Global';
import { GLYPH_CAT_DEFS } from '../settings/GlyphInspector';
import type { GlyphCatDef } from '../settings/GlyphInspector';
import notUndefined from '../utils/notUndefined';
import { sentryMessage } from '../utils/sentry';
import notNull from '../utils/notNull';
import { useGlobalState } from './GlobalRuntimeState';

const MainHolder = styled.div`
    position: relative;
`;
const Container = styled.div`
    position: relative;

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        display: grid;
        grid-auto-flow: column;
        overflow-x: scroll;

        /* counter balance the negative offset */
        padding: 0 var(--gridMarginGap) var(--gridMarginGap);

        &::-webkit-scrollbar {
            display: none;
        }

        max-width: 100vw;
    }
`;
const SideGradient = styled.div<{
    $isRight?: boolean;
}>`
    position: absolute;
    display: none;

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        display: block;
        top: 0;
        left: ${({ $isRight }): string => ($isRight ? 'auto' : '0')};
        right: ${({ $isRight }): string => ($isRight ? '0' : 'auto')};
        width: ${({ $isRight }): string =>
            $isRight ? 'var(--spacing6)' : 'var(--gridMarginGap)'};
        height: 100%;
        background: linear-gradient(
            ${({ $isRight }): string => ($isRight ? '90deg' : '270deg')},
            var(--backgroundGradientColorMin) 0%,
            var(--backgroundGradientColorMax) 100%
        );
        z-index: ${ZINDEX.GLYPH_INSPECTOR_GRADIENT};
        pointer-events: none;
    }
`;

const Inner = styled.div<{ $columnCount: number }>`
    display: grid;
    grid-gap: 1px; /* So that list-items' outlines overlap. */
    grid-template-columns: repeat(
        ${({ $columnCount }): number =>
            $columnCount < DESKTOP_GLYPHS_PER_ROW
                ? DESKTOP_GLYPHS_PER_ROW
                : $columnCount},
        1fr
    );

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        grid-template-columns: repeat(
            ${({ $columnCount }): number => $columnCount},
            1fr
        );
        max-width: ${({ $columnCount }): number =>
            $columnCount * MOBILE_GLYPH_BUTTON_WIDTH}px;
    }
`;

const Heading = styled.h6`
    font-weight: normal;
    color: var(--foregroundColorMix7);
    padding: var(--spacing2) 0;
    padding-top: 0;

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        color: var(--foregroundColor);
        white-space: nowrap;
        display: block;
    }
`;

const ListItem = styled.div<{
    $isLast: boolean;
}>`
    padding-top: var(--spacing2);

    &:first-child {
        padding-top: 0;
        margin-top: 0;
    }

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        &:first-child {
            padding-top: var(--spacing2);
        }

        /*
        Buttons have an outline instead of border.
        Parent needs a margin-left otherwise it gets cut off by scrolling.
        The last listItem need to be able to scroll further from the right hand side due to gradient.
        Resetting the margin and adding var(--spacing6) padding (the width of the right gradient)
        to the width will ensure this.
        */

        margin-left: 1px;
        margin-right: ${({ $isLast }): string =>
            $isLast ? `0` : `var(--spacing6)`};
        padding-right: ${({ $isLast }): string =>
            $isLast ? `var(--spacing6)` : `0`};
    }
`;

interface GlyphCat {
    title: string;
    hidden?: boolean;
    glyphs: Glyph[];
    orderedGlyphNames: string[];
}

function sortGlyphs(
    a: Glyph,
    b: Glyph,
    orderedGlyphNames: (string | null)[],
): number {
    return (
        orderedGlyphNames.indexOf(a.name) - orderedGlyphNames.indexOf(b.name)
    );
}

/**
 * Categorise and order glyphs based on the GLYPH_CAT_DEFS definitions.
 * @param font
 */
const useCategorisedGlyphs = (font: Font | undefined): GlyphCat[] => {
    return React.useMemo((): GlyphCat[] => {
        if (!font) {
            return [];
        }
        // Create an array of objects to hold all categorised data
        const glyphCats = GLYPH_CAT_DEFS.map(
            (glyphCatDef: GlyphCatDef): GlyphCat => ({
                title: glyphCatDef.title,
                hidden: glyphCatDef.hidden || false,
                orderedGlyphNames: glyphCatDef.orderedGlyphNames,
                glyphs: [],
            }),
        );

        // Make a special "Other" category to capture any unmatched glyphs
        const otherGlyphCat: GlyphCat = {
            title: 'Other',
            orderedGlyphNames: [],
            glyphs: [],
        };

        // Loop all the glyphs
        for (let glyphIndex = 0; glyphIndex < font.numGlyphs; glyphIndex++) {
            const glyph = font.glyphs.get(glyphIndex);

            // See if this glyph appears in the orderedGlyphNames
            const extraNamesGlyphCat = glyphCats.find((glyphCat): boolean =>
                glyph.name === null
                    ? false
                    : glyphCat.orderedGlyphNames.includes(glyph.name),
            );
            if (extraNamesGlyphCat) {
                extraNamesGlyphCat.glyphs.push(glyph);
                continue;
            }

            // Exclude "empty" characters
            if (glyph.path.commands.length === 0) {
                continue;
            }

            // Anything that didn't match, put it in the "Other" bucket for debugging.
            // eslint-disable-next-line no-console
            console.warn(
                `Unmatched glyph: ${glyph.name} (unicode: ${glyph.unicode})`,
            );
            otherGlyphCat.glyphs.push(glyph);
        }

        // Sort within each category
        glyphCats.forEach((glyphCat: GlyphCat): void => {
            glyphCat.glyphs.sort((a, b): number =>
                sortGlyphs(a, b, glyphCat.orderedGlyphNames),
            );
        });

        // Add "Other" category if it's not empty
        if (otherGlyphCat.glyphs.length > 0) {
            glyphCats.push(otherGlyphCat);

            if (process.env.NODE_ENV === 'production') {
                sentryMessage(
                    `Unmatched glyphs: ${otherGlyphCat.glyphs
                        .map((glyph): string | null => glyph.name)
                        .filter(notNull)
                        .join(', ')}`,
                );
            }
        }

        return glyphCats;
    }, [font]);
};

export default function GlyphInspectorGlyphList(): React.ReactElement {
    const [viewportWidth] = useGlobalState('viewportWidth');
    const glyphInspectorState = useGlyphInspectorState();
    const { font, glyph: activeGlyph } = glyphInspectorState;

    const glyphCats = useCategorisedGlyphs(font);
    const visibleGlyphCats = glyphCats.filter(
        (glyphCat: GlyphCat): boolean =>
            !glyphCat.hidden && glyphCat.glyphs.length > 0,
    );

    const getGlyphsPerRow = React.useCallback(
        (glyphCount: number): number => {
            if (!viewportWidth || viewportWidth > GLYPH_INSPECTOR_BREAKPOINT) {
                return Math.min(glyphCount, DESKTOP_GLYPHS_PER_ROW);
            }
            const maxRows =
                glyphCount < MOBILE_MAX_GLYPH_ROWS ? 1 : MOBILE_MAX_GLYPH_ROWS;
            return Math.ceil(glyphCount / maxRows);
        },
        [viewportWidth],
    );

    return (
        <MainHolder>
            <SideGradient />
            <Container>
                {font &&
                    visibleGlyphCats.map(
                        (
                            glyphCat: GlyphCat,
                            catIdx: number,
                        ): React.ReactElement => {
                            const definedGlyphs =
                                glyphCat.glyphs.filter(notUndefined);
                            const glyphsPerRow = getGlyphsPerRow(
                                definedGlyphs.length,
                            );
                            const title =
                                !glyphCat.title && catIdx === 0
                                    ? 'Basic Character Set'
                                    : glyphCat.title;
                            const numRows = Math.ceil(
                                definedGlyphs.length / glyphsPerRow,
                            );
                            const hasHangingLastRow =
                                definedGlyphs.length % glyphsPerRow > 0;
                            return (
                                <ListItem
                                    key={glyphCat.title}
                                    $isLast={
                                        catIdx + 1 === visibleGlyphCats.length
                                    }
                                >
                                    <Heading>{title}</Heading>
                                    <Inner $columnCount={glyphsPerRow}>
                                        {definedGlyphs.map(
                                            (
                                                glyph: Glyph,
                                                glyphIdx,
                                            ): React.ReactElement => {
                                                const indexModulo =
                                                    (glyphIdx + 1) %
                                                    glyphsPerRow;
                                                const isLastOfRow =
                                                    glyphIdx + 1 ===
                                                        definedGlyphs.length ||
                                                    indexModulo === 0;
                                                const isFirstOfRow =
                                                    glyphIdx === 0 ||
                                                    indexModulo === 1;
                                                const rowNumber =
                                                    Math.floor(
                                                        glyphIdx / glyphsPerRow,
                                                    ) + 1;
                                                const isFirstRow =
                                                    rowNumber === 1;
                                                const isLastRow =
                                                    rowNumber === numRows;
                                                const borderRadius = {
                                                    topLeft:
                                                        isFirstRow &&
                                                        isFirstOfRow,
                                                    bottomRight:
                                                        isLastOfRow &&
                                                        rowNumber >=
                                                            numRows -
                                                                (hasHangingLastRow
                                                                    ? 1
                                                                    : 0),
                                                    topRight:
                                                        isFirstRow &&
                                                        isLastOfRow,
                                                    bottomLeft:
                                                        isLastRow &&
                                                        isFirstOfRow,
                                                };
                                                return (
                                                    <GlyphInspectorGlyphListItem
                                                        key={glyph.index}
                                                        glyph={glyph}
                                                        unitsPerEm={
                                                            font.unitsPerEm
                                                        }
                                                        capHeight={
                                                            font.tables.os2
                                                                .sCapHeight
                                                        }
                                                        active={
                                                            activeGlyph ===
                                                            glyph
                                                        }
                                                        borderRadius={
                                                            borderRadius
                                                        }
                                                    />
                                                );
                                            },
                                        )}
                                    </Inner>
                                </ListItem>
                            );
                        },
                    )}
            </Container>
            <SideGradient $isRight />
        </MainHolder>
    );
}
