import type { FontFamily, FontFamilyGroup } from '../hooks/useAllFonts';
import { FontProductTypeChoice } from '../gql/api-public';
import type { FontProduct } from '../hooks/useCartQuery';

const matchesFontId = (font: FontProduct, fontFamily: FontFamily): boolean =>
    font.fontProductType === FontProductTypeChoice.FONTFAMILY &&
    (fontFamily.id as string) === font.fontId;

const matchesFontStyle = (font: FontProduct, fontFamily: FontFamily): boolean =>
    font.fontProductType === FontProductTypeChoice.FONTSTYLE &&
    fontFamily.fontStyles.findIndex(
        (style) => (style.id as string) === font.fontId,
    ) !== -1;

export default function getFontFamilyFromFontProduct(
    font: FontProduct,
    fontFamilyGroups: FontFamilyGroup[],
): FontFamily | undefined {
    return fontFamilyGroups
        .reduce(
            (carry: FontFamily[], fontFamilyGroup): FontFamily[] => [
                ...carry,
                ...fontFamilyGroup.fontFamilies,
            ],
            [],
        )
        .find(
            (family: FontFamily): boolean =>
                matchesFontId(font, family) || matchesFontStyle(font, family),
        );
}
