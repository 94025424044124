import React from 'react';
import styled from 'styled-components';
import { styles as inlineUnderlinedStyles } from './InlineUnderlinedLink';
import FragmentLink from './FragmentLink';
import { VIEWPORT } from '../settings/Global';
import { useEula } from './PageContext';

const Container = styled.ol`
    list-style-type: decimal;
    margin-left: calc(-1 * var(--gridColumnGap));

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-left: 0;
        list-style-position: inside;
    }
`;

const Li = styled.li`
    position: relative;
    margin-bottom: 4px;
    padding-left: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-left: 0;
    }
`;

const ScrollableLink = styled(FragmentLink)`
    ${inlineUnderlinedStyles};

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-left: var(--spacing1);
    }
`;

export default function LicenceSectionLinks(): React.ReactElement {
    const eula = useEula();
    return (
        <Container>
            {eula.sections.map((section): React.ReactElement => {
                return (
                    <Li key={`eulaSectionTocLink${section.id}`}>
                        <ScrollableLink href={`#${section.id}`}>
                            {section.title}
                        </ScrollableLink>
                    </Li>
                );
            })}
        </Container>
    );
}
