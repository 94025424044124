import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { VIEWPORT } from '../settings/Global';
import FragmentLink from './FragmentLink';

export const FontStylesContainer = styled.div`
    --gap: var(--spacing3);
    --gapSmall: var(--spacing1);

    margin-left: calc(-1 * var(--gap));
    margin-right: 0;
    margin-bottom: var(--spacing1);
    line-height: 1em;
    overflow: hidden;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-left: calc(-1 * var(--gapSmall));
    }
`;

export const FontStylesItem = styled.span<{
    $fontSizeMultiplier?: number;
}>`
    white-space: nowrap;
    display: inline-block;
    margin-left: var(--gap);
    margin-bottom: var(--gap);

    --baseFontSize: var(--fontSizeFluid6);

    color: inherit;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        --baseFontSize: var(--fontSizeFluid5);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-left: var(--gapSmall);
    }

    font-size: ${({ $fontSizeMultiplier }): string =>
        $fontSizeMultiplier && $fontSizeMultiplier != 1
            ? `calc(var(--baseFontSize) * ${$fontSizeMultiplier});`
            : 'var(--baseFontSize)'};
`;

export const FontStylesTitle = styled.span<{
    $fontFamily?: string;
}>`
    line-height: var(--lineHeightHeading1);
    font-family: ${({ $fontFamily }): string => $fontFamily || 'initial'};
    pointer-events: none;
`;

export const FontStylesWeight = styled.span`
    font-size: var(--fontSizeFixed1);
    line-height: 1;
    color: var(--foregroundColorMix6);
    vertical-align: top;
    padding-left: 6px;
    pointer-events: none;
    font-feature-settings: 'tnum';
`;

const linkStyles = css`
    color: var(--foregroundColor);

    &:hover,
    &:focus,
    &:active {
        color: var(--foregroundColorMix5);
        border-color: var(--foregroundColorMix5);
    }
`;

export const FontStylesFragmentLink = styled(FragmentLink)`
    display: block;
    ${linkStyles};
`;

export const FontStylesLink = styled(Link)`
    ${linkStyles};
`;
