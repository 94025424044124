import React from 'react';
import styled, { css } from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import InputSlider from './InputSlider';
import SVGIcon from './SVGIcon';
import { useLetterSpacingState } from './TypeEditorContext';
import { Div, LOZENGE_HORIZONTAL_PADDING } from './Lozenge';
import { TypeEditorActiveStatus } from './TypeEditorToolbar';

const Icon = styled(SVGIcon)<{ $activeStatus: TypeEditorActiveStatus }>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  visibility: hidden;
              `
            : null}
`;

const Slider = styled(InputSlider)<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  visibility: hidden;
              `
            : null}
`;

const Value = styled.div<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus === 'inactive'
            ? css`
                  color: var(--foregroundColorMix6);
              `
            : $activeStatus === 'hovered'
              ? css`
                    color: var(--foregroundColorMix4);
                `
              : null};

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;

const Container = styled(Div)<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    --lozengeMinWidth: 180px;
    --lozengeWidth: calc(15vw - var(--gridMarginGap));

    display: flex;
    flex-direction: row;
    gap: ${LOZENGE_HORIZONTAL_PADDING}px;
    align-items: center;
    font-feature-settings: 'tnum';

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        --lozengeMinWidth: 160px;
    }

    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  --lozengeColor: var(--foregroundColorMix6);
                  --lozengeBackgroundColor: transparent;

                  cursor: pointer;
              `
            : null}
`;

const TypeEditorLetterSpacingInput = React.forwardRef(
    (
        {
            activeStatus,
        }: {
            activeStatus: TypeEditorActiveStatus;
        },
        forwardedRef: React.Ref<HTMLSpanElement>,
    ): React.ReactElement => {
        const [letterSpacing, setLetterSpacing] = useLetterSpacingState();

        return (
            <Container $activeStatus={activeStatus} title="Letter spacing">
                <Icon type="LETTER_SPACING" $activeStatus={activeStatus} />
                <Slider
                    ref={forwardedRef}
                    onValueChange={(val): void =>
                        val.length ? setLetterSpacing(val[0]) : undefined
                    }
                    min={-0.1}
                    max={0.2}
                    value={[letterSpacing]}
                    step={0.001}
                    $activeStatus={activeStatus}
                />
                <Value $activeStatus={activeStatus}>
                    {letterSpacing.toFixed(3)}
                </Value>
            </Container>
        );
    },
);
TypeEditorLetterSpacingInput.displayName = 'TypeEditorLetterSpacingInput';

export default TypeEditorLetterSpacingInput;
