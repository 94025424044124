import { graphql, useStaticQuery } from 'gatsby';
import { ConfigQuery } from '../gql/api-ssr';

export type Config = ConfigQuery['ssr']['config'];
export type Country = Config['countries'][number];
export type StyleDiscountPercentage =
    Config['styleDiscountPercentages'][number];

export default function useConfig(): Config {
    return useStaticQuery<ConfigQuery>(graphql`
        query ConfigQuery {
            ssr {
                config {
                    userTestingFontFamilyGroupWhitelist {
                        id
                        slug
                    }
                    userTestingHostname
                    userTestingCheckoutAllowed
                    isProductionSite
                    unitPriceFontStyle
                    businessName
                    businessUrl
                    emailSupport
                    baseCurrency
                    buildDateTime
                    customOpentypeHtmlTagNames
                    awardPageIntro
                    eulas {
                        licenceTypeId
                        title
                        shortName
                    }
                    countries {
                        name
                        code
                        taxId
                    }
                    styleDiscountPercentages {
                        quantity
                        discount
                    }
                    packageDiscountPercentages {
                        fontProductType
                        discount
                    }
                    crossLicenceDiscountPercentage
                    licenceTypeShortNames
                }
            }
        }
    `).ssr.config;
}

export function useCountries(): Country[] {
    return useConfig().countries;
}
