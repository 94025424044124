import styled, { css } from 'styled-components';
import { preventPrintPageBreak } from '../utils/stylesMixins';

export const ParagraphExtraLargeStyles = css`
    font-size: var(--fontSizeFixed4);
    line-height: var(--lineHeightBody1);
    ${preventPrintPageBreak}
`;

export const ParagraphExtraLarge = styled.p`
    ${ParagraphExtraLargeStyles};
`;

export const ParagraphLarge = styled.p`
    font-size: var(--fontSizeFixed3);
    line-height: var(--lineHeightBody1);
    ${preventPrintPageBreak};
`;

export const ParagraphStyles = css`
    font-size: var(--fontSizeFixed2);
    line-height: var(--lineHeightBody2);
    ${preventPrintPageBreak};
`;

export const ParagraphMarginStyles = css`
    margin-bottom: calc(var(--lineHeightBody2) * var(--fontSizeFixed2));
`;

export const Paragraph = styled.p`
    ${ParagraphStyles};
`;

export const ParagraphSmall = styled.p`
    font-size: var(--fontSizeFixed1);
    line-height: var(--lineHeightBody2);
    ${preventPrintPageBreak};
`;

export default Paragraph;
