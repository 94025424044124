import { CurrencyChoices } from '../gql/api-public';

function getCurrencySymbol(
    currency: CurrencyChoices | null,
): string | undefined {
    switch (currency) {
        case CurrencyChoices.USD:
        case CurrencyChoices.NZD:
        case CurrencyChoices.AUD:
        case CurrencyChoices.CAD:
            return '$';
        case CurrencyChoices.GBP:
            return '£';
        case CurrencyChoices.EUR:
            return '€';
        case CurrencyChoices.JPY:
        case CurrencyChoices.CNY:
            return '¥';
    }
}

export default function printPrice(
    price: number,
    currency?: CurrencyChoices | null,
    hideDecimalsIfZero = true,
): string {
    if (!currency) {
        currency = CurrencyChoices.USD;
    }
    const [dollars, cents] = Math.abs(price)
        .toFixed(2)
        // Appends chunks of exactly-3-digits which are immediately followed by
        // a non-digit with a comma.
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        .split('.');

    // Only append cents if they are not '00'.
    let priceString =
        hideDecimalsIfZero && cents === '00'
            ? `${dollars}`
            : `${dollars}.${cents}`;

    // Use the correct currency symbol
    const currencySymbol = getCurrencySymbol(currency);
    if (currencySymbol) {
        priceString = `${currencySymbol}${priceString}`;
    }

    // Explicitly use the correct 'negative' sign (not a hyphen, which is JS default!)
    return price < 0 ? `−${priceString}` : priceString;
}
