import React from 'react';
import { useTextAlignState } from './TypeEditorContext';
import SVGIcon from './SVGIcon';
import {
    ButtonGroupToggleGroup,
    ButtonGroupToggleItem,
} from './TypeEditorToolbar';
import { Property } from 'csstype';

const TypeEditorTextAlignInput = React.forwardRef(
    (
        props: Record<string, never>,
        forwardedRef: React.Ref<HTMLDivElement>,
    ): React.ReactElement => {
        const [textAlign, setTextAlign] = useTextAlignState();

        // We no longer support justify alignment as it is buggy.
        // See:
        // https://github.com/facebook/draft-js/issues/1012
        // https://bugzilla.mozilla.org/show_bug.cgi?id=1253840
        // Please _do not_ force `white-space: normal` on the editor as this
        // workaround has unexpected outcomes, e.g. cursor jumping in Firefox
        // and white screen errors in mobile Safari.

        return (
            <ButtonGroupToggleGroup
                type="single"
                value={textAlign || 'left'}
                onValueChange={(value): void =>
                    setTextAlign(value as Property.TextAlign)
                }
                ref={forwardedRef}
            >
                <ButtonGroupToggleItem value="left" title="Align left">
                    <SVGIcon type="ALIGN_LEFT" />
                </ButtonGroupToggleItem>
                <ButtonGroupToggleItem value="center" title="Align center">
                    <SVGIcon type="ALIGN_CENTER" />
                </ButtonGroupToggleItem>
            </ButtonGroupToggleGroup>
        );
    },
);
TypeEditorTextAlignInput.displayName = 'TypeEditorTextAlignInput';
export default TypeEditorTextAlignInput;
