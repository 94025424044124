import React from 'react';
import {
    createEulaUrl,
    createHomeUrl,
    createSalesEmailUrl,
} from '../utils/urlHelper';
import InlineUnderlinedLink, {
    A as ExternalLink,
} from '../components/InlineUnderlinedLink';
import DefinitionList from '../components/DefinitionList';
import { LicenceTypeId } from '../gql/api-public';

interface DefinitionList {
    title: React.ReactNode;
    type: React.ReactNode;
    id?: string;
}

interface Section {
    id: string;
    heading: string;
    content: React.ReactNode;
    table: DefinitionList[];
}

const getEulaLink = (
    licenceTypeId: LicenceTypeId,
    titlePrefix: string,
): React.ReactNode => {
    return (
        <InlineUnderlinedLink to={createEulaUrl(licenceTypeId)}>
            {titlePrefix} Font Licence
        </InlineUnderlinedLink>
    );
};

const getSubjectToRestrictions = (
    licenceTypeId: LicenceTypeId,
): React.ReactNode => {
    return (
        <>
            Yes, subject to{' '}
            <InlineUnderlinedLink
                to={createEulaUrl(
                    licenceTypeId,
                    '#permitted-uses-restrictions',
                )}
            >
                restrictions
            </InlineUnderlinedLink>
        </>
    );
};

const licencePurchaseViaSite = (
    <>
        Via{' '}
        <InlineUnderlinedLink to={createHomeUrl()}>
            klim.co.nz
        </InlineUnderlinedLink>
    </>
);

const licencePurchaseViaRequest = (
    <>
        Via request,{' '}
        <ExternalLink href={createSalesEmailUrl(true)}>
            contact Klim
        </ExternalLink>{' '}
    </>
);

const shareFontsDevelopersAndThirdParties =
    'Yes, but only with developers or third-party services';

function getTable(
    licenceType: React.ReactNode,
    licencePurchase: React.ReactNode,
    pricing: React.ReactNode,
    fontFormat:
        | 'OTF'
        | 'WOFF2'
        | 'TTF'
        | 'OTF and WOFF2'
        | 'OTF, TTF and WOFF2',
    modifyFonts: React.ReactNode,
    embedFonts: React.ReactNode,
    shareFonts: React.ReactNode,
): DefinitionList[] {
    return [
        {
            title: 'Licence type',
            type: licenceType,
        },
        {
            title: 'Licence purchase',
            type: licencePurchase,
        },
        {
            title: 'Pricing',
            type: pricing,
        },
        { title: 'Font format', type: fontFormat },
        { title: 'Modify fonts', type: modifyFonts },
        { title: 'Embed fonts', type: embedFonts },
        { title: 'Share fonts', type: shareFonts },
    ];
}

const testFonts: Section = {
    id: 'test-fonts',
    heading: 'Test fonts',
    content: (
        <>
            Please note that test fonts can only be used within your
            organisation.
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.TEST, 'Test'),
        licencePurchaseViaSite,
        'Free',
        'OTF and WOFF2',
        'No',
        'No',
        'No',
    ),
};

const broadcast: Section = {
    id: 'broadcast',
    heading: 'Broadcasting',
    content: (
        <>
            You want to use the fonts across your broadcasting network or within
            a TV show/series, film/movie or other content intended for
            broadcast, as well as the related marketing communications to
            promote that content.
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.BROADCAST, 'Broadcasting'),
        licencePurchaseViaSite,
        'Based on production budget or audience size',
        'OTF',
        'No',
        'No',
        getSubjectToRestrictions(LicenceTypeId.BROADCAST),
    ),
};

const logosPrintMarketing: Section = {
    id: 'logos-print-marketing',
    heading: 'Logos, print & marketing material',
    content: (
        <>
            Design of: the production of paper publications, for example,
            newspapers, pamphlets, books and magazines; print advertising; vinyl
            or printed signage; posters and billboards; logos and logotypes;
            corporate documents; newsletters and annual reports; as well as
            images for print, web and social media (static images only);
            embedding in ePub publications; product/merchandise packaging;
            marketing materials; outdoor advertising (OOH); digital screens and
            billboards (non-interactive); presentations (e.g. Powerpoint); film
            and video (non-broadcast). You may use the fonts to display static
            images within online and social media advertisements (e.g. web
            banners). You may use the fonts for non-broadcast video content for
            your own business website or your own social media account(s). You
            are not entitled to use the fonts for any form of video content for
            paid advertising (e.g. a commercial), whether on social media
            platforms, online channels, TV networks, streaming services or
            otherwise. For this purpose, you would require a separate{' '}
            <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.AD)}>
                Advertising
            </InlineUnderlinedLink>{' '}
            licence.
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.DESKTOP, 'Desktop'),
        licencePurchaseViaSite,
        'Based on number of users',
        'OTF',
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
    ),
};

const internalCommunication: Section = {
    id: 'internal-communication',
    heading: 'Internal communication',
    content: <>Inter-office emails or other forms of internal communication.</>,
    table: getTable(
        getEulaLink(LicenceTypeId.DESKTOP, 'Desktop'),
        licencePurchaseViaSite,
        'Based on number of users',
        'OTF',
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
    ),
};
const designSoftware: Section = {
    id: 'design-software',
    heading: 'Design software',
    content: <>For using Sketch, InDesign, Illustrator or similar software.</>,
    table: getTable(
        getEulaLink(LicenceTypeId.DESKTOP, 'Desktop'),
        licencePurchaseViaSite,
        'Based on number of users',
        'OTF',
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
        getSubjectToRestrictions(LicenceTypeId.DESKTOP),
    ),
};

const embedDevices: Section = {
    id: 'embed',
    heading: 'Embed into devices',
    content: (
        <>
            You need to use the fonts within an operating system that enables a
            hardware device to run software applications and essential periphery
            services to provide a range of functions on the hardware device.
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.OEM, 'OEM'),
        licencePurchaseViaSite,
        'Based on number of devices',
        'OTF',
        'No',
        'Yes',
        'No',
    ),
};

const webText: Section = {
    id: 'web-text',
    heading: 'Web text',
    content: (
        <>
            Styling text on any website you own or have created that can be
            viewed or printed, but can’t be edited by the end users to create a
            new output (i.e. document or customised product).
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.WEB, 'Web'),
        licencePurchaseViaSite,
        'Based on page views or unique users',
        'WOFF2',
        'No',
        'No',
        shareFontsDevelopersAndThirdParties,
    ),
};

const dynamicWebBased: Section = {
    id: 'dynamic-web-ads',
    heading: 'Dynamic web-based advertising',
    content: (
        <>
            Styling text in dynamic web-based advertising content (e.g.
            real-time updates) that require live web fonts to be served.
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.WEB, 'Web'),
        licencePurchaseViaSite,
        <>
            Based on page views or unique users
            <br />
            <br />
            (Please note: your web font licence must cover your use of the fonts
            across your website and any ad impressions - as per media buy - for
            your dynamic web-based advertising, see the licence terms for
            details.)
        </>,
        'WOFF2',
        'No',
        'No',
        shareFontsDevelopersAndThirdParties,
    ),
};

const emailMarketing: Section = {
    id: 'email-marketing',
    heading: 'Email marketing',
    content: (
        <>
            Use of fonts in email template communications to an external
            subscriber database (e.g. via Campaign Monitor or MailChimp).
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.WEB, 'Web'),
        licencePurchaseViaSite,
        <>
            Based on page views or unique users
            <br />
            <br />
            (Please note: your web font licence must cover your use of the fonts
            across your website and any number of active email subscribers in
            your database, see the licence terms for details.)
        </>,
        'WOFF2',
        'No',
        'No',
        shareFontsDevelopersAndThirdParties,
    ),
};

const unlimitedWeb: Section = {
    id: 'unlimited-web',
    heading: 'Unlimited web',
    content: (
        <>
            You need to use the fonts on unlimited domains or websites, with
            unlimited page views, and need to provide to them to affiliates,
            partners, agencies and vendors and people outside your organisation
            (e.g. contractors or freelancers) for purposes directly related to
            your business. You also may wish to use the web fonts for styling
            text in dynamic web-based advertising content (e.g. real-time
            updates), and/or in email template communications to external
            databases (e.g. via Campaign Monitor or MailChimp) in an unlimited
            capacity.
        </>
    ),
    table: getTable(
        'Unlimited Web Font Licence',
        licencePurchaseViaRequest,
        'Bespoke',
        'WOFF2',
        'No',
        'No',
        'Yes, subject to restrictions (see licence terms)',
    ),
};

const webApplication: Section = {
    id: 'web-app',
    heading: 'Web application',
    content: (
        <>
            Styling, viewing or editing text in a web-based application that
            can’t be edited by the end users to create a new output (i.e.
            document or customised product).
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.APP, 'App'),
        licencePurchaseViaSite,
        'Based on monthly active users (MAUs) or total downloads',
        'TTF',
        'No',
        'Yes',
        shareFontsDevelopersAndThirdParties,
    ),
};

const mobileApplication: Section = {
    id: 'mobile-app',
    heading: 'Mobile application',
    content: (
        <>
            Any application designed for mobile operating systems but can’t be
            edited by the end users to create a new output (i.e. document or
            customised product).
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.APP, 'App'),
        licencePurchaseViaSite,
        'Based on monthly active users (MAUs) or total downloads',
        'TTF',
        'No',
        'Yes',
        shareFontsDevelopersAndThirdParties,
    ),
};

const exhibitionApplication: Section = {
    id: 'exhibition-app',
    heading: 'Exhibition application',
    content: (
        <>
            Displaying an application you own at a public exhibition, end users
            are unable to edit or print.
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.APP, 'App'),
        licencePurchaseViaSite,
        'Based on monthly active users (MAUs) or total downloads',
        'TTF',
        'No',
        'Yes',
        shareFontsDevelopersAndThirdParties,
    ),
};

const desktopApplication: Section = {
    id: 'desktop-app',
    heading: 'Desktop application',
    content: (
        <>
            Any application designed for desktop operating systems but can’t be
            edited by the end users to create a new output (i.e. document or
            customised product).
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.APP, 'App'),
        licencePurchaseViaSite,
        'Based on monthly active users (MAUs) or total downloads',
        'TTF',
        'No',
        'Yes',
        shareFontsDevelopersAndThirdParties,
    ),
};

const unlimitedApplication: Section = {
    id: 'unlimited-app',
    heading: 'Unlimited application',
    content: (
        <>
            You need to use the fonts on unlimited apps (web-based, desktop and
            mobile apps), with unlimited MAUs/Downloads, and need to provide the
            fonts to affiliates, partners, agencies and vendors and people
            outside your organisation (e.g. contractors or freelancers) for
            purposes directly related to your business.
        </>
    ),
    table: getTable(
        'Unlimited App Font Licence',
        licencePurchaseViaRequest,
        'Bespoke',
        'TTF',
        'No',
        'Yes',
        'Yes, subject to restrictions (see licence terms)',
    ),
};

const enterprise: Section = {
    id: 'enterprise',
    heading: 'Enterprise',
    content: (
        <>
            You need to distribute the fonts to a wide range of people (e.g.
            affiliates, agencies, partners, vendors, contractors, freelancers)
            for purposes directly related to your business, and use them across
            various mediums, such as print media, online advertising, websites
            and apps, without restriction.
        </>
    ),
    table: getTable(
        'Enterprise Font Licence',
        licencePurchaseViaRequest,
        'Bespoke',
        'OTF, TTF and WOFF2',
        'Yes',
        'Yes',
        'Yes',
    ),
};

const advertisingCommercials: Section = {
    id: 'advertising-commercials',
    heading: 'Advertising commercials',
    content: (
        <>
            You want to use the fonts within TV commercials or promotional
            videos/films for campaigns that are intended for paid advertising
            placements online, via social media and/or on TV.
            <br />
            <br />
            Note: Along with an {getEulaLink(
                LicenceTypeId.AD,
                'Advertising',
            )}{' '}
            you’ll need to own a {getEulaLink(LicenceTypeId.DESKTOP, 'Desktop')}{' '}
            for the fonts you want to use. You will not receive separate font
            downloads when you purchase this licence. For all of your
            advertising content needs, you can use the .otf font files received
            with your purchase of a Desktop Font Licence.
        </>
    ),
    table: getTable(
        getEulaLink(LicenceTypeId.AD, 'Advertising'),
        licencePurchaseViaSite,
        'Based on monthly impressions (as per your media buy)',
        'OTF',
        'No',
        'No',
        getSubjectToRestrictions(LicenceTypeId.AD),
    ),
};

const items: Section[] = [
    testFonts,
    logosPrintMarketing,
    internalCommunication,
    designSoftware,
    embedDevices,
    webText,
    dynamicWebBased,
    emailMarketing,
    unlimitedWeb,
    webApplication,
    mobileApplication,
    exhibitionApplication,
    desktopApplication,
    unlimitedApplication,
    advertisingCommercials,
    broadcast,
    enterprise,
];

export default items;
