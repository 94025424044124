/**
 * There is a special rendering case for fonts like Calibre which have only
 * a single family, in which case we shouldn't render a FontSelectorInput
 * for the 'collection' etc.
 */
export default function isCalibreSpecialCase(fontFamilyGroup: {
    fontFamilies: unknown[];
}): boolean {
    return fontFamilyGroup.fontFamilies.length === 1;
}
