import { Cart } from './useCartQuery';

export default function useIsCartEmpty(cart: Cart | null | undefined): boolean {
    if (!cart || !cart.items.length) {
        return true;
    }
    const hasItemsWithoutFonts = cart.items.some((item) => !item.font);
    if (hasItemsWithoutFonts) {
        return false;
    }
    // If all items in the cart have fonts, then we also need licence tiers
    return !cart.licenceTiers.length;
}
