/*
 * Starting from a random+arbitrary index, start concatenating sentences until
 * the chartacterCount is fulfilled.
 */

const BAILOUT_DURATION = 250; // ms

export default function createRandomTextFromSpecimenSet({
    sentences,
    characterCount,
}: {
    sentences: string[];
    characterCount: number;
}): string {
    let text = '';
    // Starting index is random.
    let index = Math.floor(Math.random() * sentences.length);

    // Protection against infinite loops.
    const bailoutTime = Date.now() + BAILOUT_DURATION;

    while (Date.now() < bailoutTime && text.length < characterCount) {
        // modulus ensures we loop back to start of array.
        const sentence = sentences[index % sentences.length];

        // Put spaces between sentences.
        if (text) {
            text += ' ';
        }

        // Concatenate, and increment.
        text += sentence;
        ++index;
    }

    return text;
}
