import React from 'react';
import styled from 'styled-components';
import Link from './InlineUnderlinedLink';
import { Paragraph } from './Paragraph';
import { createFontFamilyUrl } from '../utils/urlHelper';
import sampleArray from '../utils/sampleArray';
import { HeadingSmall } from './Heading';

const Container = styled.div``;

interface Suggestion {
    name: string;
    slug: string;
    description: string;
}

const SUGGESTIONS: Suggestion[] = [
    {
        name: 'Calibre',
        slug: 'calibre',
        description:
            'It’s a neo-grotesque sans-serif inspired by engineered geometry.',
    },
    {
        name: 'Domaine Display',
        slug: 'domaine-display',
        description:
            'It’s a sharp and refined serif with elegant detailing for large sizes.',
    },
    {
        name: 'Domaine Sans Display',
        slug: 'domaine-sans-display',
        description: 'It’s a refined, high-contrast sans serif.',
    },
    {
        name: 'Domaine Sans Text',
        slug: 'domaine-sans-text',
        description:
            'It’s a refined, contrasted sans serif for text typography.',
    },
    {
        name: 'Domaine Text',
        slug: 'domaine-text',
        description:
            'It’s a sharp and robust serif with elegant detailing for small sizes.',
    },
    {
        name: 'Financier Display',
        slug: 'financier-display',
        description: 'It’s a classic serif drawn for contemporary typography.',
    },
    {
        name: 'Financier Text',
        slug: 'financier-text',
        description:
            'It’s a classic serif drawn for contemporary text typography.',
    },
    {
        name: 'Founders Grotesk',
        slug: 'founders-grotesk',
        description:
            'It’s a contemporary amalgamation of classic British and German grotesks.',
    },
    {
        name: 'Founders Grotesk Condensed',
        slug: 'founders-grotesk-condensed',
        description:
            'Its square inner counters contrast nicely with warm outer curves.',
    },
    {
        name: 'Founders Grotesk Mono',
        slug: 'founders-grotesk-mono',
        description: 'It’s a flavoursome grotesk with a typewriter feel.',
    },
    {
        name: 'Founders Grotesk Text',
        slug: 'founders-grotesk-text',
        description:
            'It’s a flavoursome grotesk that works hard at small sizes.',
    },
    {
        name: 'Karbon',
        slug: 'karbon',
        description:
            'It’s an open, geometric sans serif with a contemporary spartan finish.',
    },
    {
        name: 'Maelstrom',
        slug: 'maelstrom',
        description: 'It’s a forceful, reversed-contrast display typeface.',
    },
    {
        name: 'Metric',
        slug: 'metric',
        description:
            'It’s a geometric humanist sans-serif, sired by West Berlin street signs.',
    },
    {
        name: 'National',
        slug: 'national',
        description:
            'It’s a deceptively simple sans serif with a distinctive — but not distracting — personality.',
    },
    {
        name: 'Pitch',
        slug: 'pitch',
        description: 'It’s a modern love-letter to the typewriter.',
    },
    {
        name: 'Tiempos Text',
        slug: 'tiempos-text',
        description:
            'It’s a modern serif for economic and legible typesetting.',
    },
];

export default function CartSummaryEmpty(): React.ReactElement | null {
    const suggestion = React.useMemo(
        (): Suggestion | undefined => sampleArray(SUGGESTIONS),
        [],
    );

    return (
        <Container>
            <HeadingSmall>Your cart is empty</HeadingSmall>
            <br />
            {suggestion ? (
                <>
                    <Paragraph>
                        What about{' '}
                        <Link to={createFontFamilyUrl(suggestion.slug)}>
                            {suggestion.name}
                        </Link>
                        ?
                    </Paragraph>
                    <br />
                    <Paragraph>{suggestion.description}</Paragraph>
                </>
            ) : null}
        </Container>
    );
}
