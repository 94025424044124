import React from 'react';
import 'styled-components';
import type { DefaultTheme } from 'styled-components';
import { COLOR } from '../settings/Global';
import { ThemeProvider, ThemeContext } from 'styled-components';
import sessionStorage from '../utils/sessionStorage';
import { useGlobalState } from './GlobalRuntimeState';
import isBrowser from '../utils/isBrowser';
import useKeyPress from '../hooks/useKeyPress';

declare module 'styled-components' {
    export interface DefaultTheme {
        foregroundColor: string;
        backgroundColor: string;
        highlightColor: string;
        foregroundColorAlt?: string | null;
        backgroundColorAlt?: string | null;
        highlightColorAlt?: string | null;
        backgroundLuminance: number;
        foregroundLuminance: number;
        contrastRatio: number;
    }
}

export type SimpleColorScheme = Pick<
    DefaultTheme,
    | 'foregroundColor'
    | 'backgroundColor'
    | 'highlightColor'
    | 'foregroundColorAlt'
    | 'backgroundColorAlt'
    | 'highlightColorAlt'
>;

export const darkColorScheme: DefaultTheme = {
    foregroundColor: COLOR.WHITE,
    backgroundColor: COLOR.BLACK,
    highlightColor: COLOR.RED,
    backgroundLuminance: 0,
    foregroundLuminance: 1,
    contrastRatio: 21,
};

export const lightColorScheme: DefaultTheme = {
    foregroundColor: COLOR.BLACK,
    backgroundColor: COLOR.WHITE,
    highlightColor: COLOR.RED,
    backgroundLuminance: 1,
    foregroundLuminance: 0,
    contrastRatio: 21,
};

export const muellerColorScheme: DefaultTheme = {
    foregroundColor: COLOR.BLACK,
    backgroundColor: COLOR.MUELLER,
    highlightColor: COLOR.WHITE,
    backgroundLuminance: 0.247,
    foregroundLuminance: 0,
    contrastRatio: 5.94,
};

export const defaultColorSchemes: DefaultTheme[] = [
    darkColorScheme,
    lightColorScheme,
    muellerColorScheme,
];

interface ProviderProps {
    colorSchemes: DefaultTheme[];
    persistKey?: string;
    children: React.ReactElement;
}

const CycleColorSchemeContext = React.createContext<(() => void) | null>(null);

const SESSION_KEY_COLOR_SCHEME_INDEX = 'ColorSchemeContext@colorSchemeIndex';
const SESSION_KEY_PERSIST_KEY = 'ColorSchemeContext@persistKey';

const getColorSchemeIndexFromSession = (): number | undefined => {
    const colorSchemeIndex = JSON.parse(
        sessionStorage.getItem(SESSION_KEY_COLOR_SCHEME_INDEX) || `""`,
    );
    if (typeof colorSchemeIndex === 'number') {
        return colorSchemeIndex;
    }
    return;
};

function getPersistKeyFromSession(): string | undefined {
    return JSON.parse(sessionStorage.getItem(SESSION_KEY_PERSIST_KEY) || `""`);
}

function setIndexToSession(colorSchemeIndex: number, persistKey: string): void {
    sessionStorage.setItem(
        SESSION_KEY_COLOR_SCHEME_INDEX,
        JSON.stringify(colorSchemeIndex),
    );
    sessionStorage.setItem(SESSION_KEY_PERSIST_KEY, JSON.stringify(persistKey));
}

function updateThemeColor(themeColor: string): void {
    if (isBrowser()) {
        document
            .querySelector('meta[name="theme-color"]')
            ?.setAttribute('content', themeColor);
    }
}

export function Provider({
    children,
    colorSchemes,
    persistKey = 'default',
}: ProviderProps): React.ReactElement {
    const [adminColorScheme, setAdminColorScheme] =
        useGlobalState('adminColorScheme');

    /*
     * Ref to store the *previous* value of the `persistKey` prop, so that we
     * can compare it against the current value and identify when it has
     * changed.
     */
    const persistKeyRef = React.useRef<string>(persistKey);

    /*
     * State to count how many times the user has changed the theme.
     */
    const [increments, setIncrements] = React.useState<number>((): number => {
        /*
         * Initial value should be the session-stored value IF the
         * `persistKey` matches the session-stored `persistKey`.
         */
        if (persistKeyRef.current === getPersistKeyFromSession()) {
            return getColorSchemeIndexFromSession() || 0;
        }
        return 0;
    });

    /*
     * Ref to store a snapshot value of the 'increments' state whenever the
     * persistKey changes.
     */
    const incrementsRef = React.useRef<number>(0);

    /*
     * If the persistKey has changed, then we need to reset the Refs defined
     * above.
     */
    if (persistKey !== persistKeyRef.current) {
        incrementsRef.current = increments;
        persistKeyRef.current = persistKey;
    }

    /*
     * How many times has the user incremented the theme since the last time
     * that the persistKey was updated?
     */
    const currentIndex =
        (increments - incrementsRef.current) % colorSchemes.length;

    /**
     * This syntax doesn't cater for the case when currentIndex is a negative
     * number:
     *
     * const colorScheme = colorSchemes[currentIndex];
     *
     * … this syntax does, though:
     */
    const [colorScheme] = colorSchemes.slice(currentIndex);

    const cycleColorScheme = (): void => {
        setAdminColorScheme(undefined);
        setIndexToSession((currentIndex + 1) % colorSchemes.length, persistKey);
        setIncrements((state): number => (state + 1) % colorSchemes.length);
    };

    // Allow the theme to be cycled with keypress
    useKeyPress({
        key: {
            keyCode: 84,
            key: 't',
            code: 'KeyT',
        },
        shiftKey: true,
        ctrlKey: true,
        altKey: true,
        preventDefault: true,
        callback: cycleColorScheme,
        postInitCallback: () =>
            // eslint-disable-next-line no-console
            console.log('Press Ctrl+Alt+Shift+t to toggle the Theme'),
    });

    // Update the theme colour in the document head
    const themeToApply = adminColorScheme || colorScheme;
    React.useEffect(() => {
        updateThemeColor(themeToApply.backgroundColor);
    }, [themeToApply.backgroundColor]);

    return (
        <ThemeProvider theme={adminColorScheme || colorScheme}>
            <CycleColorSchemeContext.Provider value={cycleColorScheme}>
                {children}
            </CycleColorSchemeContext.Provider>
        </ThemeProvider>
    );
}

export const useCurrentColorScheme = (): DefaultTheme => {
    const context = React.useContext(ThemeContext);
    if (!context) {
        throw new Error('useCurrentColorScheme used outside of Provider.');
    }
    return context;
};

export const useCycleColorScheme = (): (() => void) => {
    const context = React.useContext(CycleColorSchemeContext);
    if (!context) {
        throw new Error('useCycleColorScheme used outside of Provider.');
    }
    return context;
};
