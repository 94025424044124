import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import type { ActiveLicenceTypesQuery } from '../gql/api-ssr';

export type LicenceType =
    ActiveLicenceTypesQuery['ssr']['activeLicenceTypes'][number];
export type TieredLicence = LicenceType['tieredLicensing'][number];
export type Tier = TieredLicence['tiers'][number];

export default function useActiveLicenceTypes(): LicenceType[] {
    const query = useStaticQuery<ActiveLicenceTypesQuery>(graphql`
        query ActiveLicenceTypesQuery {
            ssr {
                activeLicenceTypes {
                    id
                    name
                    isAvailableViaCart
                    tieredLicensing {
                        id
                        title
                        isActive
                        title
                        unitStr
                        unitStrPlural
                        tiers {
                            id
                            title
                            titleAbbrev
                            amount
                            multiplier
                            isActive
                            isUnlimited
                            licenceType {
                                id
                            }
                            tieredLicence {
                                id
                            }
                        }
                    }
                }
            }
        }
    `);

    return query.ssr.activeLicenceTypes || [];
}

export function useActiveLicenceTypesAvailableViaCart(): LicenceType[] {
    return useActiveLicenceTypes().filter(
        (licenceType) => licenceType.isAvailableViaCart,
    );
}

const getActiveTieredLicencesForLicenceType = (
    activeLicenceTypes: LicenceType[],
    licenceType: LicenceType,
): TieredLicence[] => {
    return activeLicenceTypes
        .filter((activeLicenceType) => activeLicenceType.id === licenceType.id)
        .map((activeLicenceType) => activeLicenceType.tieredLicensing)
        .flat()
        .filter((tieredLicensing) => tieredLicensing.isActive);
};

export function useActiveTieredLicensingsForLicenceType(
    licenceType: LicenceType,
): TieredLicence[] {
    const activeLicenceTypes = useActiveLicenceTypes();
    return React.useMemo(
        () =>
            getActiveTieredLicencesForLicenceType(
                activeLicenceTypes,
                licenceType,
            ),
        [activeLicenceTypes, licenceType],
    );
}
