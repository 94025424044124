import notNull from './notNull';
import getWordCount from './getWordCount';
import type {
    BlogPost,
    BlogPostBody,
    BlogImageBlock,
} from '../union-types/blogPost';

function getBlockWordCount(block: BlogPostBody): number {
    if ('quote' in block && 'caption' in block) {
        return getWordCount(`${block.quote} ${block.caption}`, true);
    }
    if ('caption' in block) {
        return getWordCount(block.caption || '', true);
    }
    if ('title' in block) {
        return getWordCount(block.title || '');
    }
    if ('question' in block && 'answer' in block) {
        return getWordCount(`${block.question} ${block.answer}`, true);
    }
    if ('textContent' in block || 'footNotes' in block) {
        return getWordCount(
            `${'textContent' in block ? block.textContent : ''} ${
                block.footNotes || ''
            }`,
            true,
        );
    }
    // return 0 if none of the above conditions are met
    return 0;
}

/**
 * Returns number of words in a blog post.
 */
export default function getBlogPostWordCount(
    blogPosts: BlogPost[],
    excludeUnlistedPosts = true,
): number {
    let wordCount = 0;
    blogPosts
        .filter(notNull)
        .filter(
            (blogPost): boolean => !excludeUnlistedPosts || !blogPost.noindex,
        )
        .forEach((blogPost): void => {
            wordCount += blogPost.intro ? getWordCount(blogPost.intro) : 0;

            const body = blogPost.body ? (blogPost.body as BlogPostBody[]) : [];
            body.forEach((bodyBlock) => {
                // Get word count from various bodyBlock types
                wordCount += getBlockWordCount(bodyBlock);

                if ('content' in bodyBlock) {
                    const content = bodyBlock.content ? bodyBlock.content : [];

                    content.forEach((contentBlock) => {
                        if (
                            !contentBlock ||
                            !['CMS_SSR_ImageBlock', 'ImageBlock'].includes(
                                contentBlock.__typename,
                            )
                        ) {
                            return;
                        }
                        const captionBlock = contentBlock as BlogImageBlock;
                        if (!captionBlock || !captionBlock.caption) {
                            return;
                        }
                        wordCount += getWordCount(captionBlock.caption, true);
                    });
                }
            });
        });
    return wordCount;
}
