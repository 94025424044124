import React from 'react';
import styled from 'styled-components';
import InlineUnderlinedLink, {
    Button as InlineUnderlinedButton,
} from './InlineUnderlinedLink';
import { HeadingSmall } from './Heading';
import AccountUserDetailsForm from './AccountUserDetailsForm';
import { User } from '../hooks/useUserQuery';
import { createLogoutUrl, createRevertLoginUrl } from '../utils/urlHelper';
import notNull from '../utils/notNull';
import { useCountries } from '../hooks/useConfig';
import { VIEWPORT } from '../settings/Global';
import { TEST_ID } from '../settings/E2e';

const Container = styled.div`
    display: grid;
    grid-gap: var(--spacing1) var(--gridColumnGap);
    grid-template-columns: var(--gridTemplateColumnsDefault);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing3);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        word-wrap: break-word;
    }

    ${HeadingSmall} {
        grid-column: 1 / span 1;

        /* set the line-height here to prevent heading from growing grid row */
        line-height: var(--lineHeightHeading1);

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            display: none;
        }
    }

    ${InlineUnderlinedButton} {
        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            margin-top: var(--spacing3);
        }
    }
`;

const Column = styled.div`
    &:nth-child(1) {
        grid-column: 3 / span 6;
    }

    &:nth-child(2) {
        grid-column: 10 / span 1;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:nth-child(1) {
            grid-column: 1 / span var(--gridColumnCount);
        }

        &:nth-child(2) {
            display: none;
        }
    }
`;

const Type = styled.div`
    grid-column: 3 / span 2;
    color: var(--foregroundColorMix4);
    line-height: var(--lineHeightBody2);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 2;
    }
`;

const Details = styled.div`
    grid-column: 5 / span 3;
    line-height: var(--lineHeightBody2);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / span 3;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 4 / span 3;
    }
`;

const EditMobile = styled.div`
    display: none;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: block;
        grid-column: 4 / span 3;
    }
`;

const Action = styled.div`
    grid-column: 10 / span 3;
    display: block;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 6 / span 1;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: none;
    }
`;

const ActionMobile = styled.div`
    display: none;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: block;
        margin-bottom: var(--spacing4);
    }
`;

interface UserContentBlockProps {
    details: React.ReactNode;
    type?: string;
    action?: React.ReactNode;
    heading?: React.ReactNode;
    actionMobile?: React.ReactNode;
    editMobile?: React.ReactNode;
}

function UserContentBlock({
    type,
    details,
    action,
    heading,
    actionMobile,
    editMobile,
}: UserContentBlockProps): React.ReactElement {
    return (
        <React.Fragment>
            {actionMobile && <ActionMobile>{actionMobile}</ActionMobile>}
            {heading && <HeadingSmall>{heading}</HeadingSmall>}
            {type && <Type>{type}</Type>}
            {details && <Details>{details}</Details>}
            {action && <Action>{action}</Action>}
            {editMobile && <EditMobile>{editMobile}</EditMobile>}
        </React.Fragment>
    );
}

interface AccountUserDetailsProps {
    user: User;
}

function AccountUserDetails({
    user,
}: AccountUserDetailsProps): React.ReactElement {
    const [isEditable, setIsEditable] = React.useState<boolean>(false);
    const countries = useCountries();

    const billingCountry = countries.find(
        (country): boolean => (country.code as string) === user.billingCountry,
    );

    const userDetailsGroups = [
        {
            heading: 'Details',
            actionMobile: !isEditable && (
                <>
                    <InlineUnderlinedLink to={createLogoutUrl()}>
                        Logout
                    </InlineUnderlinedLink>
                    {user.canRevert && (
                        <>
                            <br />
                            <InlineUnderlinedLink to={createRevertLoginUrl()}>
                                Revert login
                            </InlineUnderlinedLink>
                        </>
                    )}
                </>
            ),

            name: 'Name',
            userName: user.fullName,
            action: !isEditable && (
                <>
                    <InlineUnderlinedLink to={createLogoutUrl()}>
                        Logout
                    </InlineUnderlinedLink>
                    {user.canRevert && (
                        <>
                            <br />
                            <InlineUnderlinedLink to={createRevertLoginUrl()}>
                                Revert login
                            </InlineUnderlinedLink>
                        </>
                    )}
                </>
            ),
        },
        {
            email: 'Email',
            userEmail: user.email,
            action: !isEditable && (
                <InlineUnderlinedButton
                    onClick={(): void => setIsEditable(true)}
                >
                    Edit details
                </InlineUnderlinedButton>
            ),
        },
        {
            subscribe: 'Newsletter',
            userSubscribe: (
                <Details>
                    {user.emailOptIn ? `Subscribed` : `Unsubscribed`}
                </Details>
            ),
        },
        {
            address: 'Billing address',
            userAddress: [
                user.billingCompanyName,
                user.billingAddress1,
                user.billingAddress2,
                user.billingCity,
                [user.billingState, user.billingPostcode]
                    .filter(Boolean)
                    .join(' '),
                billingCountry ? billingCountry.name : null,
            ]
                .filter(Boolean) // empty strings specifically
                .filter(notNull) // Typecasting
                .map(
                    (item: string, index): React.ReactElement => (
                        <React.Fragment key={`item-${index}`}>
                            {item}
                            <br />
                        </React.Fragment>
                    ),
                ),
            editDetails: !isEditable && (
                <InlineUnderlinedButton
                    onClick={(): void => setIsEditable(true)}
                >
                    Edit details
                </InlineUnderlinedButton>
            ),
        },
    ];

    return (
        <Container data-cy={TEST_ID.ACCOUNT_USER_DETAILS}>
            {isEditable ? (
                <>
                    <Column>
                        <AccountUserDetailsForm
                            user={user}
                            onComplete={(): void => setIsEditable(false)}
                        />
                    </Column>
                    <Column>
                        <InlineUnderlinedLink to={createLogoutUrl()}>
                            Logout
                        </InlineUnderlinedLink>
                        {user.canRevert && (
                            <>
                                <br />
                                <InlineUnderlinedLink
                                    to={createRevertLoginUrl()}
                                >
                                    Revert login
                                </InlineUnderlinedLink>
                            </>
                        )}
                    </Column>
                </>
            ) : (
                userDetailsGroups.map(
                    (
                        {
                            heading,
                            name,
                            actionMobile,
                            userName,
                            action,
                            email,
                            userEmail,
                            subscribe,
                            address,
                            userAddress,
                            userSubscribe,
                            editDetails,
                        },
                        index,
                    ): React.ReactElement => (
                        <React.Fragment key={`block-${index}`}>
                            <UserContentBlock
                                heading={heading}
                                actionMobile={actionMobile}
                                type={name}
                                details={userName}
                            />
                            <UserContentBlock
                                type={subscribe}
                                details={userSubscribe}
                            />
                            <UserContentBlock
                                type={email}
                                details={userEmail}
                                action={action}
                            />
                            <UserContentBlock
                                type={address}
                                details={userAddress}
                                editMobile={editDetails}
                            />
                        </React.Fragment>
                    ),
                )
            )}
        </Container>
    );
}

export default React.memo(AccountUserDetails);
