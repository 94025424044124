import React from 'react';
import type { Font, Glyph } from 'opentype.js';
import { GLYPH, GLYPH_RENDER_MODE, VIEWPORT } from '../../settings/Global';
import { useCurrentColorScheme } from '../../components/ColorSchemeContext';
import {
    useRenderModeState,
    useGlyphInspectorState,
    useCanvasDimensionsState,
    useFontStyleState,
} from '../../components/GlyphInspectorContext';
import { getGlyphBaseline, getGlyphScale } from './fontMetricData';
import notUndefined from '../notUndefined';
import { useGlobalState } from '../../components/GlobalRuntimeState';

const PI_SQ: number = Math.PI * 2;

const drawBezierLine = ({
    canvasContext,
    baseline,
    xOffset,
    x1,
    y1,
    x2,
    y2,
    scale,
}: {
    canvasContext: CanvasRenderingContext2D;
    baseline: number;
    xOffset: number;
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    scale: number;
}): void => {
    canvasContext.beginPath();
    canvasContext.moveTo(xOffset + x1 * scale, baseline + y1 * scale);
    canvasContext.lineTo(xOffset + x2 * scale, baseline + y2 * scale);
    canvasContext.closePath();
    canvasContext.stroke();
};

const drawBezierPoint = ({
    canvasContext,
    baseline,
    x,
    y,
    xOffset,
    scale,
    radius,
}: {
    canvasContext: CanvasRenderingContext2D;
    baseline: number;
    x: number;
    y: number;
    xOffset: number;
    scale: number;
    radius: number;
}): void => {
    canvasContext.beginPath();
    canvasContext.moveTo(xOffset + x * scale, baseline + y * scale);
    canvasContext.arc(
        xOffset + x * scale,
        baseline + y * scale,
        radius,
        0,
        PI_SQ,
        false,
    );
    canvasContext.closePath();
    canvasContext.fill();
};

interface Point {
    x: number;
    y: number;
}

const drawBezierCurves = ({
    glyph,
    canvasContext,
    baseline,
    xMin,
    scale,
    bezierPointRadius,
    color,
    mainPointColor,
}: {
    glyph: Glyph;
    canvasContext: CanvasRenderingContext2D;
    baseline: number;
    xMin: number;
    scale: number;
    bezierPointRadius: number;
    color: string;
    mainPointColor: string;
}): void => {
    let nextBezierOrigin: Point | null = null;

    canvasContext.fillStyle = color;
    canvasContext.strokeStyle = color;

    glyph.path.commands.forEach((cmd): void => {
        if (cmd.type !== 'Z' && cmd.x !== undefined && cmd.y !== undefined) {
            if (
                (cmd.type === 'Q' || cmd.type === 'C') &&
                cmd.x1 !== undefined &&
                cmd.y1 !== undefined
            ) {
                drawBezierPoint({
                    canvasContext,
                    baseline,
                    x: cmd.x1,
                    y: -cmd.y1,
                    xOffset: xMin,
                    scale: scale,
                    radius: bezierPointRadius,
                });

                // Draw line between if we have a previous origin point (the bezier points aren't grouped correctly...)
                if (nextBezierOrigin) {
                    drawBezierLine({
                        canvasContext,
                        baseline,
                        xOffset: xMin,
                        x1: nextBezierOrigin.x,
                        y1: nextBezierOrigin.y,
                        x2: cmd.x1,
                        y2: -cmd.y1,
                        scale,
                    });
                }
            }
            if (
                cmd.type === 'C' &&
                cmd.x2 !== undefined &&
                cmd.y2 !== undefined
            ) {
                drawBezierPoint({
                    canvasContext,
                    baseline,
                    x: cmd.x2,
                    y: -cmd.y2,
                    xOffset: xMin,
                    scale,
                    radius: bezierPointRadius,
                });

                // Draw line between
                drawBezierLine({
                    canvasContext,
                    baseline,
                    xOffset: xMin,
                    x1: cmd.x,
                    y1: -cmd.y,
                    x2: cmd.x2,
                    y2: -cmd.y2,
                    scale,
                });
            }
            nextBezierOrigin = {
                x: cmd.x,
                y: -cmd.y,
            };
        }
    });

    // Draw the bézier curve points on top
    canvasContext.fillStyle = mainPointColor;
    glyph.path.commands
        .map((cmd): Point | undefined => {
            if (cmd.type === 'Z' || cmd.x === undefined || cmd.y === undefined)
                return undefined;
            return { x: cmd.x, y: -cmd.y };
        })
        .filter(notUndefined)
        .forEach(({ x, y }): void => {
            drawBezierPoint({
                canvasContext,
                baseline,
                x,
                y,
                xOffset: xMin,
                scale,
                radius: bezierPointRadius,
            });
        });
};

function drawGlyph({
    canvasWidth,
    canvasHeight,
    canvas,
    glyph,
    font,
    scale,
    baseline,
    pixelRatio,
    renderMode,
    bezierPointRadius,
}: {
    canvasWidth: number;
    canvasHeight: number;
    canvas: HTMLCanvasElement;
    glyph: Glyph;
    font: Font;
    scale: number;
    baseline: number;
    pixelRatio: number;
    renderMode: GLYPH_RENDER_MODE;
    bezierPointRadius: number;
}): void {
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Clear canvas
    const canvasContext = canvas.getContext('2d');
    if (!canvasContext) {
        return;
    }
    canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);

    // Get Path
    const glyphWidth = (glyph.advanceWidth || 1) * scale;

    const xMin = (canvasWidth - glyphWidth) / 2;
    const glyphSize = scale * font.unitsPerEm;
    const glyphPath = glyph.getPath(xMin, baseline, glyphSize);

    // Get colors from current scheme styles
    const computedStyle = getComputedStyle(canvas);
    const primaryColor = computedStyle.getPropertyValue('--foregroundColor');
    const secondaryColor = computedStyle.getPropertyValue(
        '--foregroundColorMix7',
    );

    // Draw glyph
    if (renderMode === GLYPH_RENDER_MODE.OUTLINE) {
        /*
         * Outline/bezier render mode
         */
        drawBezierCurves({
            glyph,
            canvasContext,
            baseline,
            xMin,
            scale,
            bezierPointRadius: bezierPointRadius * pixelRatio,
            color: secondaryColor,
            mainPointColor: primaryColor,
        });
        glyphPath.fill = null;
        glyphPath.stroke = primaryColor;
        glyphPath.draw(canvasContext);
    } else {
        /*
         * Fill render mode (fallback)
         */
        glyphPath.fill = primaryColor;
        glyphPath.stroke = null;
        glyphPath.draw(canvasContext);
    }
}

export const useDrawGlyph = ({
    canvasRef,
}: {
    canvasRef: React.RefObject<HTMLCanvasElement | null>;
}): void => {
    const [fontStyle] = useFontStyleState();
    const [renderMode] = useRenderModeState();
    const { pixelRatio, font, glyph } = useGlyphInspectorState();
    const currentColorScheme = useCurrentColorScheme();
    const [{ width, height }] = useCanvasDimensionsState();
    const [viewportWidth] = useGlobalState('viewportWidth');

    const canvasWidth = width * pixelRatio;
    const canvasHeight = height * pixelRatio;

    React.useEffect((): void => {
        const canvas = canvasRef.current;

        const bezierPointRadius =
            viewportWidth && viewportWidth <= VIEWPORT.MOBILE
                ? (GLYPH.BEZIER_POINT_RADIUS / 3) * 2 // ⅔ size on mobile
                : GLYPH.BEZIER_POINT_RADIUS;

        if (canvas && glyph && font) {
            const scale = getGlyphScale({
                font,
                canvasHeight,
                canvasWidth,
            });

            const baseline = getGlyphBaseline({
                scale,
                capHeight: fontStyle.otfFiles?.metrics
                    ? fontStyle.otfFiles.metrics.capHeightOs2
                    : font.tables.os2.sCapHeight,
                canvasHeight,
            });

            drawGlyph({
                canvas,
                canvasHeight,
                canvasWidth,
                glyph,
                font,
                scale,
                baseline,
                pixelRatio,
                renderMode,
                bezierPointRadius,
            });
        }
    }, [
        currentColorScheme,
        canvasHeight,
        canvasWidth,
        canvasRef,
        pixelRatio,
        glyph,
        font,
        renderMode,
        viewportWidth,
        fontStyle.otfFiles?.metrics?.capHeightOs2,
    ]);
};
