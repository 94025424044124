import notUndefined from './notUndefined';

export default function createTitle(
    append?: string | (string | undefined)[],
    reverse?: boolean,
): string {
    const appendArray = Array.isArray(append)
        ? [`Klim Type Foundry`].concat(append.filter(notUndefined))
        : [`Klim Type Foundry`, append];
    if (reverse) {
        return appendArray.filter(Boolean).reverse().join(' · ');
    }
    return appendArray.filter(Boolean).join(' · ');
}

export const createTitleSet = (
    append?: string | (string | undefined)[],
): { title: string; ogTitle: string } => {
    return {
        title: createTitle(append, true),
        ogTitle: createTitle(append),
    };
};
