import React from 'react';
import useHasMounted from '../hooks/useHasMounted';

/**
 * This component will only render in a client, i.e. at runtime.
 * It can be used to exclude components from rendering at SSR time,
 * e.g. to prevent hydration issues.
 * See https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 */
export default function ClientOnly({
    children,
    ...delegated
}: {
    children: React.ReactNode;
}): React.ReactElement | null {
    const isMounted = useHasMounted();
    if (!isMounted) {
        return null;
    }
    return <React.Fragment {...delegated}>{children}</React.Fragment>;
}
