import type { StripeError } from '@stripe/stripe-js';
import {
    STRIPE_ERROR_MESSAGES,
    STRIPE_DECLINE_MESSAGES,
} from '../settings/Stripe';

function isStripeErrorCode(
    item: string,
): item is keyof typeof STRIPE_ERROR_MESSAGES {
    return item in STRIPE_ERROR_MESSAGES;
}

function isStripeDeclineCode(
    item: string,
): item is keyof typeof STRIPE_DECLINE_MESSAGES {
    return item in STRIPE_DECLINE_MESSAGES;
}

export default function printStripeError(
    stripeError:
        | StripeError
        | {
              code: string;
              message: string;
              decline_code?: string;
          },
): string {
    /**
     * We're particular about what we tell people when there's a payment error.
     */
    const {
        code: stripeCode,
        message: stripeMessage,
        decline_code: stripeDeclineCode,
    } = stripeError;

    // 1. Check STRIPE_ERROR_MESSAGES
    if (stripeCode && isStripeErrorCode(stripeCode)) {
        return STRIPE_ERROR_MESSAGES[stripeCode];
    }

    // 2. Check STRIPE_DECLINE_MESSAGES
    if (stripeCode && isStripeDeclineCode(stripeCode)) {
        return STRIPE_DECLINE_MESSAGES[stripeCode];
    }
    if (stripeDeclineCode && isStripeDeclineCode(stripeDeclineCode)) {
        return STRIPE_DECLINE_MESSAGES[stripeDeclineCode];
    }

    // 3. Use Stripe’s message with proper apostrophes.
    if (stripeMessage) {
        return stripeMessage.replace("'", '’');
    }

    // F4llb4ck
    return STRIPE_ERROR_MESSAGES.processing_error;
}
