import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
    font-family: var(--fontFamilySoehneIkon);
`;

export default function QuantityBadge({
    quantity,
}: {
    quantity: number;
}): React.ReactElement {
    return <Span>{`(${quantity})`}</Span>;
}
