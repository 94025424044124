import { LineItemType } from '../gql/api-public';
import printAmpersandSeries from './printAmpersandSeries';

export default function getLineItemUpgradeDescription(item: {
    type: LineItemType;
    upgradedOrderNumbers: string[];
}): string | undefined {
    if (item.type == LineItemType.UPGRADE && item.upgradedOrderNumbers.length) {
        return `Upgrade from Order Nº ${printAmpersandSeries(
            item.upgradedOrderNumbers,
        ).join('')}`;
    }
}
