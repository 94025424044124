import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import type { BlogHeadingBlock } from '../union-types/blogPost';
import { blogAndInUseWrapStyles } from './BlogPostStyles';
import { hyphensAuto } from '../utils/stylesMixins';
import { HeadingMedium } from './Heading';
import { useStaticFontMetricOffsets } from './PageContext';

const BlogHeadingContainer = styled.div`
    ${blogAndInUseWrapStyles};

    color: var(--foregroundColor);
    background: var(--backgroundColor);
    grid-template-areas: '. . a a a a a a . . . .';

    @media screen and (min-width: ${VIEWPORT.DESKTOP_LARGE}px) {
        grid-template-areas: '. . a a a a a . . . . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-areas: '. a a a a a a a a a . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: '. a a a a .';
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-areas: 'a a a a a a';
    }
`;

const Heading = styled(HeadingMedium)`
    grid-area: a;
    ${hyphensAuto};
    padding: 0;
    color: var(--secondaryColorRef);

    @media print {
        color: var(--primaryColorRef);
    }
`;

export function BlogBodyHeading({
    title,
}: BlogHeadingBlock): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();
    return (
        <BlogHeadingContainer>
            <Heading $metricOffsets={staticFontMetrics} as="h3">
                {title}
            </Heading>
        </BlogHeadingContainer>
    );
}
