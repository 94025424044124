import React from 'react';
import styled from 'styled-components';
import { Ul, Li } from './Primitives';
import { A } from './InlineUnderlinedLink';
import Paragraph from './Paragraph';
import { VIEWPORT } from '../settings/Global';
import { TEST_ID } from '../settings/E2e';
import useTestFontsInfo from '../hooks/useTestFontsInfo';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing5);
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing2);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-row-gap: var(--spacing3);
    }
`;

const Characters = styled.p`
    font-weight: bold;
    line-height: var(--lineHeightBody1);
    letter-spacing: 1px;
    font-size: var(--fontSizeFixed5);
    color: var(--foregroundColor);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

function TestFontsInfo(): React.ReactElement {
    const testFontsInfo = useTestFontsInfo();
    const sizeBytes = testFontsInfo.allFontsArchiveFilesize || 0;
    const sizeKilobytes = sizeBytes / 1024;
    const sizeMegabytes = sizeKilobytes / 1024;
    // Get ideal decimal precision, dropping zero decimal (.0) if applicable
    const fractionDigits =
        Math.round(sizeMegabytes * 10) / 10 === Math.round(sizeMegabytes)
            ? 0
            : 1;

    return (
        <Container>
            <Content>
                <Paragraph>
                    Use these test fonts to mock up your print, web or app
                    designs for approval before licensing the full versions.
                </Paragraph>
                <Paragraph>
                    Test fonts have no OpenType features and a limited character
                    set:
                </Paragraph>
            </Content>
            <Characters>
                {`ABCDEFGHIJKLMNOPQRSTUVWXYZ`}
                <br />
                {`abcdefghijklmnopqrstuvwxyz`}
                <br />
                {`0123456789 .,-`}
            </Characters>
            <Content>
                <Paragraph>
                    The {sizeMegabytes.toFixed(fractionDigits)} MB download
                    includes:
                </Paragraph>
                <Ul>
                    <Li>All of our retail fonts</Li>
                    <Li>OTF — OpenType Format</Li>
                    <Li>WOFF2 — Web Open Font Format 2.0</Li>
                </Ul>
                <Paragraph>
                    Our test font family names are prefixed with “Test”.
                </Paragraph>
            </Content>
        </Container>
    );
}

export default React.memo(TestFontsInfo);

export function TestFontsFormIsSubmitted({
    downloadUrl,
}: {
    downloadUrl: string | null;
}): React.ReactElement {
    return (
        <Container>
            <Content>
                <Paragraph>
                    Thank you. The test fonts will download automatically.
                </Paragraph>
                {downloadUrl && (
                    <Paragraph>
                        Alternatively, use{' '}
                        <A
                            href={downloadUrl}
                            data-cy={TEST_ID.TEST_FONTS_DL_LINK}
                        >
                            this link
                        </A>{' '}
                        to download them.
                    </Paragraph>
                )}
            </Content>
        </Container>
    );
}
