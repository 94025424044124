import { useQuery } from '@apollo/client';
import type { QueryResult } from '@apollo/client';
import { cartQuery } from '../utils/runtimeQueries';
import { CartQueryQuery, CartQueryQueryVariables } from '../gql/api-public';
import useLocationInvoiceNumber from './useLocationInvoiceNumber';
import { useIsInvoicePage } from '../components/PageContext';

export type Cart = NonNullable<CartQueryQuery['cart']>;
export type Discount = Cart['discounts'][number];
export type Item = Cart['items'][number];
export type FontProduct = NonNullable<Item['font']>;
export type FontStylesByFamilyId = FontProduct['fontStyleIdsByFamilyId'];
export type CartTier = Cart['licenceTiers'][number];
export type LicenceTier = CartTier['tier'];
export type LicenceType = LicenceTier['licenceType'];
export type TieredLicence = LicenceTier['tieredLicence'];

export default function useCartQuery(): QueryResult<
    CartQueryQuery,
    CartQueryQueryVariables
> {
    const invoiceNumber = useLocationInvoiceNumber();
    const isInvoiceCheckout = useIsInvoicePage();

    return useQuery<CartQueryQuery, CartQueryQueryVariables>(cartQuery, {
        variables: {
            invoiceNumber: isInvoiceCheckout ? invoiceNumber : undefined,
        },
        ssr: false,
    });
}
