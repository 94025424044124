import React from 'react';
import sessionStorage from '../utils/sessionStorage';
import subscribeScrollRequestAnimationFrame from '../utils/subscribeScrollRequestAnimationFrame';

/**
 * Restores the scroll position when previewing content in Wagtail
 * @param skip
 * @param contentId
 */
export default function usePreviewScrollRestore({
    skip = false,
    contentId,
}: {
    skip: boolean;
    contentId: string;
}): void {
    React.useEffect((): void | (() => void) => {
        if (skip) {
            return;
        }

        const sessionKey = `previousScroll${contentId}`;

        const previousScroll = sessionStorage.getItem(sessionKey);
        window.scrollTo(
            window.scrollX,
            previousScroll ? parseFloat(previousScroll) : 0,
        );
        const onScroll = (): void => {
            sessionStorage.setItem(sessionKey, window.scrollY.toString(10));
        };

        const scrollListener = subscribeScrollRequestAnimationFrame(onScroll);

        return (): (() => void) => {
            sessionStorage.removeItem(sessionKey);
            return scrollListener;
        };
    }, [skip, contentId]);
}
