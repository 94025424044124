import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useApolloClient, useMutation } from '@apollo/client';
import { logoutMutation } from '../utils/runtimeQueries';
import type { LogoutMutation } from '../gql/api-public';
import { HeadingExtraLarge } from '../components/Heading';
import { createHomeUrl, createNextUrlFromLocation } from '../utils/urlHelper';
import { VIEWPORT } from '../settings/Global';
import isAbsoluteUrl from 'is-absolute-url';
import ProgressBar from '../components/ProgressBar';

export { Head } from '../components/Head';

const Container = styled.div`
    position: relative; /* So that it sits above the ProgressBar */
`;

const Inner = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    ${HeadingExtraLarge} {
        margin-top: var(--spacing1);
        grid-column: 3 / span 6;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span 6;
        }
    }
`;

function LogoutPage(): React.ReactElement {
    const [doLogout] = useMutation<LogoutMutation>(logoutMutation);
    const location = useLocation();
    const client = useApolloClient();

    React.useEffect((): void => {
        // Otherwise looks like everything is OK and we can proceed with logout.
        doLogout().then(async (): Promise<void> => {
            // Reset cache
            client.resetStore();

            const nextUrl = createNextUrlFromLocation(location);

            await navigate(
                nextUrl && isAbsoluteUrl(nextUrl) ? nextUrl : createHomeUrl(),
            );
        });
    }, [location, doLogout]);

    return (
        <>
            <ProgressBar loading={true} />
            <Container>
                <Inner>
                    <HeadingExtraLarge>Signing out…</HeadingExtraLarge>
                </Inner>
            </Container>
        </>
    );
}

export default React.memo(LogoutPage);
