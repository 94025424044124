import React from 'react';
import styled from 'styled-components';
import type {
    UseFormRegister,
    FieldName,
    FieldErrors,
    Message,
} from 'react-hook-form';
import stripeJs from '@stripe/stripe-js';
import FieldInputGroup from './FieldInputGroup';
import {
    Section,
    SectionContent,
    SubSection,
    SubmitButton as _SubmitButton,
} from './CheckoutFormElements';
import Fieldset from './Fieldset';
import FieldText from './FieldText';
import { LOZENGE_SPACING, VIEWPORT } from '../settings/Global';
import printStripeError from '../utils/printStripeError';
import {
    FieldStripeCardNumber,
    FieldStripeCardExpiry,
    FieldStripeCardCvc,
} from './FieldsStripe';
import CheckoutFormPaymentSectionLabel from './CheckoutFormPaymentSectionLabel';
import { TEST_ID } from '../settings/E2e';
import type { CheckoutFormData } from './CheckoutForm';
import { MAX_DEFAULT, MAX_DEFAULT_ERROR } from './CheckoutFormSettings';

const StripeFields = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: ${LOZENGE_SPACING}px ${LOZENGE_SPACING}px;
    grid-template-areas:
        'a a a a a .'
        'b b b c d .'
        'e e e e e .';

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas:
            'a a a a a a a a'
            'b b b b c c d d'
            'e e e e e e e e';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
            'a a a a a a'
            'b b b b c d'
            'e e e e e e';
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-areas:
            'a a a a a a'
            'b b b b b b'
            'c c c d d d'
            'e e e e e e';
    }
`;

const StripeFieldWrapper = styled.div`
    &[data-field='cardName'] {
        grid-area: a;
    }

    &[data-field='cardNumber'] {
        grid-area: b;
    }

    &[data-field='cardExpiry'] {
        grid-area: c;
    }

    &[data-field='cardCvc'] {
        grid-area: d;
    }

    &[data-field='error'] {
        grid-area: e;
    }
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: ${LOZENGE_SPACING}px;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-columns: repeat(8, 1fr);
    }
`;

const SubmitButton = styled(_SubmitButton)`
    grid-column: 1 / span 5;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 1 / span 4;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        display: none;
    }
`;

const ErrorMessage = styled.p`
    color: var(--errorColor);
`;

interface Fields {
    cardName: string;
    payment: typeof undefined;
    cardNumber: typeof undefined;
    cardExpiry: typeof undefined;
    cardCvc: typeof undefined;
}

export default function CheckoutFormCreditCardSection({
    errors,
    setError,
    clearErrors,
    register,
    disabled,
    isSubmitting,
}: {
    errors: FieldErrors<Fields>;
    clearErrors: (name?: FieldName<Fields> | FieldName<Fields>[]) => void;
    setError: (
        name: keyof Fields,
        error: {
            message?: Message;
            type: string;
        },
    ) => void;
    register: UseFormRegister<CheckoutFormData>;
    disabled?: boolean;
    isSubmitting?: boolean;
}): React.ReactElement {
    const stripeErrorMessage =
        errors.payment?.message ||
        errors.cardNumber?.message ||
        errors.cardExpiry?.message ||
        errors.cardCvc?.message;

    const stripeFieldChangeHandler = (
        event:
            | stripeJs.StripeCardNumberElementChangeEvent
            | stripeJs.StripeCardExpiryElementChangeEvent
            | stripeJs.StripeCardCvcElementChangeEvent,
    ): void => {
        const { elementType, error } = event;
        /*
         * See: https://stripe.com/docs/stripe-js/reference#element-types
         *
         * elementType values include 'cardNumber', 'cardExpiry', and 'cardCvc'.
         */
        if (
            elementType === 'cardNumber' ||
            elementType === 'cardExpiry' ||
            elementType === 'cardCvc'
        ) {
            if (error) {
                setError(elementType, {
                    type: error.type,
                    message: printStripeError(error),
                });
            } else {
                clearErrors([elementType, 'payment']);
            }
        }
    };

    return (
        <Section>
            <CheckoutFormPaymentSectionLabel />
            <SectionContent>
                <SubSection>
                    <Fieldset legend="Credit card">
                        <StripeFields>
                            <StripeFieldWrapper data-field="cardName">
                                <FieldInputGroup
                                    input={
                                        <FieldText
                                            label="Name on card"
                                            {...register('cardName', {
                                                required: 'Enter a name',
                                                maxLength: {
                                                    value: MAX_DEFAULT,
                                                    message: MAX_DEFAULT_ERROR,
                                                },
                                                disabled,
                                            })}
                                            isError={!!errors.cardName}
                                        />
                                    }
                                    error={errors.cardName}
                                />
                            </StripeFieldWrapper>
                            <StripeFieldWrapper data-field="cardNumber">
                                <FieldStripeCardNumber
                                    hasError={
                                        !!errors.cardNumber || !!errors.payment
                                    }
                                    onChange={stripeFieldChangeHandler}
                                    disabled={disabled}
                                />
                            </StripeFieldWrapper>
                            <StripeFieldWrapper data-field="cardExpiry">
                                <FieldStripeCardExpiry
                                    hasError={
                                        !!errors.cardExpiry || !!errors.payment
                                    }
                                    onChange={stripeFieldChangeHandler}
                                    disabled={disabled}
                                />
                            </StripeFieldWrapper>
                            <StripeFieldWrapper data-field="cardCvc">
                                <FieldStripeCardCvc
                                    hasError={
                                        !!errors.cardCvc || !!errors.payment
                                    }
                                    onChange={stripeFieldChangeHandler}
                                    disabled={disabled}
                                />
                            </StripeFieldWrapper>
                            {stripeErrorMessage && (
                                <StripeFieldWrapper
                                    data-cy={TEST_ID.STRIPE_ERROR}
                                    data-field="error"
                                >
                                    <ErrorMessage>
                                        {stripeErrorMessage}
                                    </ErrorMessage>
                                </StripeFieldWrapper>
                            )}
                        </StripeFields>
                    </Fieldset>

                    <ButtonWrapper>
                        <SubmitButton $isSubmitting={isSubmitting} />
                    </ButtonWrapper>
                </SubSection>
            </SectionContent>
        </Section>
    );
}
