import React from 'react';
import styled from 'styled-components';
import type { CountryChoices } from '../gql/api-public';
import type { Country } from '../hooks/useConfig';
import printCountryName from '../utils/printCountryName';

const LegacyAddressContainer = styled.span`
    white-space: pre-wrap;
`;

export default function AccountCartAddress({
    personName,
    companyName,
    address1,
    address2,
    city,
    postcode,
    state,
    countryCode,
    countries,
    vatNumber,
    legacyAddress,
}: {
    personName?: string | null;
    companyName: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    postcode: string | null;
    state: string | null;
    countryCode: CountryChoices | null;
    countries: Country[];
    vatNumber: string | null;
    legacyAddress: string | null;
}): React.ReactElement {
    if (legacyAddress) {
        return <LegacyAddressContainer>{legacyAddress}</LegacyAddressContainer>;
    }
    return (
        <>
            {personName && (
                <>
                    {personName}
                    <br />
                </>
            )}
            {companyName}
            <br />
            {address1}
            <br />
            {address2 && (
                <>
                    {address2}
                    <br />
                </>
            )}
            {city} {postcode}
            <br />
            {state && (
                <>
                    {state}
                    <br />
                </>
            )}
            {printCountryName(countryCode, countries)}
            {vatNumber && (
                <>
                    <br />
                    <br />
                    {vatNumber}
                    <br />
                </>
            )}
        </>
    );
}
