import React from 'react';
import { v4 as uuid } from 'uuid';
import { useCountries } from './useConfig';

export default function useCountrySelectOptions(): JSX.Element[] {
    const countries = useCountries();

    return React.useMemo(
        (): JSX.Element[] =>
            countries.reduce((carry: JSX.Element[], country): JSX.Element[] => {
                carry.push(
                    <option value={country.code} key={uuid()}>
                        {country.name}
                    </option>,
                );
                return carry;
            }, []),
        [countries],
    );
}
