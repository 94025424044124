import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing8);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing7);
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-columns: var(--gridTemplateColumnsDefault);
        grid-row-gap: var(--spacing5);
    }
`;

const IntroWrapper = styled.div`
    grid-column: 1 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 1 / span var(--gridColumnCount);
    }
`;

const AsideWrapper = styled.div`
    grid-column: 8 / span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;

const ContentWrapper = styled.div`
    grid-column: 1 / span 10;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span var(--gridColumnCount);
    }
`;

function FontInformation({
    intro,
    aside,
    content,
}: {
    intro: React.ReactElement | null;
    aside?: React.ReactElement | null;
    content?: React.ReactElement | null;
}): React.ReactElement {
    return (
        <Container>
            <Row>
                <IntroWrapper>{intro}</IntroWrapper>
                {aside && <AsideWrapper>{aside}</AsideWrapper>}
            </Row>
            {content && (
                <Row>
                    <ContentWrapper>{content}</ContentWrapper>
                </Row>
            )}
        </Container>
    );
}

export default FontInformation;
