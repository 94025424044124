/**
 * Returns normal Font Family Group slug, but appends '-collection' if the group is a collection.
 * @param fontFamilyGroup
 */
export default function getFontFamilyGroupSlugWithCollection(fontFamilyGroup: {
    slug: string;
    isCollection: boolean;
}): string {
    return `${fontFamilyGroup.slug}${
        fontFamilyGroup.isCollection ? '-collection' : ''
    }`;
}
