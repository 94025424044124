import React from 'react';

export default function useKeyPress({
    callback,
    key,
    postInitCallback,
    shiftKey,
    ctrlKey,
    altKey,
    metaKey,
    preventDefault,
}: {
    callback: () => void;
    key: Pick<KeyboardEvent, 'key' | 'code' | 'keyCode'>;
    postInitCallback?: () => void;
    shiftKey?: boolean;
    ctrlKey?: boolean;
    altKey?: boolean;
    metaKey?: boolean;
    preventDefault?: boolean;
}): void {
    React.useEffect((): (() => void) | void => {
        const onKeyPress = (event: KeyboardEvent): void => {
            if (
                // We compare to all three, as this is the most compatible... *facepalm*
                (event.keyCode !== key.keyCode &&
                    event.key != key.key &&
                    event.code != key.code) ||
                (shiftKey && !event.shiftKey) ||
                (!shiftKey && event.shiftKey) ||
                (ctrlKey && !event.ctrlKey) ||
                (!ctrlKey && event.ctrlKey) ||
                (altKey && !event.altKey) ||
                (!altKey && event.altKey) ||
                (metaKey && !event.metaKey) ||
                (!metaKey && event.metaKey)
            ) {
                return;
            }
            if (preventDefault) {
                event.preventDefault();
            }
            callback();
        };
        window.addEventListener('keydown', onKeyPress);
        if (postInitCallback) {
            postInitCallback();
        }
        return (): void => window.removeEventListener('keydown', onKeyPress);
    }, []);
}
