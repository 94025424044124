import React from 'react';
import styled, { css } from 'styled-components';
import { Paragraph as _Paragraph } from './Paragraph';
import { COLOR, LOZENGE_SPACING, VIEWPORT } from '../settings/Global';
import printPrice from '../utils/printPrice';
import useCartQuery from '../hooks/useCartQuery';
import { labelStyles } from '../utils/formStyles';
import Button, { type ButtonProps } from './Button';
import useIsDefaultColorScheme from '../hooks/useIsDefaultColorScheme';
import useIsCartEmpty from '../hooks/useIsCartEmpty';

export const SubHeading = styled(_Paragraph)`
    color: var(--foregroundColorMix5);
`;

export const Inner = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing8);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-row-gap: var(--spacing7);
    }
`;

export const Section = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
`;

export const SubSection = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing3);
`;

export const SectionLabel = styled.div`
    grid-column: 1 / span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;

export const SectionLabelInner = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing2);
`;

export const SectionContent = styled.div`
    grid-column: 3 / span 6;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing8);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-row-gap: var(--spacing7);
        grid-column: 1 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span var(--gridColumnCount);
    }
`;

export const Fields = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${LOZENGE_SPACING}px ${LOZENGE_SPACING}px;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-columns: 100%;
    }

    [data-span='double'] {
        grid-column: 1 / span 2;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: initial;
        }
    }
`;

export const RadioFields = styled.div`
    display: inline-grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: var(--spacing3);
`;

export const CheckboxFields = styled.div`
    display: inline-grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing2);
`;

export const RadioLabel = styled.div`
    ${labelStyles};
`;

export interface CheckoutButtonProps extends ButtonProps {
    $isDefaultTheme: boolean;
}

export const checkoutButtonStyles = css<CheckoutButtonProps>`
    /* For the default themes we show a green button on hover. */
    ${({ $isDefaultTheme }): ReturnType<typeof css> | null =>
        $isDefaultTheme
            ? css`
                  --lozengeHoverColor: ${COLOR.BLACK};
                  --lozengeBackgroundHoverColor: ${COLOR.GREEN};
              `
            : null};
`;

const CheckoutButton = styled(Button)<CheckoutButtonProps>`
    ${checkoutButtonStyles};
`;

export function SubmitButton(
    buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> & {
        $isSubmitting?: boolean;
    },
): React.ReactElement | null {
    const isDefaultTheme = useIsDefaultColorScheme();
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const cartIsEmpty = useIsCartEmpty(cart) || !cart?.amountTotal;
    if (!cart) {
        return null;
    }

    return (
        <CheckoutButton
            type="submit"
            $isDefaultTheme={isDefaultTheme}
            {...buttonProps}
            disabled={
                buttonProps.disabled || buttonProps.$isSubmitting || cartIsEmpty
            }
            title={
                cartIsEmpty ? 'Your shopping cart is empty' : buttonProps.title
            }
        >
            {buttonProps.$isSubmitting
                ? 'Processing…'
                : `Pay ${
                      cartIsEmpty
                          ? ``
                          : printPrice(cart.amountTotal, cart.currency)
                  } ${cartIsEmpty ? `` : cart.currency}`}
        </CheckoutButton>
    );
}
