import React from 'react';
import { useRenderModeState } from './GlyphInspectorContext';
import { GLYPH_RENDER_MODE } from '../settings/Global';
import Switch from './Switch';
import { TEST_ID } from '../settings/E2e';

export default function GlyphInspectorRenderModeInput(): React.ReactElement {
    const [, setRenderMode] = useRenderModeState();

    const onChange = React.useCallback(
        (checked: boolean): void => {
            setRenderMode(
                checked ? GLYPH_RENDER_MODE.OUTLINE : GLYPH_RENDER_MODE.FILL,
            );
        },
        [setRenderMode],
    );

    return (
        <Switch
            data-cy={TEST_ID.GLYPH_INSPECTOR_RENDER_MODE}
            onCheckedChange={onChange}
            label="Show&nbsp;outlines"
        />
    );
}
