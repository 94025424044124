import React from 'react';
import styled from 'styled-components';
import { HeadingExtraLarge } from './Heading';
import AccountOrderHistory from './AccountOrderHistory';
import AccountUserDetails from './AccountUserDetails';
import type { User } from '../hooks/useUserQuery';
import { VIEWPORT } from '../settings/Global';
import { TEST_ID } from '../settings/E2e';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing7);
    }
`;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    ${HeadingExtraLarge} {
        grid-column: 3 / span 8;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span 6;
        }
    }
`;

export default function AccountUser({
    user,
}: {
    user: User;
}): React.ReactElement {
    let possessiveName = user.fullName ? `${user.fullName}’` : 'Your';
    if (user.fullName && !user.fullName.endsWith('s')) {
        possessiveName = `${possessiveName}s`;
    }
    return (
        <Container>
            <Wrapper>
                <HeadingExtraLarge
                    data-cy={TEST_ID.ACCOUNT_HEADING}
                    as="h1"
                >{`${possessiveName} account`}</HeadingExtraLarge>
            </Wrapper>
            <AccountUserDetails user={user} />
            <AccountOrderHistory />
        </Container>
    );
}
