import React from 'react';
import styled from 'styled-components';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import { LOZENGE_SPACING } from '../settings/Global';

const ANIM_SPEED = 50;
const ANIM_EASE = 'ease-out';

const Label = styled.label`
    display: inline-flex;
    flex-direction: row;
    gap: ${LOZENGE_SPACING}px;
    align-items: center;
    cursor: pointer;
`;

const Root = styled(SwitchPrimitive.Root)`
    width: 48px;
    height: 28px;
    background-color: var(--menuBackgroundColor);
    transition: background-color ${ANIM_SPEED}ms ${ANIM_EASE};
    will-change: background-color;
    border-radius: 9999px;
    position: relative;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

    &[data-state='checked'] {
        background-color: var(--foregroundColor);
    }
`;

const Thumb = styled(SwitchPrimitive.Thumb)`
    display: block;
    width: 22px;
    height: 22px;
    background-color: var(--foregroundColor);
    transition:
        background-color ${ANIM_SPEED}ms ${ANIM_EASE},
        transform ${ANIM_SPEED}ms ${ANIM_EASE};
    will-change: transform, background-color;
    border-radius: 9999px;
    transform: translateX(3px);

    &[data-state='checked'] {
        transform: translateX(23px);
        background-color: var(--backgroundColor);
    }
`;

interface SwitchProps extends SwitchPrimitive.SwitchProps {
    label?: string;
}

const Switch = React.forwardRef(
    (
        props: SwitchProps,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): React.ReactElement => {
        const label = props.label?.trim();
        return (
            <Label>
                {label}
                <Root ref={forwardedRef} {...props}>
                    <Thumb />
                </Root>
            </Label>
        );
    },
);
Switch.displayName = 'Switch';

export default Switch;
