import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { createEmbedVideoUrl } from '../utils/urlHelper';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import {
    VIEWPORT,
    DEFAULT_VIDEO_ASPECT_RATIO,
    COLOR,
} from '../settings/Global';
import processWagtailRichText from '../utils/processWagtailRichText';
import useConfig from '../hooks/useConfig';
import type { BlogVideoBlock } from '../union-types/blogPost';
import { blogAndInUseWrapStyles } from './BlogPostStyles';
import { hyphensAuto } from '../utils/stylesMixins';

const VideoContainer = styled.div`
    ${blogAndInUseWrapStyles};

    position: relative;
    grid-template-areas: '. a a a a a a a a a a .';

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: 'a a a a a a';
    }
`;

const Video = styled.div<{
    $aspectRatio: { width: number; height: number };
}>`
    grid-area: a;
    display: block;
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: calc(
            100% /
                ${({ $aspectRatio }): string => $aspectRatio.width.toString()} *
                ${({ $aspectRatio }): string => $aspectRatio.height.toString()}
        );
    }
`;

const PlayButtonBackgroundBaseStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.7;
`;

const PlayButtonBackgroundRemote = styled.img`
    ${PlayButtonBackgroundBaseStyles};
    object-fit: cover;
`;

const PlayButtonBackground = styled(ImageWithPlaceholder)`
    ${PlayButtonBackgroundBaseStyles};
`;

const PlayButton = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    color: ${COLOR.WHITE};
    font-weight: bold;
    font-size: 8vw;
    z-index: 2;

    &:focus + *,
    &:hover + * {
        /* refers to the following sibling, the PlayButtonBackground */
        opacity: 0.5;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: 16.6vw;
    }
`;

const VideoBlack = styled.div`
    background: #000; /* not using CSS Vars for this because it's only ever black and shouldn't be themed */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const VideoIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const VideoCaption = styled.div`
    position: absolute; /* to pull out of the flow because the caption should not affect vertical spacing, so the spacing between images and surrounding content should have consistent vertical spacing regardless of whether there is a caption */
    margin-top: var(--spacing1);
    font-size: var(--fontSizeFixed1);
    color: var(--foregroundColorMix6);

    ${hyphensAuto};

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-left: var(--gridMarginGap);
        padding-right: var(--gridMarginGap);
    }
`;

export function BlogBodyVideo(video: BlogVideoBlock): React.ReactElement {
    const config = useConfig();
    const [width, height] = (
        video.videoAspectRatio || DEFAULT_VIDEO_ASPECT_RATIO
    )
        .split(':')
        .map(Number);

    const [loaded, setLoaded] = useState<boolean>(false);

    const iframeFocusCallback = useCallback(
        (ref: HTMLIFrameElement | null): void => {
            if (ref) {
                ref.focus();
            }
        },
        [],
    );

    return (
        <VideoContainer>
            <Video $aspectRatio={{ width, height }}>
                {!loaded && (
                    <>
                        <VideoBlack />
                        <PlayButton
                            type="button"
                            aria-label={`Play video ${video.caption || ''}`}
                            onClick={(): void => {
                                setLoaded(true);
                            }}
                        >
                            Play
                        </PlayButton>
                        {video.posterImage?.file ? (
                            <PlayButtonBackground
                                src={video.posterImage.file}
                                aspectRatio={width / height}
                            />
                        ) : video.thumbnailUrl ? (
                            /* Thumbnail URL retrieved from YouTube/Vimeo */
                            <PlayButtonBackgroundRemote
                                src={video.thumbnailUrl}
                                alt=""
                            />
                        ) : null}
                    </>
                )}
                {loaded && video.video && (
                    <>
                        <VideoIframe
                            src={createEmbedVideoUrl(video.video, {
                                autoPlay: true,
                                looping: false,
                                hideControls: false,
                            })}
                            title={video.accessibilityTitle || 'Video'}
                            allow="autoplay; fullscreen"
                            allowFullScreen
                            ref={iframeFocusCallback}
                        />
                        {video.caption && (
                            <VideoCaption>
                                {processWagtailRichText(
                                    video.caption,
                                    config.customOpentypeHtmlTagNames,
                                )}
                            </VideoCaption>
                        )}
                    </>
                )}
            </Video>
        </VideoContainer>
    );
}
