import React from 'react';
import { useFontFamily } from './PageContext';
import FontIntroduction from './FontIntroduction';
import FontFamilyAccordion from './FontFamilyAccordion';
import FontInformation from './FontInformation';
import FontCounts from './FontCounts';
import FontRelease from './FontRelease';
import notPreRelease from '../utils/notPreRelease';

function FontFamilyInformation(): React.ReactElement {
    const fontFamily = useFontFamily();
    const { fontFamilyGroup, release } = fontFamily;

    const { fontFamilyCount, fontStyleCount } = React.useMemo(() => {
        const fontFamilies = fontFamilyGroup.isCollection
            ? fontFamilyGroup.fontFamilies
            : fontFamilyGroup.fontFamilies.filter(notPreRelease);
        const fontStyleCount = fontFamilies.reduce(
            (carry: number, fontFamily): number =>
                carry + fontFamily.fontStyleCount,
            0,
        );
        return {
            fontFamilyCount: fontFamilies.length,
            fontStyleCount,
        };
    }, [fontFamilyGroup]);

    return (
        <FontInformation
            intro={
                fontFamilyGroup && fontFamilyGroup.informationIntro ? (
                    <FontIntroduction
                        informationIntro={fontFamilyGroup.informationIntro}
                        blogPostSlug={
                            fontFamilyGroup.designInformationPage &&
                            fontFamilyGroup.designInformationPage.slug
                        }
                    />
                ) : null
            }
            aside={
                <>
                    {fontFamilyGroup.isCollection ||
                    !fontFamily.isPreRelease ? (
                        <FontCounts
                            isCollection={fontFamilyGroup.isCollection}
                            slug={fontFamilyGroup.slug}
                            fontFamilyCount={fontFamilyCount}
                            fontStyleCount={fontStyleCount}
                        />
                    ) : null}

                    {release && <FontRelease releaseDates={[release]} />}
                </>
            }
            content={<FontFamilyAccordion />}
        />
    );
}

export default FontFamilyInformation;
