import React from 'react';
import type { ContentBlock, DraftInlineStyle } from 'draft-js';
import {
    useEditorState,
    useFontStyleState,
} from '../components/TypeEditorContext';
import { useFontFamily } from '../components/PageContext';
import notUndefined from '../utils/notUndefined';
import notDuplicate from '../utils/notDuplicate';
import getCssFontFamilyNameFromId from '../utils/getCssFontFamilyNameFromId';

function getBlockInlineStyles(block: ContentBlock): DraftInlineStyle[] {
    return block
        .getCharacterList()
        .toArray()
        .filter(notUndefined)
        .map((character): DraftInlineStyle => character.getStyle());
}

export default function useTypeEditorUsedStyles(): string[] {
    const [fontStyle] = useFontStyleState();
    const [editorState] = useEditorState();
    const { fontStyles } = useFontFamily();

    const draftInlineStyles: DraftInlineStyle[] = React.useMemo(
        () =>
            editorState
                .getCurrentContent()
                .getBlocksAsArray()
                .filter(notUndefined)
                .reduce(
                    (carry: DraftInlineStyle[], block): DraftInlineStyle[] => [
                        ...carry,
                        ...getBlockInlineStyles(block),
                    ],
                    [],
                )
                /*
                 * Adjacent characters with the same styles have referentially equal
                 * DraftInlineStyle objects, which means we can drastically improve the
                 * performance of the upcoming search loop by removing duplicates here.
                 */
                .filter(notDuplicate),
        [editorState],
    );

    return React.useMemo(
        () =>
            fontStyles
                .filter(
                    (style): boolean =>
                        style.id === fontStyle ||
                        draftInlineStyles.some((draftInlineStyle): boolean =>
                            draftInlineStyle.includes(
                                getCssFontFamilyNameFromId(style.id),
                            ),
                        ),
                )
                .map((style): string => style.name),
        [draftInlineStyles, fontStyles, fontStyle],
    );
}
