import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

export const ulStyles = css`
    --bulletOffset: 1em;

    margin: 0;
    list-style: none;
    padding-left: var(--bulletOffset);

    li::before {
        position: absolute;
        content: '\\2022';
        margin-left: calc(-1 * var(--bulletOffset));
        text-align: right;
        display: inline-block;
    }
`;

export const olStyles = css`
    --listOffset: 1.6em;

    list-style: none;
    counter-reset: item;
    padding-left: var(--listOffset);

    li {
        counter-increment: item;
    }

    li::before {
        position: absolute;
        content: counter(item) '.';
        margin-left: calc(-1 * var(--listOffset));
        width: 1.2em;
        text-align: left;
        display: inline-block;
    }
`;

export const Ul = styled.ul`
    ${ulStyles}
`;

export const Ol = styled.ol`
    ${olStyles}
`;

export const Li = styled.li``;

export const Code = styled.code`
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    font-family: Pitch, courier, monospace;
    font-size: var(--fontSizeFixed1);
    padding: var(--spacing2);
    background: var(--foregroundColorMix8);
    display: block;
    overflow: scroll hidden;
`;

export const Italic = styled.i`
    font-style: italic;
`;

const linkStyles = css`
    text-decoration: none;
`;

export const Blockquote = styled.blockquote`
    border-left: 1px solid var(--foregroundColorMix8);
    padding-left: var(--spacing2);
    font-style: italic;
`;

export const A = styled.a`
    ${linkStyles};
`;

export const Link = styled(GatsbyLink)`
    ${linkStyles};
`;
