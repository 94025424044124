import type { FontFamily } from '../components/PageContext';
import type { FontStyle } from '../hooks/useAllFonts';
import { getSrc } from '../components/FontFace';
import type { Font } from '../components/FontFace';
import { createStaticAssetUrl } from './urlHelper';
import getCssFontFamilyNameFromId from './getCssFontFamilyNameFromId';
import isBrowser from './isBrowser';
import { sentryException } from './sentry';

type UnionFontStyle = FontFamily['fontStyles'][number] | FontStyle | Font;

const loadedFontFaceDefinitions: string[] = [];

function handleLoadSuccess(loadedFace: FontFace): void {
    document.fonts.add(loadedFace);
}

export function isFontFaceDefinitionAdded(fontFamilyName: string): boolean {
    return loadedFontFaceDefinitions.indexOf(fontFamilyName) > -1;
}

function markFontFaceDefinitionAsAdded(fontFamilyName: string): void {
    loadedFontFaceDefinitions.push(fontFamilyName);
}

function getFontFaceToLoad(
    fontFamilyName: string,
    fontStyle: UnionFontStyle,
): FontFace | undefined {
    if (!fontStyle.ttfFiles?.woff2File) {
        sentryException(
            new Error(`Couldn't load WOFF2 for FontStyle ${fontFamilyName}`),
        );
        return;
    }

    const fontSrc = getSrc({
        woff2: createStaticAssetUrl(fontStyle.ttfFiles.woff2File),
    });

    const fontWeight =
        'weight' in fontStyle ? fontStyle.weight : fontStyle.fontWeight;

    return new FontFace(fontFamilyName, fontSrc, {
        weight: String(fontWeight),
    });
}

export default function ensureFontFaceIsLoaded(
    fontStyle: UnionFontStyle,
): void {
    if (!isBrowser()) {
        return;
    }

    const fontFamilyName = getCssFontFamilyNameFromId(
        'fontFamily' in fontStyle ? fontStyle.fontFamily : fontStyle.id,
    );

    if (isFontFaceDefinitionAdded(fontFamilyName)) {
        return;
    }

    const fontFace = getFontFaceToLoad(fontFamilyName, fontStyle);

    if (!fontFace) {
        return;
    }

    fontFace.load().then(handleLoadSuccess);

    markFontFaceDefinitionAsAdded(fontFamilyName);
}
