/**
 * A simple centralised method to turn an ID (originally a Django UUID)
 * into a safe font family name for CSS usage. Because UUIDs can start
 * with a number we simply prefix with an underscore in that case.
 * @param id
 */
export default function getCssFontFamilyNameFromId(id: string): string {
    if (/^\d/.test(id)) {
        return `_${id}`;
    }
    return id;
}
