export class FontMetricsCalculator {
    lineHeight: number | 'normal';
    unitsPerEm: number;
    capHeight: number;
    ascentCalc: number;
    ascent: number;
    descentCalc: number;
    descent: number;
    lineGap: number;

    constructor(options: {
        lineHeight: number | 'normal';
        unitsPerEm: number;
        capHeight: number;
        ascentCalc: number;
        ascent: number;
        descentCalc: number;
        descent: number;
        lineGap: number;
    }) {
        this.lineHeight = options.lineHeight;
        this.unitsPerEm = options.unitsPerEm;
        this.capHeight = options.capHeight;
        this.ascentCalc = options.ascentCalc;
        this.ascent = options.ascent;
        this.descentCalc = options.descentCalc;
        this.descent = options.descent;
        this.lineGap = options.lineGap;
    }

    public calculate(): FontMetricOffsets {
        const absoluteDescent = Math.abs(this.descent);
        const capHeightScale = this.capHeight / this.unitsPerEm;
        const descentCalcScale = this.descentCalc / this.unitsPerEm;
        const ascentScale = this.ascent / this.unitsPerEm;
        const ascentCalcScale = this.ascentCalc / this.unitsPerEm;
        const lineGapScale = this.lineGap / this.unitsPerEm;
        const contentArea = this.ascent + this.lineGap + absoluteDescent;
        const lineHeightScale = contentArea / this.unitsPerEm;
        const emSquareTopOffset = (lineHeightScale - 1) / 2;
        const emSquareBottomOffset = 1 + emSquareTopOffset;
        const baselineOffset = ascentScale;
        const ascentOffset = baselineOffset - ascentCalcScale;
        const descentOffset = baselineOffset - descentCalcScale;
        const capHeightOffset = baselineOffset - capHeightScale;
        const lineHeightNumber =
            this.lineHeight === 'normal' ? lineHeightScale : this.lineHeight;
        const lineHeightOffset = (lineHeightScale - lineHeightNumber) / -2;
        const lineGapOffset = lineGapScale / 2;
        const totalOffset = lineHeightOffset + lineGapOffset;
        return {
            ascent: ascentOffset + totalOffset,
            descent: descentOffset + totalOffset - lineHeightNumber,
            baseline: baselineOffset + totalOffset,
            capHeight: capHeightOffset + totalOffset,
            emSquareTop: emSquareTopOffset + totalOffset,
            emSquareBottom: emSquareBottomOffset + totalOffset,
        };
    }
}

export type FontMetricOffsets = {
    ascent: number;
    descent: number;
    baseline: number;
    capHeight: number;
    emSquareTop: number;
    emSquareBottom: number;
};
