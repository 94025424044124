/**
 * Specific Stripe error messages.
 *
 * Generally we'll use Stripe's default error messages,
 * but for some error codes we'll have our own message.
 *
 * See https://stripe.com/docs/error-codes
 */
export const STRIPE_ERROR_MESSAGES = {
    processing_error:
        'We’re sorry, an error occurred while processing your card. ' +
        'Please try again soon.',
    card_declined:
        'We’re sorry, your card has been declined for an unknown reason. ' +
        'Please contact your card issuer for more information.',
    amount_too_large:
        'The total amount of your order is greater than the maximum allowed. ' +
        'Please try making separate smaller orders or contact us to organise an ' +
        'alternative payment method.',
};

/**
 * These match the `decline_code` that Stripe sometimes
 * passes along with a `card_declined` error.
 *
 * We only specify it here if the message should be
 * different to the generic `card_declined` specified above.
 *
 * See https://stripe.com/docs/declines/codes
 */
export const STRIPE_DECLINE_MESSAGES = {
    approve_with_id:
        'We’re sorry, an error occurred while processing your card. ' +
        'Please try again soon. ' +
        'Alternatively contact your card issuer for more information.',
    card_not_supported:
        'We’re sorry, your card does not appear to support this type of purchase. ' +
        'Please contact your card issuer for more information.',
    currency_not_supported:
        'We’re sorry, your card does not appear to support this currency. ' +
        'Please contact your card issuer for more information.',
    duplicate_transaction:
        'An identical transaction was submitted very recently. ' +
        'Please check your Klim Account or contact your card issuer for more information.',
    expired_card:
        'We’re sorry, your card appears to have expired. ' +
        'Please contact your card issuer for more information.',
    incorrect_number:
        'We’re sorry, the entered card number appears to be incorrect. ' +
        'Please try again.',
    incorrect_cvc:
        'We’re sorry, the entered CVC security code appears to be incorrect. ' +
        'Please try again.',
    incorrect_pin:
        'We’re sorry, the entered card PIN appears to be incorrect. ' +
        'Please try again.',
    invalid_cvc:
        'We’re sorry, the entered CVC security code appears to be incorrect. ' +
        'Please try again.',
    invalid_expiry_year:
        'We’re sorry, the entered expiry year appears to be incorrect. ' +
        'Please try again.',
    invalid_number:
        'We’re sorry, the entered card number appears to be incorrect. ' +
        'Please try again.',
    invalid_pin:
        'We’re sorry, the entered card PIN appears to be incorrect. ' +
        'Please try again.',
    issuer_not_available:
        'We’re sorry, an error occurred while processing your card. ' +
        'Please try again soon. ' +
        'Alternatively contact your card issuer for more information.',
    pin_try_exceeded:
        'We’re sorry, the allowable number of PIN tries has been exceeded. ' +
        'Please contact your card issuer for more information.',
    processing_error:
        'We’re sorry, an error occurred while processing your card. ' +
        'Please try again or contact your card issuer for more information.',
    reenter_transaction:
        'We’re sorry, an error occurred while processing your card. ' +
        'Please try again. ' +
        'Alternatively contact your card issuer for more information.',
    try_again_later:
        'We’re sorry, an error occurred while processing your card. ' +
        'Please try again soon. ' +
        'Alternatively contact your card issuer for more information.',
};
