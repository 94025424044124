import { useStaticQuery, graphql } from 'gatsby';
import type { CustomFontsStubsQuery } from '../gql/api-ssr';

export type CustomFontsStub =
    CustomFontsStubsQuery['ssr']['customFonts'][number];

export default function useCustomFontsStubs(): CustomFontsStub[] {
    return useStaticQuery<CustomFontsStubsQuery>(graphql`
        query CustomFontsStubsQuery {
            ssr {
                customFonts {
                    __typename
                    creditClient
                    id
                    isPreRelease
                    name
                    slug
                    svgNavigation
                    backgroundColor: colorBackground
                    foregroundColor: colorForeground
                    highlightColor: colorHighlight
                    styles {
                        name
                        family {
                            name
                            sortOrder
                        }
                    }
                }
            }
        }
    `).ssr.customFonts;
}
