import React from 'react';
import styled from 'styled-components';
import { A as InlineUnderlinedLink } from './InlineUnderlinedLink';
import { HeadingMedium } from './Heading';
import { Paragraph } from './Paragraph';
import { ParagraphExtraLarge } from './Paragraph';
import { VIEWPORT } from '../settings/Global';

const Container = styled.div`
    display: grid;
    grid-template-columns: auto;
    padding-bottom: var(--spacing9) 0;
    grid-row-gap: var(--spacing1);
`;

const LargeGap = styled.div`
    padding: var(--spacing8) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding: var(--spacing6) 0;
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    line-height: var(--lineHeightBody1);
    grid-gap: var(--spacing7) var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        line-height: var(--lineHeightBody2);
        grid-row-gap: var(--spacing3);
    }
`;

const Column = styled.div`
    &:nth-child(odd) {
        grid-column: 7 / span 5;
    }

    &:nth-child(even) {
        grid-column: 3 / span 3;
    }

    &:nth-child(1) {
        grid-column: 1 / span 1;
        padding: 0 0 var(--spacing3) 0;
    }

    &:nth-child(2) {
        grid-column: 3 / span 4;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:nth-child(odd) {
            padding: 0 0 var(--spacing6) 0;
            grid-column: 1 / -1;
        }

        &:nth-child(even) {
            grid-column: 1 / -1;
        }

        &:nth-child(1) {
            padding: 0 0 var(--spacing3) 0;
        }
    }
`;

const Content = styled(Paragraph)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

const Heading = styled(HeadingMedium)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed4);
    }
`;

const SubHeading = styled(ParagraphExtraLarge)`
    font-size: var(--fontSizeFixed5);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed4);
    }
`;

function AboutDoubleColumnBlock(): React.ReactElement {
    return (
        <LargeGap>
            <Row>
                <Column>Collaborators</Column>

                <Column>
                    <Container>
                        <Heading>Tyrone Ohia</Heading>
                        <SubHeading>Graphic design</SubHeading>
                    </Container>
                </Column>
                <Column>
                    <Container>
                        <Content>
                            Originally from Tauranga and of Ngāti Pūkenga
                            descent, Tyrone Ohia is a Creative Director based in
                            Tāmaki Makaurau. He graduated from the Whanganui
                            School of Design in 2008 and has previously worked
                            as a Design Director at award winning studio Alt
                            Group. He believes strongly in creativity and thinks
                            design is for the people.
                        </Content>

                        <Content>
                            <InlineUnderlinedLink href="https://www.tyroneohia.com">
                                tyroneohia.com
                            </InlineUnderlinedLink>
                        </Content>
                    </Container>
                </Column>

                <Column>
                    <Container>
                        <Heading>Kelvin Soh</Heading>
                        <SubHeading>Graphic & strategy</SubHeading>
                    </Container>
                </Column>
                <Column>
                    <Container>
                        <Content>
                            Kelvin Soh is the founder and creative director of
                            DDMMYY studio and works at the intersection of art
                            and visual culture, graphic design, brand ideation,
                            brand strategy and publishing. With a holistic view
                            of practice, Kelvin works strategically and
                            conceptually across a broad range of projects, with
                            a sensitivity to storytelling, audience and context.
                            Kelvin is also the creative director of art/culture
                            magazine, Le Roy, and was the co-founder of New New
                            with Jamie Duff, a ventures studio and 360°
                            strategic consultancy with three active ventures in
                            market.
                        </Content>

                        <Content>
                            <InlineUnderlinedLink href="http://www.leroy.xxx/">
                                leroy.xxx
                            </InlineUnderlinedLink>
                        </Content>
                    </Container>
                </Column>

                <Column>
                    <Container>
                        <Heading>Alt Group</Heading>
                        <SubHeading>Graphic & strategy</SubHeading>
                    </Container>
                </Column>
                <Column>
                    <Container>
                        <Content>
                            Alt Group was founded by Ben Corban and Dean Poole
                            in 1999. Alt Group is a multidisciplinary design
                            firm based in Auckland. The company works across:
                            strategy design, content design, graphic design,
                            communications design, interactive design and
                            experience design. Alt Group has won more than 500
                            international and national awards, and works for a
                            diverse range of corporates and government
                            organisations including Auckland Art Gallery, Fisher
                            & Paykel, Auckland Council and City of Melbourne.
                        </Content>
                        <Content>
                            <InlineUnderlinedLink href="http://www.altgroup.net/">
                                altgroup.net
                            </InlineUnderlinedLink>
                        </Content>
                    </Container>
                </Column>

                <Column>
                    <Container>
                        <Heading>Springload</Heading>
                        <SubHeading>Website design & development</SubHeading>
                    </Container>
                </Column>
                <Column>
                    <Container>
                        <Content>
                            Springload is Wellington’s largest independently
                            owned digital agency. They’re changing the way
                            businesses, government, and not-for-profits work and
                            connect. They do this through the clever use of
                            technology and by taking a human-centred approach to
                            everything they do. Springload improves the lives of
                            their customers, colleagues and communities by
                            making digital experiences that matter, better.
                        </Content>

                        <Content>
                            <InlineUnderlinedLink href="https://www.springload.co.nz">
                                springload.co.nz
                            </InlineUnderlinedLink>
                        </Content>
                    </Container>
                </Column>
            </Row>
        </LargeGap>
    );
}

export default React.memo(AboutDoubleColumnBlock);
