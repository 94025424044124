import React from 'react';
import styled from 'styled-components';
import {
    A as UnderlinedLink,
    styles as inlineUnderlinedStyles,
} from '../components/InlineUnderlinedLink';
import FragmentLink from '../components/FragmentLink';
import { HeadingExtraLarge } from '../components/Heading';
import { createSupportEmailUrl } from '../utils/urlHelper';
import faqAccordions from '../data/faqAccordions';
import { HeadingSmall } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { Accordion, AccordionItem } from '../components/Accordion';
import { VIEWPORT } from '../settings/Global';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    display: grid;
    grid-template-columns: 100%;
`;

export const ColumnUl = styled.ul`
    margin-bottom: var(--spacing7);
    display: block;
    columns: 3;
    column-gap: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        columns: 4;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        columns: 3;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        columns: 2;
    }
`;

const Inner = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-row-gap: var(--spacing4);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing7);
    }
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    padding-top: var(--spacing7);

    &:nth-child(1) {
        padding-top: 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
        margin-top: 0;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        line-height: var(--lineHeightBody2);
        grid-row-gap: var(--spacing3);
    }
`;

const Column = styled.div`
    grid-column: 3 / span 6;

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span var(--gridColumnCount);
    }
`;

const ScrollableLink = styled(FragmentLink)`
    ${inlineUnderlinedStyles}
`;

const LightParagraph = styled(Paragraph)`
    color: var(--foregroundColorMix4);
    margin-bottom: var(--spacing6);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing3);
    }
`;

const Heading = styled(HeadingSmall)`
    margin-bottom: var(--spacing4);
`;

const Link = styled(UnderlinedLink)`
    color: var(--foregroundColorMix4);
`;

function FaqPage(): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <>
            <Container>
                <Row>
                    <Column>
                        <Inner>
                            <HeadingExtraLarge
                                $metricOffsets={staticFontMetrics}
                                as="h1"
                            >
                                Frequently asked questions
                            </HeadingExtraLarge>
                            <LightParagraph>
                                If your question is not answered below, or you
                                require additional support, please contact{' '}
                                <Link href={createSupportEmailUrl(true)}>
                                    {createSupportEmailUrl()}
                                </Link>
                                .
                            </LightParagraph>
                        </Inner>

                        <ColumnUl>
                            {faqAccordions.map(
                                ({ id, heading }): React.ReactElement => (
                                    <li key={id}>
                                        <ScrollableLink href={`#${id}`}>
                                            {heading}
                                        </ScrollableLink>
                                    </li>
                                ),
                            )}
                        </ColumnUl>
                    </Column>
                </Row>
                {faqAccordions.map(
                    ({ id, heading, items }): React.ReactElement => (
                        <Row key={id} id={id}>
                            <Column>
                                <Heading>{heading}</Heading>
                                {items.map(
                                    (
                                        { subHeading, content },
                                        index,
                                    ): React.ReactElement => (
                                        <Accordion key={`faqa-${index}`}>
                                            <AccordionItem
                                                heading={subHeading}
                                                headingLevel={3}
                                                panel={content}
                                            />
                                        </Accordion>
                                    ),
                                )}
                            </Column>
                        </Row>
                    ),
                )}
            </Container>
        </>
    );
}

export default React.memo(FaqPage);
