/*
 * A custom 'Error' type which can be thrown+caught independently of other
 * unexpected runtime errors.
 *
 * Typically, this error would only be thrown with a customer-readable message.
 */
class PaymentError extends Error {
    public readonly name = 'PaymentError';
}

export default PaymentError;
