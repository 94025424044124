import React from 'react';
import styled, { css } from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import _LazyResponsiveImage, { type ImageSource } from './LazyResponsiveImage';
import getScaleToCoverViewport from '../utils/getScaleToCoverViewport';
import useLandscapePortrait from '../hooks/useLandscapePortrait';
import type { CampaignLayoutMode } from '../union-types/campaign';
import filenameIsSvg from '../utils/filenameIsSvg';
import type { ImageFetchPriority } from './LazyImage';
import { useGlobalState } from './GlobalRuntimeState';

const ImageWrapper = styled.div<{
    $showWithinGrid: boolean | undefined;
    $marginOverlapPercentage: number | null | undefined;
    $adjustForBrowserChrome: boolean | undefined;
}>`
    display: block;

    --viewportHeight: 100vh;
    ${({ $adjustForBrowserChrome }): ReturnType<typeof css> | null =>
        $adjustForBrowserChrome
            ? css`
                  --viewportHeight: 100svh;
              `
            : null}

    ${({
        $showWithinGrid,
        $marginOverlapPercentage,
    }): ReturnType<typeof css> => {
        return $showWithinGrid
            ? css`
                  /*
                  Worth noting that this is currently optimised for Collection pages,
                  in terms of spacing and alignment. Work (refactoring...) needs to be
                  done to use this elsewhere.
                 */
                  --withinGridMargin: ${$marginOverlapPercentage
                      ? `-${$marginOverlapPercentage / 2}%`
                      : `0px`};

                  margin: 0 var(--withinGridMargin);

                  --imageHeight: calc(
                      var(--viewportHeight) - var(--gridMarginGap) - var(
                              --navbarHeight
                          )
                  );
                  ${$marginOverlapPercentage
                      ? css`
                            --imageWidth: calc(
                                100% - (2 * var(--withinGridMargin))
                            );
                        `
                      : css`
                            --imageWidth: calc(
                                100vw - (2 * var(--gridMarginGap)) -
                                    (2 * var(--withinGridMargin))
                            );
                        `}
              `
            : css`
                  --imageHeight: var(--viewportHeight);
                  --imageWidth: 100vw;

                  max-width: 100%; /* ensure Windows doesn't add extra width for a scrollbar */
              `;
    }};
    height: var(--imageHeight);
    width: var(--imageWidth);
`;

const LazyResponsiveImage = styled(_LazyResponsiveImage)<{
    $showWithinGrid: boolean | undefined;
    $withinPageMargins: boolean | undefined;
}>`
    height: var(--viewportHeight);
    width: 100vw;
    max-width: 100%; /* ensure Windows doesn't add extra width for a scrollbar */
    object-fit: cover;

    ${({
        $withinPageMargins,
        $showWithinGrid,
    }): ReturnType<typeof css> | null =>
        $showWithinGrid
            ? css`
                  width: var(--imageWidth);
                  max-height: 100%;
                  object-fit: contain;
                  object-position: bottom;
              `
            : $withinPageMargins
              ? css`
                    height: var(--imageHeight);
                    width: var(--imageWidth);
                `
              : null};
`;

export interface ImageMeta {
    src: string;
    width?: number;
    height?: number;
    layoutMode?: CampaignLayoutMode;
    marginOverlap?: number;
}

function ImageFullBleed({
    landscapeImage,
    portraitImage,
    onLoadCallback,
    preload,
    hidden,
    lazyLoad,
    imageQuality,
    fetchPriority,
    adjustForBrowserChrome,
    withinPageMargins,
}: {
    landscapeImage: ImageMeta;
    portraitImage?: ImageMeta;
    onLoadCallback?: () => void;
    preload?: boolean;
    hidden?: boolean;
    lazyLoad?: boolean;
    imageQuality?: number;
    fetchPriority?: ImageFetchPriority;
    adjustForBrowserChrome?: boolean;
    /* This shows the image with a width that deducts the page margins. I.e. not entirely full width. */
    withinPageMargins?: boolean;
}): React.ReactElement | null {
    const mode = useLandscapePortrait();
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [viewportWidthWithoutMargins] = useGlobalState(
        'viewportWidthWithoutMargins',
    );
    const [viewportHeight] = useGlobalState('viewportHeight');

    const isLandscape = mode === 'LANDSCAPE' && landscapeImage;
    const isPortrait = !isLandscape && portraitImage;

    /* This is a campaign image setting, used e.g. by Mānuka, to show a JPEG sitting inside the page margins */
    const showWithinGrid = isPortrait
        ? portraitImage.layoutMode === 'WITHIN_GRID'
        : landscapeImage.layoutMode === 'WITHIN_GRID';
    const marginOverlapPercentage = showWithinGrid
        ? isPortrait
            ? portraitImage.marginOverlap
            : landscapeImage.marginOverlap
        : undefined;
    const primaryImage = landscapeImage || portraitImage;

    const sources: ImageSource[] = React.useMemo(() => {
        const sources: ImageSource[] = [];

        const calculateWidth = (
            image: ImageMeta,
            defaultWidth: number,
        ): number => {
            const { width, height } = image;
            if (!width || !height) {
                return defaultWidth;
            }
            const viewportWidthToUse = withinPageMargins
                ? viewportWidthWithoutMargins
                : viewportWidth;
            const [scaledWidth] = getScaleToCoverViewport(
                width / height,
                viewportWidthToUse,
                viewportHeight,
            );
            return Math.min(width, Math.ceil(scaledWidth));
        };

        const generateSource = (
            image: ImageMeta,
            defaultWidth: number,
            mediaQuery?: string,
        ): void => {
            if (filenameIsSvg(image.src)) {
                const source: ImageSource = { src: image.src };
                if (mediaQuery) {
                    source.media = mediaQuery;
                }
                sources.push(source);
            } else {
                const width = calculateWidth(image, defaultWidth);
                sources.push({
                    src: image.src,
                    sizes: [`${width}px`],
                    media: mediaQuery,
                });
            }
        };

        if (landscapeImage && portraitImage) {
            const portraitMediaQuery = `(max-width: ${VIEWPORT.TABLET}px) and (orientation: portrait)`;
            generateSource(portraitImage, 2000, portraitMediaQuery);
            generateSource(landscapeImage, 3000);
        } else {
            generateSource(primaryImage, 3000);
        }
        return sources;
    }, [
        landscapeImage,
        portraitImage,
        primaryImage,
        viewportWidth,
        viewportWidthWithoutMargins,
        viewportHeight,
        withinPageMargins,
    ]);

    return (
        <ImageWrapper
            $showWithinGrid={showWithinGrid || withinPageMargins}
            $marginOverlapPercentage={marginOverlapPercentage}
            $adjustForBrowserChrome={adjustForBrowserChrome}
            style={
                hidden
                    ? {
                          display: 'none',
                      }
                    : undefined
            }
        >
            <LazyResponsiveImage
                $showWithinGrid={showWithinGrid}
                $withinPageMargins={withinPageMargins}
                src={primaryImage.src}
                sources={sources}
                onLoadCallback={onLoadCallback}
                preload={preload}
                lazyLoad={lazyLoad}
                imageQuality={imageQuality}
                fetchPriority={fetchPriority}
            />
        </ImageWrapper>
    );
}

export default React.memo(ImageFullBleed);
