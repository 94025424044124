import type { Font, Glyph } from 'opentype.js';
import type { FontFamily } from '../../components/PageContext';
import notUndefined from '../notUndefined';

type FontStyle = FontFamily['fontStyles'][number];

export interface Metric {
    name: string;
    yoffset: number;
    value: number;
}

const getGlyphYs = (glyph: Glyph): number[] => {
    const { commands } = glyph.path;
    return commands
        .map((command): number[] | undefined =>
            command.type !== 'Z'
                ? command.type === 'C'
                    ? [command.y, command.y1, command.y2]
                    : [command.y]
                : undefined,
        )
        .filter(notUndefined)
        .flat();
};

const getYMax = (font: Font): number => {
    const { yMax } = font.tables.head;
    // Give a little extra headroom to all Y's, also, as sometimes a curve extends above the highest point
    const headroom = 25;
    const yMaxNumber = yMax === undefined ? 0 : yMax + headroom;
    // 'hcircumflex' is sometimes higher than yMax!
    if (!font.hasChar('ĥ')) {
        return yMaxNumber;
    }
    const glyph = font.charToGlyph('ĥ');
    const glyphYs = getGlyphYs(glyph).map((y) => y + headroom);
    return Math.max(yMaxNumber, ...glyphYs);
};

const getMetrics = (
    fontStyle: FontStyle,
    glyphScale: number,
    glyphBaseline: number,
): Metric[] => {
    return [
        fontStyle.otfFiles?.metrics
            ? {
                  name: 'Ascender',
                  yoffset:
                      glyphBaseline -
                      fontStyle.otfFiles.metrics.ascentCalc * glyphScale,
                  value: fontStyle.otfFiles.metrics.ascentCalc,
              }
            : undefined,
        fontStyle.otfFiles?.metrics
            ? {
                  name: 'Cap height',
                  yoffset:
                      glyphBaseline -
                      fontStyle.otfFiles.metrics.capHeightOs2 * glyphScale,
                  value: fontStyle.otfFiles.metrics.capHeightOs2,
              }
            : undefined,
        fontStyle.otfFiles?.metrics
            ? {
                  name: 'X-height',
                  yoffset:
                      glyphBaseline -
                      fontStyle.otfFiles.metrics.xHeightOs2 * glyphScale,
                  value: fontStyle.otfFiles.metrics.xHeightOs2,
              }
            : undefined,
        {
            name: 'Baseline',
            yoffset: glyphBaseline,
            value: 0,
        },
        fontStyle.otfFiles?.metrics
            ? {
                  name: 'Descender',
                  yoffset:
                      glyphBaseline -
                      fontStyle.otfFiles.metrics.descentCalc * glyphScale,
                  value: fontStyle.otfFiles.metrics.descentCalc,
              }
            : undefined,
    ].filter(notUndefined);
};

export const getGlyphScale = ({
    font,
    canvasWidth,
    canvasHeight,
}: {
    font: Font;
    canvasWidth: number;
    canvasHeight: number;
}): number => {
    const { xMin, xMax, yMin } = font.tables.head;
    const yMax = getYMax(font);
    const glyphWidth = xMax - xMin;
    const glyphHeight = yMax - yMin;

    // Scale the glyph up so that it stretches to fit the canvas.
    return Math.min(canvasWidth / glyphWidth, canvasHeight / glyphHeight);
};

export const getGlyphBaseline = ({
    capHeight,
    canvasHeight,
    scale,
}: {
    capHeight: number;
    canvasHeight: number;
    scale: number;
}): number => {
    const halfCapHeight = (capHeight / 2) * scale;
    const halfCanvasHeight = canvasHeight / 2;

    return halfCapHeight + halfCanvasHeight;
};

export function fontMetricData(
    font: Font,
    fontStyle: FontStyle,
    width: number,
    height: number,
    pixelRatio: number,
): Metric[] {
    const canvasWidth = width * pixelRatio;
    const canvasHeight = height * pixelRatio;

    const glyphScale = getGlyphScale({
        font,
        canvasHeight,
        canvasWidth,
    });
    const glyphBaseline = getGlyphBaseline({
        capHeight: fontStyle.otfFiles?.metrics
            ? fontStyle.otfFiles.metrics.capHeightOs2
            : font.tables.os2.sCapHeight,
        canvasHeight,
        scale: glyphScale,
    });

    return getMetrics(fontStyle, glyphScale, glyphBaseline);
}
