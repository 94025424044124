import React from 'react';
import styled from 'styled-components';
import AboutContentBlock from '../components/AboutContentBlock';
import AboutContentImageBlock from '../components/AboutContentImageBlock';
import AboutDoubleColumnBlock from '../components/AboutDoubleColumnBlock';
import AboutFooter from '../components/AboutFooter';
import { VIEWPORT } from '../settings/Global';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    display: grid;
    grid-template-columns: 100%;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding: var(--spacing5) 0 var(--spacing9) 0;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding: var(--spacing8) 0 var(--spacing9) 0;
    }
`;

const Spacer = styled.div`
    border-bottom: 1px solid var(--borderColor);
`;

function AboutPage(): React.ReactElement {
    return (
        <Container>
            <AboutContentBlock />
            <AboutContentImageBlock />
            <Spacer />
            <AboutDoubleColumnBlock />
            <Spacer />
            <AboutFooter />
        </Container>
    );
}

export default React.memo(AboutPage);
