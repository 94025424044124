const fallbackWindowWidth = 1920;
const fallbackWindowHeight = 1080;

export default function getScaleToCoverViewport(
    ratio: number,
    windowWidth: number = fallbackWindowWidth,
    windowHeight: number = fallbackWindowHeight,
    maxWidth?: number,
    marginOverlapPercentage: number | null = 0,
): [number, number] {
    let height = windowHeight;
    let width = windowHeight * ratio;

    if (width < windowWidth) {
        width = windowWidth;
        height = windowWidth / ratio;
    }

    if (maxWidth && width > maxWidth) {
        width = maxWidth;
        height = maxWidth / ratio;
    }

    // We can make the scale overlap the margins, if we like. I.e. show bigger.
    if (marginOverlapPercentage) {
        width *= 1 + marginOverlapPercentage / 100;
        height *= 1 + marginOverlapPercentage / 100;
    }

    return [width, height];
}
