export default function convert3DigitHexColorTo6Digits(
    hexColor: string,
): string {
    return hexColor
        .split('')
        .map(function (hex) {
            if (hex === '#') {
                return hex;
            }
            return hex + hex;
        })
        .join('');
}
