import React from 'react';
import styled from 'styled-components';
import InUseIndexFontsInUse from '../components/InUseIndexFontsInUse';
import { HeadingSuperLarge } from '../components/Heading';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageDefault);
    padding-top: 50vh;
    margin: 0 var(--gridMarginGap);
    display: grid;
    grid-template-columns: 100%;
    min-height: 90vh;
`;

const Hero = styled(HeadingSuperLarge)`
    padding-bottom: 5px;
`;

function InUseIndexPage(): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <Container>
            <Hero $metricOffsets={staticFontMetrics}>Fonts in use</Hero>
            <InUseIndexFontsInUse />
        </Container>
    );
}

export default React.memo(InUseIndexPage);
