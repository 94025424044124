import React from 'react';
import { createGlobalStyle, css, type DefaultTheme } from 'styled-components';
import mix from 'polished/lib/color/mix';
import rgba from 'polished/lib/color/rgba';
import {
    CART_IN_PAGE_BREAKPOINT,
    COLOR,
    FONT_FAMILY,
    LINE_HEIGHT,
    MARGIN_BIG,
    MARGIN_SMALL,
    NAVBAR_VERTICAL_MARGIN,
    VIEWPORT,
} from '../settings/Global';
import { resetColorVariables } from '../utils/stylesMixins';
import getLozengeHeight from '../utils/getLozengeHeight';

function serializeTheme(theme: DefaultTheme | undefined): string {
    if (!theme) {
        return '';
    }
    return Object.keys(theme)
        .sort()
        .reduce((previousValue, currentValue) => {
            return previousValue + theme[currentValue];
        }, '');
}

function arePropsEqual(
    prevProps: { theme?: DefaultTheme },
    nextProps: { theme?: DefaultTheme },
): boolean {
    return serializeTheme(prevProps.theme) === serializeTheme(nextProps.theme);
}

const MIX_INCREMENTS = 9;

export function getMixedColor(weight: number, theme: DefaultTheme): string {
    // Depending on the contrast ratio between background and foreground
    // we will adjust the colours. If the contrast ratio is low then we'll
    // adjust more. If it's high anyway then there's little (or no) need
    // for adjustment.
    // See https://www.w3.org/TR/WCAG20/#contrast-ratiodef

    // Put on a scale of 0 to 1
    const normalisedContrastRatio = 1 - theme.contrastRatio / 21;

    // The maximum adjustment is 60% for dark themes and 30% adjusted for contrast ratio for light ones.
    const maxAdjustmentRatio =
        theme.backgroundLuminance < 0.5 ? 0.6 : normalisedContrastRatio * 0.3;

    // The final maximum adjustment
    const maxAdjustment = maxAdjustmentRatio * normalisedContrastRatio;

    // We are mixing 9 in-between steps of colour, so put it onto a scale of 0 to 1, not 0 to 0.9
    const weightNormalised = (weight / MIX_INCREMENTS) * 10;

    const divisor = 1 + weightNormalised * maxAdjustment;

    const mixAmount = weight / divisor;

    return mix(mixAmount, theme.backgroundColor, theme.foregroundColor);
}

export const CssColorVariableDefinitions = React.memo(
    createGlobalStyle`
        :root {
            /* #################################################################
            COLORS
            ################################################################# */

            ${({ theme }): ReturnType<typeof css> => css`
                --primaryColorRef: ${theme.foregroundColor};
                --mixedColor1Ref: ${getMixedColor(1 / MIX_INCREMENTS, theme)};
                --mixedColor2Ref: ${getMixedColor(2 / MIX_INCREMENTS, theme)};
                --mixedColor3Ref: ${getMixedColor(3 / MIX_INCREMENTS, theme)};
                --mixedColor4Ref: ${getMixedColor(4 / MIX_INCREMENTS, theme)};
                --mixedColor5Ref: ${getMixedColor(5 / MIX_INCREMENTS, theme)};
                --mixedColor6Ref: ${getMixedColor(6 / MIX_INCREMENTS, theme)};
                --mixedColor7Ref: ${getMixedColor(7 / MIX_INCREMENTS, theme)};
                --mixedColor8Ref: ${mix(
                    8 / MIX_INCREMENTS,
                    theme.backgroundColor,
                    theme.foregroundColor,
                )};
                --secondaryColorRef: ${theme.backgroundColor};
                --tertiaryColorRef: ${theme.highlightColor};
                --secondaryColorGradientMin: ${rgba(theme.backgroundColor, 0)};
                --secondaryColorGradientMax: ${rgba(
                    theme.backgroundColor,
                    100,
                )};
                --primaryColorGradientMin: ${rgba(theme.foregroundColor, 0)};
                --primaryColorGradientMax: ${rgba(theme.foregroundColor, 100)};
            `};
            --menuBackgroundColor: var(--mixedColor8Ref);
            --lozengeColor: var(--primaryColorRef);
            --lozengeBackgroundColor: var(--secondaryColorRef);

            /*
            We need to create a gradient that is _darker_ than the cart background,
            which is --mixedColor8Ref. So we need to see which one is the darker of
            the two theme colours.
             */
            --overlayBackgroundColor: var(--mixedColor8Ref);
            ${(props): ReturnType<typeof css> => {
                if (
                    props.theme.backgroundLuminance <=
                    props.theme.foregroundLuminance
                ) {
                    return css`
                        --overlayGradientMin: ${rgba(
                            props.theme.backgroundColor,
                            0,
                        )};
                        --overlayGradientMax: ${rgba(
                            props.theme.backgroundColor,
                            100,
                        )};
                    `;
                } else {
                    return css`
                        --overlayGradientMin: ${rgba(
                            getMixedColor(7 / MIX_INCREMENTS, props.theme),
                            0,
                        )};
                        --overlayGradientMax: ${rgba(
                            getMixedColor(7 / MIX_INCREMENTS, props.theme),
                            100,
                        )};
                    `;
                }
            }};

            @media print {
                --primaryColorRef: ${COLOR.BLACK};
                --secondaryColorRef: ${COLOR.WHITE};
                --tertiaryColorRef: ${COLOR.GREY};
                --secondaryColorGradientMin: ${rgba(COLOR.WHITE, 0)};
                --secondaryColorGradientMax: ${rgba(COLOR.WHITE, 100)};
                --primaryColorGradientMin: ${rgba(COLOR.BLACK, 0)};
                --primaryColorGradientMax: ${rgba(COLOR.BLACK, 100)};
                --overlayGradientMin: ${rgba(
                    mix(7 / MIX_INCREMENTS, COLOR.WHITE, COLOR.BLACK),
                    0,
                )};
                --overlayGradientMax: ${rgba(
                    mix(7 / MIX_INCREMENTS, COLOR.WHITE, COLOR.BLACK),
                    100,
                )};
                --mixedColor1Ref: ${mix(
                    1 / MIX_INCREMENTS,
                    COLOR.WHITE,
                    COLOR.BLACK,
                )};
                --mixedColor2Ref: ${mix(
                    2 / MIX_INCREMENTS,
                    COLOR.WHITE,
                    COLOR.BLACK,
                )};
                --mixedColor3Ref: ${mix(
                    3 / MIX_INCREMENTS,
                    COLOR.WHITE,
                    COLOR.BLACK,
                )};
                --mixedColor4Ref: ${mix(
                    4 / MIX_INCREMENTS,
                    COLOR.WHITE,
                    COLOR.BLACK,
                )};
                --mixedColor5Ref: ${mix(
                    5 / MIX_INCREMENTS,
                    COLOR.WHITE,
                    COLOR.BLACK,
                )};
                --mixedColor6Ref: ${mix(
                    6 / MIX_INCREMENTS,
                    COLOR.WHITE,
                    COLOR.BLACK,
                )};
                --mixedColor7Ref: ${mix(
                    7 / MIX_INCREMENTS,
                    COLOR.WHITE,
                    COLOR.BLACK,
                )};
                --mixedColor8Ref: ${mix(
                    8 / MIX_INCREMENTS,
                    COLOR.WHITE,
                    COLOR.BLACK,
                )};
            }

            ${resetColorVariables}
        }
    `,
    arePropsEqual,
);

// These are defined here so that we can use their values to round the nav line height in px, using JS
const FONT_SIZE_FIXED2_DESKTOP = 16;
const FONT_SIZE_FIXED2_DESKTOP_LARGE = 18;
const FONT_SIZE_FIXED2_NETBOOK = 14;
export const FONT_SIZE_FIXED2_TABLET_LARGE = 14;

export const CssVariableDefinitions = React.memo(createGlobalStyle<{
    $numBreadCrumbs: number;
    $maxFontFamilies: number;
}>`
    :root {
        /* #################################################################
        VIEWPORT BASIS
        Used for the basis of responsive font-sizes.
        ################################################################# */

        --viewportBasis: ${VIEWPORT.DESKTOP};

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            --viewportBasis: ${VIEWPORT.TABLET};
        }

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            --viewportBasis: ${VIEWPORT.MOBILE};
        }

        /* #################################################################
        FONT SIZES
        ################################################################# */

        /* Fluid */
        --fontSizeFluid1: calc(14 / var(--viewportBasis) * 100vw);
        --fontSizeFluid2: calc(16 / var(--viewportBasis) * 100vw);
        --fontSizeFluid3: calc(18 / var(--viewportBasis) * 100vw);
        --fontSizeFluid4: calc(24 / var(--viewportBasis) * 100vw);
        --fontSizeFluid5: calc(30 / var(--viewportBasis) * 100vw);
        --fontSizeFluid5_2: calc(32 / var(--viewportBasis) * 100vw);
        --fontSizeFluid5_5: calc(36 / var(--viewportBasis) * 100vw);
        --fontSizeFluid6: calc(48 / var(--viewportBasis) * 100vw);
        --fontSizeFluid6_5: calc(58 / var(--viewportBasis) * 100vw);
        --fontSizeFluid7: calc(64 / var(--viewportBasis) * 100vw);
        --fontSizeFluid7_5: calc(72 / var(--viewportBasis) * 100vw);
        --fontSizeFluid8: calc(80 / var(--viewportBasis) * 100vw);
        --fontSizeFluid9: calc(100 / var(--viewportBasis) * 100vw);

        /* Fixed */
        --fontSizeFixed1: 14px;
        --fontSizeFixed2: ${FONT_SIZE_FIXED2_DESKTOP}px;
        --fontSizeFixed3: 18px;
        --fontSizeFixed4: 24px;
        --fontSizeFixed5: 36px;
        --fontSizeFixed6: 48px;
        --fontSizeFixed7: 64px;
        --fontSizeFixed8: 100px;

        @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
            --fontSizeFixed1: 12px;
            --fontSizeFixed2: ${FONT_SIZE_FIXED2_NETBOOK}px;
            --fontSizeFixed3: 16px;
            --fontSizeFixed4: 20px;
            --fontSizeFixed5: 25px;
            --fontSizeFixed6: 30px;
            --fontSizeFixed7: 48px;
            --fontSizeFixed8: 72px;
        }

        @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
            --fontSizeFixed1: 12px;
            --fontSizeFixed2: ${FONT_SIZE_FIXED2_TABLET_LARGE}px;
            --fontSizeFixed3: 16px;
            --fontSizeFixed4: 18px;
            --fontSizeFixed5: 21px;
            --fontSizeFixed6: 24px;
            --fontSizeFixed7: 32px;
            --fontSizeFixed8: 56px;
        }

        @media screen and (min-width: ${VIEWPORT.DESKTOP_LARGE}px) {
            --fontSizeFixed1: 16px;
            --fontSizeFixed2: ${FONT_SIZE_FIXED2_DESKTOP_LARGE}px;
            --fontSizeFixed3: 20px;
            --fontSizeFixed4: 28px;
            --fontSizeFixed5: 42px;
            --fontSizeFixed6: 56px;
            --fontSizeFixed7: 72px;
            --fontSizeFixed8: 120px;
        }

        /*
        The font blocks used in the font menu and in other places have a
        height based on the block with the most families.
        */
        ${({ $maxFontFamilies }): ReturnType<typeof css> => css`
            --fontBlockVerticalPadding: ${MARGIN_BIG + MARGIN_SMALL}px;
            --fontBlockHorizontalPadding: ${MARGIN_BIG}px;

            @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                --fontBlockHorizontalPadding: ${MARGIN_SMALL}px;
            }

            @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
                --fontBlockVerticalPadding: ${MARGIN_BIG}px;
            }

            --fontBlockHeight: calc(
                (
                        ${$maxFontFamilies} * var(--fontSizeFixed2) *
                            ${LINE_HEIGHT.BODY_1}
                    ) + (var(--fontBlockVerticalPadding) * 2)
            );
        `};

        /*
        This was previously hard-coded in various places, and caused
        inconsistencies. At least now it's an inconsistency in a single
        place: here.
        */
        --fontSizeBlog: 22px;
        --fontSizeBlogGlyph: 21px;

        @media screen and (max-width: ${VIEWPORT.DESKTOP}px) {
            --fontSizeBlog: 20px;
            --fontSizeBlogGlyph: 19px;
        }

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            --fontSizeBlog: 18px;
            --fontSizeBlogGlyph: 17px;
        }

        @media screen and (min-width: ${VIEWPORT.DESKTOP_LARGE}px) {
            --fontSizeBlog: 24px;
            --fontSizeBlogGlyph: 23px;
        }

        @media print {
            --fontSizeBlog: 1.8vw;
            --fontSizeBlogGlyph: 1.7vw;
            --fontSizeFixed1: 1.2vw;
            --fontSizeFixed2: 1.4vw;
            --fontSizeFixed3: 1.6vw;
            --fontSizeFixed4: 1.8vw;
            --fontSizeFixed5: 2.0vw;
            --fontSizeFixed6: 2.4vw;
            --fontSizeFixed7: 3.2vw;
            --fontSizeFixed8: 5.6vw;
            --fontSizeFluid1: 1.4vw;
            --fontSizeFluid2: 1.6vw;
            --fontSizeFluid3: 1.8vw;
            --fontSizeFluid4: 2.4vw;
            --fontSizeFluid5: 3.0vw;
            --fontSizeFluid6: 4.8vw;
            --fontSizeFluid7: 6.4vw;
            --fontSizeFluid8: 8.0vw;
            --fontSizeFluid9: 10.0vw;

            /* Safari print CSS hack... */
            @supports (-webkit-backdrop-filter: blur(1px)) {
                --fontSizeBlog: 9pt;
                --fontSizeFixed1: 6pt;
                --fontSizeFixed2: 7pt;
                --fontSizeFixed3: 8pt;
                --fontSizeFixed4: 9pt;
                --fontSizeFixed5: 10pt;
                --fontSizeFixed6: 12pt;
                --fontSizeFixed7: 16pt;
                --fontSizeFixed8: 28pt;
                --fontSizeFluid1: 7pt;
                --fontSizeFluid2: 8pt;
                --fontSizeFluid3: 9pt;
                --fontSizeFluid4: 12pt;
                --fontSizeFluid5: 15pt;
                --fontSizeFluid6: 24pt;
                --fontSizeFluid7: 32pt;
                --fontSizeFluid8: 40pt;
                --fontSizeFluid9: 50pt;
            }
        }

        /* #################################################################
        FONT FAMILIES
        ################################################################# */

        --fontFamilyFallbackSansSerif: -apple-system, system-ui,
        blinkmacsystemfont, 'Helvetica', 'Arial', sans-serif;
        --fontFamilyFallbackSerif: georgia, cambria, 'Times New Roman', times,
        serif;
        --fontFamilyFallbackMono: "Lucida Console", monaco, monospace;
        --fontFamilySoehne: ${JSON.stringify(FONT_FAMILY.SOEHNE)},
        var(--fontFamilyFallbackSansSerif);
        --fontFamilySoehneMono: ${JSON.stringify(FONT_FAMILY.SOEHNE_MONO)},
        var(--fontFamilyFallbackMono);
        --fontFamilySoehneIkon: ${JSON.stringify(FONT_FAMILY.SOEHNE_IKON)};
        --fontFamilyMartinaPlantijn: ${JSON.stringify(
            FONT_FAMILY.MARTINA_PLANTIJN,
        )},
        var(--fontFamilyFallbackSerif);
        --fontFamilyCalibre2: ${JSON.stringify(
            FONT_FAMILY.CALIBRE2,
        )}, var(--fontFamilyFallbackSansSerif);

        /* #################################################################
        GRID VARIABLES
        ################################################################# */

        --gridColumnCount: 12;
        --gridColumnGap: ${MARGIN_SMALL}px;
        --gridMarginGap: ${MARGIN_BIG}px;

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            --gridMarginGap: ${MARGIN_SMALL}px;
        }

        --gridTemplateColumnsDefault: repeat(
            var(--gridColumnCount),
            minmax(0, 1fr)
        );

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            --gridColumnCount: 6;
        }

        /* #################################################################
        LINE HEIGHT
        ################################################################# */

        --lineHeightHeading1: ${LINE_HEIGHT.HEADING_1};
        --lineHeightHeading2: ${LINE_HEIGHT.HEADING_2};
        --lineHeightHeading3: ${LINE_HEIGHT.HEADING_3};
        --lineHeightBody1: ${LINE_HEIGHT.BODY_1};
        --lineHeightBody2: ${LINE_HEIGHT.BODY_2};
        --lineHeightBody3: ${LINE_HEIGHT.BODY_3};
        --lineHeightBody4: ${LINE_HEIGHT.BODY_4};
        --lineHeightBlogBody: ${LINE_HEIGHT.BODY_BLOG};
        --lineHeightBlogBodyPx: calc(var(--lineHeightBlogBody) * var(--fontSizeBlog));

        /* #################################################################
        SPACING
        ################################################################# */

        --spacing0: calc(4 / var(--viewportBasis) * 100vw);
        --spacing1: calc(8 / var(--viewportBasis) * 100vw);
        --spacing2: calc(16 / var(--viewportBasis) * 100vw);
        --spacing3: calc(24 / var(--viewportBasis) * 100vw);
        --spacing4: calc(32 / var(--viewportBasis) * 100vw);
        --spacing5: calc(40 / var(--viewportBasis) * 100vw);
        --spacing6: calc(60 / var(--viewportBasis) * 100vw);
        --spacing7: calc(80 / var(--viewportBasis) * 100vw);
        --spacing8: calc(160 / var(--viewportBasis) * 100vw);
        --spacing9: calc(240 / var(--viewportBasis) * 100vw);
        --spacing10: calc(320 / var(--viewportBasis) * 100vw);
        --spacing11: calc(580 / var(--viewportBasis) * 100vw);

        /* For the space between Checkboxes/Radios and their labels. */
        --spacingCheckable: 0.33em;

        /* Vertical spacing between Streamfields on blog and "in use" posts */
        --streamFieldSpacing: calc(130 / var(--viewportBasis) * 100vw);

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            --streamFieldSpacing: calc(65 / var(--viewportBasis) * 100vw);
        }

        /* Safari print CSS hack... */
        @media print {
            @supports (-webkit-backdrop-filter: blur(1px)) {
                --spacing0: 2px;
                --spacing1: 3px;
                --spacing2: 6px;
                --spacing3: 9px;
                --spacing4: 12px;
                --spacing5: 15px;
                --spacing6: 23px;
                --spacing7: 30px;
                --spacing8: 60px;
                --spacing9: 90px;
                --spacing10: 120px;
                --spacing11: 220px;
                --streamFieldSpacing: 48px;
            }
        }

        /* #################################################################
        Navbar
        ################################################################# */

        --navbarVerticalMargin: ${NAVBAR_VERTICAL_MARGIN}px;
        --navbarHeightCollapsed: calc(${getLozengeHeight()}px + var(--navbarVerticalMargin) * 2);

        ${({ $numBreadCrumbs }): ReturnType<typeof css> => css`
            --navbarHeight: var(
                --navbarCalculatedHeight,
                var(--navbarHeightCollapsed)
            );

            @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
                --navbarHeight: var(
                    --navbarCalculatedHeight,
                    calc(
                        ${getLozengeHeight($numBreadCrumbs)}px +
                            var(--navbarVerticalMargin) * 2
                    )
                );
            }
        `};

        /* #################################################################
        Cart
        ################################################################# */
        --cartPaddingTop: ${MARGIN_BIG}px;
        --cartPaddingRight: 40px;
        --cartPaddingBottom: 30px;
        --cartPaddingLeft: 30px;

        @media screen and (max-width: ${CART_IN_PAGE_BREAKPOINT}px) {
            --cartPaddingRight: var(--cartPaddingLeft);
        }

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            --cartPaddingLeft: var(--cartPaddingTop);
            --cartPaddingRight: var(--cartPaddingTop);
            --cartPaddingBottom: var(--cartPaddingTop);
        }

        /* #################################################################
        PADDING
        ################################################################# */

        --paddingPageDefault: var(--navbarHeight) 0 25vh 0;
        --paddingPageMedium: max(100px, 26vh) 0 25vh 0;

        /* #################################################################
        IMAGE DEFAULTS
        ################################################################# */

        --imageFilter: none;
        --imageOpacity: 1;
        --imageHoverOpacity: 0.8;
    }
`);
