import React from 'react';

/**
 * Checks if a component has mounted. This can be used a check to
 * ensure we're running at runtime. Things never mount during SSR.
 * See https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 */
export default function useHasMounted(): boolean {
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
        setHasMounted(true);
    }, []);
    return hasMounted;
}
