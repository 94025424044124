import React from 'react';
import styled from 'styled-components';
import { ParagraphSmall } from './Paragraph';
import printReleaseRange from '../utils/printReleaseRange';

const Container = styled.div``;

const Title = styled(ParagraphSmall)`
    color: var(--foregroundColorMix4);
`;

function FontRelease({
    releaseDates,
}: {
    releaseDates: string[];
}): React.ReactElement | null {
    const releaseRange = printReleaseRange(releaseDates);
    return releaseRange ? (
        <Container>
            <Title>Release</Title>
            <ParagraphSmall>{releaseRange}</ParagraphSmall>
        </Container>
    ) : null;
}

export default React.memo(FontRelease);
