import isBrowser from './isBrowser';

export default function isBrowserSafari(): boolean {
    return (
        isBrowser() &&
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1 &&
        navigator.userAgent.indexOf('Chromium') === -1
    );
}
