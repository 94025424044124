import React from 'react';
import styled, { css } from 'styled-components';
import { invertColorVariables } from '../utils/stylesMixins';
import { useDisclosure } from './Disclosure';
import { Button } from './InlineUnderlinedLink';
import {
    LOZENGE_HEIGHT,
    MARGIN_BIG,
    VIEWPORT,
    ZINDEX,
} from '../settings/Global';
import { trackClick } from '../utils/googleEvents';
import {
    useActiveTieredLicensingsForLicenceType,
    type Tier,
    type LicenceType,
} from '../hooks/useActiveLicenceTypes';
import getOptionForLicenceType from '../utils/getOptionForLicenceType';
import {
    appLicenceDisclosure,
    appLicenceSummary,
    AppLicenceIcon,
    desktopLicenceDisclosure,
    desktopLicenceSummary,
    DesktopLicenceIcon,
    webLicenceDisclosure,
    webLicenceSummary,
    WebLicenceIcon,
    UdlaLicenceIcon,
    udlaLicenceDisclosure,
    udlaLicenceSummary,
    BroadcastLicenceIcon,
    broadcastLicenceDisclosure,
    broadcastLicenceSummary,
    OemLicenceIcon,
    oemLicenceDisclosure,
    oemLicenceSummary,
    AdvertisingLicenceIcon,
    advertisingLicenceDisclosure,
    advertisingLicenceSummary,
} from '../data/LicenceSelectorDisclosures';
import useCartQuery from '../hooks/useCartQuery';
import LicenceQuantitySelector from './LicenceQuantitySelector';
import useConfig from '../hooks/useConfig';
import { sentryException } from '../utils/sentry';
import { TEST_ID } from '../settings/E2e';
import useUuid from '../hooks/useUuid';
import { useDebouncedCartUpdateMutationContext } from '../hooks/useDebouncedCartUpdateMutation';
import { useErrorOverlayState } from './GlobalRuntimeState';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';

const Container = styled.div<{ $isChecked: boolean }>`
    display: flex;
    flex-direction: column;

    --padding: ${MARGIN_BIG}px;

    border: 1px solid var(--foregroundColorMix7);
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
    background-color: var(--backgroundColor);
    color: var(--foregroundColor);

    ${({ $isChecked }): ReturnType<typeof css> =>
        $isChecked
            ? css`
                  ${invertColorVariables};
              `
            : css`
                  &:hover {
                      border-color: var(--foregroundColor);
                      z-index: ${ZINDEX.FONT_SELECTOR_INPUT_ACTIVE};
                  }
              `}
`;

const Inner = styled.label`
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-gap: var(--spacing2) var(--gridColumnGap);
    padding: var(--padding) 0;
    cursor: pointer;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-columns: 100%;
        padding: var(--padding);
    }
`;

const CheckboxColumn = styled.div`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-column-gap: 0.6em;
    align-items: center;
    padding-left: var(--padding);
    grid-column: 1 / span 2;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding-left: 0;
        grid-column: 1;
    }
`;

const HiddenInput = styled.input`
    display: none;
`;

const Title = styled.span`
    font-weight: bold;
`;

const InfoColumn = styled.div`
    /* Match select line height for baseline alignment */
    line-height: ${LOZENGE_HEIGHT}px;
    grid-column: 3 / span 3;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1;
    }
`;

const SelectColumn = styled.div<{ $isChecked?: boolean }>`
    grid-column: 6 / span 2;
    padding-right: var(--padding);
    color: ${({ $isChecked }): string =>
        $isChecked ? 'var(--foregroundColor)' : 'var(--foregroundColorMix5)'};

    --selectHoverColor: ${({ $isChecked }): string =>
        $isChecked ? 'var(--foregroundColorMix5)' : 'var(--foregroundColor)'};

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding-right: 0;
        grid-column: 1;
    }
`;

const LearnMoreButton = styled(Button)`
    color: var(--foregroundColorMix5);

    --underlineColor: var(--foregroundColorMix5);

    &:hover,
    &:focus,
    &:active {
        color: var(--hoverColor);
    }
`;

const LearnMorePanel = styled.div`
    background-color: var(--foregroundColorMix8);
    padding: var(--padding) 0;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        padding: var(--padding);
    }

    --border: 1px solid var(--borderColor);

    dl {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: var(--spacing2) var(--gridColumnGap);

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            grid-template-columns: 100%;
        }
    }

    hr {
        grid-column: 1 / span 7;
        border: none;
        border-top: var(--border);
        margin: 0 var(--padding);

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            grid-column: initial;
            margin: 0;
        }
    }

    dt {
        color: var(--foregroundColorMix5);
        grid-column: 1 / span 2;
        padding-left: var(--padding);

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            grid-column: initial;
            padding-left: 0;
        }
    }

    dd {
        grid-column: 3 / span 3;

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            grid-column: initial;
        }
    }

    p + p {
        margin-top: var(--spacing1);
    }

    b {
        font-weight: bold;
    }
`;

function LicenceSelectorOption({
    licenceType,
}: {
    licenceType: LicenceType;
}): React.ReactElement {
    const config = useConfig();
    const inputId = useUuid();
    const title = licenceType.name;
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const doDebouncedCartUpdate = useDebouncedCartUpdateMutationContext();
    const tieredLicences = useActiveTieredLicensingsForLicenceType(licenceType);
    const [, setShowErrorOverlay] = useErrorOverlayState();
    const [isChecked, setIsChecked] = React.useState<boolean>(false);

    const selectedTier = React.useMemo(() => {
        const cartTier = cart?.licenceTiers.find(
            (cartTier) => cartTier.tier.licenceType.id === licenceType.id,
        )?.tier;

        setIsChecked(Boolean(cartTier));

        if (!cartTier) {
            return tieredLicences[0].tiers[0];
        }
        const tieredLicence = tieredLicences.find(
            (tl) => tl.id === cartTier.tieredLicence.id,
        );
        if (!tieredLicence) {
            throw Error(
                `Couldn't retrieve tieredLicence for ${cartTier.tieredLicence.id}`,
            );
        }
        const tier = tieredLicence.tiers.find((t) => t.id === cartTier.id);
        if (!tier) {
            throw Error(`Couldn't retrieve tier for ${cartTier.id}`);
        }
        return tier;
    }, [cart?.licenceTiers]);

    const eventContentType = 'licence_selector_disclosure';
    const disclosure = useDisclosure({
        onExpand: () =>
            trackClick(
                eventContentType,
                `${eventContentType}_${
                    title ? title.toString().toLowerCase() : ''
                }`,
            ),
    });

    return (
        <Container $isChecked={isChecked}>
            <Inner
                htmlFor={inputId}
                data-cy={TEST_ID.LICENCE_SELECTOR_LABEL}
                data-cy-licence={licenceType.id}
            >
                <CheckboxColumn>
                    <HiddenInput
                        /* Ensure the Advertising modal shows and isn't automatically dismissed */
                        onClick={(e): void => e.stopPropagation()}
                        name="licenceType"
                        checked={isChecked}
                        onChange={(e): void => {
                            if (!cart) {
                                return;
                            }

                            const tierIdToRemove = !e.target.checked
                                ? selectedTier.id
                                : undefined;

                            const tierToAdd = e.target.checked
                                ? selectedTier
                                : undefined;

                            doDebouncedCartUpdate({
                                cart,
                                config,
                                tierIdToRemove,
                                tierToAdd,
                            }).catch((error) => {
                                sentryException(error);
                                setShowErrorOverlay({
                                    isShown: true,
                                });
                            });
                        }}
                        id={inputId}
                        type="checkbox"
                    />
                    {getOptionForLicenceType(licenceType, {
                        desktop: <DesktopLicenceIcon />,
                        web: <WebLicenceIcon />,
                        app: <AppLicenceIcon />,
                        udla: <UdlaLicenceIcon />,
                        oem: <OemLicenceIcon />,
                        broadcast: <BroadcastLicenceIcon />,
                        advertising: <AdvertisingLicenceIcon />,
                    })}
                    <Title>{title}</Title>
                </CheckboxColumn>
                <InfoColumn>
                    <p>
                        {getOptionForLicenceType(licenceType, {
                            desktop: desktopLicenceSummary,
                            web: webLicenceSummary,
                            app: appLicenceSummary,
                            udla: udlaLicenceSummary,
                            oem: oemLicenceSummary,
                            broadcast: broadcastLicenceSummary,
                            advertising: advertisingLicenceSummary,
                        })}
                    </p>
                    <p>
                        <LearnMoreButton {...disclosure.buttonAttributes}>
                            {disclosure.panelAttributes.hidden
                                ? 'Learn more'
                                : 'Close'}
                        </LearnMoreButton>
                    </p>
                </InfoColumn>
                <SelectColumn $isChecked={isChecked}>
                    {(cart && (
                        <LicenceQuantitySelector
                            licenceType={licenceType}
                            onChange={(tierToAdd: Tier): void => {
                                doDebouncedCartUpdate({
                                    cart,
                                    config,
                                    tierToAdd,
                                }).catch((error) => {
                                    sentryException(error);
                                    setShowErrorOverlay({
                                        isShown: true,
                                    });
                                });
                            }}
                            invertSelectColor={isChecked}
                        />
                    )) ||
                        null}
                </SelectColumn>
            </Inner>
            <LearnMorePanel {...disclosure.panelAttributes}>
                {getOptionForLicenceType(licenceType, {
                    desktop: desktopLicenceDisclosure,
                    web: webLicenceDisclosure,
                    app: appLicenceDisclosure,
                    udla: udlaLicenceDisclosure,
                    broadcast: broadcastLicenceDisclosure,
                    advertising: advertisingLicenceDisclosure,
                    oem: oemLicenceDisclosure,
                })}
            </LearnMorePanel>
        </Container>
    );
}

export default LicenceSelectorOption;
