import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { createFontsInUsePostUrl } from '../utils/urlHelper';
import printAmpersandSeries from '../utils/printAmpersandSeries';
import notNull from '../utils/notNull';
import ImageWithPlaceholder from './ImageWithPlaceholder';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import type {
    InUsePostStub,
    InUsePostStubFontFamily,
} from '../union-types/inUsePostStub';
import type { ImageFetchPriority } from './LazyImage';

export type ImageRenderStyle = 'opacityOnHover' | 'grayscale';

const Container = styled.div<{ $renderStyle: ImageRenderStyle | undefined }>`
    /* Set filter/opacity on Image */
    ${({ $renderStyle }): ReturnType<typeof css> | null => {
        // For grayscale we let the background colour bleed through.
        // Can be seen in action on themed font family pages.
        return $renderStyle === 'grayscale'
            ? css`
                  --imageFilter: grayscale(100%);
                  --imageOpacity: var(--imageHoverOpacity);
              `
            : null;
    }};

    &:hover {
        ${({ $renderStyle }): ReturnType<typeof css> | null => {
            return $renderStyle === 'grayscale'
                ? css`
                      --imageFilter: grayscale(0%);
                      --imageOpacity: var(--imageHoverOpacity);
                  `
                : $renderStyle === 'opacityOnHover'
                  ? css`
                        --imageOpacity: var(--imageHoverOpacity);
                    `
                  : null;
        }};
    }
`;

const Caption = styled.div`
    font-size: var(--fontSizeFixed2);
    line-height: var(--lineHeightBody1);
    margin: 2px 0 4px;
`;

const Title = styled.span`
    color: var(--foregroundColor);
`;

const Subtitle = styled.span`
    color: var(--foregroundColorMix6);
    ${/* sc-selector */ Container}:hover & {
        color: var(--foregroundColorMix2);
    }
`;

const MediaCount = styled.span`
    font-family: var(--fontFamilySoehneIkon);
`;

function FontsInUseItem({
    inUsePost,
    lazyLoad = true,
    renderStyle,
    fetchPriority,
}: {
    inUsePost: InUsePostStub;
    lazyLoad?: boolean;
    renderStyle?: ImageRenderStyle;
    fetchPriority?: ImageFetchPriority;
}): React.ReactElement {
    const fontFamilies = inUsePost.fontFamilies
        ? (inUsePost.fontFamilies as InUsePostStubFontFamily[])
        : [];
    const subtitle = printAmpersandSeries(
        fontFamilies
            .filter(notNull)
            .sort(sortFontsByTypographicRanking)
            .map((fontFamily): string | null =>
                fontFamily ? fontFamily.name : null,
            )
            .filter(notNull),
    );

    return (
        <Link
            to={createFontsInUsePostUrl(inUsePost.slug)}
            title={`${inUsePost.title ? inUsePost.title + ' - ' : ''}${
                inUsePost.mediaCount
            } image${inUsePost.mediaCount === 1 ? '' : 's'}${
                subtitle ? ' - ' + subtitle.join('') : ''
            }`}
        >
            <Container $renderStyle={renderStyle}>
                <ImageWithPlaceholder
                    src={inUsePost.blurbImage.file}
                    aspectRatio={
                        inUsePost.blurbImage.width / inUsePost.blurbImage.height
                    }
                    placeholderColor={
                        !inUsePost.blurbImage.hasAlpha &&
                        renderStyle !== 'grayscale'
                            ? inUsePost.blurbImage.placeholderColor
                            : undefined
                    }
                    lazyLoad={lazyLoad}
                    fetchPriority={fetchPriority}
                />

                <Caption>
                    {inUsePost.title && (
                        <Title>
                            {inUsePost.title}
                            {` `}
                        </Title>
                    )}
                    {subtitle && (
                        <Subtitle>
                            <MediaCount>{`(${inUsePost.mediaCount})`}</MediaCount>
                            {` `}
                            {subtitle}
                        </Subtitle>
                    )}
                </Caption>
            </Container>
        </Link>
    );
}

export default React.memo(FontsInUseItem);
