import type { FontFamilyGroup } from '../components/PageContext';
import type { FontFamilyGroup as FontFamilyGroupAllFonts } from '../hooks/useAllFonts';
import printAmpersandSeries from './printAmpersandSeries';
import sortFontsByTypographicRanking from './sortFontsByTypographicRanking';

type FontFamilyGroupUnion = FontFamilyGroup | FontFamilyGroupAllFonts;
type FontFamilyUnion = FontFamilyGroupUnion['fontFamilies'][number];

export default function printFontFamilyGroupSubtitle(
    fontFamilyGroup: FontFamilyGroupUnion,
): string | undefined {
    const { styleCount, familyNames } = (
        fontFamilyGroup.fontFamilies as FontFamilyUnion[]
    )
        .sort(sortFontsByTypographicRanking)
        .reduce(
            (carry, fontFamily) => {
                if (fontFamilyGroup.isCollection || !fontFamily.isPreRelease) {
                    carry.styleCount += fontFamily.fontStyleCount;
                    carry.familyNames.push(fontFamily.name);
                }
                return carry;
            },
            { styleCount: 0, familyNames: [] as string[] },
        );

    if (styleCount <= 1) return;

    if (styleCount === familyNames.length) {
        return `Includes ${printAmpersandSeries(familyNames).join('')}.`;
    }

    return `Includes all ${styleCount} styles of ${printAmpersandSeries(
        familyNames,
    ).join('')}.`;
}
