import React from 'react';
import type { KlimPageProps } from '../layouts';
import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client';
import {
    PreviewInUsePostQuery,
    PreviewBlogPostQuery,
    PreviewHomePageQuery,
} from '../utils/previewQueries';
import previewApolloClient from '../utils/previewApolloClient';
import getBlogPostWordCount from '../utils/getBlogPostWordCount';
import getSearchVariableFromLocation from '../utils/getSearchVariableFromLocation';
import BlogPage from './BlogPage';
import InUsePostPage from './InUsePostPage';
import Homepage from './Homepage';
import type {
    PreviewBlogPostQuery as PreviewBlogPostQueryType,
    PreviewInUsePostQuery as PreviewInUsePostQueryType,
    PreviewHomePageQuery as PreviewHomePageQueryType,
} from '../gql/api-preview';
import { useGlobalState } from '../components/GlobalRuntimeState';
import usePreviewScrollRestore from '../hooks/usePreviewScrollRestore';

export { Head } from '../components/Head';

// These need to match Django ContentType strings
const CONTENT_TYPE_HOMEPAGE = 'core.homepage';
const CONTENT_TYPE_BLOG_POST = 'blog.blogpage';
const CONTENT_TYPE_IN_USE_POST = 'fontsinuse.fontsinusedetailpage';

function PreviewPage(props: KlimPageProps): React.ReactElement | null {
    const [previewBlogPost, setPreviewBlogPost] =
        useGlobalState('previewBlogPost');
    const [, setPreviewBlogPostWordCount] = useGlobalState(
        'previewBlogPostWordCount',
    );
    const [previewHomepage, setPreviewHomepage] =
        useGlobalState('previewHomepage');
    const [previewInUsePost, setPreviewInUsePost] =
        useGlobalState('previewInUsePost');
    const { location } = props;
    const previewTokenFromLocation = getSearchVariableFromLocation(
        location,
        'token',
    );
    const previewToken =
        typeof previewTokenFromLocation === 'string'
            ? previewTokenFromLocation
            : undefined;
    const previewContentTypeFromLocation = getSearchVariableFromLocation(
        location,
        'content_type',
    );
    const previewContentType =
        typeof previewContentTypeFromLocation === 'string'
            ? previewContentTypeFromLocation
            : undefined;

    const baseQueryOptions: QueryHookOptions = {
        client: previewApolloClient,
        ssr: false,
        fetchPolicy: 'no-cache',
    };

    const homeQuery = useQuery<PreviewHomePageQueryType>(PreviewHomePageQuery, {
        ...baseQueryOptions,
        variables: {
            token: previewToken || '',
        },
        skip: !previewToken || previewContentType !== CONTENT_TYPE_HOMEPAGE,
    });

    const inUseQuery = useQuery<PreviewInUsePostQueryType>(
        PreviewInUsePostQuery,
        {
            ...baseQueryOptions,
            variables: {
                token: previewToken || '',
            },
            skip:
                !previewToken ||
                previewContentType !== CONTENT_TYPE_IN_USE_POST,
        },
    );

    const blogQuery = useQuery<PreviewBlogPostQueryType>(PreviewBlogPostQuery, {
        ...baseQueryOptions,
        variables: {
            token: previewToken || '',
        },
        skip: !previewToken || previewContentType !== CONTENT_TYPE_BLOG_POST,
    });

    // Homepage
    React.useEffect(() => {
        if (homeQuery.data?.homepage) {
            setPreviewHomepage(homeQuery.data.homepage);
        }
    }, [homeQuery.data?.homepage]);

    // Blog post
    React.useEffect(() => {
        if (blogQuery.data?.blogPost) {
            setPreviewBlogPost(blogQuery.data.blogPost);
            setPreviewBlogPostWordCount(
                getBlogPostWordCount([blogQuery.data.blogPost], false),
            );
        }
    }, [blogQuery.data?.blogPost]);

    // In use
    React.useEffect(() => {
        if (inUseQuery.data?.inUsePost) {
            setPreviewInUsePost(inUseQuery.data.inUsePost);
        }
    }, [inUseQuery.data?.inUsePost]);

    const previewItem = previewHomepage || previewBlogPost || previewInUsePost;
    usePreviewScrollRestore({
        skip: previewItem === undefined,
        contentId: `${previewItem?.__typename}${previewItem?.slug}`,
    });

    if (previewHomepage) {
        return <Homepage />;
    } else if (previewBlogPost) {
        return <BlogPage />;
    } else if (previewInUsePost) {
        return <InUsePostPage />;
    }

    return null;
}

export default React.memo(PreviewPage);
