import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import type { BlogPostStub } from '../union-types/blogPostStub';
import { MARGIN_SMALL, VIEWPORT } from '../settings/Global';
import BlogCard from './BlogCard';

export default function BlogCards({
    blogPosts,
}: {
    blogPosts: BlogPostStub[];
}): React.ReactElement {
    return (
        <ResponsiveMasonry
            columnsCountBreakPoints={{
                [VIEWPORT.MOBILE]: 1,
                [VIEWPORT.TABLET]: 2,
                [VIEWPORT.NETBOOK_SMALL]: 3,
            }}
        >
            <Masonry gutter={`${MARGIN_SMALL}px`}>
                {blogPosts.map(
                    (blogPost, index): React.ReactElement => (
                        <BlogCard
                            key={blogPost.id}
                            blogPostStub={blogPost}
                            lazyLoad={index > 2}
                        />
                    ),
                )}
            </Masonry>
        </ResponsiveMasonry>
    );
}
