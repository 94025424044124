import type { Item } from '../hooks/useCartQuery';
import { LineItemType } from '../gql/api-public';
import sortFontsByTypographicRanking from './sortFontsByTypographicRanking';
import type { LicenceType } from '../hooks/useActiveLicenceTypes';

interface LineItemGroup {
    items: Item[];
    name: string;
}

function printLicenceHeading(licenceType: LicenceType): string {
    return `${licenceType.name} fonts`;
}

export default function getGroupedLineItems({
    items,
    licenceType,
    lineItemType,
    cartHasSimpleLicensing,
}: {
    items: Item[];
    licenceType?: LicenceType;
    lineItemType?: LineItemType;
    cartHasSimpleLicensing?: boolean;
}): LineItemGroup | undefined {
    if (licenceType || cartHasSimpleLicensing) {
        // Retail and Upgrade (i.e. normal font purchases) or simple licensing
        return {
            items: items
                .filter((item): boolean =>
                    Boolean(
                        (cartHasSimpleLicensing ||
                            (item.font &&
                                item.fontLicenceType?.id ===
                                    licenceType?.id)) &&
                            (item.type === LineItemType.RETAIL ||
                                item.type === LineItemType.UPGRADE),
                    ),
                )
                .sort((a, b) => {
                    if (!a.font && !b.font) {
                        return 0;
                    }
                    if (!a.font) {
                        return 1;
                    }
                    if (!b.font) {
                        return -1;
                    }
                    return sortFontsByTypographicRanking(
                        {
                            typographicRanking: a.font?.typographicRanking,
                            name: a.fontDescription || '',
                        },
                        {
                            typographicRanking: b.font?.typographicRanking,
                            name: b.fontDescription || '',
                        },
                    );
                }),
            name: licenceType ? printLicenceHeading(licenceType) : '',
        };
    } else if (lineItemType === LineItemType.RETAIL) {
        // Catch-all for purchases that are tagged as RETAIL or UPGRADE without product attached. Edge case!
        return {
            items: items.filter(
                (item): boolean =>
                    !item.font &&
                    (item.type === LineItemType.RETAIL ||
                        item.type === LineItemType.UPGRADE),
            ),
            name: 'Retail',
        };
    } else if (lineItemType === LineItemType.GOODS) {
        return {
            items: items.filter(
                (item): boolean => item.type === LineItemType.GOODS,
            ),
            name: 'Goods',
        };
    } else if (lineItemType === LineItemType.CUSTOM) {
        return {
            items: items.filter(
                (item): boolean => item.type === LineItemType.CUSTOM,
            ),
            name: 'Custom work',
        };
    } else {
        return {
            items: items.filter(
                (item): boolean => item.type === LineItemType.OTHER,
            ),
            name: 'Other',
        };
    }
}
