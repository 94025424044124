import React from 'react';
import imageCreateSrcSet from '../utils/imageCreateSrcSet';
import { createImgixUrl, createStaticAssetUrl } from '../utils/urlHelper';
import LazyImage from './LazyImage';
import type { ImageFetchPriority } from './LazyImage';
import filenameIsSvg from '../utils/filenameIsSvg';
import isBrowserSafari from '../utils/isBrowserSafari';
import isBrowser from '../utils/isBrowser';

// When defining multiple sources, those with media queries should come first
export interface ImageSource {
    src: string;
    media?: string;
    sizes?: string[];
}

function Image({
    className,
    src,
    sources = [],
    alt = '',
    onLoadCallback,
    preload,
    lazyLoad,
    imageQuality,
    fetchPriority,
}: {
    src: string;
    sources: ImageSource[];
    className?: string;
    alt?: string;
    onLoadCallback?: () => void;
    preload?: boolean;
    lazyLoad?: boolean;
    imageQuality?: number;
    fetchPriority?: ImageFetchPriority;
}): React.ReactElement | null {
    const isSvg = filenameIsSvg(src);

    if (!isBrowser()) {
        return null;
    }

    return (
        <>
            {preload ? (
                isSvg ? (
                    <link
                        rel="preload"
                        href={createStaticAssetUrl(src)}
                        as="image"
                        type="image/svg+xml"
                    />
                ) : !isBrowserSafari() ? (
                    sources.map((source) => {
                        // Sadly we can't use <link rel="preload" imagesrcset="foo" /> because Safari...
                        // https://caniuse.com/?search=imagesrcset
                        // I tried using separate <link rel="preload" media="foo" /> instead...
                        // but browsers kept loading the image for the wrong breakpoint... so...
                        // well... no preloading in Safari!
                        const imageSizes = source.sizes
                            ? source.sizes.join(',')
                            : undefined;
                        return (
                            <link
                                key={`prel${source.src}${source.media}${imageSizes}`}
                                rel="preload"
                                as="image"
                                imageSrcSet={imageCreateSrcSet(
                                    source.src,
                                    imageQuality,
                                )}
                                // @ts-ignore Can be removed when @types/react supports this attr
                                imageSizes={imageSizes}
                                media={source.media}
                            />
                        );
                    })
                ) : null
            ) : null}
            <picture>
                {!isSvg
                    ? sources.map((source) => (
                          <source
                              key={`lri-${source.src}${source.media}${source.sizes}`}
                              media={source.media || undefined}
                              srcSet={imageCreateSrcSet(source.src)}
                              sizes={
                                  source.sizes && source.sizes.length
                                      ? source.sizes.join(',')
                                      : undefined
                              }
                          />
                      ))
                    : null}
                {/* Load a super lightweight image as the fallback as some browsers (Safari, FF to a lesser degree) always load the fallback also */}
                <LazyImage
                    src={
                        isSvg
                            ? createStaticAssetUrl(src)
                            : createImgixUrl(
                                  {
                                      src,
                                      imgixParams: {
                                          auto: 'format,compress',
                                          fit: 'max',
                                          w: 240,
                                          h: 240,
                                      },
                                  },
                                  true,
                              )
                    }
                    alt={alt}
                    className={className}
                    onLoadCallback={onLoadCallback}
                    loading={lazyLoad ? 'lazy' : undefined}
                    fetchPriority={fetchPriority}
                />
            </picture>
        </>
    );
}

export default React.memo(Image);
