import React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import isAbsoluteUrl from 'is-absolute-url';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/client';
import AccountLoginForm from '../components/AccountLoginForm';
import { HeadingExtraLarge } from '../components/Heading';
import { loginWithCodeMutation } from '../utils/runtimeQueries';
import type {
    LoginWithCodeMutationMutation,
    LoginWithCodeMutationMutationVariables,
} from '../gql/api-public';
import {
    createAccountUrl,
    createNextUrlFromLocation,
} from '../utils/urlHelper';
import { VIEWPORT } from '../settings/Global';
import getSearchVariableFromLocation from '../utils/getSearchVariableFromLocation';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
`;

const InnerWrapper = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    ${HeadingExtraLarge} {
        margin-top: var(--spacing1);
        grid-column: 3 / span 6;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span 6;
        }
    }
`;

function LoginPage(): React.ReactElement {
    const client = useApolloClient();
    const [doLoginWithCode] = useMutation<
        LoginWithCodeMutationMutation,
        LoginWithCodeMutationMutationVariables
    >(loginWithCodeMutation);
    const location = useLocation();
    const [isLoginFailed, setIsLoginFailed] = React.useState<boolean>(false);

    React.useEffect((): void => {
        const code = getSearchVariableFromLocation(location, 'code');
        const nextUrl = getSearchVariableFromLocation(location, 'next');

        if (typeof code !== 'string') {
            // 'code' was not passed in query-string.
            setIsLoginFailed(true);
            return;
        }

        // Otherwise, looks like everything is OK, and we can proceed with login.
        doLoginWithCode({
            variables: { input: { code } },
        }).then(async (response): Promise<void> => {
            const result = response?.data?.loginWithCode;

            if (result?.errors && result.errors.length > 0) {
                // Code was probably expired. Could dive into
                // the errors to be certain, but it doesn't
                // really matter *why* ultimately.
                setIsLoginFailed(true);
            } else {
                // Success!

                // Forward to the passed relative URL, or otherwise to Account
                const forwardUrl =
                    typeof nextUrl === 'string' && !isAbsoluteUrl(nextUrl)
                        ? nextUrl
                        : createAccountUrl();

                // Reset cache
                await client.resetStore();
                // Forward
                await navigate(forwardUrl);
            }
        });
    }, [location.search, doLoginWithCode]);

    return (
        <>
            <Container>
                {isLoginFailed ? (
                    <AccountLoginForm
                        wasInvalid
                        next={
                            createNextUrlFromLocation(location) ||
                            createAccountUrl()
                        }
                    />
                ) : (
                    <InnerWrapper>
                        <HeadingExtraLarge>Signing in…</HeadingExtraLarge>
                    </InnerWrapper>
                )}
            </Container>
        </>
    );
}

export default React.memo(LoginPage);
