import React from 'react';
import styled from 'styled-components';
import { Paragraph } from './Paragraph';
import { VIEWPORT } from '../settings/Global';
import printReleaseRange from '../utils/printReleaseRange';
import notDuplicate from '../utils/notDuplicate';
import type { AwardFragment } from '../gql/api-ssr';
import useConfig from '../hooks/useConfig';
import processWagtailRichText from '../utils/processWagtailRichText';
import { awardsSortFn } from '../templates/AwardsPage';

export type { AwardFragment };

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: var(--gridColumnGap);

    --gap: var(--spacing2);
    --border: 1px solid var(--borderColor);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-columns: 100%;
    }

    &[data-list='awards'] {
        @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
            border-top: var(--border);
            margin-top: var(--gap);
            padding-top: var(--gap);
        }
    }
`;

const DescriptionList = styled.dl`
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-column-gap: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-columns: var(--gridTemplateColumnsDefault);

        &[data-list='awards'] {
            margin-top: var(--gap);
            padding-top: var(--gap);
            border-top: 1px solid var(--borderColor);
        }
    }
`;

const DescriptionTitle = styled(Paragraph).attrs({ as: 'dt' })`
    grid-column: 1;
    color: var(--foregroundColorMix4);

    ${/* sc-selector */ DescriptionList}[data-list="misc"] & {
        grid-column: 1 / span 2;
    }

    ${/* sc-selector */ DescriptionList}[data-list="awards"] & {
        grid-column: 1 / span 1;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        ${/* sc-selector */ DescriptionList}[data-list="awards"] &,
        ${/* sc-selector */ DescriptionList}[data-list="misc"] & {
            grid-column: 1 / span 2;
        }
    }
`;

const DescriptionDefinition = styled(Paragraph).attrs({ as: 'dd' })`
    grid-column: 2;
    line-height: var(--lineHeightBody2);
    color: var(--foregroundColor);
    white-space: pre-line; /* Because some fields have newlines in them. */

    ${/* sc-selector */ DescriptionList}[data-list="misc"] & {
        grid-column: 3 / span 3;
    }

    ${/* sc-selector */ DescriptionList}[data-list="awards"] & {
        grid-column: 2 / span 4;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        ${/* sc-selector */ DescriptionList}[data-list="awards"] &,
        ${/* sc-selector */ DescriptionList}[data-list="misc"] & {
            grid-column: 3 / span 10;
        }
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        ${/* sc-selector */ DescriptionList}[data-list="awards"] &,
        ${/* sc-selector */ DescriptionList}[data-list="misc"] & {
            grid-column: 3 / span 4;
        }
    }
`;

const Hr = styled.div.attrs({ role: 'presentation ' })`
    display: block;
    height: 0;
    padding: 0;
    grid-column: 1 / span 5;
    border: none;
    border-top: var(--border);
    width: 100%;
    margin: var(--gap) 0;

    &:first-child {
        display: none;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 1 / span var(--gridColumnCount);

        [data-list='awards'] & {
            border-color: var(--backgroundColor);
            margin: var(--spacing1) 0;
        }
    }
`;

/**
 * When comparing strings we remove any non-alphabet characters.
 * @param string
 */
function prepStringForComparison(string: string): string {
    return string.replace(/[^A-Za-z0-9]/g, '').toLowerCase();
}

export function notDuplicateAward(
    award: AwardFragment,
    index: number,
    awards: AwardFragment[],
): boolean {
    const trimmedName = prepStringForComparison(award.name);
    const matchIndex = awards.findIndex(
        ({ year, name }): boolean =>
            year === award.year && prepStringForComparison(name) == trimmedName,
    );
    return matchIndex === index;
}

function Credits({
    client,
    releases,
    designers,
    agency,
    engineers,
    classifications,
    collaborators,
    awards,
}: {
    client?: string[];
    releases?: string[] | null;
    designers?: string[];
    agency?: string[];
    engineers?: string[];
    collaborators?: string[];
    classifications?: string[];
    awards?: AwardFragment[] | null;
}): React.ReactElement {
    const config = useConfig();

    interface Credit {
        name: string;
        value: React.ReactNode;
    }

    const creditsDetails: Credit[] = [
        {
            name: 'Client',
            value:
                client &&
                client.filter(Boolean).filter(notDuplicate).join('\n'),
        },
        {
            name: 'Release',
            value:
                releases &&
                printReleaseRange(
                    releases.filter(Boolean).sort().filter(notDuplicate),
                ),
        },
        {
            name: 'Design',
            value:
                designers &&
                designers.filter(Boolean).filter(notDuplicate).join('\n'),
        },
        {
            name: 'Engineering',
            value:
                engineers &&
                engineers.filter(Boolean).filter(notDuplicate).join('\n'),
        },
        {
            name: 'Agency',
            value:
                agency &&
                agency.filter(Boolean).filter(notDuplicate).join('\n'),
        },
        {
            name: 'Collaborators',
            value:
                collaborators &&
                collaborators.filter(Boolean).filter(notDuplicate).join('\n'),
        },
        {
            name: 'Classification',
            value:
                classifications &&
                classifications.filter(Boolean).filter(notDuplicate).join('\n'),
        },
    ];

    const awardsSorted = awards
        ? awards.sort(awardsSortFn).filter(notDuplicateAward)
        : [];

    const hasAwards = awardsSorted.length > 0;
    const awardsListHasOwnColumn = awardsSorted.length > 1;

    const awardsList = awardsSorted.length > 0 && (
        <>
            <DescriptionTitle>Awards</DescriptionTitle>
            {awardsSorted.map(
                ({ year, name, id }, index: number): React.ReactElement => (
                    <React.Fragment key={id}>
                        {index !== 0 && <Hr />}
                        <DescriptionDefinition
                            dangerouslySetInnerHTML={{
                                __html: [
                                    processWagtailRichText(
                                        name,
                                        config.customOpentypeHtmlTagNames,
                                    ),
                                    year,
                                ].join(', '),
                            }}
                        />
                    </React.Fragment>
                ),
            )}
        </>
    );

    return (
        <Container>
            <DescriptionList data-list="misc">
                {creditsDetails.map(
                    (
                        { name, value }: Credit,
                        index: number,
                    ): React.ReactElement | null =>
                        value ? (
                            <React.Fragment key={name}>
                                {index !== 0 && <Hr />}
                                <DescriptionTitle>{name}</DescriptionTitle>
                                <DescriptionDefinition>
                                    {value}
                                </DescriptionDefinition>
                            </React.Fragment>
                        ) : null,
                )}
                {!awardsListHasOwnColumn && hasAwards && (
                    <>
                        <Hr />
                        {awardsList}
                    </>
                )}
            </DescriptionList>
            {awardsListHasOwnColumn && hasAwards && (
                <DescriptionList data-list="awards">
                    {awardsList}
                </DescriptionList>
            )}
        </Container>
    );
}

export default Credits;
