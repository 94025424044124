import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import Button from './Button';
import Fieldset from './Fieldset';
import FieldText from './FieldText';
import FieldSelect from './FieldSelect';
import FieldInputGroup from './FieldInputGroup';
import type { User } from '../hooks/useUserQuery';
import useCountrySelectOptions from '../hooks/useCountrySelectOptions';
import type {
    CountryChoices,
    AccountUserDetailsMutationMutation,
    AccountUserDetailsMutationMutationVariables,
} from '../gql/api-public';
import { accountUserDetailsMutation } from '../utils/runtimeQueries';
import { VIEWPORT } from '../settings/Global';
import { FieldCheckbox } from './FieldRadioCheckbox';
import notNull from '../utils/notNull';
import { sentryException } from '../utils/sentry';
import { useErrorOverlayState } from './GlobalRuntimeState';
import useTaxIdLabel from '../hooks/useTaxIdLabel';

const Inner = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing4);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin: var(--spacing6) 0 0 0;
    }
`;

const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: var(--gridColumnGap);
`;

const CancelButton = styled(Button)`
    border: solid 1px var(--foregroundColor);
    background-color: var(--backgroundColor);
    color: var(--foregroundColor);
    grid-column: 3 / span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 4 / span 3;
        margin: var(--spacing3) 0;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 6;
        margin: var(--spacing1) 0;
    }
`;

const SaveButton = styled(Button)`
    border: solid 1px var(--backgroundColor);
    background-color: var(--foregroundColor);
    color: var(--backgroundColor);
    grid-column: 1 / span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 3;
        margin: var(--spacing3) 0;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 6;
        margin: var(--spacing1) 0;
    }
`;
const FieldsDoubleColumn = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--spacing2) var(--gridColumnGap);
`;

const FieldsColumn = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacing2) var(--gridColumnGap);
    margin: var(--spacing2) 0 0 0;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

interface FormValues {
    email: string;
    fullName: string;
    emailOptIn: boolean;
    billingCompanyName: string;
    billingAddress1: string;
    billingAddress2: string;
    billingCity: string;
    billingState: string;
    billingPostcode: string;
    billingCountry: CountryChoices;
    billingVatNumber: string;
}

function AccountUserDetailsForm({
    user,
    onComplete,
}: {
    user: User;
    onComplete: () => void;
}): React.ReactElement {
    const [, setErrorOverlayState] = useErrorOverlayState();
    const countryOptions = useCountrySelectOptions();
    const [doUpdateUserDetails] = useMutation<
        AccountUserDetailsMutationMutation,
        AccountUserDetailsMutationMutationVariables
    >(accountUserDetailsMutation);

    const { register, handleSubmit, formState, setError } = useForm<FormValues>(
        {
            defaultValues: {
                email: user.email || '',
                fullName: user.fullName || '',
                emailOptIn: user.emailOptIn || false,
                billingCompanyName: user.billingCompanyName || '',
                billingAddress1: user.billingAddress1 || '',
                billingAddress2: user.billingAddress2 || '',
                billingCity: user.billingCity || '',
                billingState: user.billingState || '',
                billingPostcode: user.billingPostcode || '',
                billingCountry: user.billingCountry || undefined,
                billingVatNumber: user.billingVatNumber || '',
            },
        },
    );

    const taxIdLabel = useTaxIdLabel(user.billingCountry || '__');

    const onSubmit = async (formData: FormValues): Promise<void> => {
        try {
            const result = await doUpdateUserDetails({
                variables: {
                    input: formData,
                },
            });

            const errors =
                result?.data?.accountUserDetails?.errors?.filter(notNull) || [];

            if (errors.length > 0) {
                errors.forEach((error): void => {
                    setError(error.field as keyof FormValues, {
                        type: 'generic',
                        message: error.messages.pop(),
                    });
                });
                return;
            } else {
                onComplete();
            }
        } catch (e) {
            sentryException(e);
            setErrorOverlayState({ isShown: true });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Inner>
                <Fieldset legend="Your details">
                    <FieldsColumn>
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="Full name"
                                    isError={!!formState.errors.fullName}
                                    {...register('fullName', {
                                        required: 'Enter a full name',
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'Full name must not exceed 255 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.fullName}
                        />
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="Email address"
                                    isError={!!formState.errors.email}
                                    {...register('email', {
                                        required: 'Enter an email address',
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'Email address must not exceed 255 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.email}
                        />
                        <FieldInputGroup
                            input={
                                <FieldCheckbox
                                    label="Subscribe to emails about new font releases."
                                    {...register('emailOptIn')}
                                    isError={!!formState.errors.emailOptIn}
                                />
                            }
                            error={formState.errors.emailOptIn}
                        />
                    </FieldsColumn>
                </Fieldset>

                <Fieldset legend="Billing address">
                    <FieldsDoubleColumn>
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="Company name"
                                    isError={
                                        !!formState.errors.billingCompanyName
                                    }
                                    {...register('billingCompanyName', {
                                        required: 'Enter a company name',
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'Company name must not exceed 255 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.billingCompanyName}
                        />
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="Street"
                                    isError={!!formState.errors.billingAddress1}
                                    {...register('billingAddress1', {
                                        required: 'Enter a street address',
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'Street must not exceed 255 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.billingAddress1}
                        />
                    </FieldsDoubleColumn>
                    <FieldsColumn>
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="Suburb (optional)"
                                    isError={!!formState.errors.billingAddress2}
                                    {...register('billingAddress2', {
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'Suburb must not exceed 255 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.billingAddress2}
                        />

                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="City"
                                    isError={!!formState.errors.billingCity}
                                    {...register('billingCity', {
                                        required: 'Enter a city',
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'City must not exceed 255 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.billingCity}
                        />
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="State (optional)"
                                    isError={!!formState.errors.billingState}
                                    {...register('billingState', {
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'State must not exceed 255 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.billingState}
                        />
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label="Post code"
                                    isError={!!formState.errors.billingPostcode}
                                    {...register('billingPostcode', {
                                        required: 'Enter a post code',
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'Post code must not exceed 255 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.billingPostcode}
                        />
                        <FieldInputGroup
                            input={
                                <FieldSelect
                                    label="Country"
                                    isError={!!formState.errors.billingCountry}
                                    {...register('billingCountry', {
                                        required: 'Enter a country',
                                        maxLength: 2,
                                    })}
                                >
                                    {countryOptions}
                                </FieldSelect>
                            }
                            error={formState.errors.billingCountry}
                        />
                        <FieldInputGroup
                            input={
                                <FieldText
                                    label={`${taxIdLabel} (optional)`}
                                    isError={
                                        !!formState.errors.billingVatNumber
                                    }
                                    {...register('billingVatNumber', {
                                        maxLength: {
                                            value: 100,
                                            message:
                                                'VAT number must not exceed 100 characters',
                                        },
                                    })}
                                />
                            }
                            error={formState.errors.billingVatNumber}
                        />
                    </FieldsColumn>
                </Fieldset>
                <ButtonContainer>
                    <SaveButton
                        type="submit"
                        disabled={formState.isSubmitting || undefined}
                    >
                        {formState.isSubmitting ? 'Saving…' : 'Save details'}
                    </SaveButton>
                    <CancelButton
                        $secondary
                        onClick={(): void => onComplete()}
                        // We can't *really* cancel if there's a pending submission.
                        disabled={formState.isSubmitting || undefined}
                    >
                        Cancel{' '}
                    </CancelButton>
                </ButtonContainer>
            </Inner>
        </form>
    );
}

export default React.memo(AccountUserDetailsForm);
