import React from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { Paragraph } from '../components/Paragraph';
import SubscribeForm from '../components/SubscribeForm';
import { HeadingExtraLarge } from '../components/Heading';
import { VIEWPORT } from '../settings/Global';
import type { KlimPageProps } from '../layouts';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    min-height: 90vh;

    ${HeadingExtraLarge} {
        margin-bottom: var(--spacing4);

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            word-wrap: break-word;
        }
    }

    ${Paragraph} {
        margin-bottom: var(--spacing2);

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            margin-bottom: var(--spacing7);
        }
    }
`;

const Inner = styled.div`
    grid-column: 3 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

function SubscribePage({ pageContext }: KlimPageProps): React.ReactElement {
    const location = useLocation();
    const subscriptionSuccess = Boolean(pageContext.subscriptionSuccess);
    const submittedEmail =
        location.state && (location.state as KlimLinkState).email
            ? (location.state as KlimLinkState).email
            : 'you';

    return (
        <Container>
            <Inner>
                {subscriptionSuccess ? (
                    <>
                        <HeadingExtraLarge>
                            An email is on its way to {submittedEmail}
                        </HeadingExtraLarge>
                        <Paragraph>
                            It contains a link to verify your email address.
                        </Paragraph>
                    </>
                ) : (
                    <>
                        <HeadingExtraLarge>Newsletter</HeadingExtraLarge>
                        <Paragraph>
                            Subscribe to receive updates about font releases and
                            news from Klim.
                        </Paragraph>
                        <SubscribeForm />
                    </>
                )}
            </Inner>
        </Container>
    );
}

export default React.memo(SubscribePage);
