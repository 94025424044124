import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import styled from 'styled-components';
import { statefulLozengeStyles } from './Lozenge';
import { CheckboxProps } from '@radix-ui/react-checkbox';

export { CheckboxProps };

const CheckboxRoot = styled(Checkbox.Root)`
    --lozengeBackgroundHoverColor: var(--foregroundColorMix3);
    ${statefulLozengeStyles};
`;

const LozengeCheckbox = React.forwardRef(
    (
        props: React.PropsWithChildren<CheckboxProps>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): React.ReactElement => {
        return (
            <CheckboxRoot ref={forwardedRef} {...props}>
                {props.children}
            </CheckboxRoot>
        );
    },
);

LozengeCheckbox.displayName = 'LozengeCheckbox';

export default LozengeCheckbox;
