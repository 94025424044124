import React from 'react';
import { useFontFamily } from '../../components/PageContext';
import getCssFontFamilyNameFromId from '../getCssFontFamilyNameFromId';

export interface CustomStyleMap {
    [key: string]: { [cssKey: string]: string | number | undefined };
}

export default function useCustomStyleMap(): CustomStyleMap {
    const { fontStyles } = useFontFamily();

    return React.useMemo(() => {
        const styleEntries = fontStyles.map((fontStyle) => {
            const cssFontFamilyName = getCssFontFamilyNameFromId(fontStyle.id);
            return [cssFontFamilyName, { fontFamily: cssFontFamilyName }];
        });

        return Object.fromEntries(styleEntries);
    }, [fontStyles]);
}
