import styled, { css } from 'styled-components';

import { Link as _Link } from 'gatsby';

export const styles = css`
    --color: var(--foregroundColor);
    --hoverColor: var(--foregroundColor);
    --underlineColor: var(
        ${(props): string =>
            props.theme.backgroundLuminance >= 0.5
                ? '--foregroundColorMix6'
                : '--foregroundColorMix4'}
    );
    --hoverUnderlineColor: var(--foregroundColorMix1);

    color: var(--color);
    display: inline;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: var(--underlineColor);

    &:hover,
    &:focus,
    &:active {
        color: var(--hoverColor);
        text-decoration-color: var(--hoverUnderlineColor);
    }
`;

const Link = styled(_Link)`
    ${styles};
`;

export default Link;

export const A = styled.a`
    ${styles};
`;

export const Button = styled.button`
    ${styles};
`;
