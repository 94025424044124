import React from 'react';
import styled, { css } from 'styled-components';
import SVGIcon from './SVGIcon';
import { SpecimenType, VIEWPORT } from '../settings/Global';
import { useFontSizeState, useSpecimenContext } from './TypeEditorContext';
import printFontSizePx from '../utils/printFontSizePx';
import InputSlider from './InputSlider';
import { Div, LOZENGE_HORIZONTAL_PADDING } from './Lozenge';
import { TypeEditorActiveStatus } from './TypeEditorToolbar';

const Icon = styled(SVGIcon)<{ $activeStatus: TypeEditorActiveStatus }>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  visibility: hidden;
              `
            : null}
`;

const Slider = styled(InputSlider)<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  visibility: hidden;
              `
            : null}
`;

const Container = styled(Div)<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    --lozengeMinWidth: 220px;
    --lozengeWidth: calc(20vw - var(--gridMarginGap));

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${LOZENGE_HORIZONTAL_PADDING}px;
    font-feature-settings: 'tnum';

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        --lozengeMinWidth: 200px;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        --lozengeWidth: calc(40vw - var(--gridMarginGap));
        --lozengeMinWidth: auto;
    }

    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  --lozengeColor: var(--foregroundColorMix6);
                  --lozengeBackgroundColor: transparent;

                  cursor: pointer;
              `
            : null}
`;

const Value = styled.div<{ $activeStatus: TypeEditorActiveStatus }>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus === 'inactive'
            ? css`
                  color: var(--foregroundColorMix6);
              `
            : $activeStatus === 'hovered'
              ? css`
                    color: var(--foregroundColorMix4);
                `
              : null};

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;

const TypeEditorFontSizeInput = React.forwardRef(
    (
        {
            activeStatus,
        }: {
            activeStatus: TypeEditorActiveStatus;
        },
        forwardedRef: React.Ref<HTMLSpanElement>,
    ): React.ReactElement => {
        const specimen = useSpecimenContext();
        const [fontSize, setFontSize] = useFontSizeState();

        return (
            <Container $activeStatus={activeStatus} title="Font size">
                <Icon type="FONT_SIZE" $activeStatus={activeStatus} />
                <Slider
                    ref={forwardedRef}
                    value={[fontSize]}
                    min={10}
                    max={
                        specimen.type === SpecimenType.SMALL
                            ? Math.max(specimen.fontSize * 2, 60)
                            : Math.max(Math.round(specimen.fontSize * 1.5), 40)
                    }
                    onValueChange={(val): void =>
                        val.length ? setFontSize(val[0]) : undefined
                    }
                    $activeStatus={activeStatus}
                />
                <Value $activeStatus={activeStatus}>
                    {printFontSizePx(fontSize)}
                </Value>
            </Container>
        );
    },
);
TypeEditorFontSizeInput.displayName = 'TypeEditorFontSizeInput';

export default TypeEditorFontSizeInput;
