import React from 'react';
import styled, { css } from 'styled-components';
import { HeadingExtraLarge, HeadingSmall } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { Ul, Li, Ol } from '../components/Primitives';
import { A as InlineUnderlinedLink } from '../components/InlineUnderlinedLink';
import { VIEWPORT } from '../settings/Global';
import {
    createContactUrl,
    createAccountUrl,
    createSubscribeUrl,
    createTestFontsUrl,
    createEulaOverviewUrl,
    createStripeUrl,
    createCampaignMonitorUrl,
    createCampaignMonitorPrivacyPolicyUrl,
    createGoogleAnalyticsUrl,
    createGooglePrivacyPolicyUrl,
    createPrivacyActUrl,
} from '../utils/urlHelper';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--spacing3) var(--gridColumnGap);
    margin: 0 var(--gridMarginGap);
`;

const Column = styled.div`
    grid-column: 3 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 6;
    }
`;

const Title = styled(HeadingExtraLarge)`
    padding-bottom: var(--spacing4);
`;

const ParagraphHeading = styled(HeadingSmall)`
    padding: var(--spacing6) 0 var(--spacing4) 0;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed5);
        padding: var(--spacing7) 0 var(--spacing3) 0;
    }
`;

const DateHeading = styled(Paragraph)`
    color: var(--foregroundColorMix4);
`;

const sectionStyles = css`
    line-height: var(--lineHeightBody4);
    margin-bottom: var(--spacing3);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

const ParagraphSection = styled(Paragraph)`
    ${sectionStyles}
`;

const ListSection = styled.div`
    ${sectionStyles}
`;

function PrivacyPolicyPage(): React.ReactElement {
    const staticFontMetrics = useStaticFontMetricOffsets();

    return (
        <Container>
            <Column>
                <Title $metricOffsets={staticFontMetrics} as="h1">
                    Privacy policy
                </Title>
                <DateHeading>22 May 2018</DateHeading>
                <ParagraphHeading $metricOffsets={staticFontMetrics}>
                    Scope
                </ParagraphHeading>
                <ParagraphSection>
                    This privacy notice applies to personal information that we
                    collect through this website:{' '}
                    <InlineUnderlinedLink href={'/'}>
                        www.klim.co.nz,
                    </InlineUnderlinedLink>{' '}
                    or any email or phone communication you may have with Klim.
                </ParagraphSection>
                <ParagraphHeading $metricOffsets={staticFontMetrics}>
                    Collection of personal information
                </ParagraphHeading>
                <ParagraphSection>
                    We do not collect identifiable information about you if you
                    only browse our website.
                </ParagraphSection>
                <ParagraphSection>
                    We do not collect sensitive or special categories of
                    identifiable information relating to racial or ethnic
                    origin, political opinions, religious or philosophical
                    beliefs, or trade union membership, and the processing of
                    genetic data, biometric data for the purpose of uniquely
                    identifying a natural person, data concerning health or data
                    concerning a natural person’s sex life or sexual
                    orientation.
                </ParagraphSection>
                <ParagraphSection>
                    We do not collect your personal information on this site
                    unless you choose to give it to us. There are three places
                    on the site where you can choose to give us personal
                    information:
                </ParagraphSection>
                <ListSection>
                    <Ol>
                        <Li>By purchasing a typeface licence.</Li>
                        <Li>
                            By{' '}
                            <InlineUnderlinedLink href={createSubscribeUrl()}>
                                subscribing
                            </InlineUnderlinedLink>{' '}
                            to our mailing list.
                        </Li>
                        <Li>
                            By downloading our{' '}
                            <InlineUnderlinedLink href={createTestFontsUrl()}>
                                test fonts.
                            </InlineUnderlinedLink>{' '}
                        </Li>
                    </Ol>
                </ListSection>
                <ParagraphSection>
                    In each of the above places, you may give us details of
                    identifiable information including one or more of the
                    following:
                </ParagraphSection>
                <ListSection>
                    <Ul>
                        <Li>Name</Li>
                        <Li>Physical address</Li>
                        <Li>Email address </Li>
                        <Li>Telephone number </Li>
                    </Ul>
                </ListSection>
                <ParagraphSection>
                    We may also collect transactional behaviour relating to
                    purchases you make through this website or through invoices
                    sent by Klim. Any transactional information used is to
                    improve your experience with us.
                </ParagraphSection>
                <ParagraphSection>
                    We are required by law to retain certain pieces of personal
                    information you provide us with for a minimum of 7 years —
                    this relates primarily to financial records; other
                    information we collect we will only retain for the amount of
                    time that is required for us to use the information for the
                    purposes stated below.
                </ParagraphSection>
                <ParagraphSection>
                    When you purchase a font, you agree to one or more of our{' '}
                    <InlineUnderlinedLink href={createEulaOverviewUrl()}>
                        various font licences
                    </InlineUnderlinedLink>
                    . This means we have a contractual relationship. We will
                    retain the data relevant to this contract for as long as our
                    relationship is in effect for legitimate and essential
                    business purposes.
                </ParagraphSection>
                <ParagraphHeading $metricOffsets={staticFontMetrics}>
                    Use and disclosure
                </ParagraphHeading>
                <ParagraphSection>
                    We will primarily use personal information that you provide
                    to us through this site for the purposes for which you
                    supplied it or, in exceptional situations, for other reasons
                    permitted under the{' '}
                    <InlineUnderlinedLink href={createPrivacyActUrl()}>
                        Privacy Act 1993
                    </InlineUnderlinedLink>{' '}
                    and the General Data Protection Regulations (GDPR). When we
                    collect your personal information we do so with the
                    appropriate technical and organisational measures in place
                    to protect your personal information for the duration we use
                    and store it.
                </ParagraphSection>
                <ParagraphSection>
                    The law may require us to disclose the information (for
                    instance to investigate a criminal offence), or there may be
                    safety reasons for disclosing the information.
                </ParagraphSection>
                <ParagraphHeading $metricOffsets={staticFontMetrics}>
                    Third party suppliers/providers
                </ParagraphHeading>
                <ParagraphSection>
                    We do not store or directly process your credit card data,
                    that is handled securely by{' '}
                    <InlineUnderlinedLink href={createStripeUrl()}>
                        Stripe.
                    </InlineUnderlinedLink>{' '}
                </ParagraphSection>
                <ParagraphSection>
                    If you have signed up to receive emails, we share your email
                    address with{' '}
                    <InlineUnderlinedLink href={createCampaignMonitorUrl()}>
                        Campaign Monitor
                    </InlineUnderlinedLink>{' '}
                    . Here is their{' '}
                    <InlineUnderlinedLink
                        href={createCampaignMonitorPrivacyPolicyUrl()}
                    >
                        privacy policy.
                    </InlineUnderlinedLink>{' '}
                    We will not send you marketing material or promotions
                    without your prior consent.
                </ParagraphSection>
                <ParagraphSection>
                    We use{' '}
                    <InlineUnderlinedLink href={createGoogleAnalyticsUrl()}>
                        Google Analytics
                    </InlineUnderlinedLink>
                    . Here is Google’s{' '}
                    <InlineUnderlinedLink href={createGooglePrivacyPolicyUrl()}>
                        privacy policy.
                    </InlineUnderlinedLink>
                </ParagraphSection>
                <ParagraphSection>
                    We may also use a third party to help us with improving your
                    customer experience, which generally relates, for example,
                    to the time you spend on a page, the operating systems of
                    the machine you’ve used to access the site, the web browser
                    you have used to access the site, the time and date stamp
                    when you visited the site and the types of purchases you
                    make and the types of licences you consume. When we use a
                    third party, your information will only be shared with them
                    to the extent needed to perform their service for us, and in
                    accordance with what is allowed under the Privacy Act and
                    the GDPR. We take your privacy seriously and we ensure our
                    third party suppliers have adequate privacy/data protection
                    measures in place before we agree to use their products or
                    services — we can provide a link or copy of the privacy
                    policies of the third parties we use on request.
                </ParagraphSection>
                <ParagraphHeading $metricOffsets={staticFontMetrics}>
                    Your rights and choices
                </ParagraphHeading>
                <ParagraphSection>
                    Under the Privacy Act and GDPR you have certain entitlements
                    relating to our collection, use and storage of your personal
                    information.
                </ParagraphSection>
                <ParagraphSection>
                    We are happy to provide you with access to any personal
                    information that we hold about you. If it is wrong, please
                    ask us to correct it; if you no longer want your personal
                    information to be held by us, please request for it to be
                    deleted. There may be certain circumstances you may ask for
                    the processing of your data to be restricted, if this is
                    required, you can contact us and we will confirm the options
                    available to you and the personal information that is
                    subject to the restriction. You can update your account
                    details at any time by logging in to{' '}
                    <InlineUnderlinedLink href={createAccountUrl()}>
                        your account.
                    </InlineUnderlinedLink>{' '}
                    To ask for access, correction or deletion, please{' '}
                    <InlineUnderlinedLink href={createContactUrl()}>
                        contact us.
                    </InlineUnderlinedLink>
                </ParagraphSection>
            </Column>
        </Container>
    );
}

export default React.memo(PrivacyPolicyPage);
