import React from 'react';
import FontsInUse, { MAX_IN_USE_POSTS_PER_ROW } from './FontsInUse';
import useAllFonts from '../hooks/useAllFonts';
import { useInUseFilter } from './PageContext';
import useInUsePostStubs, { InUsePostStub } from '../hooks/useInUsePostStubs';
import { IN_USE_ALL_CATEGORIES_TAG_SLUG } from '../settings/Global';
import subscribeScrollRequestAnimationFrame from '../utils/subscribeScrollRequestAnimationFrame';
import notUndefined from '../utils/notUndefined';
import Paragraph from './Paragraph';

function useFilteredFontsInUsePages(): InUsePostStub[] {
    const {
        fontFamilyGroup: fontFamilyGroupFromFilter = undefined,
        category = IN_USE_ALL_CATEGORIES_TAG_SLUG,
    } = useInUseFilter();
    const fontFamilyGroups = useAllFonts();
    const fontFamilyGroup = fontFamilyGroups.find((fontFamilyGroup): boolean =>
        Boolean(
            fontFamilyGroupFromFilter &&
                fontFamilyGroup.slug === fontFamilyGroupFromFilter.slug,
        ),
    );
    const fontFamilySlugs = fontFamilyGroup?.fontFamilies
        .map((ff) => ff.slug)
        .filter(notUndefined);
    return useInUsePostStubs({
        fontFamilySlugs: fontFamilySlugs ? fontFamilySlugs : undefined,
        categorySlug: category,
    });
}

function InUseIndexFontsInUse(): React.ReactElement {
    const [chunk, setChunk] = React.useState<number>(1);
    const inUsePosts = useFilteredFontsInUsePages();

    React.useEffect((): (() => void) => {
        const onScroll = (): void => {
            if (
                // Check if we're less than 2-viewports away from the bottom.
                window.innerHeight * 2 + window.scrollY >=
                document.body.scrollHeight
            ) {
                setChunk((state): number => state + 1);
            }
        };

        return subscribeScrollRequestAnimationFrame(onScroll);
    }, []);

    return inUsePosts.length ? (
        <FontsInUse
            inUsePosts={inUsePosts}
            rows={chunk * MAX_IN_USE_POSTS_PER_ROW}
            renderStyle="grayscale"
            lazyLoad={chunk > 1}
        />
    ) : (
        <Paragraph>Nothing to see here, yet.</Paragraph>
    );
}

export default React.memo(InUseIndexFontsInUse);
