import { usePreviewInUsePost } from '../components/GlobalRuntimeState';
import { usePageQueryInUsePost } from '../components/PageContext';
import type { InUsePost } from '../union-types/inUsePost';

export default function usePreviewOrPageQueryInUsePost(): InUsePost {
    const previewPage = usePreviewInUsePost();
    const pageQueryPage = usePageQueryInUsePost();
    if (!previewPage && !pageQueryPage) {
        throw Error('inUsePost is undefined');
    }
    return (previewPage || pageQueryPage) as InUsePost;
}
