import type { StripeElementStyle } from '@stripe/stripe-js';

export default function stripeFieldStyle(
    element: HTMLElement,
): StripeElementStyle {
    /**
     * Stripe Elements only allows specific styles to be specified on its inputs.
     * See https://stripe.com/docs/stripe-js/reference#element-options
     */
    const computedStyle = getComputedStyle(element);
    return {
        base: {
            fontFamily: computedStyle.getPropertyValue(
                '--fontFamilySoehneMono',
            ),
            color: computedStyle.getPropertyValue('--foregroundColor'),
            fontSize: computedStyle.getPropertyValue('--fontSizeFixed2'),
            lineHeight: computedStyle.getPropertyValue('--lineHeightBody2'),
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: computedStyle.getPropertyValue('--foregroundColorMix6'),
            },
        },
        invalid: {
            color: computedStyle.getPropertyValue('--foregroundColor'),
        },
    };
}
