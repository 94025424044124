import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { HeadingExtraLarge, HeadingLarge } from '../components/Heading';
import { Paragraph, ParagraphMarginStyles } from '../components/Paragraph';
import Link, {
    A,
    styles as inlineUnderlinedStyles,
} from '../components/InlineUnderlinedLink';
import FragmentLink from '../components/FragmentLink';
import fontLicences from '../data/fontLicences';
import DefinitionList from '../components/DefinitionList';
import { LinkAccordion, LinkAccordionItem } from '../components/LinkAccordion';
import { VIEWPORT } from '../settings/Global';
import { createEulaUrl, createSalesEmailUrl } from '../utils/urlHelper';
import { LicenceTypeId } from '../gql/api-public';
import useConfig from '../hooks/useConfig';
import { useStaticFontMetricOffsets } from '../components/PageContext';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    grid-row-gap: var(--spacing3);
    display: grid;
    grid-template-columns: 100%;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: var(--spacing3) var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-row-gap: var(--spacing3);
    }
`;

const SectionRow = styled.div`
    display: grid;
    grid-gap: 0 var(--gridColumnGap);
    grid-template-columns: var(--gridTemplateColumnsDefault);
`;

export const ColumnUl = styled.ul`
    margin-bottom: var(--spacing6);
    display: block;
    columns: 2;
    column-gap: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        columns: 1;
        font-size: var(--fontSizeFixed3);
    }
`;

const ColumnTop = styled.div`
    grid-column: 3 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const Column = styled.div`
    grid-column: 3 / span 6;
    padding-top: var(--spacing6);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 3 / span 8;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const ScrollableLink = styled(FragmentLink)`
    ${inlineUnderlinedStyles};
`;

const ColouredInlineUnderlinedLink = styled(A)`
    color: var(--foregroundColorMix6);
`;

const Title = styled(HeadingExtraLarge)`
    padding-bottom: var(--spacing4);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-bottom: var(--spacing1);
    }
`;

const Title2 = styled(HeadingLarge)`
    padding-bottom: var(--spacing4);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-bottom: var(--spacing1);
    }
`;

const P = styled(Paragraph)`
    ${ParagraphMarginStyles};

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

const Heading = styled(Paragraph)`
    grid-column: 10 / span 3;
    color: var(--foregroundColorMix6);

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 3 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 6;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        font-size: var(--fontSizeFixed3);
    }
`;

function FontLicenceAccordionBlock(): React.ReactElement {
    return (
        <SectionRow>
            {fontLicences.map(
                ({ heading, content, id, table }): React.ReactElement => (
                    <Column id={id} key={`flcol${id}`}>
                        <LinkAccordion>
                            <LinkAccordionItem
                                heading={heading}
                                subHeading={content}
                                panel={table.map(
                                    ({ title, type }): React.ReactElement => (
                                        <DefinitionList
                                            key={`fldef${id}${title}`}
                                            name={title}
                                            type={type}
                                        />
                                    ),
                                )}
                            />
                        </LinkAccordion>
                    </Column>
                ),
            )}
        </SectionRow>
    );
}

interface FontLicenceNavLinkBlockProps {
    href: string;
    title?: string;
}

function FontLicenceNavLinkBlock({
    href,
    title,
}: FontLicenceNavLinkBlockProps): React.ReactElement {
    return (
        <li>
            <ScrollableLink href={href}>{title}</ScrollableLink>
        </li>
    );
}

function FontLicencePage(): React.ReactElement | null {
    const config = useConfig();
    const staticFontMetrics = useStaticFontMetricOffsets();

    // See createPages.createRedirects for more info on this redirecting
    const location = useLocation();
    switch (location.hash.substring(1)) {
        case 'desktop':
            navigate(createEulaUrl(LicenceTypeId.DESKTOP), { replace: true });
            return null;
        case 'web':
            navigate(createEulaUrl(LicenceTypeId.WEB), { replace: true });
            return null;
        case 'app':
            navigate(createEulaUrl(LicenceTypeId.APP), { replace: true });
            return null;
        case 'test':
            navigate(createEulaUrl(LicenceTypeId.TEST), {
                replace: true,
            });
            return null;
    }
    return (
        <>
            <Container>
                <Row>
                    <ColumnTop>
                        <Title $metricOffsets={staticFontMetrics} as="h1">
                            Font licences
                        </Title>
                    </ColumnTop>
                    <ColumnTop>
                        <P>
                            All fonts can be used worldwide, all fees are
                            one-off, and all licences have no expiry.
                        </P>
                        <ColumnUl>
                            {config.eulas.map(
                                (eula): React.ReactElement => (
                                    <li key={eula.licenceTypeId}>
                                        <Link
                                            to={createEulaUrl(
                                                eula.licenceTypeId,
                                            )}
                                        >
                                            {eula.shortName}
                                        </Link>
                                    </li>
                                ),
                            )}
                        </ColumnUl>
                    </ColumnTop>
                    <ColumnTop>
                        <Title2 $metricOffsets={staticFontMetrics} as="h2">
                            Common use cases
                        </Title2>
                    </ColumnTop>
                    <ColumnTop>
                        <P>
                            These examples summarise the most common use cases.
                            Once you have identified your use case please read
                            all licence terms carefully, as the licence will
                            become a formal contract between you and Klim Type
                            Foundry.
                        </P>
                        <ColumnUl>
                            {fontLicences.map(
                                ({ heading, id }): React.ReactElement => (
                                    <FontLicenceNavLinkBlock
                                        key={id}
                                        href={`#${id}`}
                                        title={heading}
                                    />
                                ),
                            )}
                        </ColumnUl>
                    </ColumnTop>
                    <Heading>
                        If your use case is not included below please contact us
                        at{' '}
                        <ColouredInlineUnderlinedLink
                            href={createSalesEmailUrl(true)}
                        >
                            {createSalesEmailUrl()}
                        </ColouredInlineUnderlinedLink>{' '}
                        and we will discuss the right licence for your needs.
                    </Heading>
                </Row>
                <FontLicenceAccordionBlock />
            </Container>
        </>
    );
}

export default React.memo(FontLicencePage);
