import React from 'react';
import styled from 'styled-components';
import type {
    BlogPostStub,
    BlogPostStubFontFamilyGroup,
} from '../union-types/blogPostStub';
import { createBlogIndexUrl } from '../utils/urlHelper';
import { HeadingMedium } from './Heading';
import Link from './Lozenge';
import { MARGIN_SMALL, VIEWPORT } from '../settings/Global';
import notNull from '../utils/notNull';
import isWhitelisted from '../utils/isWhitelisted';
import useConfig from '../hooks/useConfig';
import useIsUserTestingSite from '../hooks/useIsUserTestingSite';
import usePreviewOrPageQueryHomepage from '../hooks/usePreviewOrPageQueryHomepage';
import BlogCards from './BlogCards';
import { useStaticFontMetricOffsets } from './PageContext';
import Icon, { IconType } from './Icon';

const Container = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-gap: ${MARGIN_SMALL}px var(--gridColumnGap);
`;

const Section = styled.div`
    grid-column: 1 / span var(--gridColumnCount);

    &[data-section='view-link'] {
        display: grid;
        grid-template-columns: var(--gridTemplateColumnsDefault);
        grid-column-gap: var(--gridColumnGap);
        padding-top: var(--spacing5);
    }
`;

const LinkWrapper = styled.div`
    margin-top: var(--spacing5);
    grid-column: 3 / span 4;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 1 / span 6;
    }
`;

function HomePageBlogs(): React.ReactElement | null {
    const homepage = usePreviewOrPageQueryHomepage();
    const isUserTestingSite = useIsUserTestingSite();
    const config = useConfig();
    const fontMetricOffsets = useStaticFontMetricOffsets();

    // Limit the blog posts for the user testing site,
    // so that we're not exposing any pre-release fonts.
    const blogPosts = React.useMemo((): BlogPostStub[] => {
        return homepage.featuredBlogPosts
            ? (homepage.featuredBlogPosts as BlogPostStub[]).filter(
                  (blogPostStub) => {
                      if (!isUserTestingSite) {
                          return true;
                      }
                      return !blogPostStub.fontFamilyGroups?.some(
                          (fontFamilyGroup: BlogPostStubFontFamilyGroup) => {
                              return !isWhitelisted(
                                  config.userTestingFontFamilyGroupWhitelist,
                                  fontFamilyGroup.id,
                              );
                          },
                      );
                  },
              )
            : [];
    }, [
        isUserTestingSite,
        homepage.featuredBlogPosts,
        config.userTestingFontFamilyGroupWhitelist,
    ]);

    if (!blogPosts.length) {
        return null;
    }

    return (
        <Container>
            <Section>
                <HeadingMedium $metricOffsets={fontMetricOffsets}>
                    Blog
                </HeadingMedium>
            </Section>
            <Section>
                <BlogCards blogPosts={blogPosts.filter(notNull)} />
            </Section>
            <Section data-section="view-link">
                <LinkWrapper>
                    <Link to={createBlogIndexUrl()}>
                        See more blog posts&nbsp;
                        <Icon type={IconType.ARROW_RIGHT} />
                    </Link>
                </LinkWrapper>
            </Section>
        </Container>
    );
}

export default React.memo(HomePageBlogs);
