import notNull from './notNull';
import notDuplicate from './notDuplicate';

export default function printReleaseRange(dates: string[]): string | null {
    const years = dates
        .filter(notNull)
        // Dates are in the format 2012-05-17
        .map((date) => date.split('-')[0])
        .filter(notDuplicate)
        .sort();
    if (years.length === 0) {
        return null;
    }
    if (years.length === 1) {
        return years[0];
    }
    const oldest = years[0];
    const newest = years[years.length - 1];

    return `${oldest}–${newest}`;
}
