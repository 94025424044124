import React from 'react';
import type { Item } from '../hooks/useCartQuery';
import type { FontFamilyGroup } from '../hooks/useAllFonts';
import ensureFontFaceIsLoaded, {
    isFontFaceDefinitionAdded,
} from './ensureFontFaceIsLoaded';
import getFontStyleFromId from './getFontStyleFromId';
import getCssFontFamilyNameFromId from './getCssFontFamilyNameFromId';
import getSafeCssStringValue from './getSafeCssStringValue';

/**
 * Returns a styled font product name, if possible.
 * @param item
 * @param fontFamilyGroups
 */
export default function getStyledFontName(
    item: Item,
    fontFamilyGroups: FontFamilyGroup[],
): React.ReactNode | undefined {
    if (!item.font || !item.fontDescription || !fontFamilyGroups.length) {
        return;
    }

    const renderFontStyleId = item.font.renderFontStyleId;

    if (!renderFontStyleId) {
        return item.fontDescription;
    }

    const fontFamilyName = getCssFontFamilyNameFromId(renderFontStyleId);
    if (!isFontFaceDefinitionAdded(fontFamilyName)) {
        const fontStyle = getFontStyleFromId(
            fontFamilyGroups,
            renderFontStyleId,
        );
        if (fontStyle) {
            ensureFontFaceIsLoaded(fontStyle);
        }
    }

    const cssFontFamily = getSafeCssStringValue(fontFamilyName || '');

    return (
        <span
            style={{
                /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
                fontFamily: cssFontFamily,
            }}
        >
            {item.fontDescription}
        </span>
    );
}
