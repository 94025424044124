import React from 'react';
import styled from 'styled-components';
import {
    LINE_HEIGHT,
    MARGIN_BIG,
    MARGIN_SMALL,
    VIEWPORT,
} from '../settings/Global';
import { signifierStyle, hyperlinkStyle } from './BlogPostStyles';
import printNumber from '../utils/printNumber';
import processWagtailRichText from '../utils/processWagtailRichText';
import useConfig from '../hooks/useConfig';
import { TEST_ID } from '../settings/E2e';
import { hyphensAuto } from '../utils/stylesMixins';
import formatDjangoDateString from '../utils/formatDjangoDateString';
import usePreviewOrPageQueryBlogPost, {
    usePreviewOrPageQueryBlogPostWordCount,
} from '../hooks/usePreviewOrPageQueryBlogPost';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';
import { BlogPostHero } from './BlogPostHero';
import { HeadingSuperLarge } from './Heading';
import useNavColorFromIntersection from '../hooks/useNavColorFromIntersection';
import { useStaticFontMetricOffsets } from './PageContext';

const TitleWrap = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    align-content: end;
    margin: 0 var(--gridMarginGap);
    padding: var(--navbarHeight) 0 ${MARGIN_BIG}px 0;
    width: calc(100% - (var(--gridMarginGap) * 2));
    height: 100vh;
    border-radius: ${LOZENGE_BORDER_RADIUS}px;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-bottom: 15px;
    }
`;

const Title = styled(HeadingSuperLarge)`
    font-weight: bold;
    grid-column: 3 / span 8;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

const BlurbWrap = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    padding: ${MARGIN_BIG}px var(--gridMarginGap) ${MARGIN_SMALL}px
        var(--gridMarginGap);
    background-color: var(--foregroundColorMix2);
    color: var(--foregroundColorMix7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        padding-top: 15px;
    }
`;

const Blurb = styled.div`
    grid-column: 3 / span 8;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }

    ${signifierStyle};

    font-size: var(--fontSizeFixed5);
    line-height: ${LINE_HEIGHT.BODY_5};

    /* These values are specific to the typeface and line-height. */
    margin-top: -0.1819em;

    /* margin-bottom: -0.1779em; */

    font-feature-settings: 'dlig', 'liga', 'calt', 'onum'; /* overriding signifierStyle with custom settings */

    ${hyphensAuto};

    a {
        ${hyperlinkStyle};
        --underlineColor: var(--foregroundColorMix4);
        --hoverUnderlineColor: var(--foregroundColorMix7);
    }
`;

const Author = styled.div`
    color: var(--foregroundColorMix5);
    grid-column: 3 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span 3;
    }

    height: 25vh;
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        white-space: pre-wrap;
    }
`;

const Date = styled.div`
    color: var(--foregroundColorMix5);
    grid-column: 9 / span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 4 / span 3;
    }

    text-align: right;
    height: 25vh;
    display: flex;
    align-items: flex-end;
    justify-content: right;
`;

export default function BlogPostHeader(): React.ReactElement {
    const config = useConfig();
    const fontMetricOffsets = useStaticFontMetricOffsets();
    const blogPost = usePreviewOrPageQueryBlogPost();
    const [createNavColorRef] = useNavColorFromIntersection();
    const blogWordCount = usePreviewOrPageQueryBlogPostWordCount();
    const processedIntro = blogPost.intro
        ? processWagtailRichText(
              blogPost.intro,
              config.customOpentypeHtmlTagNames,
          )
        : undefined;
    const heroImage = React.useMemo(() => {
        const blogPostHero = blogPost.hero && blogPost.hero[0];
        return blogPostHero &&
            (blogPostHero.__typename === 'ImageWithPortraitBlock' ||
                blogPostHero.__typename === 'CMS_SSR_ImageWithPortraitBlock')
            ? blogPostHero
            : undefined;
    }, [blogPost.hero]);
    return (
        <>
            <div ref={createNavColorRef()}>
                {heroImage ? <BlogPostHero heroImage={heroImage} /> : null}
                <TitleWrap>
                    <Title
                        data-cy={TEST_ID.BLOG_POST_TITLE}
                        $metricOffsets={fontMetricOffsets}
                    >
                        {blogPost.title}
                    </Title>
                </TitleWrap>
            </div>
            <BlurbWrap
                id="intro"
                ref={createNavColorRef({
                    background: 'var(--foregroundColorMix2)',
                    foreground: 'var(--foregroundColorMix7)',
                })}
            >
                <Blurb
                    dangerouslySetInnerHTML={
                        processedIntro
                            ? {
                                  __html: processedIntro,
                              }
                            : undefined
                    }
                />
                <Author>{`${printNumber(blogWordCount)} words\nby ${
                    blogPost.author
                }`}</Author>
                <Date>{formatDjangoDateString(blogPost.postDate)}</Date>
            </BlurbWrap>
        </>
    );
}
