import { graphql, useStaticQuery } from 'gatsby';
import type { InUsePostStubsQuery } from '../gql/api-ssr';
import notNull from '../utils/notNull';
import notNoIndex from '../utils/notNoIndex';
import isWhitelisted from '../utils/isWhitelisted';
import useConfig from './useConfig';
import useIsUserTestingSite from './useIsUserTestingSite';
import { IN_USE_ALL_CATEGORIES_TAG_SLUG } from '../settings/Global';

export type InUsePostStub = NonNullable<
    InUsePostStubsQuery['ssr']['inUsePosts']
>[number];

export default function useInUsePostStubs(
    filter: {
        fontFamilySlugs?: string[];
        excludeInUseSlug?: string;
        categorySlug?: string;
    } = {},
    maxResults?: number,
): InUsePostStub[] {
    const config = useConfig();
    const isUserTestingSite = useIsUserTestingSite();

    const query = useStaticQuery<InUsePostStubsQuery>(graphql`
        query InUsePostStubsQuery {
            ssr {
                inUsePosts(limit: 999999999) {
                    blurbImage {
                        file
                        width
                        height
                        placeholderColor
                        hasAlpha
                    }
                    categories {
                        id
                        name
                        slug
                    }
                    fontFamilies {
                        name
                        slug
                        typographicRanking
                        fontFamilyGroup {
                            id
                        }
                    }
                    id
                    title
                    seoTitle
                    slug
                    postDate
                    noindex
                    mediaCount
                }
            }
        }
    `);

    const inUsePosts = (query.ssr.inUsePosts || [])
        .filter(notNoIndex)
        .filter((inUsePost) => {
            // Are we excluding this particular post?
            return !(
                filter.excludeInUseSlug &&
                inUsePost.slug === filter.excludeInUseSlug
            );
        })
        .filter((inUsePost) => {
            // Limit the "in use" stubs for the user testing site,
            // so that we're not exposing any pre-release fonts.
            if (!isUserTestingSite) {
                return true;
            }
            return !inUsePost.fontFamilies?.some(
                (fontFamily) =>
                    !isWhitelisted(
                        config.userTestingFontFamilyGroupWhitelist,
                        fontFamily.fontFamilyGroup.id,
                    ),
            );
        })
        .filter((inUsePost) => {
            // Are we filtering by category?
            if (
                !filter.categorySlug ||
                filter.categorySlug === IN_USE_ALL_CATEGORIES_TAG_SLUG
            ) {
                return true;
            }
            if (!inUsePost.categories) {
                return false;
            }
            return inUsePost.categories
                .map((postCategory): string | null =>
                    postCategory ? postCategory.slug : null,
                )
                .filter(notNull)
                .includes(filter.categorySlug);
        })
        .filter((inUsePost) => {
            // Filter for particular font families?
            if (!filter.fontFamilySlugs || !filter.fontFamilySlugs.length) {
                return true;
            }
            return inUsePost.fontFamilies
                ? inUsePost.fontFamilies
                      .filter(notNull)
                      .some(
                          (fontFamily): boolean =>
                              filter.fontFamilySlugs?.includes(
                                  fontFamily.slug,
                              ) === true,
                      )
                : false;
        });

    return inUsePosts.slice(0, maxResults);
}
