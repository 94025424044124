import React from 'react';
import styled from 'styled-components';
import { HeadingMedium } from './Heading';
import FontsInUse, { MAX_IN_USE_POSTS_PER_ROW } from './FontsInUse';
import { createFontsInUseUrl } from '../utils/urlHelper';
import type {
    InUsePostStub,
    InUsePostStubFontFamily,
} from '../union-types/inUsePostStub';
import notNull from '../utils/notNull';
import isWhitelisted from '../utils/isWhitelisted';
import useConfig from '../hooks/useConfig';
import useIsUserTestingSite from '../hooks/useIsUserTestingSite';
import usePreviewOrPageQueryHomepage from '../hooks/usePreviewOrPageQueryHomepage';
import { useStaticFontMetricOffsets } from './PageContext';
import { MARGIN_SMALL } from '../settings/Global';
import Link from './Lozenge';
import Icon, { IconType } from './Icon';

const Container = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-row-gap: ${MARGIN_SMALL}px;
`;

const Section = styled.div`
    grid-column: 1 / span var(--gridColumnCount);
`;

const NUM_ROWS = 2;

function HomeFontsInUse(): React.ReactElement | null {
    const homepage = usePreviewOrPageQueryHomepage();
    const config = useConfig();
    const isUserTestingSite = useIsUserTestingSite();
    const fontMetricOffsets = useStaticFontMetricOffsets();

    // Limit the "in use" stubs for the user testing site,
    // so that we're not exposing any pre-release fonts.
    const inUsePostStubs = React.useMemo((): InUsePostStub[] => {
        return homepage.featuredInUsePosts
            ? (homepage.featuredInUsePosts as InUsePostStub[])
                  .filter(notNull)
                  .filter((inUsePost) => {
                      if (!isUserTestingSite) {
                          return true;
                      }
                      return !inUsePost.fontFamilies?.some(
                          (fontFamily: InUsePostStubFontFamily) =>
                              !isWhitelisted(
                                  config.userTestingFontFamilyGroupWhitelist,
                                  fontFamily.fontFamilyGroup.id,
                              ),
                      );
                  })
                  .slice(0, NUM_ROWS * MAX_IN_USE_POSTS_PER_ROW)
            : [];
    }, [
        isUserTestingSite,
        homepage.featuredInUsePosts,
        config.userTestingFontFamilyGroupWhitelist,
    ]);

    if (!inUsePostStubs.length) {
        return null;
    }

    return (
        <Container>
            <Section>
                <HeadingMedium $metricOffsets={fontMetricOffsets}>
                    Fonts in use
                </HeadingMedium>
            </Section>
            <Section>
                <FontsInUse
                    inUsePosts={inUsePostStubs}
                    renderStyle="grayscale"
                    rows={NUM_ROWS}
                    forceShowMoreLink
                    moreLink={
                        <Link to={createFontsInUseUrl()}>
                            See more fonts in use&nbsp;
                            <Icon type={IconType.ARROW_RIGHT} />
                        </Link>
                    }
                />
            </Section>
        </Container>
    );
}

export default React.memo(HomeFontsInUse);
