import usePublicConfigQuery from './usePublicConfigQuery';
import { ErrorOverlayType } from '../components/ErrorOverlay';
import { useErrorOverlayState } from '../components/GlobalRuntimeState';

/**
 * Shows a full page overlay if the site is in maintenance mode.
 */
export default function useMaintenanceModeOverlay(): void {
    const configQuery = usePublicConfigQuery();
    const [showErrorOverlay, setShowErrorOverlay] = useErrorOverlayState();

    if (
        !showErrorOverlay.isShown &&
        !configQuery.loading &&
        !configQuery.data?.config.apiIsUp
    ) {
        setShowErrorOverlay({
            isShown: true,
            type: ErrorOverlayType.MAINTENANCE_MODE,
        });
    }
}
