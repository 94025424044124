import React from 'react';
import styled, { css } from 'styled-components';
import {
    bodyStyles,
    hyperlinkStyle,
    hyperlinkLighterStyle,
    blogAndInUseWrapStyles,
} from './BlogPostStyles';
import { VIEWPORT } from '../settings/Global';
import { hyphensAuto, preventPrintPageBreak } from '../utils/stylesMixins';
import processWagtailRichText from '../utils/processWagtailRichText';
import useConfig from '../hooks/useConfig';
import type { BlogQuoteBlock } from '../union-types/blogPost';

const Container = styled.div`
    ${blogAndInUseWrapStyles};

    grid-template-areas: '. . a a a a a a . . . .';

    @media screen and (min-width: ${VIEWPORT.DESKTOP_LARGE}px) {
        grid-template-areas: '. . a a a a a . . . . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-template-areas: '. a a a a a a a a a . .';
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-areas: 'a a a a a a';
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-areas: 'a a a a a a';
    }
`;

const BlogQuote = styled.div`
    ${bodyStyles};

    grid-area: a;
    padding: 0 calc(1.1 * var(--gridColumnGap));
    margin-left: calc(-1.1 * var(--gridColumnGap));
    border-left: solid 1px var(--foregroundColorMix8);

    div {
        ${preventPrintPageBreak}
    }

    a {
        ${hyperlinkStyle}
    }

    i {
        font-stretch: normal;
        font-style: italic;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        padding: 0 calc(2 * var(--gridColumnGap));
        margin-left: calc(0.05 * var(--gridColumnGap));
    }
`;

const BlogQuoteAuthor = styled.div<{ $isItalic: boolean }>`
    margin-top: var(--lineHeightBlogBodyPx);
    color: var(--foregroundColorMix6);

    ${({ $isItalic }): ReturnType<typeof css> | null =>
        $isItalic
            ? css`
                  font-style: italic;
              `
            : null}

    ${hyphensAuto};

    a {
        ${hyperlinkLighterStyle};
    }
`;

export function BlogBodyQuote({
    block,
}: {
    block: BlogQuoteBlock;
}): React.ReactElement {
    const { quote, caption } = block;
    const config = useConfig();
    const quoteIsItalic = React.useMemo(() => quote.includes('<i>'), [quote]);
    return (
        <Container>
            <BlogQuote>
                <div
                    dangerouslySetInnerHTML={{
                        __html: processWagtailRichText(
                            quote,
                            config.customOpentypeHtmlTagNames,
                        ),
                    }}
                />
                {caption && (
                    <BlogQuoteAuthor
                        $isItalic={quoteIsItalic}
                        dangerouslySetInnerHTML={{
                            __html: caption
                                ? processWagtailRichText(
                                      caption,
                                      config.customOpentypeHtmlTagNames,
                                  )
                                : '',
                        }}
                    />
                )}
            </BlogQuote>
        </Container>
    );
}
