import React from 'react';
import styled from 'styled-components';
import _LazyResponsiveImage from '../components/LazyResponsiveImage';

export { Head } from '../components/Head';

// NOTE: This image manually-uploaded to S3 from the src/assets/images folder
const error = 'manual/404.gif';

const Container = styled.div`
    padding-top: var(--navbarHeight);
    margin: 0 var(--gridMarginGap);
`;

const LazyResponsiveImage = styled(_LazyResponsiveImage)`
    width: 100%;
`;

function NotFoundPage(): React.ReactElement {
    return (
        <Container>
            <LazyResponsiveImage src={error} sources={[{ src: error }]} />
        </Container>
    );
}

export default React.memo(NotFoundPage);
