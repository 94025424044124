import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';

export const Inner = styled.div`
    padding: var(--paddingPageMedium);
    padding-left: var(--gridMarginGap);
    padding-right: var(--gridMarginGap);
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    grid-auto-rows: min-content;
    grid-auto-columns: 1fr;
`;

export const Content = styled.div`
    grid-column: 3 / span 8;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing3);

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-column: 3 / span 10;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / span var(--gridColumnCount);
    }
`;

export const LinkWrapper = styled.div`
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: grid;
        grid-template-columns: 100%;
    }
`;
