import type { StyleDiscountPercentage } from '../hooks/useConfig';

export default function getStyleDiscountPercentageFromFontStyleCount(
    styleCount: number,
    styleDiscountPercentages: StyleDiscountPercentage[],
): number {
    return (
        styleDiscountPercentages
            // Sort from low to high quantity
            .sort((a, b) => a.quantity - b.quantity)
            .reduce((carry, styleDiscount) => {
                if (styleCount < styleDiscount.quantity) {
                    return carry;
                }
                return styleDiscount.discount;
            }, 0)
    );
}
