import React from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import AccountLoginForm from '../components/AccountLoginForm';
import AccountUser from '../components/AccountUser';
import useUserQuery from '../hooks/useUserQuery';
import {
    createAccountUrl,
    createNextUrlFromLocation,
} from '../utils/urlHelper';
import { HeadingExtraLarge } from '../components/Heading';
import { VIEWPORT } from '../settings/Global';
import ProgressBar from '../components/ProgressBar';

export { Head } from '../components/Head';

const Container = styled.div`
    position: relative;
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
`;

const InnerWrapper = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    ${HeadingExtraLarge} {
        margin-top: var(--spacing1);
        grid-column: 3 / span 6;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span 6;
        }
    }
`;

function AccountPage(): React.ReactElement {
    const location = useLocation();
    const userQuery = useUserQuery();
    const user = userQuery?.data?.user;

    return (
        <>
            <ProgressBar loading={userQuery.loading} />
            <Container>
                {userQuery.loading ? (
                    <InnerWrapper>
                        <HeadingExtraLarge>
                            Please wait…
                            <br />
                            Loading account details
                        </HeadingExtraLarge>
                    </InnerWrapper>
                ) : user ? (
                    <AccountUser user={user} />
                ) : (
                    <AccountLoginForm
                        next={
                            createNextUrlFromLocation(location) ||
                            createAccountUrl()
                        }
                    />
                )}
            </Container>
        </>
    );
}

export default React.memo(AccountPage);
