import React from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { useMutation } from '@apollo/client';
import { revertLoginMutation } from '../utils/runtimeQueries';
import type { RevertLoginMutationMutation } from '../gql/api-public';
import { HeadingExtraLarge } from '../components/Heading';
import { VIEWPORT } from '../settings/Global';

export { Head } from '../components/Head';

const Container = styled.div`
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);

    ${HeadingExtraLarge} {
        margin-top: var(--spacing1);
        grid-column: 3 / span 6;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 1 / span 6;
        }
    }
`;

function RevertLoginPage(): React.ReactElement {
    const [doRevertLogin] =
        useMutation<RevertLoginMutationMutation>(revertLoginMutation);
    const location = useLocation();

    React.useEffect((): void => {
        // Otherwise looks like everything is OK and we can proceed with logout.
        doRevertLogin().then((response): void => {
            const next = response?.data?.revertLogin?.next;

            if (next) {
                window.location.replace(next);
            }
        });
    }, [location, doRevertLogin]);

    return (
        <Container>
            <HeadingExtraLarge>Reverting login…</HeadingExtraLarge>
        </Container>
    );
}

export default React.memo(RevertLoginPage);
