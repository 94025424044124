import React from 'react';
import styled from 'styled-components';
import InlineUnderlinedLink, { A } from '../components/InlineUnderlinedLink';
import printAmpersandSeries from '../utils/printAmpersandSeries';
import notNull from '../utils/notNull';
import type { InUsePostDesignerLink } from '../union-types/inUsePost';
import { VIEWPORT } from '../settings/Global';
import usePreviewOrPageQueryInUsePost from '../hooks/usePreviewOrPageQueryInUsePost';
import type {
    InUsePostCategory,
    InUsePostFontFamily,
} from '../union-types/inUsePost';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import { createFontFamilyUrl } from '../utils/urlHelper';
import sortByName from '../utils/sortByName';
import { HeadingSuperLarge } from './Heading';
import { useStaticFontMetricOffsets } from './PageContext';

const Container = styled(HeadingSuperLarge)`
    margin-left: var(--gridMarginGap);
    margin-right: var(--gridMarginGap);
    padding-bottom: 5px;
`;

const Title = styled.span`
    /* Allow soft hyphens to be added via CMS to control hyphenation */
    hyphens: manual;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        font-size: var(--fontSizeFixed7);
    }
`;

const Details = styled.span`
    font-size: var(--fontSizeFixed2);
    line-height: var(--lineHeightBody1);
    letter-spacing: normal;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
    display: inline-block;
    white-space: nowrap;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        position: relative;
        display: block;
        margin-bottom: 0;
        margin-top: 5px;
        white-space: normal;
    }
`;

function InUsePostHeader(): React.ReactElement {
    const inUsePost = usePreviewOrPageQueryInUsePost();
    const staticFontMetrics = useStaticFontMetricOffsets();

    const categoryLinks = printAmpersandSeries(
        ((inUsePost.categories || []) as InUsePostCategory[])
            .filter(notNull)
            .sort(sortByName)
            .map((cat): string | null => cat.name)
            .filter(notNull),
    );

    const designerLinks = printAmpersandSeries(
        ((inUsePost.designerLinks as InUsePostDesignerLink[]) || [])
            .map((designerLink): React.ReactNode | null => {
                if (
                    !designerLink ||
                    (designerLink.__typename !== 'DesignerLink' &&
                        designerLink.__typename !== 'CMS_SSR_DesignerLink')
                ) {
                    return null;
                }
                const { url, name } = designerLink;
                return (
                    <React.Fragment key={String(name)}>
                        {url ? <A href={url}>{name}</A> : name}
                    </React.Fragment>
                );
            })
            .filter(notNull),
    );

    const fontFamilyLinks = printAmpersandSeries(
        ((inUsePost.fontFamilies as InUsePostFontFamily[]) || [])
            .filter(notNull)
            .sort(sortFontsByTypographicRanking)
            .map(
                (fontFamily): React.ReactNode => (
                    <InlineUnderlinedLink
                        key={fontFamily.slug}
                        to={createFontFamilyUrl(fontFamily.slug)}
                    >
                        {fontFamily.name}
                    </InlineUnderlinedLink>
                ),
            ),
    );

    return (
        <Container $metricOffsets={staticFontMetrics}>
            <Title>{inUsePost.title}&nbsp;</Title>
            <Details>
                By {designerLinks}
                <br />
                Using {fontFamilyLinks}
                <br />
                {categoryLinks}
            </Details>
        </Container>
    );
}

export default React.memo(InUsePostHeader);
