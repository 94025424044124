import React from 'react';
import styled, { css } from 'styled-components';
import Icon, { IconType } from './Icon';
import { TEST_ID } from '../settings/E2e';
import {
    LOZENGE_SPACING,
    MARGIN_SMALL,
    CART_IN_PAGE_BREAKPOINT,
} from '../settings/Global';

const Container = styled.div`
    border-bottom: var(--border);
    line-height: var(--lineHeightBody1);

    /*
    Padding in absolute pixels to prevent uneven padding due to fractional numbers,
    e.g. when using var(--spacing0), resulting in ~3.568px padding things weren't looking even!
     */
    padding: 4px 0;

    --textColor: var(--foregroundColor);
`;

const ColumnWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
`;

const LeftColumn = styled.div`
    color: var(--textColor);
    white-space: pre-line;
`;

const RightColumn = styled.div<{ $hasRemoveButton: boolean }>`
    text-align: right;
    margin-left: ${MARGIN_SMALL}px;
    color: var(--textColor);
    ${({ $hasRemoveButton }): ReturnType<typeof css> | null =>
        $hasRemoveButton
            ? css`
                  display: flex;
                  align-items: baseline;
              `
            : null};
`;

const RemoveButton = styled.button`
    color: var(--foregroundColorMix5);
    width: var(--cartPaddingRight);
    margin-right: calc(var(--cartPaddingRight) * -1);
    text-align: center;

    &:hover {
        color: var(--foregroundColor);
    }

    @media screen and (max-width: ${CART_IN_PAGE_BREAKPOINT}px) {
        margin-right: 0;
        text-align: right;
        margin-left: ${LOZENGE_SPACING}px;
        width: 1em;
    }
`;

export const CartSummaryRowsWrapper = styled.div`
    --border: 1px solid var(--borderColor);

    display: grid;
    grid-template-columns: 100%;
`;

export const Amount = styled.div`
    display: inline-block;
    white-space: nowrap;
    font-family: var(--fontFamilySoehneMono);
`;

function CartSummaryRow({
    leftContent,
    rightContent,
    belowContent,
    onRemove,
    removeLabel,
    styleOverride,
    testId,
}: {
    leftContent: React.ReactNode;
    rightContent?: React.ReactNode;
    belowContent?: React.ReactNode;
    onRemove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    removeLabel?: string;
    styleOverride?: ReturnType<typeof css>;
    testId?: TEST_ID;
}): React.ReactElement {
    return (
        <Container
            css={styleOverride}
            data-cy={testId ? testId : TEST_ID.CART_ROW}
        >
            <ColumnWrapper>
                <LeftColumn>{leftContent}</LeftColumn>
                {rightContent || onRemove ? (
                    <RightColumn $hasRemoveButton={Boolean(onRemove)}>
                        {rightContent}
                        {onRemove && (
                            <RemoveButton
                                data-cy={TEST_ID.CART_ITEM_REMOVE_BUTTON}
                                onClick={onRemove}
                                aria-label={removeLabel}
                                title={removeLabel}
                            >
                                <Icon type={IconType.CROSS} />
                            </RemoveButton>
                        )}
                    </RightColumn>
                ) : null}
            </ColumnWrapper>
            {belowContent}
        </Container>
    );
}

export default React.memo(CartSummaryRow);
