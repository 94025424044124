import React from 'react';
import styled, { css } from 'styled-components';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
} from '@stripe/react-stripe-js';
import stripeFieldStyle from '../utils/stripeFieldStyle';
import stripeJs, { StripeElementStyle } from '@stripe/stripe-js';
import {
    LOZENGE_BORDER_RADIUS,
    lozengeHorizontalPadding,
    lozengeVerticalPadding,
} from './Lozenge';
import { BUTTON_AND_FIELD_HEIGHT } from '../settings/Global';

const Container = styled.div<{ $hasError?: boolean; $isDisabled?: boolean }>`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing1);

    /*
     * Intended to match behaviour specified in 'formStyles.tsx'
     */
    border-radius: ${LOZENGE_BORDER_RADIUS}px;
    height: ${BUTTON_AND_FIELD_HEIGHT}px;

    --stripeFieldBackgroundColor: var(--foregroundColorMix8);

    border: 1px solid var(--foregroundColorMix8);
    ${({ $hasError }): ReturnType<typeof css> | null =>
        $hasError
            ? css`
                  border-color: var(--errorColor);
              `
            : null};
    ${({ $isDisabled }): ReturnType<typeof css> =>
        $isDisabled
            ? css`
                  border-color: var(--foregroundColorMix7);
                  cursor: not-allowed;

                  --stripeFieldBackgroundColor: var(--backgroundColor);
              `
            : css`
                  &:hover,
                  &:focus {
                      border-color: var(--foregroundColorMix5);
                  }
              `};

    /* stylelint-disable-next-line declaration-empty-line-before */
    background-color: var(--stripeFieldBackgroundColor);
`;

// We can't fully style Stripe's inputs, so we use a container that can provide padding, background-color, etc.
const FieldContainer = styled.div`
    background-color: var(--stripeFieldBackgroundColor);
    height: ${BUTTON_AND_FIELD_HEIGHT - 2}px;
    padding: ${lozengeVerticalPadding} ${lozengeHorizontalPadding};
`;

function useComputedStyle(): [
    StripeElementStyle | undefined,
    (ref: HTMLElement | null) => void,
] {
    const [element, setElement] = React.useState<HTMLElement | null>(null);

    const style = React.useMemo((): StripeElementStyle | undefined => {
        return element ? stripeFieldStyle(element) : undefined;
    }, [element]);

    return [style, setElement];
}

export function FieldStripeCardNumber({
    hasError,
    onChange,
    disabled,
}: {
    hasError?: boolean;
    onChange?: (event: stripeJs.StripeCardNumberElementChangeEvent) => void;
    disabled?: boolean;
}): React.ReactElement {
    const [style, onRef] = useComputedStyle();
    return (
        <Container $hasError={hasError} $isDisabled={disabled}>
            <FieldContainer ref={onRef}>
                <CardNumberElement
                    options={{
                        disabled,
                        style,
                    }}
                    onChange={onChange}
                />
            </FieldContainer>
        </Container>
    );
}

export function FieldStripeCardExpiry({
    hasError,
    onChange,
    disabled,
}: {
    hasError?: boolean;
    onChange?: (event: stripeJs.StripeCardExpiryElementChangeEvent) => void;
    disabled?: boolean;
}): React.ReactElement {
    const [style, onRef] = useComputedStyle();
    return (
        <Container $hasError={hasError} $isDisabled={disabled}>
            <FieldContainer ref={onRef}>
                <CardExpiryElement
                    options={{
                        disabled,
                        style,
                    }}
                    onChange={onChange}
                />
            </FieldContainer>
        </Container>
    );
}

export function FieldStripeCardCvc({
    hasError,
    onChange,
    disabled,
}: {
    hasError?: boolean;
    onChange?: (event: stripeJs.StripeCardCvcElementChangeEvent) => void;
    disabled?: boolean;
}): React.ReactElement {
    const [style, onRef] = useComputedStyle();
    return (
        <Container $hasError={hasError} $isDisabled={disabled}>
            <FieldContainer ref={onRef}>
                <CardCvcElement
                    options={{
                        disabled,
                        style,
                    }}
                    onChange={onChange}
                />
            </FieldContainer>
        </Container>
    );
}
