import isBrowser from './isBrowser';

import { MARGIN_BIG, MARGIN_SMALL, VIEWPORT } from '../settings/Global';

export default function getWindowSize(): {
    width?: number;
    height?: number;
    widthWithoutMargins?: number;
} {
    if (!isBrowser()) {
        return {};
    }

    const width = window.innerWidth;
    const height = window.innerHeight;

    // Viewport without page margins
    const margin =
        window.innerWidth <= VIEWPORT.MOBILE ? MARGIN_SMALL : MARGIN_BIG;
    const widthWithoutMargins = width - margin * 2;

    return {
        width,
        height,
        widthWithoutMargins,
    };
}
