import React from 'react';
import styled from 'styled-components';
import { ZINDEX } from '../settings/Global';
import useKeyPress from '../hooks/useKeyPress';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    --border: 1px solid var(--highlightColor);

    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    padding: 0 var(--gridMarginGap);
    z-index: ${ZINDEX.GRID_OVERLAY};
`;

const Hr = styled.hr`
    position: absolute;
    left: 0;
    width: 100%;
    border: 0;
    outline: 0;
    height: 0;
    margin: 0;
    border-top: var(--border);
`;

const Inner = styled.div`
    border-left: var(--border);
    border-right: var(--border);
`;

function GridOverlay(): React.ReactElement | null {
    const [isGridShown, setIsGridShown] = React.useState(false);
    const [mouseY, setMouseY] = React.useState<number>(0);

    const onMousemove = React.useCallback((e: MouseEvent): void => {
        setMouseY(e.clientY);
    }, []);

    useKeyPress({
        key: {
            keyCode: 71,
            key: 'g',
            code: 'KeyG',
        },
        shiftKey: true,
        ctrlKey: true,
        altKey: true,
        preventDefault: true,
        callback: () => setIsGridShown((state): boolean => !state),
        postInitCallback: () =>
            // eslint-disable-next-line no-console
            console.log('Press Ctrl+Alt+Shift+g to toggle the Grid Overlay'),
    });

    React.useEffect(() => {
        if (!isGridShown) {
            window.removeEventListener('mousemove', onMousemove);
        } else {
            window.addEventListener('mousemove', onMousemove);
        }
        return (): void => window.removeEventListener('mousemove', onMousemove);
    }, [isGridShown]);

    if (!isGridShown) {
        return null;
    }

    return (
        <Container>
            <Hr style={{ top: `${mouseY.toFixed(0)}px` }} />
            {Array.from(Array(12)).map(
                (column, columnIndex): React.ReactElement => (
                    <Inner key={columnIndex}>
                        <Inner />
                    </Inner>
                ),
            )}
        </Container>
    );
}

export default React.memo(GridOverlay);
