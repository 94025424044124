import React from 'react';

interface ScrollHelperParams {
    // Param for testing purposes only.
    __window?: { scrollY: number };
    scrollDownThreshold?: number;
    scrollUpThreshold?: number;
    scrollTopThreshold?: number;
    onScrollDown?: () => void;
    onScrollUp?: () => void;
    onScrollTop?: () => void;
}

export default class ScrollHelper {
    private readonly window: {
        scrollY: number;
    };
    private readonly scrollDownThreshold: number;
    private readonly scrollUpThreshold: number;
    private readonly scrollTopThreshold: number;
    private readonly onScrollUp?: () => void;
    private readonly onScrollDown?: () => void;
    private readonly onScrollTop?: () => void;

    private lastScrollY: number;
    private lastDownScrollY: number;
    private lastUpScrollY: number;
    private raf: number | null = null;

    public constructor({
        __window = window,
        scrollDownThreshold = 0,
        scrollUpThreshold = 0,
        scrollTopThreshold = 0,
        onScrollDown,
        onScrollUp,
        onScrollTop,
    }: ScrollHelperParams) {
        this.window = __window;
        this.scrollDownThreshold = scrollDownThreshold;
        this.scrollUpThreshold = scrollUpThreshold;
        this.scrollTopThreshold = scrollTopThreshold;
        this.onScrollDown = onScrollDown;
        this.onScrollUp = onScrollUp;
        this.onScrollTop = onScrollTop;
        this.lastScrollY = this.window.scrollY;
        this.lastDownScrollY = this.window.scrollY;
        this.lastUpScrollY = this.window.scrollY;
    }

    public onScroll = (): void => {
        /* requestAnimationFrame throttling mechanism */
        this.raf =
            this.raf ||
            requestAnimationFrame((): void => {
                this._onScroll();
                this.raf = null;
            });
    };

    private _onScroll = (): void => {
        const { scrollY } = this.window;

        const deltaY = this.lastScrollY - scrollY;
        // Ternary is to catch some odd behaviour in Safari where 'elastic'
        // scroll triggers scroll events. In Chrome it does not.
        // See: https://springload-nz.atlassian.net/browse/KLIM-470
        this.lastScrollY = scrollY < 0 ? 0 : scrollY;

        if (deltaY < 0) {
            this.lastDownScrollY = scrollY;
            const deltaYSinceLastUpScroll = scrollY - this.lastUpScrollY;

            if (
                deltaYSinceLastUpScroll > this.scrollDownThreshold &&
                this.onScrollDown
            ) {
                this.onScrollDown();
            }
        } else if (deltaY > 0) {
            this.lastUpScrollY = scrollY;

            const deltaYSinceLastDownScroll = this.lastDownScrollY - scrollY;

            if (
                deltaYSinceLastDownScroll > this.scrollUpThreshold &&
                this.onScrollUp
            ) {
                this.onScrollUp();
            }
            if (scrollY < this.scrollTopThreshold && this.onScrollTop) {
                this.onScrollTop();
            }
        }
    };
}

export const useOnScroll = ({
    onScrollDown,
    scrollDownThreshold,
    onScrollUp,
    scrollUpThreshold,
    onScrollTop,
    scrollTopThreshold,
}: {
    onScrollDown?: () => void;
    scrollDownThreshold?: number;
    onScrollUp?: () => void;
    scrollUpThreshold?: number;
    onScrollTop?: () => void;
    scrollTopThreshold?: number;
}): void =>
    React.useEffect(() => {
        const scrollHelper = new ScrollHelper({
            onScrollDown,
            scrollDownThreshold,
            onScrollUp,
            scrollUpThreshold,
            onScrollTop,
            scrollTopThreshold,
        });

        window.addEventListener('scroll', scrollHelper.onScroll, false);

        return (): void =>
            window.removeEventListener('scroll', scrollHelper.onScroll);
    }, [
        onScrollDown,
        scrollDownThreshold,
        onScrollUp,
        scrollUpThreshold,
        onScrollTop,
        scrollTopThreshold,
    ]);
