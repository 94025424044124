import React from 'react';
import Link from './Lozenge';
import FontsInUse from './FontsInUse';
import { createFontsInUseUrl } from '../utils/urlHelper';
import { useFontFamily } from './PageContext';
import type { FeaturedInUsePostFragment } from '../gql/api-ssr';
import Icon, { IconType } from './Icon';

function FontFamilyFontsInUse(): React.ReactElement | null {
    const fontFamily = useFontFamily();
    const { featuredInUsePosts } = fontFamily;
    if (!featuredInUsePosts) {
        return null;
    }

    return (
        <FontsInUse
            // A bit of a hack to circumvent annoying wagtail-grapple non-specific typing
            inUsePosts={featuredInUsePosts as FeaturedInUsePostFragment[]}
            renderStyle="grayscale"
            rows={2}
            moreLink={
                <Link
                    to={createFontsInUseUrl({
                        fontFamilyGroup: fontFamily.fontFamilyGroup,
                    })}
                >
                    {`See more ${fontFamily.name} in use`}&nbsp;
                    <Icon type={IconType.ARROW_RIGHT} />
                </Link>
            }
        />
    );
}

export default React.memo(FontFamilyFontsInUse);
