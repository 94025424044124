import { Cart } from '../hooks/useCartQuery';

export default function getLicenceQuantity(
    licenceTypeId: string,
    cart: Cart,
): number | undefined {
    const cartTier = cart.licenceTiers.find(
        (cartTier) => cartTier.tier.licenceType.id === licenceTypeId,
    );
    if (cartTier) {
        return cartTier.tier.multiplier;
    }
}
