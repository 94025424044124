import React from 'react';
import styled from 'styled-components';
import {
    GLYPH_INSPECTOR_BREAKPOINT,
    LOZENGE_SPACING,
} from '../settings/Global';
import { useGlyphInspectorState } from './GlyphInspectorContext';
import GlyphInspectorStylesInput from './GlyphInspectorStylesInput';
import GlyphInspectorRenderModeInput from './GlyphInspectorRenderModeInput';
import { Div as _Lozenge } from './Lozenge';

const Lozenge = styled(_Lozenge)`
    font-feature-settings: 'tnum';

    --lozengeColor: var(--foregroundColorMix7);

    border: 1px solid var(--lozengeColor);
`;

const Wrap = styled.div`
    display: flex;
    flex-basis: content;
    flex-wrap: wrap;
    gap: ${LOZENGE_SPACING}px;

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        margin-top: var(--spacing4);
    }
`;

const GlyphCodePoint = styled.div`
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: ${LOZENGE_SPACING}px;
    flex-grow: 2;

    @media screen and (max-width: ${GLYPH_INSPECTOR_BREAKPOINT}px) {
        justify-content: initial;
        flex-basis: 100%;
    }
`;

export default function GlyphInspectorToolbar(): React.ReactElement {
    const { glyph } = useGlyphInspectorState();

    const unicodeLabels = {
        name: glyph && glyph.name,
        decimal: glyph && glyph.unicode && `#${glyph.unicode}`,
        hexadecimal:
            glyph &&
            glyph.unicode &&
            `U+${glyph.unicode.toString(16).padStart(4, '0').toUpperCase()}`,
    };

    return (
        <Wrap>
            <GlyphInspectorStylesInput />
            <GlyphInspectorRenderModeInput />
            <GlyphCodePoint
                title={
                    unicodeLabels.hexadecimal ||
                    unicodeLabels.name ||
                    unicodeLabels.decimal
                        ? `${
                              unicodeLabels.name
                                  ? `‘${unicodeLabels.name}’ `
                                  : ''
                          }${
                              unicodeLabels.hexadecimal
                                  ? `unicode code point ${unicodeLabels.hexadecimal} `
                                  : ''
                          }${
                              unicodeLabels.decimal
                                  ? `(decimal ${unicodeLabels.decimal})`
                                  : ''
                          }`
                        : undefined
                }
            >
                {unicodeLabels.hexadecimal ? (
                    <Lozenge>{unicodeLabels.hexadecimal}</Lozenge>
                ) : null}
                <Lozenge>{unicodeLabels.name}</Lozenge>
            </GlyphCodePoint>
        </Wrap>
    );
}
