import { css } from 'styled-components';
import { COLOR } from '../settings/Global';

export const hiddenButFocusable = css`
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
`;

export const resetColorVariables = css`
    --foregroundColor: var(--primaryColorRef);
    --foregroundColorMix1: var(--mixedColor1Ref);
    --foregroundColorMix2: var(--mixedColor2Ref);
    --foregroundColorMix3: var(--mixedColor3Ref);
    --foregroundColorMix4: var(--mixedColor4Ref);
    --foregroundColorMix5: var(--mixedColor5Ref);
    --foregroundColorMix6: var(--mixedColor6Ref);
    --foregroundColorMix7: var(--mixedColor7Ref);
    --foregroundColorMix8: var(--mixedColor8Ref);
    --backgroundColor: var(--secondaryColorRef);
    --backgroundGradientColorMin: var(--secondaryColorGradientMin);
    --backgroundGradientColorMax: var(--secondaryColorGradientMax);
    --borderColor: var(--mixedColor7Ref);
    --highlightColor: var(--tertiaryColorRef);
    --errorColor: ${COLOR.RED};
`;

export const invertColorVariables = css`
    --foregroundColor: var(--secondaryColorRef);
    --foregroundColorMix1: var(--mixedColor8Ref);
    --foregroundColorMix2: var(--mixedColor7Ref);
    --foregroundColorMix3: var(--mixedColor6Ref);
    --foregroundColorMix4: var(--mixedColor5Ref);
    --foregroundColorMix5: var(--mixedColor4Ref);
    --foregroundColorMix6: var(--mixedColor3Ref);
    --foregroundColorMix7: var(--mixedColor2Ref);
    --foregroundColorMix8: var(--mixedColor1Ref);
    --backgroundColor: var(--primaryColorRef);
    --backgroundGradientColorMin: var(--primaryColorGradientMin);
    --backgroundGradientColorMax: var(--primaryColorGradientMax);
    --borderColor: var(--mixedColor2Ref);
    --highlightColor: var(--tertiaryColorRef);
    --errorColor: ${COLOR.RED};
`;

export const hideScrollbars = css`
    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;

export const fakeNativeFocusOutlines = css`
    outline: auto 5px Highlight;
    outline: auto 5px -webkit-focus-ring-color;
`;

export const hideFromPrint = css`
    @media print {
        display: none;
    }
`;

export const preventPrintPageBreak = css`
    @media print {
        display: inline-block;
        page-break-inside: avoid;
    }
`;

export const hyphensAuto = css`
    hyphens: auto;
`;
