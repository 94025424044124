import sortByName from './sortByName';

interface FontPlaceholder {
    name: string;
    typographicRanking: number | null | undefined;
    weight?: number;
}

export default function sortFontsByTypographicRanking(
    a: FontPlaceholder,
    b: FontPlaceholder,
): number {
    // Fallback for items without typographicRanking
    if (!a.typographicRanking && !b.typographicRanking) {
        // Sort by weight first (if applicable)
        if (a.weight && b.weight) {
            return a.weight - b.weight;
        }
        // ...then by name
        return sortByName(a, b);
    }
    if (!a.typographicRanking) {
        return -1;
    }
    if (!b.typographicRanking) {
        return 1;
    }
    // This is the ideal sorting, by typographicRanking:
    return a.typographicRanking - b.typographicRanking;
}
