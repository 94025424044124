import React from 'react';
import debounce from 'lodash/debounce';
import { useGlobalState } from './GlobalRuntimeState';
import getWindowSize from '../utils/getWindowSize';

interface Size {
    width?: number;
    height?: number;
    widthWithoutMargins?: number;
}

function subscribeResizeRequestAnimationFrame(
    cb: () => void,
    debounceWait = 250,
): () => void {
    let raf: number | null = null;

    const onResize = debounce((): void => {
        raf =
            raf ||
            window.requestAnimationFrame((): void => {
                cb();
                raf = null;
            });
    }, debounceWait);

    window.addEventListener('resize', onResize, false);

    const unsubscribe = (): void => {
        if (raf) cancelAnimationFrame(raf);
        window.removeEventListener('resize', onResize);
    };

    return unsubscribe;
}

function useWindowSize(): Size {
    const [windowSize, setWindowSize] = React.useState<Size>(getWindowSize());
    const handleResize = React.useCallback(
        () => setWindowSize(getWindowSize()),
        [],
    );

    React.useEffect((): void | (() => void) => {
        const unsubscribe = subscribeResizeRequestAnimationFrame(handleResize);

        return unsubscribe;
    }, []);

    return windowSize;
}

function WindowSizeCalc(): null {
    const [, setViewportWidth] = useGlobalState('viewportWidth');
    const [, setViewportHeight] = useGlobalState('viewportHeight');
    const [, setViewportWidthWithoutMargins] = useGlobalState(
        'viewportWidthWithoutMargins',
    );
    const ref = React.useRef<HTMLDivElement | null>(null);
    const measuredWindowSize = useWindowSize();
    const updateWindowSize = React.useCallback((): void => {
        // Set width
        setViewportWidth((state) =>
            measuredWindowSize.width && measuredWindowSize.width !== state
                ? measuredWindowSize.width
                : state,
        );

        // Set height
        setViewportHeight((state) =>
            measuredWindowSize.height && measuredWindowSize.height !== state
                ? measuredWindowSize.height
                : state,
        );

        // Set width without margins
        setViewportWidthWithoutMargins((state) =>
            measuredWindowSize.widthWithoutMargins &&
            measuredWindowSize.widthWithoutMargins !== state
                ? measuredWindowSize.widthWithoutMargins
                : state,
        );
    }, [measuredWindowSize, ref]);

    React.useEffect((): void => {
        updateWindowSize();
    }, [updateWindowSize]);

    return null;
}

export default React.memo(WindowSizeCalc);
