import React from 'react';
import styled from 'styled-components';
import LicenceSelectorOption from './LicenceSelectorOption';
import { MARGIN_BIG, VIEWPORT } from '../settings/Global';
import { useActiveLicenceTypesAvailableViaCart } from '../hooks/useActiveLicenceTypes';
import LicenceSelectorAdvertisingModal from './LicenceSelectorAdvertisingModal';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: ${MARGIN_BIG}px;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--gridMarginGap);
    }

    margin-bottom: var(--spacing7);
`;

export default function LicenceSelector(): React.ReactElement {
    const activeLicenceTypes = useActiveLicenceTypesAvailableViaCart();

    return (
        <>
            <LicenceSelectorAdvertisingModal />
            <Container>
                {activeLicenceTypes.map((activeLicenceType) => {
                    return (
                        <LicenceSelectorOption
                            key={`lic-select-${activeLicenceType.id}`}
                            licenceType={activeLicenceType}
                        />
                    );
                })}
            </Container>
        </>
    );
}
