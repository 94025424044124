/*
 * Good reference material on `font-feature-settings`:
 *
 * https://developer.mozilla.org/en-US/docs/Web/CSS/font-feature-settings
 * https://docs.microsoft.com/en-us/typography/opentype/spec/featurelist
 */

import React from 'react';
import type { DraftInlineStyle } from 'draft-js';
import tokensObject, { Token } from './tokens';

const tokens = Object.values(tokensObject);

/*
 * Loops through all the tokens that are *available*, and returns an array
 * of all the matching tokens’ `font-feature-settings` features.
 */
export function getFeaturesFromInlineStyles(
    inlineStyles: DraftInlineStyle,
): string[] {
    return tokens.reduce((carry: string[], token: Token): string[] => {
        if (inlineStyles.has(token.name)) {
            return [...carry, ...token.features];
        }
        return carry;
    }, []);
}

/*
 * Given an array like ['smcp', 'swsh'], returns a valid comma-delimited
 * `font-feature-settings` CSS value, in the format:
 *
 * "smcp", "swsh"
 */
export function getStylesFromFeatures(features: string[]): string {
    return features.map((feature: string): string => `"${feature}"`).join(', ');
}

/*
 * Returns a CSSStyleDeclaration with `font-feature-settings` property. Tells
 * DraftJS which inline styles to actually apply to any given piece of content.
 */

export default function customStyleFn(
    inlineStyles: DraftInlineStyle,
): React.CSSProperties {
    const features: string[] = getFeaturesFromInlineStyles(inlineStyles);

    return features.length > 0
        ? {
              fontFeatureSettings: getStylesFromFeatures(features),
          }
        : {};
}
