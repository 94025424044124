import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { offsetLimitPagination } from '@apollo/client/utilities';
import pRetry from 'p-retry';

import sessionStorage from '../utils/sessionStorage';

import promiseToObservable from './promiseToObservable';

const CSRF_CACHE_NAME = 'csrfCache';
const CSRF_IS_REFRESHING_NAME = 'csrfIsRefreshing';
const CSRF_REFRESHING_TIMEOUT_MS = 10 * 1000;

/**
 * Retrieves a new CSRF token from a simple endpoint and caches the response.
 */
const getCsrfToken = async (): Promise<string> => {
    const cachedToken = sessionStorage.getItem(CSRF_CACHE_NAME);
    if (cachedToken) {
        return cachedToken;
    }
    // Check whether another concurrent request with its own ApolloLink is already
    // fetching a CSRF token. We don't want multiple concurrent token refresh requests
    // as each subsequent request invalidates the previous CSRF token. So this could
    // cause a flurry of repeated token requests. In practice, it seems to result in two
    // CSRF token requests on average, whereas only one is really required/desired.
    const tokenIsRefreshingSince = sessionStorage.getItem(
        CSRF_IS_REFRESHING_NAME,
    );
    const tokenIsRefreshing = Boolean(
        tokenIsRefreshingSince &&
            Date.now() - parseInt(tokenIsRefreshingSince, 10) <
                CSRF_REFRESHING_TIMEOUT_MS,
    );

    if (!tokenIsRefreshing) {
        // No CSRF is being fetched yet, or the previous request has timed out, so fetch one.

        sessionStorage.setItem(
            CSRF_IS_REFRESHING_NAME,
            Date.now().toString(10),
        );
        const freshToken = await fetch(
            `${process.env.GATSBY_API_PUBLIC_URL}csrf/`,
            {
                method: 'GET',
                credentials: 'include',
            },
        )
            .then((response) => response.json())
            .then((data) => data.token);

        sessionStorage.setItem(CSRF_CACHE_NAME, freshToken);
        sessionStorage.removeItem(CSRF_IS_REFRESHING_NAME);

        return freshToken;
    } else {
        // We're already fetching a CSRF in a concurrent request.
        // Poll the session data until it contains a new token.

        return await pRetry(
            async () => {
                const cachedToken = sessionStorage.getItem(CSRF_CACHE_NAME);
                if (cachedToken) {
                    return cachedToken;
                }
                throw new Error('Retry...');
            },
            { retries: 10, minTimeout: 300 },
        );
    }
};

/**
 * This function fetches a token at the start when the link is initialised.
 */
const waitForCsrfToken = setContext(
    async (_, { headers }): Promise<unknown> => {
        return {
            headers: {
                ...headers,
                'X-CSRFToken': await getCsrfToken(),
            },
        };
    },
);

/**
 * Generally we want to retry requests whenever there are network issues.
 */
const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 5,
        retryIf: (error): boolean => !!error,
    },
});

/**
 * The error handler will re-fetch the CSRF token upon `403 - Unauthorized`.
 */
const handleLinkError = onError(({ networkError, operation, forward }) => {
    if (
        networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === 403
    ) {
        // Try refreshing the CSRF token
        sessionStorage.removeItem(CSRF_CACHE_NAME);
        const oldHeaders = operation.getContext().headers;
        return promiseToObservable(getCsrfToken()).flatMap((newToken) => {
            operation.setContext({
                headers: {
                    ...oldHeaders,
                    'X-CSRFToken': newToken,
                },
            });
            return forward(operation);
        });
    }
});

const runtimeApolloClient = new ApolloClient({
    link: ApolloLink.from([
        waitForCsrfToken,
        retryLink,
        handleLinkError,
        createHttpLink({
            uri: process.env.GATSBY_API_PUBLIC_URL,
            // So that queries are authenticated against the correct session:
            credentials: 'include',
        }),
    ]),
    cache: new InMemoryCache({
        typePolicies: {
            UserInterface: {
                fields: {
                    orders: {
                        ...offsetLimitPagination(),
                        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                        read(existing, { args }) {
                            if (!existing || !args) {
                                return;
                            }
                            const offset = args.offset;
                            const limit = args.limit;
                            return existing.slice(offset, offset + limit);
                        },
                    },
                },
            },
        },
    }),
});
export default runtimeApolloClient;
