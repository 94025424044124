import { css } from 'styled-components';
import { BUTTON_AND_FIELD_HEIGHT } from '../settings/Global';
import { lozengeStyles } from '../components/Lozenge';

export const labelStyles = css`
    font-weight: bold;
    font-size: var(--fontSizeFixed2);
    line-height: var(--lineHeightBody2);
    color: var(--foregroundColor);
`;

export const inputStyles = {
    base: css`
        ${lozengeStyles};
        --lozengeBackgroundColor: var(--foregroundColorMix8);
        --lozengeBorderStyle: solid;
        --lozengeBorderColor: var(--foregroundColorMix8);

        &:hover,
        &:focus {
            --lozengeBorderColor: var(--foregroundColorMix5);
        }

        &[disabled] {
            --lozengeBorderColor: var(--foregroundColorMix7);
            --lozengeBackgroundColor: var(--backgroundColor);
            --lozengeColor: var(--foregroundColor);

            cursor: not-allowed;
        }

        font-family: var(--fontFamilySoehne);
        appearance: none;
        width: 100%;
        height: ${BUTTON_AND_FIELD_HEIGHT}px;
        margin: 0;

        /* Overriding the user-agent autofill styles */
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: var(--foregroundColor);
            /* stylelint-disable-next-line property-no-vendor-prefix */
            -webkit-box-shadow: 0 0 0 1000px var(--foregroundColorMix8) inset;
        }
    `,
    errorMod: css`
        &,
        &:hover,
        &:focus {
            --lozengeBorderColor: var(--errorColor);
        }
    `,
};

export const placeholderStyles = {
    base: css`
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: var(--foregroundColorMix6);

        /* For Firefox, to ensure full opacity placeholder */
        opacity: 1;
    `,
    focusMod: css`
        color: var(--foregroundColorMix5);
    `,
};

export const errorStyles = css`
    font-size: var(--fontSizeFixed2);
    line-height: var(--lineHeightBody2);
    color: var(--errorColor);
`;
