import React from 'react';
import styled from 'styled-components';
import HomepageCampaigns from '../components/HomepageCampaigns';
import HomeFontGroups from '../components/HomeFontGroups';
import HomeFontsInUse from '../components/HomeFontsInUse';
import HomeBlogPosts from '../components/HomeBlogPosts';
import useNavColorFromIntersection from '../hooks/useNavColorFromIntersection';

export { Head } from '../components/Head';

const Container = styled.div`
    margin: 0 var(--gridMarginGap) 50vh var(--gridMarginGap);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 50vh;
    position: relative;
`;

const NavColorResetTop = styled.div`
    position: absolute;
    top: 0;
    height: var(--navbarHeight);
`;

const NavColorResetBottom = styled.div`
    height: 50vh;
`;

export default function Homepage(): React.ReactElement {
    const [createNavColorRef] = useNavColorFromIntersection();
    return (
        <>
            <NavColorResetTop ref={createNavColorRef()} />
            <HomepageCampaigns />
            <NavColorResetBottom ref={createNavColorRef()} />
            <Container>
                <HomeFontGroups />
                <HomeFontsInUse />
                <HomeBlogPosts />
            </Container>
        </>
    );
}
