import React from 'react';
import styled from 'styled-components';
import { useTransition, animated } from '@react-spring/web';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { HeadingExtraLarge } from './Heading';
import { Paragraph } from './Paragraph';
import { ZINDEX } from '../settings/Global';
import { Inner, Content } from './ProcessingElements';
import { TEST_ID } from '../settings/E2e';

const Container = styled(animated.div)`
    z-index: ${ZINDEX.PROCESSING_OVERLAY};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor);
    padding-top: var(--navbarHeight);
`;

function ProcessingOverlay({
    title,
    description,
    shown,
}: {
    title: React.ReactNode;
    description: React.ReactNode;
    shown: boolean;
}): React.ReactElement {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const transitions = useTransition(shown, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });

    React.useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        containerRef.current.focus();
    }, [containerRef]);

    return transitions((style, isShown): React.ReactElement | null =>
        isShown ? (
            <AlertDialog.Root open>
                <AlertDialog.Portal>
                    <AlertDialog.Overlay />
                    <AlertDialog.Content asChild>
                        {/* @ts-ignore TS2589: Type instantiation is excessively deep and possibly infinite. */}
                        <Container
                            style={style}
                            data-cy={TEST_ID.CHECKOUT_PROCESSING_OVERLAY}
                            ref={containerRef}
                        >
                            <Inner>
                                <Content>
                                    <HeadingExtraLarge>
                                        {title}
                                    </HeadingExtraLarge>
                                    <Paragraph>{description}</Paragraph>
                                </Content>
                            </Inner>
                        </Container>
                    </AlertDialog.Content>
                </AlertDialog.Portal>
            </AlertDialog.Root>
        ) : null,
    );
}

export default React.memo(ProcessingOverlay);
