import React from 'react';

import VideoFullBleed from './VideoFullBleed';
import type { TwoVideoMeta } from './VideoFullBleed';
import ImagesFullBleed from './ImagesFullBleed';
import type { TwoImageMeta } from './ImagesFullBleed';
import notUndefined from '../utils/notUndefined';
import shuffleArray from '../utils/shuffleArray';
import sampleArray from '../utils/sampleArray';
import type {
    CampaignImage,
    CampaignVideo,
    Campaign as CampaignType,
} from '../union-types/campaign';
import useLoaderBackgroundColor from '../hooks/useLoaderBackgroundColor';

function campaignImageToTwoImageMeta(image: CampaignImage): TwoImageMeta {
    return {
        colorLoaderBackground: image.colorLoaderBackground,
        colorLoaderForeground: image.colorLoaderForeground,
        colorBackground: image.colorBackground,
        landscape: {
            src: image.imageLandscape,
            width: image.imageLandscapeWidth,
            height: image.imageLandscapeHeight,
            layoutMode: image.layoutModeLandscape,
            marginOverlap: image.marginOverlapLandscape,
        },
        portrait: image.imagePortrait
            ? {
                  src: image.imagePortrait,
                  width: image.imagePortraitWidth || undefined,
                  height: image.imagePortraitHeight || undefined,
                  layoutMode: image.layoutModePortrait || undefined,
                  marginOverlap: image.marginOverlapPortrait || undefined,
              }
            : undefined,
    };
}

function campaignVideoToTwoVideoMeta(video: CampaignVideo): TwoVideoMeta {
    return {
        colorLoaderBackground: video.colorLoaderBackground,
        colorLoaderForeground: video.colorLoaderForeground,
        landscape: {
            video: video.videoLandscape,
            layoutMode: video.layoutModeLandscape,
            marginOverlap: video.marginOverlapLandscape,
        },
        portrait: video.videoPortrait
            ? {
                  video: video.videoPortrait,
                  layoutMode: video.layoutModePortrait,
                  marginOverlap: video.marginOverlapPortrait,
              }
            : undefined,
    };
}

function useCampaignMediaMeta(
    campaign: CampaignType,
): TwoImageMeta[] | TwoVideoMeta {
    if (campaign.type === 'VIDEO') {
        const video = sampleArray(campaign.videos as CampaignVideo[]);
        if (!video) {
            throw new Error(`No video on video campaign!`);
        }
        return campaignVideoToTwoVideoMeta(video);
    } else if (campaign.type === 'IMAGE') {
        const images =
            campaign.displayMode === 'RANDOM_SINGLE'
                ? [sampleArray(campaign.images as CampaignImage[])].filter(
                      notUndefined,
                  )
                : campaign.displayMode === 'CROSS_FADE'
                  ? campaign.images
                  : campaign.displayMode === 'CROSS_FADE_RANDOM'
                    ? shuffleArray(campaign.images as CampaignImage[])
                    : [];
        if (!images.length) {
            throw new Error(`No images on image campaign!`);
        }
        return images.map((img) => campaignImageToTwoImageMeta(img));
    }
    throw new Error(`Invalid campaign type ${campaign.type}!`);
}

function Campaign({
    campaign,
    linkUrl,
    linkText,
    lazyLoad,
    loaderBackgroundColor,
    adjustForBrowserChrome,
    withinPageMargins,
}: {
    campaign: CampaignType;
    linkUrl?: string;
    linkText?: string;
    lazyLoad?: boolean;
    loaderBackgroundColor?: string;
    adjustForBrowserChrome?: boolean;
    withinPageMargins?: boolean;
}): React.ReactElement {
    const mediaMeta = useCampaignMediaMeta(campaign);
    const themeLoadingBackgroundColor = useLoaderBackgroundColor();
    const loadingBackgroundColor =
        loaderBackgroundColor || themeLoadingBackgroundColor;

    switch (campaign.type) {
        case 'VIDEO':
            if (Array.isArray(mediaMeta)) {
                throw Error(`Can't pass media array to video campaign.`);
            }
            return (
                <VideoFullBleed
                    landscapeVideo={mediaMeta.landscape}
                    portraitVideo={mediaMeta.portrait}
                    url={linkUrl}
                    linkText={linkText}
                    colorBackground={loadingBackgroundColor}
                    lazyLoad={lazyLoad}
                    withinPageMargins={withinPageMargins}
                />
            );

        case 'IMAGE':
            if (!Array.isArray(mediaMeta)) {
                throw Error(`Need to pass media array to image campaign.`);
            }
            return (
                <ImagesFullBleed
                    images={mediaMeta}
                    linkUrl={linkUrl}
                    linkText={linkText}
                    transitionDuration={campaign.transitionDuration}
                    transitionInterval={campaign.transitionInterval}
                    colorBackground={loadingBackgroundColor}
                    lazyLoad={lazyLoad}
                    // Highest quality for campaign images
                    imageQuality={100}
                    adjustForBrowserChrome={adjustForBrowserChrome}
                    withinPageMargins={withinPageMargins}
                />
            );
    }
    throw Error(`Invalid campaign type ${campaign.type}`);
}

export default React.memo(Campaign);
