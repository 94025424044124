// We're only importing the `react-imgix` types here! The actual react-imgix package is overkill...
import type { SharedImgixAndSourceProps } from 'react-imgix';
import notUndefined from './notUndefined';
import type { WindowLocation } from '@reach/router';
import getSearchVariableFromLocation from './getSearchVariableFromLocation';
import getFontFamilyGroupSlugWithCollection from './getFontFamilyGroupSlugWithCollection';
import type { InUseFilter } from '../components/PageContext';
import {
    CMS_SSR_LicenceTypeId,
    CMS_SSR_LicenceTypeShortName,
} from '../gql/api-ssr';
import type { LicenceTypeId } from '../gql/api-public';
import objectToUrlSearchParams from './objectToUrlSearchParams';
import { IN_USE_ALL_CATEGORIES_TAG_SLUG } from '../settings/Global';

/**
 * WARNING! All URLs need to have trailing slashes. This is a requirement for AWS S3 hosting.
 */

/* ============================================================================
 * MISC PAGES
 * ========================================================================== */

export const createHomeUrl = (): string => '/';

export const createPreviewHomeUrl = (): string => '/home/__preview/';

export const create404NotFoundUrl = (): string => '/404/';

export const createStyleGuideUrl = (): string => '/style-guide/';

export const createSubscribeUrl = (): string => '/subscribe/';

export const createSubscribeSuccessUrl = (): string => '/subscribe/success/';

export const createPrivacyPolicyUrl = (): string => '/privacy-policy/';

export const createRefundPaymentPolicyUrl = (): string =>
    '/payments-and-refunds/';

export const createAboutUrl = (): string => '/about/';

export const createContactUrl = (): string => '/contact/';

export const createSupportUrl = (): string => '/support/';

export const createFaqUrl = (): string => '/faqs/';

export const createTestFontMetricsUrl = (): string => '/test-font-metrics/';
export const createTestFontsUrl = (): string => '/test-fonts/';

export const createPreReleaseTestFontsUrl = (id: string): string => {
    // The `test-fonts` URI must match the one defined in back end code...
    return `${process.env.GATSBY_APP_URL}/test-fonts/${id}/`;
};

export const createAwardsAndAccoladesUrl = (): string =>
    `/awards-and-accolades/`;

export const createTopFragmentUri = (): string => '#top';

/* ============================================================================
 * BLOG + FONTS IN USE
 * ========================================================================== */

export const createFontsInUseUrl = (args?: InUseFilter): string => {
    const root = '/in-use';
    let url = root;

    if (args) {
        const { category = IN_USE_ALL_CATEGORIES_TAG_SLUG, fontFamilyGroup } =
            args;
        const fontFamilyGroupSlug = fontFamilyGroup
            ? getFontFamilyGroupSlugWithCollection(fontFamilyGroup)
            : undefined;
        url = [root, category, fontFamilyGroupSlug]
            .filter(notUndefined)
            .join('/');
    }

    return `${url}/`;
};

export const createFontsInUsePostUrl = (slug: string): string =>
    `${createFontsInUseUrl()}${slug}/`;

export const createPreviewInUsePostUrl = (): string =>
    createFontsInUsePostUrl('__preview');

export const createBlogIndexUrl = (tag?: string): string => {
    const root = '/blog';
    const url = [root, tag ? `tag/${tag}` : undefined]
        .filter(notUndefined)
        .join('/');

    return `${url}/`;
};

export const createBlogPostUrl = (slug: string): string => {
    const root = '/blog';
    const url = [root, slug].join('/');

    return `${url}/`;
};

export const createPreviewBlogPostUrl = (): string =>
    createBlogPostUrl('__preview');

/* ============================================================================
 * MISC BLOG TAGS + PAGES
 * ========================================================================== */

export const createFontDesignInfoUrl = (): string =>
    `/blog/tag/font-design-information/`;

export const createInterviewUrl = (): string => `/blog/tag/interviews/`;

/* ============================================================================
 * FONTS PAGES
 * ========================================================================== */

export const createCollectionUrl = (slug: string): string =>
    `/collections/${slug}/`;

export const createFontsMenuUrl = (): string => '/retail-fonts/';

export const createFontFamilyUrl = (slug: string): string =>
    `${createFontsMenuUrl()}${slug}/`;

export const createCustomFontsMenuUrl = (): string => '/custom-fonts/';

export const createCustomFontUrl = (slug: string): string =>
    `${createCustomFontsMenuUrl()}${slug}/`;

/* ============================================================================
 * LICENSING PAGES
 * ========================================================================== */

export const createEulaOverviewUrl = (): string => `/licences/`;

export const createEulaUrl = (
    licenceTypeId: LicenceTypeId | CMS_SSR_LicenceTypeId,
    hash?: string,
): string => {
    let urlPart;
    // Use TS enum for type safety
    switch (licenceTypeId) {
        case CMS_SSR_LicenceTypeId.DESKTOP:
            urlPart = CMS_SSR_LicenceTypeShortName.Desktop;
            break;
        case CMS_SSR_LicenceTypeId.WEB:
            urlPart = CMS_SSR_LicenceTypeShortName.Web;
            break;
        case CMS_SSR_LicenceTypeId.APP:
            urlPart = CMS_SSR_LicenceTypeShortName.App;
            break;
        case CMS_SSR_LicenceTypeId.TEST:
            urlPart = CMS_SSR_LicenceTypeShortName.Test;
            break;
        case CMS_SSR_LicenceTypeId.BROADCAST:
            urlPart = CMS_SSR_LicenceTypeShortName.Broadcasting;
            break;
        case CMS_SSR_LicenceTypeId.OEM:
            urlPart = CMS_SSR_LicenceTypeShortName.OEM;
            break;
        case CMS_SSR_LicenceTypeId.AD:
            urlPart = CMS_SSR_LicenceTypeShortName.Advertising;
            break;
        // case CMS_SSR_LicenceTypeId.UDLA:
        //     urlPart = CMS_SSR_LicenceTypeShortName.Enterprise;
        //     break;
        default:
            throw Error(`Invalid licenceTypeId: ${licenceTypeId}`);
    }
    return `/licences/${urlPart.toLowerCase()}-fonts/${hash || ''}`;
};

/* ============================================================================
 * E-COMMERCE PAGES
 * ========================================================================== */

export const createBuyUrl = (slug: string): string => `/buy/${slug}/`;

export const createCheckoutUrl = (): string => `/checkout/`;

export const createCheckoutSuccessUrl = (): string => `/checkout/success/`;

export const createLegacyInvoiceCheckoutUrl = (invoiceId?: string): string => {
    const url = `/checkout-invoice/`;
    return invoiceId ? `${url}?id=${encodeURI(invoiceId)}` : url;
};

export const createInvoiceCheckoutUrl = (invoiceNumber?: string): string => {
    const url = `/pay/`;
    return invoiceNumber ? `${url}#${encodeURI(invoiceNumber)}` : url;
};

export const createSharedCartBaseUrl = (): string => {
    return `/shared-cart/`;
};

export const createSharedCartUrl = (sharedCartId: string): string => {
    return `${createStaticSiteOrigin()}${createSharedCartBaseUrl()}#${encodeURI(
        sharedCartId,
    )}`;
};

export const createCartQuoteDownloadUrl = (): string => {
    return `${process.env.GATSBY_APP_URL}/download-quote/`;
};

export const createAccountUrl = (params?: { next?: string }): string => {
    return `/account/${params ? `?${objectToUrlSearchParams(params)}` : ''}`;
};

export const createAccountVerifiedUrl = (): string => {
    return `${createAccountUrl()}verified/`;
};

// If you're looking for the login *page* then use 'createAccountUrl'.
export const createLoginUrl = (): string => '/login/';

export const createLogoutUrl = (params?: { next?: string }): string => {
    return `/logout/${params ? `?${objectToUrlSearchParams(params)}` : ''}`;
};

export const createRevertLoginUrl = (): string => {
    return '/revert-login/';
};

export function createNextUrlFromLocation(
    location: WindowLocation,
): string | null {
    const next = getSearchVariableFromLocation(location, 'next');
    if (typeof next !== 'string') return null;
    return next;
}

/* ============================================================================
 * E-MAIL ADDRESSES
 * ========================================================================== */

const createEmailUrl = (email: string, mailto = false): string => {
    return mailto ? `mailto:${email}` : email;
};

export const createSalesEmailUrl = (mailto = false): string => {
    return createEmailUrl('sales@klim.co.nz', mailto);
};

export const createSupportEmailUrl = (mailto = false): string => {
    return createEmailUrl('support@klim.co.nz', mailto);
};

export const createCommercialEmailUrl = (mailto = false): string => {
    return createEmailUrl('commercial@klim.co.nz', mailto);
};

export const createMediaEmailUrl = (mailto = false): string => {
    return createEmailUrl('media@klim.co.nz', mailto);
};

export const createKlimInUseEmailUrl = (mailto = false): string => {
    return createEmailUrl('inuse@klim.co.nz', mailto);
};

/* ============================================================================
 * External websites
 * ========================================================================== */

export const createFacebookUrl = (): string =>
    `https://www.facebook.com/klimtypefoundry/`;

export const createTumblrlUrl = (): string =>
    `https://klim-type-foundry.tumblr.com/`;

export const createInstagramUrl = (): string =>
    `https://www.instagram.com/klim_type_foundry/`;

export const createPinterestUrl = (): string =>
    `https://www.pinterest.nz/klimtype/`;

export const createTwitterUrl = (): string =>
    `https://twitter.com/klimtypefoundry`;

export const createVimeoUrl = (): string => `https://vimeo.com/user20273759`;

export const createFlickrUrl = (): string =>
    `https://www.flickr.com/photos/20994543@N04/`;

export const createStripeUrl = (): string => `https://stripe.com`;

export const createCampaignMonitorUrl = (): string =>
    `https://www.campaignmonitor.com/`;

export const createCampaignMonitorPrivacyPolicyUrl = (): string =>
    `https://www.campaignmonitor.com/policies/#privacy-policy`;

export const createGoogleAnalyticsUrl = (): string =>
    `https://www.google.com/analytics/`;

export const createGooglePrivacyPolicyUrl = (): string =>
    `https://policies.google.com/privacy`;

export const createPrivacyActUrl = (): string =>
    `https://www.privacy.org.nz/the-privacy-act-and-codes/privacy-act-and-codes-introduction/`;

/* ============================================================================
 * ASSETS
 * ========================================================================== */

const leadingSlash = /^\//;

export const createStaticSiteOrigin = (): string =>
    `${process.env.GATSBY_STATIC_SITE_PROTOCOL}://${process.env.GATSBY_STATIC_SITE_DOMAIN}`;

export const createStaticAssetOrigin = (): string =>
    `${process.env.GATSBY_STATIC_ASSET_PROTOCOL}${process.env.GATSBY_STATIC_ASSET_DOMAIN}`;

export const createStaticAssetUrl = (
    path: string,
    origin = createStaticAssetOrigin(),
): string => {
    return [origin, 'media', path.replace(leadingSlash, '')].join('/');
};

export const createImgixOrigin = (): string =>
    `${process.env.GATSBY_STATIC_ASSET_PROTOCOL}${process.env.GATSBY_IMGIX_DOMAIN}`;

export const createImgixUrl = (
    { src, imgixParams }: SharedImgixAndSourceProps,
    allowGifResizing = false,
): string => {
    const params = imgixParams || {};

    // GIFs with animation don't support format=auto or image resizing (maybe on Premium Imgix?)
    if (!allowGifResizing && src.toLowerCase().endsWith('.gif')) {
        delete params.auto;
        delete params.w;
        delete params.fit;
        delete params.q;
        delete params.cs;
    }

    const url = new URL(src, createImgixOrigin());
    if (params) {
        Object.entries(params).forEach((value) => {
            if (value[1] === undefined || value[1] === null) {
                return;
            }
            url.searchParams.append(...value);
        });
    }
    return url.href;
};

interface VideoOptions {
    autoPlay?: boolean;
    looping?: boolean;
    hideControls?: boolean;
    hideChrome?: boolean;
    muted?: boolean;
    background?: boolean;
}

function applyVideoOptions(url: string, options?: VideoOptions): string {
    const newUrl = new URL(url);

    const lowercaseUrl = url.toLowerCase();

    if (lowercaseUrl.includes('vimeo')) {
        // Remove quirky error messages in the player (e.g. Oops, Fiddlesticks)
        newUrl.searchParams.append('fun', '0');
        // Show Vimeo UI in white (instead of Vimeo blue)
        newUrl.searchParams.append('color', 'ffffff');
        if (options?.background) {
            newUrl.searchParams.append('background', '1');
        }
        if (options?.autoPlay) {
            newUrl.searchParams.append('autoplay', '1');
            newUrl.searchParams.append('autopause', '0');
        }
        if (options?.looping) {
            newUrl.searchParams.append('loop', '1');
        }
        if (options?.muted) {
            newUrl.searchParams.append('muted', '1');
        }
        if (options?.hideControls) {
            newUrl.searchParams.append('controls', '0');
        }
        if (options?.hideControls || options?.hideChrome) {
            newUrl.searchParams.append('title', '0');
            newUrl.searchParams.append('byline', '0');
            newUrl.searchParams.append('sidedock', '0');
        }
    } else if (lowercaseUrl.includes('youtube')) {
        newUrl.searchParams.append('modestbranding', '1');
        // Disable youtube autoplay as we have no control over muted sound
        if (options?.autoPlay) {
            newUrl.searchParams.append('autoplay', '1');
        }
        if (options?.looping) {
            newUrl.searchParams.append('loop', '1');
        }
        if (options?.hideControls) {
            newUrl.searchParams.append('controls', '0');
        }
    }

    return newUrl.toString();
}

export const createEmbedVideoUrl = (
    url: string,
    options?: VideoOptions,
): string => {
    const lowercaseUrl = url.toLowerCase();
    if (lowercaseUrl.includes('vimeo')) {
        if (lowercaseUrl.includes('player.vimeo.com')) {
            return applyVideoOptions(url, options);
        }
        const videoId = url.match(/[0-9]{3,}/);
        if (!videoId) return url;
        return applyVideoOptions(
            `https://player.vimeo.com/video/${videoId}`,
            options,
        );
    } else if (
        lowercaseUrl.includes('youtube') ||
        lowercaseUrl.includes('youtu.be')
    ) {
        const youtubeUrl = new URL(url);
        // use YouTube's nocookie domain for greater privacy
        youtubeUrl.host = 'www.youtube-nocookie.com';

        if (lowercaseUrl.includes('/embed/')) {
            return applyVideoOptions(youtubeUrl.toString(), options);
        }

        const videoId = lowercaseUrl.includes('youtu.be')
            ? youtubeUrl.pathname.split('/', 2).pop()
            : youtubeUrl.searchParams.get('v');
        youtubeUrl.searchParams.delete('v');

        if (!videoId) {
            // Just returning the original url as-is
            // because we don't recognise it
            return url;
        }
        youtubeUrl.pathname = `/embed/${videoId}`;

        return applyVideoOptions(youtubeUrl.toString(), options);
    }
    return url;
};
