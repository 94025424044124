import React from 'react';
import { useFontFamilyGroup } from './PageContext';
import Credits, { AwardFragment } from './Credits';
import notNull from '../utils/notNull';

function FontCollectionCredits(): React.ReactElement {
    const { fontFamilies, awards } = useFontFamilyGroup();

    const releases = fontFamilies
        .map(({ release }): string | null => release && release.trim())
        .filter(notNull);

    const designers = fontFamilies.reduce(
        (carry: string[], fontFamily): string[] => [
            ...carry,
            ...fontFamily.creditDesign,
        ],
        [],
    );

    const engineers = fontFamilies.reduce(
        (carry: string[], fontFamily): string[] => [
            ...carry,
            ...fontFamily.creditEngineering,
        ],
        [],
    );

    const collaborators = fontFamilies.reduce(
        (carry: string[], fontFamily): string[] => [
            ...carry,
            ...fontFamily.creditCollaborators,
        ],
        [],
    );

    const classifications = fontFamilies.reduce(
        (carry: string[], fontFamily): string[] => [
            ...carry,
            ...fontFamily.classification,
        ],
        [],
    );

    const familyAwards = fontFamilies.reduce(
        (carry: AwardFragment[], fontFamily): AwardFragment[] => [
            ...carry,
            ...fontFamily.awards,
        ],
        [],
    );

    return (
        <Credits
            releases={releases}
            designers={designers}
            engineers={engineers}
            collaborators={collaborators}
            classifications={classifications}
            awards={[...awards, ...familyAwards]}
        />
    );
}

export default React.memo(FontCollectionCredits);
