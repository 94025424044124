import useConfig from './useConfig';
import { useLocation } from '@reach/router';

export default function useIsUserTestingSite(): boolean {
    const config = useConfig();
    const location = useLocation();

    return (
        config.userTestingHostname !== null &&
        config.userTestingHostname === location.hostname
    );
}
