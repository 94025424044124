import type { LicenceType } from '../hooks/useActiveLicenceTypes';
import { LicenceTypeId } from '../gql/api-public';

/**
 * Returns the appropriate option for the licence type.
 * @param licenceType
 * @param desktop
 * @param web
 * @param app
 * @param udla
 * @param broadcast
 * @param oem
 * @param advertising
 */
export default function getOptionForLicenceType<T>(
    licenceType: LicenceType,
    {
        desktop,
        web,
        app,
        udla,
        broadcast,
        oem,
        advertising,
    }: {
        desktop?: T;
        web?: T;
        app?: T;
        udla?: T;
        broadcast?: T;
        oem?: T;
        advertising?: T;
    },
): T {
    switch (licenceType.id) {
        case LicenceTypeId.DESKTOP:
            if (typeof desktop === 'undefined') {
                throw Error(`Desktop option is undefined`);
            }
            return desktop;
        case LicenceTypeId.WEB:
            if (typeof web === 'undefined') {
                throw Error(`Web option is undefined`);
            }
            return web;
        case LicenceTypeId.APP:
            if (typeof app === 'undefined') {
                throw Error(`App option is undefined`);
            }
            return app;
        case LicenceTypeId.UDLA:
            if (typeof udla === 'undefined') {
                throw Error(`UDLA option is undefined`);
            }
            return udla;
        case LicenceTypeId.BROADCAST:
            if (typeof broadcast === 'undefined') {
                throw Error(`Broadcast option is undefined`);
            }
            return broadcast;
        case LicenceTypeId.OEM:
            if (typeof oem === 'undefined') {
                throw Error(`OEM option is undefined`);
            }
            return oem;
        case LicenceTypeId.AD:
            if (typeof advertising === 'undefined') {
                throw Error(`Advertising option is undefined`);
            }
            return advertising;
    }
    throw Error(`Invalid licence type: ${licenceType.id}`);
}
