import React from 'react';
import { useTransition, animated, easings } from '@react-spring/web';
import styled from 'styled-components';
import ImageFullBleed, { ImageMeta } from './ImageFullBleed';
import LinkedImageFullBleed from './LinkedImageFullBleed';

export type TwoImageMeta = {
    colorLoaderForeground: string | null;
    colorLoaderBackground: string | null;
    colorBackground: string | null | undefined;
    landscape: ImageMeta;
    portrait?: ImageMeta;
};

interface TwoImageMetaWithLoadStatus extends TwoImageMeta {
    loaded?: boolean;
    onLoadCallback?: () => void;
}

const SlideContainer = styled.div<{
    $colorBackground?: string | null;
}>`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${({ $colorBackground }): string =>
        $colorBackground ? $colorBackground : 'transparent'};
`;

const SlideWrap = styled(animated.div)`
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%; /* ensure Windows doesn't show horizontal scrollbar */
`;

/**
 * Component to render one or multiple images.
 */
function ImagesFullBleed({
    images,
    linkText,
    linkUrl,
    transitionDuration,
    transitionInterval,
    colorBackground,
    lazyLoad,
    imageQuality,
    adjustForBrowserChrome,
    withinPageMargins,
}: {
    images: TwoImageMeta[];
    transitionDuration: number;
    transitionInterval: number;
    linkText?: string;
    linkUrl?: string;
    colorBackground?: string | null;
    lazyLoad?: boolean;
    imageQuality?: number;
    adjustForBrowserChrome?: boolean;
    withinPageMargins?: boolean;
}): React.ReactElement {
    if (!images.length) {
        throw Error(`Component didn't receive images.`);
    }
    const imagesWithLoadStatus: TwoImageMetaWithLoadStatus[] =
        React.useMemo(() => {
            return images.map((image) => {
                return {
                    ...image,
                    loaded: false,
                    onLoadCallback: undefined,
                };
            });
        }, [images]);
    const [isUnMounted, setIsUnMounted] = React.useState(false);
    const [slideIndex, setSlideIndex] = React.useState(0);
    const loadCheckIntervalRef = React.useRef<number>();
    const [showLoading, setShowLoading] = React.useState(true);
    const [hasAnimated, setHasAnimated] = React.useState(false);

    React.useEffect(() => {
        return (): void => {
            setIsUnMounted(true);
            window.clearInterval(loadCheckIntervalRef.current);
        };
    }, []);

    const transitions = useTransition(imagesWithLoadStatus[slideIndex], {
        keys: (img) => `${img.landscape.src}${img.portrait?.src}`,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        /* We check `hasAnimated` as we want to skip the initial fade-in (and delay thereof). */
        immediate: !hasAnimated,
        delay: hasAnimated ? transitionInterval : 0,
        config: {
            duration: transitionDuration,
            easing: easings.easeInOutCubic,
        },
        onStart:
            imagesWithLoadStatus.length > 1
                ? (_result, ctrl, image): void => {
                      // Pause animation until the image has loaded...
                      if (!image.loaded) {
                          ctrl.pause();
                          loadCheckIntervalRef.current = window.setInterval(
                              () => {
                                  if (image.loaded) {
                                      ctrl.resume();
                                      window.clearInterval(
                                          loadCheckIntervalRef.current,
                                      );
                                  }
                              },
                              250,
                          );
                      }
                  }
                : undefined,
        onRest:
            imagesWithLoadStatus.length > 1
                ? (): void => {
                      if (isUnMounted) {
                          return;
                      }
                      if (!hasAnimated) {
                          setHasAnimated(true);
                      }
                      // Change slides
                      setSlideIndex(
                          (slideIndex + 1) % imagesWithLoadStatus.length,
                      );
                  }
                : undefined,
    });

    const imgOnLoadCallback = (image: TwoImageMetaWithLoadStatus): void => {
        if (isUnMounted) {
            return;
        }
        // Hide progress bar
        if (showLoading) {
            setShowLoading(false);
        }
        // This will allow the image to be displayed in the slideshow.
        image.loaded = true;
    };

    const doPreload = !lazyLoad && imagesWithLoadStatus.length > 1;

    return (
        <SlideContainer
            $colorBackground={showLoading ? colorBackground : undefined}
        >
            {/* Preload images (before they are animated). When lazy loading this has no effect, so don't bother */}
            {doPreload
                ? imagesWithLoadStatus.map((image, index) => {
                      if (image.loaded) {
                          return null;
                      }
                      return (
                          <ImageFullBleed
                              key={`prel${image.landscape.src}${image.portrait?.src}`}
                              landscapeImage={image.landscape}
                              portraitImage={image.portrait}
                              preload={index === 0}
                              hidden
                              onLoadCallback={(): void =>
                                  imgOnLoadCallback(image)
                              }
                              fetchPriority={index === 0 ? 'high' : 'low'}
                          />
                      );
                  })
                : null}
            {transitions((renderProps, image, _transition, index) => {
                // For lazy loaded images we will not preload, so we need a load callback here. Also, when the image is
                // not loaded, we add a callback to ensure that when the page colour scheme changes, and the component
                // re-renders, we re-trigger the onLoad.
                const onLoadCallback =
                    !doPreload || !image.loaded
                        ? (): void => imgOnLoadCallback(image)
                        : undefined;
                return (
                    /* @ts-ignore TS2589: Type instantiation is excessively deep and possibly infinite. */
                    <SlideWrap
                        style={renderProps}
                        key={image.landscape.src + image.portrait?.src}
                    >
                        {linkUrl ? (
                            <LinkedImageFullBleed
                                landscapeImage={image.landscape}
                                portraitImage={image.portrait}
                                title={linkText}
                                url={linkUrl}
                                lazyLoad={lazyLoad}
                                onLoadCallback={onLoadCallback}
                                imageQuality={imageQuality}
                                fetchPriority={
                                    !lazyLoad && index === 0 ? 'high' : 'low'
                                }
                                adjustForBrowserChrome={adjustForBrowserChrome}
                                withinPageMargins={withinPageMargins}
                            />
                        ) : (
                            <ImageFullBleed
                                landscapeImage={image.landscape}
                                portraitImage={image.portrait}
                                lazyLoad={lazyLoad}
                                onLoadCallback={onLoadCallback}
                                imageQuality={imageQuality}
                                fetchPriority={
                                    !lazyLoad && index === 0 ? 'high' : 'low'
                                }
                                adjustForBrowserChrome={adjustForBrowserChrome}
                                withinPageMargins={withinPageMargins}
                            />
                        )}
                    </SlideWrap>
                );
            })}
        </SlideContainer>
    );
}

export default React.memo(ImagesFullBleed);
